import { ServiceIdentifier } from '../service-locator';
import { ErrorService } from './interfaces';

export * from './error';
export * from './interfaces';

/**
 * The service locator identifier for the ErrorService interface.
 */
export const ERROR_SERVICE = ServiceIdentifier.get<ErrorService>('ErrorService');
