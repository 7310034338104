/* eslint-disable @typescript-eslint/ban-types */

export type Keys<T> = (keyof T)[];

export type Values<T> = (T[keyof T])[];

export type Entries<T> = { [K in keyof T]: [K, T[K]] }[keyof T][];

export const keys = <T extends {}> (o: T): (keyof T)[] => Object.keys(o) as Keys<T>;

export const values = <T extends {}> (o: T): (T[keyof T])[] => Object.values(o) as Values<T>;

export const entries = <T extends {}> (o: T): { [K in keyof T]: [K, T[K]] }[keyof T][] => Object.entries(o) as Entries<T>;

export const hasOwn = <T extends {}> (o: T, k: keyof T): boolean => Object.hasOwn(o, k);

export const TypedObject = {
    keys,
    values,
    entries,
    hasOwn,
};
