import { PanelContainerElement, PanelDirection, PanelNavigationEvent } from '@swivel-finance/ui/elements/panel-container';
import { dispatch } from '@swivel-finance/ui/utils/events';
import { html, LitElement, nothing, PropertyValues } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { createRef, ref } from 'lit/directives/ref.js';
import { trim } from '../../core/amount';
import { ROLLOVER_TIME } from '../../core/constants';
import { previewBurn } from '../../core/pools';
import { iPT, lpT } from '../../core/services/token';
import { DATE_FORMAT, formatDate } from '../../shared/helpers';
import { maturityBar, rate, tokenBalance, tokenSymbol } from '../../shared/templates';
import { orchestrator, POSITION } from '../../state/orchestrator';
import { PoolPosition } from '../../types';
import { PositionEvent } from './events';
import { poolPositionActions, poolPositionStatus, poolPositionTooltips } from './helpers';
import { PoolPositionTooltips, PositionStatus, POSITION_MODES, POSITION_TYPES } from './types';

const detailsTemplate = function (
    this: PositionDetailsPoolElement,
    position: PoolPosition,
    status: PositionStatus,
    tooltips: PoolPositionTooltips,
): unknown {

    const pool = position.pool;
    const info = position.info;
    const token = position.pool.token;

    if (!pool || !token) return nothing;

    const [underlyingValue, iptValue] = previewBurn(
        info.lpBalance.balance,
        info.yieldSpaceInfo.fyTokenBalance,
        info.yieldSpaceInfo.sharesBalance,
        info.yieldSpaceInfo.totalSupply,
        info.yieldSpaceInfo.sharesPrice,
    ).map(amount => trim(amount.floor().toString()));

    const ipt = iPT(pool);
    const lpt = lpT(pool);

    return html`
    <ul class="position-details">

    ${ position.pools.length
        ? html`
        <button class="show-position-history" @click=${ () => this.showHistory() }>
            <ui-icon name="history"></ui-icon>
            History
        </button>
        `
        : nothing
    }

    ${ !status.isDivested && !status.isExited
        ? html`
        <li>
            <span class="label" aria-describedby="${ tooltips.share.id }">
                Share
                <ui-icon name="question"></ui-icon>
            </span>
            <ui-tooltip id="${ tooltips.share.id }">
                ${ tooltips.share.content }
            </ui-tooltip>
            <span class="value">${ rate(info.lpShare, '%', false) }</span>
            <span class="value">of ${ tokenBalance(info.yieldSpaceInfo.totalSupply, lpt) }</span>
        </li>
        `
        : nothing
    }

    ${ !status.isRedeemed
        ? html`
        <li>
            <span class="label" aria-describedby="${ tooltips.positionValue.id }">
                Position Value
                <ui-icon name="question"></ui-icon>
            </span>
            <ui-tooltip id="${ tooltips.positionValue.id }">
                ${ tooltips.positionValue.content }
            </ui-tooltip>
            ${ !status.isDivested
                ? html`
                <span class="value">${ tokenBalance(underlyingValue, token) }</span>
                <span class="value">${ tokenBalance(iptValue, ipt) }</span>
                `
                : html`
                <span class="value">${ tokenBalance(position.currentPositionValue, token) }</span>
                `
            }
        </li>
        `
        : nothing
    }

    <li>
        <span class="label" aria-describedby="${ tooltips.currentRate.id }">
            ${ !status.isDivested && !status.isExited ? 'Current' : 'Average' } Rate
            <ui-icon name="question"></ui-icon>
        </span>
        <ui-tooltip id="${ tooltips.currentRate.id }">
            ${ tooltips.currentRate.content }
        </ui-tooltip>
        <span class="value">${ rate(pool.apr, '% APR') }</span>
        ${ !status.isDivested && !status.isExited
            ? html`<ill-share-rate .rate=${ pool.apr } .underlying=${ token.symbol } .type=${ 'pool' }></ill-share-rate>`
            : nothing
        }
    </li>

    </ul>
    `;
};

const historyTemplate = function (this: PositionDetailsPoolElement, position: PoolPosition): unknown {

    const historyPageCurrent = this.historyContainer.value?.current ?? this.historyPageMax;

    const isLastPage = historyPageCurrent === this.historyPageMax;

    return position.pools.length
        ? html`
        ${ this.historyPages.length > 1
            ? html`
            <button class="position-history-previous" aria-label="previous pools" .disabled=${ historyPageCurrent === 0 } @click=${ () => this.historyBackwards() }>
                <ui-icon name="chevron"></ui-icon>
            </button>
            `
            : nothing
        }
        <ui-panel-container class="position-history-container" .current=${ historyPageCurrent } ${ ref(this.historyContainer) }>
            <div data-part="panels">
                ${ this.historyPages.map((page, index) => html`
                <div data-part="panel">
                    <ul class="position-history">
                        ${ index > 0
                            ? html`<ui-icon name="rollover"></ui-icon>`
                            : nothing
                        }
                        ${ page.map(entry => html`
                        <li class="${ entry.class ?? '' }">
                            <span class="label">${ entry.label }</span>
                            <span class="value">${ entry.value }</span>
                        </li>
                        ${ entry.historic
                            ? html`<ui-icon name="rollover"></ui-icon>`
                            : nothing
                        }
                        `) }
                    </ul>
                </div>
                `) }
            </div>
        </ui-panel-container>
        <button class="show-position-details${ !isLastPage ? ' position-history-next' : '' }" @click=${ () => isLastPage ? this.showDetails() : this.historyForwards() }>
            <span class="label">Current</span>
            <ui-icon name="chevron"></ui-icon>
        </button>
        `
        : nothing;
};

const template = function (this: PositionDetailsPoolElement): unknown {

    const position = this.position;
    const positionState = this.positionMachine.state;

    if (!position || !positionState.matches(POSITION.STATES.SUCCESS)) return nothing;

    const pool = position.pool;
    const token = position.pool.token;

    if (!pool || !token) return nothing;

    const status = poolPositionStatus(this.positionMachine.state.context, position);
    const tooltips = poolPositionTooltips(position);

    const rollover = (parseInt(pool.maturity) + ROLLOVER_TIME) * 1000;

    return html`

    ${ maturityBar(pool, token, 'long') }

    ${ status.isMatured && !status.isRedeemed
        ? html`
        <span class="rollover-notice">
            <ui-icon name="rollover"></ui-icon>
            Rolling over into a new ${ tokenSymbol(token) } pool on ${ formatDate(rollover, DATE_FORMAT.MEDIUM) }.
        </span>
        `
        : nothing
    }

    <ui-panel-container .current=${ 1 } ${ ref(this.viewContainer) }>
        <div data-part="panels">
            <div data-part="panel">
                ${ historyTemplate.call(this, position) }
            </div>
            <div data-part="panel">
                ${ detailsTemplate.call(this, position, status, tooltips) }
                ${ poolPositionActions(position, status, tooltips, this.addLiquidity.bind(this), this.removeLiquidity.bind(this)) }
            </div>
        </div>
    </ui-panel-container>
    `;
};

interface HistoryEntry {
    label: unknown;
    value: unknown;
    historic: boolean;
    class?: string;
}

@customElement('ill-position-details-pool')
export class PositionDetailsPoolElement extends LitElement {

    protected positionMachine = orchestrator.position;

    protected viewContainer = createRef<PanelContainerElement>();

    protected historyContainer = createRef<PanelContainerElement>();

    protected historyPages: HistoryEntry[][] = [];

    protected historyPageMax = 0;

    @property()
    isLenderPaused = false;

    @property()
    position?: PoolPosition;

    @property()
    historyEntriesPerPage = 3;

    public connectedCallback (): void {

        super.connectedCallback();

        this.handlePanelChanged = this.handlePanelChanged.bind(this);

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.addEventListener('ui-panel-changed', this.handlePanelChanged);
    }

    public disconnectedCallback (): void {

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.removeEventListener('ui-panel-changed', this.handlePanelChanged);

        super.disconnectedCallback();
    }

    protected update (changedProperties: PropertyValues<PositionDetailsPoolElement>): void {

        // update the history if the position was changed
        if (changedProperties.has('position')) {

            this.createHistory(this.position);
        }

        super.update(changedProperties);
    }

    protected createRenderRoot (): Element | ShadowRoot {

        return this;
    }

    protected render (): unknown {

        return template.apply(this);
    }

    protected showDetails (): void {

        const container = this.viewContainer.value;

        if (!container) return;

        dispatch(container, new PanelNavigationEvent({
            target: container,
            panel: PanelDirection.NEXT,
        }));
    }

    protected showHistory (): void {

        const container = this.viewContainer.value;

        if (!container) return;

        dispatch(container, new PanelNavigationEvent({
            target: container,
            panel: PanelDirection.PREVIOUS,
        }));
    }

    protected historyBackwards (): void {

        const container = this.historyContainer.value;

        if (!container) return;

        dispatch(container, new PanelNavigationEvent({
            target: container,
            panel: PanelDirection.PREVIOUS,
        }));
    }

    protected historyForwards (): void {

        const container = this.historyContainer.value;

        if (!container) return;

        dispatch(container, new PanelNavigationEvent({
            target: container,
            panel: PanelDirection.NEXT,
        }));
    }

    protected handlePanelChanged (): void {

        this.requestUpdate();
    }

    protected addLiquidity (position: PoolPosition): void {

        dispatch(this, new PositionEvent({ type: POSITION_TYPES.POOL, mode: POSITION_MODES.ENTER, position }));
    }

    protected removeLiquidity (position: PoolPosition): void {

        dispatch(this, new PositionEvent({ type: POSITION_TYPES.POOL, mode: POSITION_MODES.EXIT, position }));
    }

    protected createHistory (position?: PoolPosition): void {

        const averageAPR = position && position.pools.length > 0
            ? position.pools.reduce((apr, pool) => apr + pool.apr, 0) / position.pools.length
            : 0;

        const pages: HistoryEntry[][] = [];

        const entries: HistoryEntry[] = position
            ? [
                {
                    label: 'Average Rate',
                    value: rate(averageAPR, '% APR', false),
                    class: 'rate-summary',
                    historic: false,
                },
                {
                    label: formatDate(position.pool.maturity, DATE_FORMAT.MEDIUM),
                    value: rate(position.pool.apr, '% APR', true),
                    class: 'pool-current',
                    historic: false,
                },
                ...position.pools.map(pool => ({
                    label: formatDate(pool.maturity, DATE_FORMAT.MEDIUM),
                    value: rate(pool.apr, '% APR', false),
                    class: 'pool-previous',
                    historic: true,
                })),
            ]
            : [];

        const pageMax = Math.ceil(entries.length / this.historyEntriesPerPage) - 1;
        const itemMax = this.historyEntriesPerPage - 1;

        let page = pageMax;
        let item = itemMax;

        entries.forEach(entry => {

            if (!pages[page]) pages[page] = [];

            pages[page][item] = entry;

            item--;

            if (item < 0) {

                item = itemMax;
                page--;
            }
        });

        this.historyPages = pages;
        this.historyPageMax = pageMax;
    }
}
