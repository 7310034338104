import { LendPosition, PoolPosition } from '../../types';
import { POSITION_MODES, POSITION_TYPES } from './types';

export type PositionEventDetail = {
    type: POSITION_TYPES.LEND;
    mode: POSITION_MODES;
    position: LendPosition;
} | {
    type: POSITION_TYPES.POOL;
    mode: POSITION_MODES;
    position: PoolPosition;
};

export class PositionEvent extends CustomEvent<PositionEventDetail> {

    type!: 'ill-position';

    constructor (detail: PositionEventDetail) {

        super('ill-position', {
            bubbles: true,
            cancelable: true,
            composed: true,
            detail,
        });
    }
}

/**
 * Add the event to the global HTMLElementEventMap.
 */
declare global {
    interface HTMLElementEventMap {
        'ill-position': PositionEvent;
    }
}
