import { Token } from '../../types';

/**
 * A record of static token info for stablecoins.
 *
 * @remarks
 * This record contains stablecoin token info used by illuminate markets for different networks,
 * keyed by the token address.
 */
export const TOKENS: Record<string, Token> = {

    // mainnet

    '0x6B175474E89094C44Da98b954EedeAC495271d0F': {
        address: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
        name: 'Dai Stablecoin',
        symbol: 'DAI',
        decimals: 18,
        image: 'dai',
    },
    '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48': {
        address: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
        name: 'USD Coin',
        symbol: 'USDC',
        decimals: 6,
        image: 'usdc',
    },
    // display WETH as ETH: we don't want to confuse users with the wrapped token
    '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2': {
        address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
        // name: 'Wrapped Ether',
        name: 'Ether',
        // symbol: 'WETH',
        symbol: 'ETH',
        decimals: 18,
        // image: 'weth',
        image: 'eth',
    },
    '0xae7ab96520DE3A18E5e111B5EaAb095312D7fE84': {
        address: '0xae7ab96520DE3A18E5e111B5EaAb095312D7fE84',
        name: 'Liquid staked Ether 2.0',
        symbol: 'stETH',
        decimals: 18,
        image: 'steth',
    },

    // rinkeby

    '0x5592EC0cfb4dbc12D3aB100b257153436a1f0FEa': {
        address: '0x5592EC0cfb4dbc12D3aB100b257153436a1f0FEa',
        name: 'Dai',
        symbol: 'DAI',
        decimals: 18,
        image: 'dai',
    },
    '0x4DBCdF9B62e891a7cec5A2568C3F4FAF9E8Abe2b': {
        address: '0x4DBCdF9B62e891a7cec5A2568C3F4FAF9E8Abe2b',
        name: 'USD Coin',
        symbol: 'USDC',
        decimals: 6,
        image: 'usdc',
    },
    /**
     * @remarks
     * Seems to be just a protocol mock on rinkeby:
     * https://docs.lido.fi/deployed-contracts#protocol-mocks
     */
    '0xF4242f9d78DB7218Ad72Ee3aE14469DBDE8731eD': {
        address: '0xF4242f9d78DB7218Ad72Ee3aE14469DBDE8731eD',
        name: 'Liquid staked Ether 2.0',
        symbol: 'stETH',
        decimals: 18,
        image: 'steth',
    },

    // goerli

    '0x2899a03ffDab5C90BADc5920b4f53B0884EB13cC': {
        address: '0x2899a03ffDab5C90BADc5920b4f53B0884EB13cC',
        name: 'Dai Stablecoin',
        symbol: 'DAI',
        decimals: 18,
        image: 'dai',
    },
    '0x07865c6E87B9F70255377e024ace6630C1Eaa37F': {
        address: '0x07865c6E87B9F70255377e024ace6630C1Eaa37F',
        name: 'USD Coin',
        symbol: 'USDC',
        decimals: 6,
        image: 'usdc',
    },
    /**
     * stETH on goerli + prater testnet
     *
     * @remarks
     * https://docs.lido.fi/deployed-contracts#g%C3%B6rliprater-testnet
     */
    '0x1643E812aE58766192Cf7D2Cf9567dF2C37e9B7F': {
        address: '0x1643E812aE58766192Cf7D2Cf9567dF2C37e9B7F',
        name: 'Liquid staked Ether 2.0',
        symbol: 'stETH',
        decimals: 18,
        image: 'steth',
    },
    /**
     * stETH on goerli + pyrmont testnet
     *
     * @remarks
     * https://docs.lido.fi/deployed-contracts#g%C3%B6rlipyrmont-testnet
     */
    '0xA0cA1c13721BAB3371E0609FFBdB6A6B8e155CC0': {
        address: '0xA0cA1c13721BAB3371E0609FFBdB6A6B8e155CC0',
        name: 'Liquid staked Ether 2.0',
        symbol: 'stETH',
        decimals: 18,
        image: 'steth',
    },
};

/**
 * Checks if a token is an ETH-based token.
 *
 * @remarks
 * We consider a token to be ETH-based if it is either ETH or WETH.
 *
 * @param token - the token or token address to check
 */
export const isETHToken = (token: string | Token): boolean => (typeof token === 'string')
    ? TOKENS[token]?.symbol === 'ETH'
    : TOKENS[token.address]?.symbol === 'ETH';
