import { FixedNumber } from 'ethers';
import { ensureFixed, fixed } from '../amount';
import { ERRORS } from '../constants';
import { matured, timeUntilMaturity } from '../markets';
import { ERROR_SERVICE, LOG_SERVICE, serviceLocator } from '../services';
import type { YieldSpaceInfo } from '../services/yieldspace';

// the number of passes to run when calculating fyTokenToBuy (must be at least 1)
const FYTOKEN_BUY_PASSES = 1;

const BASE_18 = fixed('1000000000000000000');
const ZERO = fixed('0');
const ONE = fixed('1');

/**
 * Converts an amount in base to shares
 *
 * @remarks
 * sharesPrice = baseAmount / sharesAmount
 * sharesAmount = baseAmount / sharesPrice
 */
export function baseToShares (baseAmount: string | FixedNumber, sharesPrice: string | FixedNumber): FixedNumber {

    sharesPrice = ensureFixed(sharesPrice);

    return sharesPrice.isZero()
        ? ZERO
        : ensureFixed(baseAmount).divUnsafe(sharesPrice);
}

/**
 * Converts an amount in shares to base
 *
 * @remarks
 * sharesPrice = baseAmount / sharesAmount
 * baseAmount = sharesAmount * sharesPrice
 */
export function sharesToBase (sharesAmount: string | FixedNumber, sharesPrice: string | FixedNumber): FixedNumber {

    return ensureFixed(sharesAmount).mulUnsafe(ensureFixed(sharesPrice));
}

/**
 * Calculates the ratio of the pool
 *
 * @remarks
 * ratio = baseBalance / fyTokenBalance
 */
export function ratio (baseBalance: string | FixedNumber, fyTokenBalance: string | FixedNumber): FixedNumber {

    fyTokenBalance = ensureFixed(fyTokenBalance);

    return fyTokenBalance.isZero()
        ? ZERO
        : ensureFixed(baseBalance).divUnsafe(fyTokenBalance);
}

/**
 * Calculates the (tradeable) amount of fy tokens in the YieldSpace contract
 *
 * @remarks
 * We need to subtract the amount of virtual fy tokens (equivalent to the totalSupply) from the fyTokenBalance.
 */
export function realFYTokenBalance (fyTokenBalance: string | FixedNumber, totalSupply: string | FixedNumber): FixedNumber {

    return ensureFixed(fyTokenBalance).subUnsafe(ensureFixed(totalSupply));
}

/**
 * Calculates the (approximate) total liquidity of a pool in terms of base
 *
 * @remarks
 * The total liquidity is the amount of base and fy tokens in the pool. To express it in terms of
 * underlying, we consider the current value of the fy tokens. We do a preview sell of 1 fy token
 * to establish a baseline price for selling fy tokens.
 *
 * NOTE: This is not 100% accurate, as the sell price tends to decrease with an increasing amount
 * of fy tokens sold, but we cannot preview sell the entire fy token balance of the pool for
 * math reasons (the amount of sellable fy tokens is limited by the available shares balance in the pool).
 */
export function liquidity (
    baseBalance: string | FixedNumber,
    sharesBalance: string | FixedNumber,
    fyTokenBalance: string | FixedNumber,
    totalSupply: string | FixedNumber,
    info: YieldSpaceInfo,
): FixedNumber {

    totalSupply = ensureFixed(totalSupply);

    // if the pool has no total supply, it has no liquidity
    if (totalSupply.isZero()) return ZERO;

    const base = BASE_18.divUnsafe(fixed(info.scaleFactor));

    const fyTokenPrice = sellFYTokenPrice(base, sharesBalance, fyTokenBalance, info);
    const realFYBalance = realFYTokenBalance(fyTokenBalance, totalSupply);

    const totalLiquidity = ensureFixed(realFYBalance).mulUnsafe(ensureFixed(fyTokenPrice)).addUnsafe(ensureFixed(baseBalance));

    return totalLiquidity;
}

/**
 * Calculates the amount of base required to buy the specified amount of fy tokens
 */
export function buyFYTokenPreview (
    fyTokenAmount: string | FixedNumber,
    sharesBalance: string | FixedNumber,
    fyTokenBalance: string | FixedNumber,
    info: YieldSpaceInfo,
): FixedNumber {

    return sharesToBase(
        buyFYTokenPreviewShares(fyTokenAmount, sharesBalance, fyTokenBalance, info),
        fixed(info.sharesPrice),
    );
}

/**
 * Calculates the amount of shares required to buy the specified amount of fy tokens
 */
export function buyFYTokenPreviewShares (
    fyTokenAmount: string | FixedNumber,
    sharesBalance: string | FixedNumber,
    fyTokenBalance: string | FixedNumber,
    info: YieldSpaceInfo,
): FixedNumber {

    const time = timeUntilMaturity(info.maturity).toString();
    const base = BASE_18.divUnsafe(fixed(info.scaleFactor));

    const sharesIn = sharesInForFYTokenOut(
        fyTokenAmount,
        sharesBalance,
        fyTokenBalance,
        time,
        info.ts,
        info.g1,
        info.c,
        info.mu,
        base,
    );

    return sharesIn;
}

/**
 * Calculates the fy token price for buying a specified amount of fy tokens
 */
export function buyFYTokenPrice (
    fyTokenAmount: string | FixedNumber,
    sharesBalance: string | FixedNumber,
    fyTokenBalance: string | FixedNumber,
    info: YieldSpaceInfo,
): FixedNumber {

    fyTokenAmount = ensureFixed(fyTokenAmount);

    if (fyTokenAmount.isZero()) return ZERO;

    const price = matured(info)
        ? fixed('1')
        : buyFYTokenPreview(fyTokenAmount, sharesBalance, fyTokenBalance, info).divUnsafe(fyTokenAmount);

    return price;
}

/**
 * Calculates the amount of base returned for selling the specified amount of fy tokens
 */
export function sellFYTokenPreview (
    fyTokenAmount: string | FixedNumber,
    sharesBalance: string | FixedNumber,
    fyTokenBalance: string | FixedNumber,
    info: YieldSpaceInfo,
): FixedNumber {

    return sharesToBase(
        sellFYTokenPreviewShares(fyTokenAmount, sharesBalance, fyTokenBalance, info),
        fixed(info.sharesPrice),
    );
}

/**
 * Calculates the amount of shares returned for selling the specified amount of fyTokens
 */
export function sellFYTokenPreviewShares (
    fyTokenAmount: string | FixedNumber,
    sharesBalance: string | FixedNumber,
    fyTokenBalance: string | FixedNumber,
    info: YieldSpaceInfo,
): FixedNumber {

    const errors = serviceLocator.get(ERROR_SERVICE);
    const time = timeUntilMaturity(info.maturity).toString();
    const base = BASE_18.divUnsafe(fixed(info.scaleFactor));

    try {

        const sharesOut = sharesOutForFYTokenIn(
            fyTokenAmount,
            sharesBalance,
            fyTokenBalance,
            time,
            info.ts,
            info.g2,
            info.c,
            info.mu,
            base,
        );

        return sharesOut;

    } catch (error) {

        throw errors.process(error, ERRORS.SERVICES.POOL.SELL_FY_TOKEN_PREVIEW);
    }
}

/**
 * Calculates the fy token price for selling a specified amount of fy tokens
 */
export function sellFYTokenPrice (
    fyTokenAmount: string | FixedNumber,
    sharesBalance: string | FixedNumber,
    fyTokenBalance: string | FixedNumber,
    info: YieldSpaceInfo,
): FixedNumber {

    fyTokenAmount = ensureFixed(fyTokenAmount);

    if (fyTokenAmount.isZero()) return ZERO;

    const price = matured(info)
        ? fixed('1')
        : sellFYTokenPreview(fyTokenAmount, sharesBalance, fyTokenBalance, info).divUnsafe(fyTokenAmount);

    return price;
}

/**
 * Calculates the amount of lp tokens minted for a specified amount of fy tokens (bought and/or transferred) added to the pool
 *
 * @remarks
 * lpTokensMinted = (totalSupply * (fyTokenToBuy + fyTokenIn)) / (realFYTokenBalance - fyTokenToBuy);
 */
export function lpTokensMinted (
    fyTokenBalance: string | FixedNumber,
    totalSupply: string | FixedNumber,
    fyTokenToBuy: string | FixedNumber = ZERO,
    fyTokenIn: string | FixedNumber = ZERO,
): FixedNumber {

    const realFYBalance = realFYTokenBalance(fyTokenBalance, totalSupply);
    const fyBalance = realFYBalance.subUnsafe(ensureFixed(fyTokenToBuy));

    return fyBalance.isZero()
        ? ZERO
        : ensureFixed(totalSupply)
            .mulUnsafe(
                ensureFixed(fyTokenToBuy)
                    .addUnsafe(ensureFixed(fyTokenIn)),
            )
            .divUnsafe(fyBalance);
}

/**
 * Calculates the amount of base needed to buy and provide liquidity for a specified amount of fy tokens
 */
export function baseIn (
    sharesBalance: string | FixedNumber,
    totalSupply: string | FixedNumber,
    lpTokensMinted: string | FixedNumber,
    sharesPrice: string | FixedNumber,
    baseToSell: string | FixedNumber = ZERO,
): FixedNumber {

    return sharesToBase(
        sharesIn(sharesBalance, totalSupply, lpTokensMinted, baseToShares(baseToSell, sharesPrice)),
        sharesPrice,
    );
}

/**
 * Calculates the amount of shares needed to buy and/or provide liquidity for a specified amount of fy tokens
 *
 * @remarks
 * sharesIn = sharesToSell + ((sharesBalance + sharesToSell) * lpTokensMinted) / totalSupply;
 */
export function sharesIn (
    sharesBalance: string | FixedNumber,
    totalSupply: string | FixedNumber,
    lpTokensMinted: string | FixedNumber,
    sharesToSell: string | FixedNumber = ZERO,
): FixedNumber {

    totalSupply = ensureFixed(totalSupply);

    return totalSupply.isZero()
        ? ZERO
        : ensureFixed(sharesToSell)
            .addUnsafe(
                ensureFixed(sharesBalance)
                    .addUnsafe(ensureFixed(sharesToSell))
                    .mulUnsafe(ensureFixed(lpTokensMinted))
                    .divUnsafe(totalSupply),
            );
}

/**
 * Calculates the amount of fy tokens to buy for a specified amount of base tokens
 * (base tokens are used to buy fy tokens and provide a proportional amount of base for adding liquidity)
 *
 * @remarks
 * We start at the formula for calculating the amount of shares required to add a specific amount of liquidity (lpTokensMinted):
 *
 * sharesIn = sharesToSell + ((sharesBalance + sharesToSell) * lpTokensMinted) / totalSupply;
 *
 * Both, lpTokensMinted and sharesToSell, depend on the amount of fyTokenToBuy, the respective formulas are:
 *
 * lpTokensMinted = (totalSupply * fyTokenToBuy) / (realFYTokenBalance - fyTokenToBuy);
 *
 * sharesToSell = _buyFYTokenPreview(fyTokenToBuy) = _buyFYTokenPreview(1) * fyTokenToBuy <=> fyTokenPrice * fyTokenToBuy
 *
 * Given that we know the amount of sharesIn (it is provided by the user), we then substitute these formulas into
 * the formula for sharesIn and solve the equation for fyTokenToBuy:
 *
 * sharesIn = (fyTokenPrice * fyTokenToBuy) + ((sharesBalance + (fyTokenPrice * fyTokenToBuy)) * (totalSupply * fyTokenToBuy) / (realFYTokenBalance - fyTokenToBuy)) / totalSupply
 *
 * Which results in the final formula for calculating the amount of fyTokenToBuy from an amount of sharesIn:
 *
 * fyTokenToBuy = sharesIn * realFYTokenBalance / (fyTokenPrice * realFYTokenBalance + sharesBalance + sharesIn)
 */
export function fyTokenToBuy (
    baseIn: string | FixedNumber,
    fyTokenBalance: string | FixedNumber,
    sharesBalance: string | FixedNumber,
    totalSupply: string | FixedNumber,
    priceSlippage: string | FixedNumber,
    info: YieldSpaceInfo,
): FixedNumber {

    const logger = serviceLocator.get(LOG_SERVICE).group('pool-math');

    // we need to convert the amount of baseIn into sharesIn
    const sharesIn = baseToShares(baseIn, info.sharesPrice);
    const realFYBalance = realFYTokenBalance(fyTokenBalance, totalSupply);

    // if we don't have any shares in, we can't buy any fy tokens
    if (sharesIn.isZero()) return ZERO;

    // we get a baseline for the fyTokenPrice (in shares per fy token)
    // (this price is for buying 1 fy token, the price will be higher when buying more)
    let fyTokenToBuy = BASE_18.divUnsafe(fixed(info.scaleFactor));
    let fyTokenPrice = buyFYTokenPreviewShares(fyTokenToBuy, sharesBalance, fyTokenBalance, info).divUnsafe(fyTokenToBuy);

    const logs = ['fyTokenToBuy()...'];

    // we need to do multiple passes to get really close to the correct fyTokenToBuy value,
    // as the fyTokenPrice is not static, but depends on the amount of fy tokens to buy
    for (let i = 0; i < FYTOKEN_BUY_PASSES; i++) {

        logs.push(`pass:                  ${ i + 1 }`);
        logs.push(`sharesIn:              ${ sharesIn.toString() }`);
        logs.push(`baseline fyTokenPrice: ${ fyTokenPrice.toString() }`);

        // we calculate how much fy tokens we can buy from the shares at the last baseline price
        // (this will be too much, as the baseline price is too small, but will give us a decent idea)
        fyTokenToBuy = fyTokenToBuyInternal(sharesIn, ensureFixed(sharesBalance), realFYBalance, fyTokenPrice, ensureFixed(priceSlippage));

        logs.push(`fyTokenToBuy:          ${ fyTokenToBuy.toString() }`);

        // if we can't buy fy tokens at the current price, something is wrong and we can't calculate the updated price
        // we break the loop and return the last calculated `fyTokenToBuy`
        if (fyTokenToBuy.isZero()) break;

        // we get a more accurate fyTokenPrice for the amount of fy tokens we just calculated
        // (the amount should be fairly close, but a bit too high, the new price will be a bit higher then
        // what we actually need but gives us a little bit of headroom for the time based price slippage)
        fyTokenPrice = buyFYTokenPreviewShares(fyTokenToBuy, sharesBalance, fyTokenBalance, info).divUnsafe(fyTokenToBuy);

        logs.push(`adjusted fyTokenPrice: ${ fyTokenPrice.toString() }`);

        // we do a second pass calculating how much fy tokens we can buy from the shares using the adjusted price
        // (this time our price is slightly too high and gives us a little less fy tokens)
        fyTokenToBuy = fyTokenToBuyInternal(sharesIn, ensureFixed(sharesBalance), realFYBalance, fyTokenPrice, ensureFixed(priceSlippage));

        logs.push(`adjusted fyTokenToBuy: ${ fyTokenToBuy.toString() }`);

        // if we can't buy fy tokens at the current price, something is wrong and we can't calculate the updated price
        // we break the loop and return the last calculated `fyTokenToBuy`
        if (fyTokenToBuy.isZero()) break;

        // we calculate the new baseline fyTokenPrice for the next pass
        // (this time the price will be slightly too low, as fyTokenToBuy is a bit smaller from the previous calculation)
        fyTokenPrice = buyFYTokenPreviewShares(fyTokenToBuy, sharesBalance, fyTokenBalance, info).divUnsafe(fyTokenToBuy);
    }

    logger.log(logs.join('\n'));

    return fyTokenToBuy;
}

/**
 * Calculates the amount of fy tokens to buy for a specified amount of shares tokens and fyTokenPrice in shares per fy token
 *
 * @remarks
 * The internal implementation of the formula:
 *
 * fyTokenToBuy = sharesIn * realFYTokenBalance / (fyTokenPrice * realFYTokenBalance + sharesBalance + sharesIn)
 *
 * This method is separate, as we want to run it in multiple passes.
 */
function fyTokenToBuyInternal (
    sharesIn: FixedNumber,
    sharesBalance: FixedNumber,
    realFYTokenBalance: FixedNumber,
    fyTokenPrice: FixedNumber,
    fyTokenPriceSlippage: FixedNumber,
): FixedNumber {

    // as time progresses, a pool gets closer to maturity and the fy token price will increase
    // we account for that by applying a slightly higher price beforehand, defined through the slippage
    // fyTokenPrice = fyTokenPrice * (1 + priceSlippage)
    const divisor = fyTokenPrice.mulUnsafe(ONE.addUnsafe(fyTokenPriceSlippage))
        .mulUnsafe(realFYTokenBalance)
        .addUnsafe(ensureFixed(sharesBalance))
        .addUnsafe(sharesIn);

    return divisor.isZero()
        ? ZERO
        : sharesIn
            .mulUnsafe(realFYTokenBalance)
            .divUnsafe(divisor);
}

/**
 * Calculates the amount of fy tokens needed to provide liquidity for a specified amount of shares tokens,
 * if no fy tokens need to be bought from the shares amount
 *
 * @remarks
 * We start at the formula for calculating the amount of shares required to add a specific amount of liquidity
 * (lpTokensMinted), but we assume sharesToSell is 0, as we don't want to buy fyTokens:
 *
 * sharesIn = sharesToSell + ((sharesBalance + sharesToSell) * lpTokensMinted) / totalSupply
 * sharesIn = (sharesBalance * lpTokensMinted) / totalSupply
 *
 * The lpTokensMinted depend on the amount of fyTokenIn and fyTokenToBuy. Again, we assume fyTokenToBuy is 0:
 *
 * lpTokensMinted = (totalSupply * (fyTokenToBuy + fyTokenIn)) / (realFYTokenBalance - fyTokenToBuy)
 * lpTokensMinted = (totalSupply * fyTokenIn) / realFYTokenBalance
 *
 * Given that we know the amount of sharesIn (it is provided by the user), we then substitute the formula into
 * the formula for sharesIn and solve the equation for fyTokenIn:
 *
 * sharesIn = (sharesBalance * ((totalSupply * fyTokenIn) / realFYTokenBalance)) / totalSupply
 *
 * Which results in the final formula for calculating the amount of fyTokenIn from an amount of sharesIn:
 *
 * fyTokenIn = sharesIn * realFYTokenBalance / sharesBalance
 */
export function fyTokenIn (
    baseIn: string | FixedNumber,
    fyTokenBalance: string | FixedNumber,
    sharesBalance: string | FixedNumber,
    totalSupply: string | FixedNumber,
    sharesPrice: string | FixedNumber,
): FixedNumber {

    sharesBalance = ensureFixed(sharesBalance);

    // we need to convert the amount of baseIn into sharesIn
    const sharesIn = baseToShares(baseIn, sharesPrice);

    const realFYBalance = realFYTokenBalance(fyTokenBalance, totalSupply);

    return sharesBalance.isZero()
        ? ZERO
        : sharesIn.mulUnsafe(realFYBalance).divUnsafe(ensureFixed(sharesBalance));
}

/**
 * Calculates the amount of shares returned for burning a specified amount of lp tokens
 *
 * @remarks
 * sharesOut = (lpTokensBurned * sharesBalance) / totalSupply;
 */
export function sharesOutForLPTokenIn (
    lpTokensBurned: string | FixedNumber,
    sharesBalance: string | FixedNumber,
    totalSupply: string | FixedNumber,
): FixedNumber {

    totalSupply = ensureFixed(totalSupply);

    return totalSupply.isZero()
        ? ZERO
        : ensureFixed(lpTokensBurned).mulUnsafe(ensureFixed(sharesBalance)).divUnsafe(totalSupply);
}

/**
 * Calculates the amount of fy tokens returned for burning a specified amount of lp tokens
 *
 * @remarks
 * fyTokenOut = (lpTokensBurned * realFYTokenBalance) / totalSupply;
 */
export function fyTokenOutForLPTokenIn (
    lpTokensBurned: string | FixedNumber,
    fyTokenBalance: string | FixedNumber,
    totalSupply: string | FixedNumber,
): FixedNumber {

    totalSupply = ensureFixed(totalSupply);

    const realFYBalance = realFYTokenBalance(fyTokenBalance, totalSupply);

    return totalSupply.isZero()
        ? ZERO
        : ensureFixed(lpTokensBurned).mulUnsafe(realFYBalance).divUnsafe(totalSupply);
}

/**
 * Calculates the amount of base and fy tokens returned when burning a specified amount of lp tokens
 *
 * @returns a tuple containing the amount of base and the amount of fy tokens returned
 */
export function previewBurn (
    lpTokens: string | FixedNumber,
    fyTokenBalance: string | FixedNumber,
    sharesBalance: string | FixedNumber,
    totalSupply: string | FixedNumber,
    sharesPrice: string | FixedNumber,
): [FixedNumber, FixedNumber] {

    const sharesOut = sharesOutForLPTokenIn(lpTokens, sharesBalance, totalSupply);
    const fyTokenOut = fyTokenOutForLPTokenIn(lpTokens, fyTokenBalance, totalSupply);

    const baseOut = sharesToBase(sharesOut, sharesPrice);

    return [baseOut, fyTokenOut];
}

/**
 * Calculates the amount of base returned when burning a specified amount of lp tokens and selling
 * the obtained fy tokens to the pool immediately
 *
 * @returns the amount of base returned
 */
export function previewBurnForUnderlying (
    lpTokens: string | FixedNumber,
    fyTokenBalance: string | FixedNumber,
    sharesBalance: string | FixedNumber,
    totalSupply: string | FixedNumber,
    sharesPrice: string | FixedNumber,
    info: YieldSpaceInfo,
): FixedNumber {

    const sharesOut = sharesOutForLPTokenIn(lpTokens, sharesBalance, totalSupply);
    const fyTokenOut = fyTokenOutForLPTokenIn(lpTokens, fyTokenBalance, totalSupply);

    // during a real burn, the user would have obtained the shares and fyTokens
    // and the pool balances would have updated
    // we need to take this into account when we preview sell the fyTokens
    const sharesForFYTokens = sellFYTokenPreviewShares(
        fyTokenOut,
        ensureFixed(sharesBalance).subUnsafe(sharesOut),
        ensureFixed(fyTokenBalance).subUnsafe(fyTokenOut),
        info,
    ).floor();

    const baseOut = sharesToBase(sharesOut.addUnsafe(sharesForFYTokens), sharesPrice);

    return baseOut;
}

// **********************
// YieldMath calculations
// **********************

/**
 * Calculates the amount of shares tokens required to purchase a desired amount of fy tokens
 *
 * @remarks
 * This is essentially a `buyFYTokenPreview` and replicates the calculations in the YieldMath contract.
 *
 * https://github.com/yieldprotocol/yieldspace-tv/blob/main/src/YieldMath.sol#L378
 *
 * @param fyTokenOut - fyToken amount to be bought
 * @param sharesBalance - shares reserves amount
 * @param fyTokenBalance - fyToken reserves amount
 * @param timeTillMaturity - time till maturity in seconds
 * @param ts - time till maturity coefficient (1 / seconds in x years, where x varies per contract)
 * @param g - fee coefficient (should be g1 for buying fyTokens)
 * @param c - price of shares in terms of underlying
 * @param mu - normalization factor (starts as c at initialization)
 * @param b - the base denomination of the underlying, fyToken and usually shares token, e.g. '1000000' for 6 decimals
 */
export function sharesInForFYTokenOut (
    fyTokenOut: string | FixedNumber,
    sharesBalance: string | FixedNumber,
    fyTokenBalance: string | FixedNumber,
    timeTillMaturity: string,
    ts: string,
    g: string,
    c: string,
    mu: string,
    b: string | FixedNumber,
): FixedNumber {

    const base = ensureFixed(b);
    const a = computeA(timeTillMaturity, ts, g);

    // normalizedSharesReserves = μ * sharesReserves
    const normalizedSharesReserves = fixed(mu).mulUnsafe(ensureFixed(sharesBalance));

    // za = c/μ * (normalizedSharesReserves ** a)
    // in order to calculate `normalizedSharesReserves ** a` we need to normalize the
    // shares reserves amount (Math.pow() needs a standard float to prevent overflow)

    // normalize the shares reserves and convert to float
    const nsrF = normalizedSharesReserves.divUnsafe(base).toUnsafeFloat();
    // convert a to float
    const aF = a.toUnsafeFloat();

    // after calculating the power, we de-normalize and convert back to FixedNumber
    const nsrPow = fixed(Math.pow(nsrF, aF)).mulUnsafe(base);

    // now we can calculate za
    const za = fixed(c).divUnsafe(fixed(mu)).mulUnsafe(nsrPow);

    // ya = fyTokenReserves ** a
    const fyrF = ensureFixed(fyTokenBalance).divUnsafe(base).toUnsafeFloat();

    const ya = fixed(Math.pow(fyrF, aF)).mulUnsafe(base);

    // yxa = (fyTokenReserves - x) ** a   # x is aka Δy
    const yxF = ensureFixed(fyTokenBalance).subUnsafe(ensureFixed(fyTokenOut)).divUnsafe(base).toUnsafeFloat();
    const yxa = fixed(Math.pow(yxF, aF)).mulUnsafe(base);

    // zaYaYxa = za + ya - yxa
    const zaYaYxa = za.addUnsafe(ya).subUnsafe(yxa);

    // subtotal = ((zaYaYxa / (c/μ))^(1/a)) / μ
    const zyxF = zaYaYxa.divUnsafe(fixed(c).divUnsafe(fixed(mu))).divUnsafe(base).toUnsafeFloat();
    const invAF = 1 / aF;

    const zyxPow = fixed(Math.pow(zyxF, invAF)).mulUnsafe(base);

    const subtotal = zyxPow.divUnsafe(fixed(mu));

    // sharesIn = subtotal - sharesReserves
    const sharesIn = subtotal.subUnsafe(ensureFixed(sharesBalance));

    return sharesIn;
}



/**
 * Calculates the amount of shares tokens returned when selling a desired amount of fy tokens
 *
 * @remarks
 * This is essentially a `sellFYTokenPreview` and replicates the calculations in the YieldMath contract.
 *
 * https://github.com/yieldprotocol/yieldspace-tv/blob/main/src/YieldMath.sol#L165
 *
 * @param fyTokenIn - fyToken amount to be sold
 * @param sharesBalance - shares reserves amount
 * @param fyTokenBalance - fyToken reserves amount
 * @param timeTillMaturity - time till maturity in seconds
 * @param ts - time till maturity coefficient (1 / seconds in x years, where x varies per contract)
 * @param g - fee coefficient (should be g2 for selling fyTokens)
 * @param c - price of shares in terms of underlying
 * @param mu - normalization factor (starts as c at initialization)
 * @param b - the base denomination of the underlying, fyToken and usually shares token, e.g. '1000000' for 6 decimals
 */
export function sharesOutForFYTokenIn (
    fyTokenIn: string | FixedNumber,
    sharesBalance: string | FixedNumber,
    fyTokenBalance: string | FixedNumber,
    timeTillMaturity: string,
    ts: string,
    g: string,
    c: string,
    mu: string,
    b: string | FixedNumber,
): FixedNumber {

    const base = ensureFixed(b);
    const a = computeA(timeTillMaturity, ts, g);

    // normalizedSharesReserves = μ * sharesReserves
    const normalizedSharesReserves = fixed(mu).mulUnsafe(ensureFixed(sharesBalance));

    // za = c/μ * (normalizedSharesReserves ** a)
    // in order to calculate `normalizedSharesReserves ** a` we need to normalize the
    // shares reserves amount (Math.pow() needs a standard float to prevent overflow)

    // normalize the shares reserves and convert to float
    const nsrF = normalizedSharesReserves.divUnsafe(base).toUnsafeFloat();
    // convert a to float
    const aF = a.toUnsafeFloat();

    // after calculating the power, we de-normalize and convert back to FixedNumber
    const nsrPow = fixed(Math.pow(nsrF, aF)).mulUnsafe(base);

    // now we can calculate za
    const za = fixed(c).divUnsafe(fixed(mu)).mulUnsafe(nsrPow);

    // ya = fyTokenReserves ** a
    const fyrF = ensureFixed(fyTokenBalance).divUnsafe(base).toUnsafeFloat();

    const ya = fixed(Math.pow(fyrF, aF)).mulUnsafe(base);

    // yxa = (fyTokenReserves + x) ** a   # x is aka Δy
    const yxF = ensureFixed(fyTokenBalance).addUnsafe(ensureFixed(fyTokenIn)).divUnsafe(base).toUnsafeFloat();
    const yxa = fixed(Math.pow(yxF, aF)).mulUnsafe(base);

    // zaYaYxa = za + ya - yxa
    const zaYaYxa = za.addUnsafe(ya).subUnsafe(yxa);

    // rightTerm = ((zaYaYxa / (c/μ))^(1/a)) / μ
    const zyxF = zaYaYxa.divUnsafe(fixed(c).divUnsafe(fixed(mu))).divUnsafe(base).toUnsafeFloat();
    const invAF = 1 / aF;

    const zyxPow = fixed(Math.pow(zyxF, invAF)).mulUnsafe(base);

    const rightTerm = zyxPow.divUnsafe(fixed(mu));

    // sharesOut = sharesReserves - rightTerm
    const sharesOut = ensureFixed(sharesBalance).subUnsafe(rightTerm);

    return sharesOut;
}



/**
 * @remarks
 * https://github.com/yieldprotocol/yieldspace-tv/blob/main/src/YieldMath.sol#L731
 */
export function computeA (
    timeTillMaturity: string,
    ts: string,
    g: string,
): FixedNumber {

    // t = k * timeTillMaturity
    const t = fixed(ts).mulUnsafe(fixed(timeTillMaturity));

    // ensure (t >= 0)
    if (t.isNegative()) throw 'YieldMath: t must be positive';

    // a = (1 - gt)
    const a = fixed(1).subUnsafe(fixed(g).mulUnsafe(t));

    // ensure (a > 0)
    if (a.isNegative() || a.isZero()) throw 'YieldMath: Too far from maturity';

    // ensure (a <= 1)
    if (!a.subUnsafe(fixed(1)).isNegative() && !a.subUnsafe(fixed(1)).isZero()) throw 'YieldMath: g must be positive';

    return a;
}
