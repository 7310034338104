import { BrowserConnectionProvider } from '@swivel-finance/connect/connection/providers/browser/provider';
import { TOKENS } from './core/constants';
import { ENV } from './core/env';
import { LendTransaction, RedeemTransaction } from './core/markets';
import { AddLiquidityTransaction, PoolService, POOL_SERVICE, RemoveLiquidityTransaction } from './core/pools';
import { ServiceFactory, ServiceIdentifier, serviceLocator } from './core/services';
import { APIService, API_SERVICE } from './core/services/api';
import { IllumigateAPIService } from './core/services/api/service';
import { ErrorNotifier, ErrorService, ERROR_SERVICE } from './core/services/errors';
import { NotifyingErrorService } from './core/services/errors/service';
import { HttpService, HTTP_SERVICE } from './core/services/http';
import { WebHttpService } from './core/services/http/service';
import { IlluminateService, ILLUMINATE_SERVICE } from './core/services/illuminate';
import { ChainIlluminateService } from './core/services/illuminate/chain/service';
import { InteractivityService, INTERACTIVITY_SERVICE } from './core/services/interactivity';
import { BrowserInteractivityService } from './core/services/interactivity/service';
import { LogService, LOG_SERVICE } from './core/services/logger';
import { logService } from './core/services/logger/service';
import { MessageService, MESSAGE_SERVICE } from './core/services/messages';
import { BrowserMessageService } from './core/services/messages/service';
import { PreferencesService, PREFERENCES_SERVICE } from './core/services/preferences';
import { BrowserPreferencesService } from './core/services/preferences/service';
import { SettingsService, SETTINGS_SERVICE } from './core/services/settings';
import { BrowserSettingsService } from './core/services/settings/service';
import { StatusService, STATUS_SERVICE } from './core/services/status';
import { WebStatusService } from './core/services/status/service';
import { StrategyService, STRATEGY_SERVICE } from './core/services/strategy';
import { StrategyChainService } from './core/services/strategy/chain/service';
import { TimeService, TIME_SERVICE } from './core/services/time';
import { LocalTimeService } from './core/services/time/local-time-service';
import { TokenService, TOKEN_SERVICE } from './core/services/token';
import { ChainTokenService } from './core/services/token/chain/service';
import { TransactionService, TRANSACTION_SERVICE } from './core/services/transaction';
import { confirm } from './core/services/transaction/confirmation/confirm';
import { BrowserTransactionService } from './core/services/transaction/service';
import { WalletService, WALLET_SERVICE } from './core/services/wallet';
import { GenericWalletService } from './core/services/wallet/service';
import { YieldSpaceService, YIELDSPACE_SERVICE } from './core/services/yieldspace';
import { YieldSpaceChainService } from './core/services/yieldspace/chain/service';
import { router, ROUTES } from './routes';
import { consent } from './services/consent';
import { BugsnagNotifier } from './services/error-notifier';

export const configureServices = () => {

    const serviceCache = new Map<ServiceIdentifier, unknown>();

    const logServiceFactory: ServiceFactory<LogService> = () => {

        return logService;
    };

    const errorServiceFactory: ServiceFactory<ErrorService> = () => {

        if (!serviceCache.has(ERROR_SERVICE)) {

            const notifiers: ErrorNotifier[] = [new BugsnagNotifier()];
            const logger = serviceLocator.get(LOG_SERVICE);

            serviceCache.set(ERROR_SERVICE, new NotifyingErrorService(notifiers, logger));
        }

        return serviceCache.get(ERROR_SERVICE) as ErrorService;
    };

    const httpServiceFactory: ServiceFactory<HttpService> = () => {

        if (!serviceCache.has(HTTP_SERVICE)) {

            const errors = serviceLocator.get(ERROR_SERVICE);
            const logger = serviceLocator.get(LOG_SERVICE);

            serviceCache.set(HTTP_SERVICE, new WebHttpService(errors, logger));
        }

        return serviceCache.get(HTTP_SERVICE) as HttpService;
    };

    const walletServiceFactory: ServiceFactory<WalletService> = () => {

        if (!serviceCache.has(WALLET_SERVICE)) {

            serviceCache.set(WALLET_SERVICE, new GenericWalletService({
                connectionProvider: new BrowserConnectionProvider({
                    chainId: ENV.chainId,
                }),
            }));
        }

        return serviceCache.get(WALLET_SERVICE) as WalletService;
    };

    const timeServiceFactory: ServiceFactory<TimeService> = () => {

        if (!serviceCache.has(TIME_SERVICE)) {

            serviceCache.set(TIME_SERVICE, new LocalTimeService());
        }

        return serviceCache.get(TIME_SERVICE) as TimeService;
    };

    // TODO: use the blockchain time service when testing on tenderly with time manipulation
    // const timeServiceFactory: ServiceFactory<TimeService> = () => {

    //     if (!serviceCache.has(TIME_SERVICE)) {

    //         const wallet = serviceLocator.get(WALLET_SERVICE);
    //         const errors = serviceLocator.get(ERROR_SERVICE);
    //         const logger = serviceLocator.get(LOG_SERVICE);

    //         serviceCache.set(TIME_SERVICE, new BlockChainTimeService(wallet, errors, logger));
    //     }

    //     return serviceCache.get(TIME_SERVICE) as TimeService;
    // };

    const tokenServiceFactory: ServiceFactory<TokenService> = () => {

        if (!serviceCache.has(TOKEN_SERVICE)) {

            const wallet = serviceLocator.get(WALLET_SERVICE);
            const timer = serviceLocator.get(TIME_SERVICE);
            const errors = serviceLocator.get(ERROR_SERVICE);
            const logger = serviceLocator.get(LOG_SERVICE);

            serviceCache.set(TOKEN_SERVICE, new ChainTokenService(TOKENS, wallet, timer, errors, logger));
        }

        return serviceCache.get(TOKEN_SERVICE) as TokenService;
    };

    const yieldSpaceServiceFactory: ServiceFactory<YieldSpaceService> = () => {

        if (!serviceCache.has(YIELDSPACE_SERVICE)) {

            const wallet = serviceLocator.get(WALLET_SERVICE);
            const errors = serviceLocator.get(ERROR_SERVICE);
            const logger = serviceLocator.get(LOG_SERVICE);

            serviceCache.set(YIELDSPACE_SERVICE, new YieldSpaceChainService(wallet, errors, logger));
        }

        return serviceCache.get(YIELDSPACE_SERVICE) as YieldSpaceService;
    };

    const strategyServiceFactory: ServiceFactory<StrategyService> = () => {

        if (!serviceCache.has(STRATEGY_SERVICE)) {

            const wallet = serviceLocator.get(WALLET_SERVICE);
            const token = serviceLocator.get(TOKEN_SERVICE);
            const errors = serviceLocator.get(ERROR_SERVICE);
            const logger = serviceLocator.get(LOG_SERVICE);

            serviceCache.set(STRATEGY_SERVICE, new StrategyChainService(wallet, token, errors, logger));
        }

        return serviceCache.get(STRATEGY_SERVICE) as StrategyService;
    };

    const apiServiceFactory: ServiceFactory<APIService> = () => {

        if (!serviceCache.has(API_SERVICE)) {

            const http = serviceLocator.get(HTTP_SERVICE);
            const errors = serviceLocator.get(ERROR_SERVICE);
            const logger = serviceLocator.get(LOG_SERVICE);

            serviceCache.set(API_SERVICE, new IllumigateAPIService(http, errors, logger));
        }

        return serviceCache.get(API_SERVICE) as APIService;
    };

    const illuminateServiceFactory: ServiceFactory<IlluminateService> = () => {

        if (!serviceCache.has(ILLUMINATE_SERVICE)) {

            const wallet = serviceLocator.get(WALLET_SERVICE);
            const token = serviceLocator.get(TOKEN_SERVICE);
            const yieldspace = serviceLocator.get(YIELDSPACE_SERVICE);
            const errors = serviceLocator.get(ERROR_SERVICE);
            const logger = serviceLocator.get(LOG_SERVICE);

            serviceCache.set(ILLUMINATE_SERVICE, new ChainIlluminateService(wallet, token, yieldspace, errors, logger));
        }

        return serviceCache.get(ILLUMINATE_SERVICE) as IlluminateService;
    };

    const poolServiceFactory: ServiceFactory<PoolService> = () => {

        if (!serviceCache.has(POOL_SERVICE)) {

            const illuminate = serviceLocator.get(ILLUMINATE_SERVICE);
            const strategy = serviceLocator.get(STRATEGY_SERVICE);
            const yieldspace = serviceLocator.get(YIELDSPACE_SERVICE);
            const errors = serviceLocator.get(ERROR_SERVICE);
            const logger = serviceLocator.get(LOG_SERVICE);

            serviceCache.set(POOL_SERVICE, new PoolService(illuminate, strategy, yieldspace, errors, logger));
        }

        return serviceCache.get(POOL_SERVICE) as PoolService;
    };

    const statusServiceFactory: ServiceFactory<StatusService> = () => {

        if (!serviceCache.has(STATUS_SERVICE)) {

            const api = serviceLocator.get(API_SERVICE);
            const errors = serviceLocator.get(ERROR_SERVICE);
            const logger = serviceLocator.get(LOG_SERVICE);

            serviceCache.set(STATUS_SERVICE, new WebStatusService(api, errors, logger));
        }

        return serviceCache.get(STATUS_SERVICE) as StatusService;
    };

    const interactivityServiceFactory: ServiceFactory<InteractivityService> = () => {

        if (!serviceCache.has(INTERACTIVITY_SERVICE)) {

            serviceCache.set(INTERACTIVITY_SERVICE, new BrowserInteractivityService());
        }

        return serviceCache.get(INTERACTIVITY_SERVICE) as InteractivityService;
    };

    const messageServiceFactory: ServiceFactory<MessageService> = () => {

        if (!serviceCache.has(MESSAGE_SERVICE)) {

            const interactivity = serviceLocator.get(INTERACTIVITY_SERVICE);
            const timer = serviceLocator.get(TIME_SERVICE);
            const errors = serviceLocator.get(ERROR_SERVICE);
            const logger = serviceLocator.get(LOG_SERVICE);

            serviceCache.set(MESSAGE_SERVICE, new BrowserMessageService(interactivity, timer, errors, logger));
        }

        return serviceCache.get(MESSAGE_SERVICE) as MessageService;
    };

    const preferencesServiceFactory: ServiceFactory<PreferencesService> = () => {

        if (!serviceCache.has(PREFERENCES_SERVICE)) {

            serviceCache.set(PREFERENCES_SERVICE, new BrowserPreferencesService(consent, router({ routes: ROUTES })));
        }

        return serviceCache.get(PREFERENCES_SERVICE) as PreferencesService;
    };

    const settingsServiceFactory: ServiceFactory<SettingsService> = () => {

        if (!serviceCache.has(SETTINGS_SERVICE)) {

            const preferences = serviceLocator.get(PREFERENCES_SERVICE);
            const errors = serviceLocator.get(ERROR_SERVICE);
            const logger = serviceLocator.get(LOG_SERVICE);

            serviceCache.set(SETTINGS_SERVICE, new BrowserSettingsService(preferences, errors, logger));
        }

        return serviceCache.get(SETTINGS_SERVICE) as SettingsService;
    };

    const transactionServiceFactory: ServiceFactory<TransactionService> = () => {

        if (!serviceCache.has(TRANSACTION_SERVICE)) {

            const confirmer = confirm;
            const preferences = serviceLocator.get(PREFERENCES_SERVICE);
            const wallet = serviceLocator.get(WALLET_SERVICE);
            const settings = serviceLocator.get(SETTINGS_SERVICE);
            const errors = serviceLocator.get(ERROR_SERVICE);
            const logger = serviceLocator.get(LOG_SERVICE);

            const transactionService: TransactionService = new BrowserTransactionService(
                confirmer,
                preferences,
                wallet,
                settings,
                errors,
                logger,
            );

            // register all transaction constructors here, so the transaction service can read the preferences
            // and correctly de-serialize all transactions in the storage
            transactionService.register(LendTransaction);
            transactionService.register(RedeemTransaction);
            transactionService.register(AddLiquidityTransaction);
            transactionService.register(RemoveLiquidityTransaction);

            serviceCache.set(TRANSACTION_SERVICE, transactionService);
        }

        return serviceCache.get(TRANSACTION_SERVICE) as TransactionService;
    };

    serviceLocator.register(LOG_SERVICE, logServiceFactory);
    serviceLocator.register(ERROR_SERVICE, errorServiceFactory);
    serviceLocator.register(HTTP_SERVICE, httpServiceFactory);
    serviceLocator.register(WALLET_SERVICE, walletServiceFactory);
    serviceLocator.register(TIME_SERVICE, timeServiceFactory);
    serviceLocator.register(TOKEN_SERVICE, tokenServiceFactory);
    serviceLocator.register(YIELDSPACE_SERVICE, yieldSpaceServiceFactory);
    serviceLocator.register(STRATEGY_SERVICE, strategyServiceFactory);
    serviceLocator.register(API_SERVICE, apiServiceFactory);
    serviceLocator.register(ILLUMINATE_SERVICE, illuminateServiceFactory);
    serviceLocator.register(POOL_SERVICE, poolServiceFactory);
    serviceLocator.register(STATUS_SERVICE, statusServiceFactory);
    serviceLocator.register(INTERACTIVITY_SERVICE, interactivityServiceFactory);
    serviceLocator.register(MESSAGE_SERVICE, messageServiceFactory);
    serviceLocator.register(PREFERENCES_SERVICE, preferencesServiceFactory);
    serviceLocator.register(SETTINGS_SERVICE, settingsServiceFactory);
    serviceLocator.register(TRANSACTION_SERVICE, transactionServiceFactory);

    const logger = serviceLocator.get(LOG_SERVICE).group('config');
    const timer = serviceLocator.get(TIME_SERVICE);

    logger.log(`service locator configured... (${ new Date(timer.time()).toUTCString() }})`);
};

// immediately run the configuration to ensure all services are configured before other modules are loaded
configureServices();
