import { html, nothing } from 'lit';

/**
 * Creates a status template.
 *
 * @param s - the status to render (default: `'initial'`)
 * @param m - an optional message
 */
export const status = (s: 'initial' | 'loading' = 'initial', m?: string) => html`
<div class="loader">
    ${ s === 'initial'
        ? html`<ui-icon name="plug"></ui-icon>`
        : html`<svg class="spinner" viewBox="0 0 16 16"><circle class="circle" cx="8" cy="8" r="8"/></svg>`
    }
    ${ m ? html`<span class="message">${ m }</span>` : nothing }
</div>`;

/**
 * Creates a market status template
 *
 * @param s - the status of the market to render (`'locked'` or `'paused'`)
 */
export const marketStatus = (s: 'locked' | 'paused') => html`
<span class="market-status">
    <span class="icon">
        <ui-icon name="${ s === 'locked' ? 'lock-clock' : 'pause' }"></ui-icon>
    </span>
    <span class="label">
        ${ s === 'locked' ? 'Locked' : 'Paused' }
    </span>
</span>`;

/**
 * Creates a loading bar template.
 *
 * @param m - an optional message
 */
export const loadingBar = (m?: string) => html`
<div class="loading-bar">
    ${ m
        ? html`<span class="loading-bar-message">${ m }</span>`
        : nothing
    }
    <span class="loading-bar-segments">
        <span class="loading-bar-segment"></span>
        <span class="loading-bar-segment"></span>
        <span class="loading-bar-segment"></span>
        <span class="loading-bar-segment"></span>
        <span class="loading-bar-segment"></span>
        <span class="loading-bar-segment"></span>
        <span class="loading-bar-segment"></span>
        <span class="loading-bar-segment"></span>
        <span class="loading-bar-segment"></span>
    </span>
</div>`;
