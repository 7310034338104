import { html, nothing } from 'lit';
import { NOTIFICATIONS } from '../../core/constants';
import { LendTransaction, LEND_STATUS, marketKey, matured, RedeemTransaction, REDEEM_STATUS } from '../../core/markets';
import { AddLiquidityTransaction, ADD_LIQUIDITY_STATUS, RemoveLiquidityTransaction, REMOVE_LIQUIDITY_STATUS } from '../../core/pools';
import { isLendPosition } from '../../core/positions';
import { ERROR_SERVICE, LOG_SERVICE, serviceLocator } from '../../core/services';
import { Transaction, TransactionMessage, TransactionTopic, TRANSACTION_SERVICE, TRANSACTION_TOPIC } from '../../core/services/transaction';
import { WALLET_SERVICE } from '../../core/services/wallet';
import { createNotification, notifications, NOTIFICATION_TIMEOUTS } from '../../services/notification';
import { ACCOUNT, orchestrator } from '../../state/orchestrator';
import { LendPosition, PoolPosition, Position } from '../../types';
import type { PositionsPageElement } from './positions-page';
import { POSITION_MODES, POSITION_STEPS, POSITION_TYPES, STEP_LABELS } from './types';

/**
 * A list of labels indexed by POSITION_TYPES -> POSITION_MODES -> matured / not matured
 */
const POSITION_LABELS = [
    // POSITION_TYPES.LEND
    [
        // POSITION_MODES.ENTER
        [
            '',
            'Lend',
        ],
        // POSITION_MODES.EXIT
        [
            'Redeem',
            'Exit',
        ],
    ],
    // POSITION_TYPES.POOL
    [
        // POSITION_MODES.ENTER
        [
            '',
            'Add Liquidity',
        ],
        // POSITION_MODES.EXIT
        [
            'Exit',
            'Remove Liquidity',
        ],
    ],
];

/**
 * An abstract class to define the core functionality of a PositionStrategy.
 *
 * @remarks
 * The positions page is relatively complex, displaying lending and pool positions for active and
 * matured markets, as well as allowing users to immediately perform different operations on their
 * existing positions (up to 3 different transactions per position type: enter / exit / redeem).
 * To make this more manageable, we use a strategy pattern, which allows a separate class (the
 * strategy) to implement the behavior and flow of a particular type of positions (e.g. lend positions)
 * and the positions page to simply defer that logic to the strategy.
 */
export abstract class PositionStrategy {

    protected logger = serviceLocator.get(LOG_SERVICE).group('position-strategy');

    protected errors = serviceLocator.get(ERROR_SERVICE);

    protected transactionService = serviceLocator.get(TRANSACTION_SERVICE);

    protected walletService = serviceLocator.get(WALLET_SERVICE);

    protected accountMachine = orchestrator.account;

    protected notification?: string;

    protected transaction?: Transaction;

    protected position: LendPosition | PoolPosition;

    type: POSITION_TYPES;

    mode: POSITION_MODES;

    host: PositionsPageElement;

    constructor (position: Position, mode: POSITION_MODES, host: PositionsPageElement) {

        this.position = position;
        this.type = isLendPosition(position) ? POSITION_TYPES.LEND : POSITION_TYPES.POOL;
        this.mode = mode;
        this.host = host;

        this.handleTransactionChange = this.handleTransactionChange.bind(this);
    }

    stepLabel (step: POSITION_STEPS): unknown {

        const isMatured = matured(this.position) ? 0 : 1;

        return [
            POSITION_LABELS[this.type][this.mode][isMatured],
            STEP_LABELS[step],
        ].join(' ');
    }

    stepDisabled (step: POSITION_STEPS): boolean {

        // when a transaction is terminal, theoretically all steps could be accessed
        const isTerminal = this.transaction?.isPending() || this.transaction?.isFinal();

        return this.host.step < step && !isTerminal;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    stepPanelContent (step: POSITION_STEPS): unknown {

        // override in concrete class
        return nothing;
    }

    dispose (): void {

        this.disposeTransaction();
    }

    createTransaction (): void {

        // override in concrete class
    }

    disposeTransaction (): void {

        this.logger.log('disposeTransaction()... ', this.transaction);

        // we first want to dispose of ongoing notifications for the current transaction
        this.disposeNotifications();

        // when a call is made to dispose of a transaction, we always want to unsubscribe from future updates,
        // as the strategy is done with this transaction (it might be further observed by the transaction service)

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.transaction?.unsubscribe(TRANSACTION_TOPIC.STATE, this.handleTransactionChange);
        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.transaction?.unsubscribe(TRANSACTION_TOPIC.STATUS, this.handleTransactionChange);

        // don't dispose of transactions which are submitted to the chain...
        if (this.transaction?.isPending() || this.transaction?.isFinal()) return;

        this.transaction?.dispose();

        this.transaction = undefined;
    }

    protected disposeNotifications (): void {

        if (!this.notification) return;

        if (this.transaction?.isPending()) {

            const content = isLendPosition(this.position)
                ? this.mode === POSITION_MODES.ENTER
                    ? NOTIFICATIONS.LEND.TRANSACTION_DISPOSED
                    : NOTIFICATIONS.REDEEM.TRANSACTION_DISPOSED
                : this.mode === POSITION_MODES.ENTER
                    ? NOTIFICATIONS.POOL_ENTER.TRANSACTION_DISPOSED
                    : NOTIFICATIONS.POOL_EXIT.TRANSACTION_DISPOSED;

            notifications.update(this.notification, createNotification({
                id: this.notification,
                type: 'info',
                content,
            }));

        } else {

            // ensure the notification will dismiss automatically
            notifications.update(this.notification, { dismissable: true, timeout: NOTIFICATION_TIMEOUTS.info });
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected handleTransactionChange (message: TransactionMessage<Transaction, TransactionTopic>): void {

        // override in concrete class
    }
}

/**
 * A strategy for lend positions.
 *
 * @remarks
 * Implements the logic for lending, exiting and redeeming lend positions.
 */
export class LendPositionStrategy extends PositionStrategy {

    protected logger = serviceLocator.get(LOG_SERVICE).group('lend-position-strategy');

    protected marketMachine = orchestrator.market;

    protected transaction?: LendTransaction | RedeemTransaction;

    protected position!: LendPosition;

    stepPanelContent (step: POSITION_STEPS): unknown {

        const isPending = this.transaction?.isPending();

        switch (step) {

            case POSITION_STEPS.PREVIEW:

                return this.mode === POSITION_MODES.ENTER
                    ? html`<ill-lend-transaction-preview .transaction=${ this.transaction }></ill-lend-transaction-preview>`
                    : html`<ill-redeem-transaction-preview .transaction=${ this.transaction }></ill-redeem-transaction-preview>`;

            case POSITION_STEPS.RESULT:

                return isPending
                    ? this.mode === POSITION_MODES.ENTER
                        ? html`<ill-lend-transaction-status .transaction=${ this.transaction }></ill-lend-transaction-status>`
                        : html`<ill-redeem-transaction-status .transaction=${ this.transaction }></ill-redeem-transaction-status>`
                    : this.mode === POSITION_MODES.ENTER
                        ? html`<ill-lend-transaction-result .transaction=${ this.transaction }></ill-lend-transaction-result>`
                        : html`<ill-redeem-transaction-result .transaction=${ this.transaction }></ill-redeem-transaction-result>`;

            default:

                return nothing;
        }
    }

    createTransaction (): void {

        if (!this.accountMachine.state.matches(ACCOUNT.STATES.CONNECTED)) return;

        const connection = this.walletService.state.connection;
        const quotesByMarket = this.marketMachine.state.context.quotesByMarket;
        const isMatured = matured(this.position);
        const market = this.position.market;
        const key = marketKey(market);
        const apr = quotesByMarket.get(key)?.apr;

        this.disposeTransaction();

        // we create transactions through a service - this allows other components to detect when a transaction is created
        // and subscribe to it for status changes (e.g. globally notify on failed transactions)
        // and it allows the transaction service to serialize/parse transaction into/from local storage and associate
        // transaction constructors with the serialized data
        this.transaction = this.mode === POSITION_MODES.ENTER
            ? this.transactionService.create(LendTransaction, {
                state: {
                    market,
                    apr,
                },
                connection,
            })
            : this.transactionService.create(RedeemTransaction, {
                state: {
                    market,
                    position: this.position,
                    amount: isMatured ? this.position.iptBalance : undefined,
                },
                connection,
            });

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.transaction.subscribe(TRANSACTION_TOPIC.STATUS, this.handleTransactionChange);
        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.transaction.subscribe(TRANSACTION_TOPIC.STATE, this.handleTransactionChange);

        void this.transaction.update();

        this.logger.log('createTransation()... ', this.transaction);
    }

    protected handleTransactionChange (message: TransactionMessage<LendTransaction | RedeemTransaction, TransactionTopic>): void {

        this.logger.log('handleTransactionChange()... ', message);

        if (message.topic === TRANSACTION_TOPIC.STATUS) {

            switch (message.transaction.status) {

                case LEND_STATUS.APPROVING:
                case REDEEM_STATUS.APPROVING:

                    // TODO: i think this is resolved using disposeNotifications()
                    // TODO: when we navigate away from the positions page while we have one of these notifications pending
                    // they don't get automatically discarded (because the positions page unsubscribes from the transaction
                    // when unloaded and won't catch further events to update the notifications)
                    // this might require a separate browser-based service, whose only job is showing notifications for
                    // transactions when running in the browser
                    this.notification = notifications.show({
                        type: 'progress',
                        content: NOTIFICATIONS.LEND.APPROVAL_PENDING,
                        dismissable: false,
                    });

                    this.host.step = POSITION_STEPS.RESULT;

                    break;

                case LEND_STATUS.PENDING:
                case REDEEM_STATUS.PENDING:

                    if (this.notification) {

                        notifications.update(this.notification, createNotification({
                            id: this.notification,
                            type: 'success',
                            content: NOTIFICATIONS.LEND.APPROVAL_SUCCESS,
                        }));
                    }

                    this.host.step = POSITION_STEPS.RESULT;

                    break;

                case LEND_STATUS.DISPOSED:
                case REDEEM_STATUS.DISPOSED:

                    // transaction is terminal and won't change any longer
                    // eslint-disable-next-line @typescript-eslint/unbound-method
                    message.transaction.unsubscribe(TRANSACTION_TOPIC.STATUS, this.handleTransactionChange);
                    // eslint-disable-next-line @typescript-eslint/unbound-method
                    message.transaction.unsubscribe(TRANSACTION_TOPIC.STATE, this.handleTransactionChange);

                    break;

                case LEND_STATUS.SUCCESS:
                case LEND_STATUS.FAILURE:
                case REDEEM_STATUS.SUCCESS:
                case REDEEM_STATUS.FAILURE:

                    // transaction is terminal and won't change any longer
                    // eslint-disable-next-line @typescript-eslint/unbound-method
                    message.transaction.unsubscribe(TRANSACTION_TOPIC.STATUS, this.handleTransactionChange);
                    // eslint-disable-next-line @typescript-eslint/unbound-method
                    message.transaction.unsubscribe(TRANSACTION_TOPIC.STATE, this.handleTransactionChange);

                    this.host.step = POSITION_STEPS.RESULT;

                    break;

                case LEND_STATUS.ERROR:
                case REDEEM_STATUS.ERROR:

                    // if we're coming from APPROVING to ERROR
                    if (message.detail === REDEEM_STATUS.APPROVING) {

                        if (this.notification) {

                            notifications.update(this.notification, createNotification({
                                id: this.notification,
                                type: 'failure',
                                content: NOTIFICATIONS.LEND.APPROVAL_FAILURE(message.transaction.error?.message ?? 'Unknown error.'),
                            }));

                        } else {

                            this.notification = notifications.show({
                                type: 'failure',
                                content: NOTIFICATIONS.LEND.APPROVAL_FAILURE(message.transaction.error?.message ?? 'Unknown error.'),
                            });
                        }

                        this.host.step = POSITION_STEPS.PREVIEW;
                    }

                    break;
            }
        }

        this.host.requestUpdate();
    }
}

/**
 * A strategy for pool positions.
 *
 * @remarks
 * Implements the logic for adding liquidity and removing liquidity to/from pool positions.
 */
export class PoolPositionStrategy extends PositionStrategy {

    protected logger = serviceLocator.get(LOG_SERVICE).group('pool-position-strategy');

    protected transaction?: AddLiquidityTransaction | RemoveLiquidityTransaction;

    protected position!: PoolPosition;

    stepPanelContent (step: POSITION_STEPS): unknown {

        const isPending = this.transaction?.isPending();

        switch (step) {

            case POSITION_STEPS.PREVIEW:

                return this.mode === POSITION_MODES.ENTER
                    ? html`<ill-add-liquidity-transaction-preview .transaction=${ this.transaction }></ill-add-liquidity-transaction-preview>`
                    : html`<ill-remove-liquidity-transaction-preview .transaction=${ this.transaction }></ill-remove-liquidity-transaction-preview>`;

            case POSITION_STEPS.RESULT:

                return isPending
                    ? this.mode === POSITION_MODES.ENTER
                        ? html`<ill-add-liquidity-transaction-status .transaction=${ this.transaction }></ill-add-liquidity-transaction-status>`
                        : html`<ill-remove-liquidity-transaction-status .transaction=${ this.transaction }></ill-remove-liquidity-transaction-status>`
                    : this.mode === POSITION_MODES.ENTER
                        ? html`<ill-add-liquidity-transaction-result .transaction=${ this.transaction }></ill-add-liquidity-transaction-result>`
                        : html`<ill-remove-liquidity-transaction-result .transaction=${ this.transaction }></ill-remove-liquidity-transaction-result>`;

            default:

                return nothing;
        }
    }

    createTransaction (): void {

        if (!this.accountMachine.state.matches(ACCOUNT.STATES.CONNECTED)) return;

        const connection = this.walletService.state.connection;

        const pool = this.position.pool;

        // FIXME: throw if trying to create an AddLiquidityTransaction for a matured pool...

        this.disposeTransaction();

        // we create transactions through a service - this allows other components to detect when a transaction is created
        // and subscribe to it for status changes (e.g. globally notify on failed transactions)
        // and it allows the transaction service to serialize/parse transaction into/from local storage and associate
        // transaction constructors with the serialized data
        this.transaction = this.mode === POSITION_MODES.ENTER
            ? this.transactionService.create(AddLiquidityTransaction, {
                state: {
                    pool,
                },
                connection,
            })
            : this.transactionService.create(RemoveLiquidityTransaction, {
                state: {
                    pool,
                    burnForUnderlying: true,
                },
                connection,
            });

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.transaction.subscribe(TRANSACTION_TOPIC.STATUS, this.handleTransactionChange);
        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.transaction.subscribe(TRANSACTION_TOPIC.STATE, this.handleTransactionChange);

        this.logger.log('createTransation()... ', this.transaction);
    }

    protected handleTransactionChange (message: TransactionMessage<AddLiquidityTransaction | RemoveLiquidityTransaction, TransactionTopic>): void {

        this.logger.log('handleTransactionChange()... ', message);

        if (message.topic === TRANSACTION_TOPIC.STATUS) {

            switch (message.transaction.status) {

                case ADD_LIQUIDITY_STATUS.APPROVING:
                case REMOVE_LIQUIDITY_STATUS.APPROVING:

                    this.notification = notifications.show({
                        type: 'progress',
                        content: NOTIFICATIONS.POOL_ENTER.APPROVAL_PENDING,
                        dismissable: false,
                    });

                    this.host.step = POSITION_STEPS.RESULT;

                    break;

                case ADD_LIQUIDITY_STATUS.PENDING:
                case REMOVE_LIQUIDITY_STATUS.PENDING:

                    if (this.notification) {

                        notifications.update(this.notification, createNotification({
                            id: this.notification,
                            type: 'success',
                            content: NOTIFICATIONS.POOL_ENTER.APPROVAL_SUCCESS,
                        }));
                    }

                    this.host.step = POSITION_STEPS.RESULT;

                    break;

                case ADD_LIQUIDITY_STATUS.DISPOSED:
                case REMOVE_LIQUIDITY_STATUS.DISPOSED:

                    // transaction is terminal and won't change any longer
                    // eslint-disable-next-line @typescript-eslint/unbound-method
                    message.transaction.unsubscribe(TRANSACTION_TOPIC.STATUS, this.handleTransactionChange);
                    // eslint-disable-next-line @typescript-eslint/unbound-method
                    message.transaction.unsubscribe(TRANSACTION_TOPIC.STATE, this.handleTransactionChange);

                    break;

                case ADD_LIQUIDITY_STATUS.SUCCESS:
                case ADD_LIQUIDITY_STATUS.FAILURE:
                case REMOVE_LIQUIDITY_STATUS.SUCCESS:
                case REMOVE_LIQUIDITY_STATUS.FAILURE:

                    // transaction is terminal and won't change any longer
                    // eslint-disable-next-line @typescript-eslint/unbound-method
                    message.transaction.unsubscribe(TRANSACTION_TOPIC.STATUS, this.handleTransactionChange);
                    // eslint-disable-next-line @typescript-eslint/unbound-method
                    message.transaction.unsubscribe(TRANSACTION_TOPIC.STATE, this.handleTransactionChange);

                    this.host.step = POSITION_STEPS.RESULT;

                    break;

                case ADD_LIQUIDITY_STATUS.ERROR:
                case REMOVE_LIQUIDITY_STATUS.ERROR:

                    // if we're coming from APPROVING to ERROR
                    if (message.detail === ADD_LIQUIDITY_STATUS.APPROVING) {

                        if (this.notification) {

                            notifications.update(this.notification, createNotification({
                                id: this.notification,
                                type: 'failure',
                                content: NOTIFICATIONS.POOL_ENTER.APPROVAL_FAILURE(message.transaction.error?.message ?? 'Unknown error.'),
                            }));

                        } else {

                            this.notification = notifications.show({
                                type: 'failure',
                                content: NOTIFICATIONS.POOL_ENTER.APPROVAL_FAILURE(message.transaction.error?.message ?? 'Unknown error.'),
                            });
                        }

                        this.host.step = POSITION_STEPS.PREVIEW;
                    }

                    break;
            }
        }

        this.host.requestUpdate();
    }
}
