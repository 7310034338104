import { ErrorService } from '../errors';
import { LogService } from '../logger';
import { PreferencesService } from '../preferences';
import { DEFAULT_SETTINGS } from './constants';
import { Settings, SettingsService, Subscriber } from './interfaces';

export class BrowserSettingsService implements SettingsService {

    protected subscribers = new Set<Subscriber<Settings>>();

    protected settings: Settings = { ...DEFAULT_SETTINGS };

    protected isSyncing = false;

    protected preferences?: PreferencesService;

    protected errors?: ErrorService;

    protected logger?: LogService;

    constructor (preferences?: PreferencesService, errors?: ErrorService, logger?: LogService) {

        this.preferences = preferences;
        this.errors = errors;
        this.logger = logger?.group('settings-service');

        this.handleStorageChange = this.handleStorageChange.bind(this);

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.preferences?.subscribe(this.handleStorageChange, 'settings');

        this.initialize();
    }

    set (value: Partial<Settings>): void {

        this.settings = { ...this.settings, ...value };

        this.syncStorage();

        this.notify();
    }

    get (): Settings {

        return this.settings;
    }

    subscribe (subscriber: Subscriber<Settings>): void {

        this.subscribers.add(subscriber);
    }

    unsubscribe (subscriber: Subscriber<Settings>): void {

        this.subscribers.delete(subscriber);
    }

    protected initialize (): void {

        if (this.preferences?.enabled) {

            // check if there are settings stored in the preferences
            const settings = this.preferences.get('settings');

            // apply those settings to memory or store the current settings in storage
            this.syncStorage(settings ?? undefined);

            this.notify();
        }
    }

    protected handleStorageChange (storage: Settings | null): void {

        // this storage change event (most likely) originated from the `syncStorage` method
        // we already have updated the storage and synced it with the memory, so we can
        // ignore this event (it's essentially a replay from storing the updated storage)
        if (this.isSyncing) {

            // mark the service as not syncing storage (we're done syncing when this event is caught)
            this.isSyncing = false;
            // and return early (we don't need to sync another time and events are dispatched)
            return;
        }

        this.syncStorage(storage ?? DEFAULT_SETTINGS);

        this.notify();
    }

    /**
     * Synchronizes the memory and preferences storage of transactions
     *
     * @remarks
     * Ensures that transactions in memory and in preferences storage are synced and up-to-date.
     * Purges invalid transactions both from memory and preferences storage and handles different
     * paths if preferences are disabled or when an update comes from a memory transaction vs from
     * the preferences storage (in which case the storage argument should be provided).
     */
    protected syncStorage (storage?: Settings): void {

        if (this.preferences?.enabled) {

            if (storage) {

                // sync from storage to memory
                // we use a deep merge here to ensure we can add new settings keys to the service
                // in the future, and the (outdated) storage doesn't erase the new keys during sync
                // e.g.: this.settings = { ...this.settings, ...storage }; would override nested
                // settings keys that exist in the default settings but not in the storage
                this.settings = deepMerge(this.settings, storage);

            } else {

                // sync from memory to storage
                // mark the service as syncing storage, so we know where storage changes originate from
                this.isSyncing = true;

                this.preferences?.set('settings', this.settings);
            }
        }
    }

    protected notify (): void {

        this.subscribers.forEach(subscriber => subscriber(this.settings));
    }
}

/**
 * A helper type for mapped object types to get around string index signatures
 */
type Dict<T = Record<string, unknown>> = { [K in keyof T]: T[K]; };

/**
 * Checks if a value is a plain object / record
 *
 * @remarks
 * We assume an object that's not null and not an array is actually an object.
 * We get settings from a serializable storage, so we can assume it's
 * JSON-parsed and objects are plain objects / records.
 */
const isDict = (value: unknown): value is Dict => {

    return (typeof value === 'object')
        && (value !== null)
        && !(value instanceof Array);
};

/**
 * Deeply merge object b into object a returning a new object
 *
 * @remarks
 * We need to do this for nested settings objects, to ensure if new settings are added
 * to the settings service, their defaults don't get erased by a read from outdated
 * localStorage objects.
 * The method doesn't merge arrays, but will override the value of a with the value of b.
 */
function deepMerge<T extends { [K in keyof T]: T[K]; }> (a: T, b: Partial<T>): T {

    const result = {} as T;

    const keys = new Set(Object.keys(a).concat(Object.keys(b))) as Set<keyof T>;

    keys.forEach(key => {

        const valueA = a[key];
        const valueB = b[key];

        if (valueB === undefined) {

            result[key] = valueA;

        } else if (valueA === undefined) {

            result[key] = valueB as T[typeof key];

        } else {

            if (isDict(valueA)) {

                result[key] = deepMerge(valueA as Dict, valueB as Dict) as T[typeof key];

            } else {

                result[key] = valueB as T[typeof key];
            }
        }
    });

    return result;
}
