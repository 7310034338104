import { html } from 'lit';

/**
 * Creates a progress bar template.
 *
 * @remarks
 * The progress is calculated as a percentage of the current value relative to the min and max value.
 * When using the default min and max values, the current should be a value between 0 and 1.
 *
 * @param current - the current progress (a value between min and max)
 * @param min - the minimum progress value (default: 0)
 * @param max - the maximum progress value (default: 1)
 */
export const progressBar = (current: number, min = 0, max = 1) => {

    // cap the max fill at 1 (100%)
    const fill = Math.min((current - min) / (max - min), 1).toFixed(2);

    return html`
    <span class="progress-bar" style="--progress:${ fill };">
        <span class="progress-bar-track"></span>
        <span class="progress-bar-fill"></span>
    </span>
    `;
};
