import { ValueChangeEvent } from '@swivel-finance/ui/elements/input';
import { html, LitElement, nothing, svg } from 'lit';
import { customElement } from 'lit/decorators.js';
import { PREFERENCES_SERVICE, serviceLocator } from '../../core/services';
import { EIP6963ProviderDetail, getEthereumProvider, getEthereumProviderIdentifier, getEthereumProviders } from '../../core/services/wallet';
import { ACCOUNT, orchestrator } from '../../state/orchestrator';

const LUMI_CONNECT = svg`
<svg viewBox="0 0 190 284" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1690_15270)">
        <path d="M175.425 121.315C175.425 121.315 176.756 94.593 140.874 62.6932C119.347 43.5546 98.4659 36.1377 91.7938 35.5113C98.0069 42.4551 101.646 48.5058 99.7365 50.425C90.1041 47.7728 63.5219 40.5692 64.5065 0.0329909C64.5131 -0.273546 62.1848 1.47905 62.0651 1.60566C60.9409 2.79183 60.0761 4.24454 59.1847 5.60397C56.6569 9.45568 54.169 13.3407 50.9626 16.6793C47.2706 20.5177 41.1307 24.7492 37.7713 28.9874C31.6712 36.6908 29.15 42.1485 28.6644 50.9115C28.1589 59.9943 29.8618 66.6515 29.8618 66.6515C29.8618 66.6515 21.6996 67.831 13.4974 50.4317C12.0739 48.146 12.6593 74.6415 8.10916 92.5139C6.65898 98.2048 -0.259317 126.52 10.2578 153.581C20.7749 180.643 33.607 185.688 33.607 185.688L8.60141 203.907C8.60141 203.907 -3.26611 212.823 0.884863 219.214C5.03583 225.604 13.3444 222.486 13.3444 222.486C19.4245 220.013 25.5978 215.589 31.8043 210.391C31.8043 210.391 29.8552 223.379 29.8552 231.549C29.8552 239.718 36.4874 250.867 48.0556 251.014C48.6809 255.465 50.2042 259.823 51.6877 264.048C53.3441 268.773 54.8541 273.924 57.4019 278.262C59.1781 281.281 62.1782 284.006 65.8569 284.006C72.09 284.006 77.8707 273.011 77.8707 273.011C77.8707 273.011 83.2124 261.602 83.5051 259.283C96.4104 259.43 103.382 259.49 116.587 256.964C120.757 264.455 138.765 291.843 145.81 273.311C149.369 263.948 150.26 250.274 150.26 250.274C150.26 250.274 168.553 247.448 168.407 229.17C168.261 210.891 162.573 194.398 162.573 194.398C165.626 191.126 168.58 187.76 171.48 184.355C176.443 178.524 182.064 172.354 185.304 165.343C187.486 160.618 188.789 155.161 189.534 150.016C190.133 145.885 190.659 140.274 187.645 136.882C184.718 133.59 180.321 137.468 178.159 139.714C172.798 145.278 170.409 152.728 167.13 159.559C164.21 165.643 161.13 172.433 156.42 177.371C156.247 177.551 156.074 177.725 155.901 177.898C154.271 173.44 154.644 172.7 154.644 172.7C154.644 172.7 175.419 152.935 175.419 121.322" fill="#203A6F"/>
        <path d="M141.879 176.559C141.879 176.559 164.144 145.872 160.871 118.757C160.871 118.757 159.574 85.2776 123.219 58.6022C115.696 52.9179 115.117 52.2249 114.073 51.8783C113.028 51.5318 111.638 51.4119 112.104 53.3844C112.569 55.3569 119.401 65.6792 114.538 71.4767C111.299 75.4217 96.4775 62.5472 96.4775 62.5472C96.4775 62.5472 90.9229 56.4031 81.0777 53.3844C71.2391 50.3656 58.3671 38.8505 60.2364 18.5857C59.6576 13.4812 55.3736 21.138 55.3736 21.138C55.3736 21.138 41.1313 41.4361 40.7853 61.1544C40.4394 80.8727 45.0694 87.9498 45.0694 87.9498C45.0694 87.9498 33.3748 89.689 21.1015 73.1027C19.8309 70.897 19.4784 70.897 19.1325 71.71C18.7865 72.5229 19.3653 81.6857 18.7865 86.0905C18.2078 90.4953 12.7663 115.318 14.9682 130.279C14.9682 130.279 15.4338 164.144 54.103 186.068C54.103 187.115 42.6413 212.164 46.6925 229.91C50.7437 247.656 81.1376 247.542 87.823 247.656C94.5085 247.769 141.167 246.729 152.283 230.603C163.399 214.483 148.844 194.185 141.879 176.552V176.559Z" fill="#187FC3"/>
        <path d="M106.142 172.047C106.142 172.047 158.508 160.105 153.991 121.222C149.501 82.538 123.73 82.7113 123.724 82.3848C123.724 82.3848 109.022 82.6713 102.537 94.0798C96.0507 105.488 80.7706 97.8316 80.7706 97.8316C80.7706 97.8316 70.2468 93.0669 55.9779 95.9524C41.7089 98.8378 35.223 113.425 35.223 113.425C35.223 113.425 24.8456 135.083 49.0596 157.893C73.2736 180.703 94.0284 174.066 106.135 172.04L106.142 172.047Z" fill="#3AA6DE"/>
        <path d="M126.692 119.057C120.803 119.057 116.029 114.274 116.029 108.375C116.029 102.475 120.803 97.6924 126.692 97.6924C132.581 97.6924 137.355 102.475 137.355 108.375C137.355 114.274 132.581 119.057 126.692 119.057Z" fill="#231F20"/>
        <path d="M134.369 110.86C134.369 110.86 134.914 109.181 134.369 107.441" stroke="white" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round"/>
        <path d="M132.4 103.883C132.4 103.883 131.017 101.178 127.737 101.424" stroke="white" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round"/>
        <path d="M64.858 130.519C58.9687 130.519 54.1945 125.736 54.1945 119.836C54.1945 113.937 58.9687 109.154 64.858 109.154C70.7473 109.154 75.5215 113.937 75.5215 119.836C75.5215 125.736 70.7473 130.519 64.858 130.519Z" fill="#231F20"/>
        <path d="M72.5349 122.322C72.5349 122.322 73.0804 120.643 72.5349 118.903" stroke="white" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round"/>
        <path d="M70.5664 115.338C70.5664 115.338 69.1827 112.633 65.9032 112.879" stroke="white" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round"/>
        <path d="M102.351 133.098C100.994 130.532 101.18 127.773 102.757 126.934C104.34 126.094 106.715 127.5 108.072 130.066C109.429 132.631 109.243 135.39 107.666 136.23C106.083 137.069 103.708 135.663 102.351 133.098Z" fill="#E6A4A1" stroke="#231F20" stroke-width="5" stroke-miterlimit="10"/>
    </g>
    <defs>
        <clipPath id="clip0_1690_15270">
            <rect width="190" height="284" fill="white" transform="matrix(-1 0 0 1 190 0)"/>
        </clipPath>
    </defs>
</svg>
`;

const template = function (this: AccountConnectElement) {

    const accountState = this.accountMachine.state;

    if (!accountState.matches(ACCOUNT.STATES.DISCONNECTED)
        && !accountState.matches(ACCOUNT.STATES.ERROR)) {

        return nothing;
    }

    return html`
    ${ LUMI_CONNECT }
    ${ this.providers.length <= 1
        ? html`
        <button class="primary" @click=${ () => this.handleConnect() }>Connect Wallet</button>
        `
        : html`
        <ui-select
            class="account-popup-wallet-select"
            .placeholder=${ 'Connect Wallet' }
            @ui-value-changed=${ (event: ValueChangeEvent<EIP6963ProviderDetail>) => this.handleSelectProvider(event) }>
            <button type="button" data-part="trigger" class="ui-select-trigger primary">
                <span class="ui-select-trigger-label">
                    Connect Wallet
                </span>
                <ui-icon class="ui-select-trigger-toggle" name="triangle"></ui-icon>
            </button>
            <ui-listbox data-part="overlay" class="account-popup-wallets">
            ${
                this.providers.map(provider => html`
                <ui-listitem class="account-popup-wallet" .value=${ provider }>
                    <img class="icon" src="${ provider.info.icon }" alt="${ provider.info.name }" />
                    <span class="name">${ provider.info.name }</span>
                </ui-listitem>
                `)
            }
            </ui-listbox>
        </ui-select>
        `
    }
    `;
};

@customElement('ill-account-connect')
export class AccountConnectElement extends LitElement {

    protected accountMachine = orchestrator.account;

    protected preferencesService = serviceLocator.get(PREFERENCES_SERVICE);

    protected providers: EIP6963ProviderDetail[] = [];

    protected selectedProvider?: EIP6963ProviderDetail;

    constructor () {

        super();

        this.handleTransition = this.handleTransition.bind(this);
    }

    connectedCallback (): void {

        super.connectedCallback();

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.accountMachine.onTransition(this.handleTransition);

        void this.updateProviders();
    }

    disconnectedCallback (): void {

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.accountMachine.off(this.handleTransition);

        super.disconnectedCallback();
    }

    protected createRenderRoot (): Element | ShadowRoot {

        return this;
    }

    protected render (): unknown {

        return template.apply(this);
    }

    protected async updateProviders (): Promise<void> {

        // get the available browser wallet providers
        this.providers = await getEthereumProviders();

        // if we have don't have multiple providers, use the one installed
        if (this.providers.length <= 1) {

            this.selectedProvider = this.providers[0];

        } else {

            // get the last connected browser wallet provider
            const identifier = this.selectedProvider
                ? getEthereumProviderIdentifier(this.selectedProvider)
                : this.preferencesService.get('walletIdentifier') ?? undefined;

            // check if the last connected browser wallet provider is still available
            this.selectedProvider = getEthereumProvider(this.providers, identifier);
        }

        this.requestUpdate();
    }

    protected handleSelectProvider (event: ValueChangeEvent<EIP6963ProviderDetail>): void {

        this.selectedProvider = event.detail.current;

        this.handleConnect();

        this.requestUpdate();
    }

    protected handleTransition () {

        this.requestUpdate();
    }

    protected handleConnect () {

        this.accountMachine.send(ACCOUNT.model.events[ACCOUNT.EVENTS.CONNECT]({
            walletIdentifier: getEthereumProviderIdentifier(this.selectedProvider),
        }));
    }
}
