import { Token } from '../../types';

export const SECONDS_PER_DAY = 86_400;

export const SECONDS_PER_MONTH = 2_592_000;

export const SECONDS_PER_YEAR = 31_536_000;

export const DAYS_PER_YEAR = 365;

export const DEFAULT_LOCALE = 'en-US';

export const PRECISION = 18;

export const ETH: Token = {
    address: 'ETH',
    decimals: 18,
    name: 'Ether',
    symbol: 'ETH',
    image: 'eth',
};

/**
 * A record of known token prices for stable coins
 */
export const TOKEN_PRICES = {
    DAI: '1',
    FEI: '1',
    FRAX: '1',
    USDC: '1',
    USDT: '1',
};

/**
 * The amount used for cached market quotes
 */
export const QUOTE_PREVIEW_AMOUNT = '100';

/**
 * The amount used for cached ether market quotes
 */
export const QUOTE_PREVIEW_AMOUNT_ETHER = '1';

/**
 * Time since maturity after which a position is considered 'archived' in seconds
 *
 * @remarks
 * We consider 2 weeks after maturity as archived: 60 * 60 * 24 * 7 * 2
 */
export const ARCHIVED_TIME = 1209600;

/**
 * Time after maturity when a strategy will roll over liquidity
 *
 * @remarks
 * We assume 3 days after maturity as the target for roll-over: 60 * 60 * 24 * 3
 */
export const ROLLOVER_TIME = 259200;
