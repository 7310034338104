import { TransactionResponse } from '@ethersproject/abstract-provider';
import { ERC20 } from '@swivel-finance/illuminate-js';
import { BigNumber, BigNumberish, ethers } from 'ethers';
import { TypedObject } from '../../../../shared/helpers';
import { Token } from '../../../../types';
import { fixed } from '../../../amount';
import { ERRORS, SECONDS_PER_DAY } from '../../../constants';
import { ENV } from '../../../env';
import { ErrorService } from '../../errors';
import { LogService } from '../../logger';
import { TimeService } from '../../time';
import { Connection, WalletService } from '../../wallet';
import { TokenService } from '../interfaces';
import { ChainlinkAggregatorContract, CHAINLINK_AGGREGATOR_ABI, FaucetContract, FAUCET_ABI } from '../utils';

export class ChainTokenService implements TokenService {

    /**
     * A cache for fetched token info.
     *
     * @remarks
     * We can prefill the cache with known static token info through the service constructor.
     */
    protected cache: Map<string, Token>;

    protected wallet: WalletService;

    protected timer: TimeService;

    protected errors: ErrorService;

    protected logger: LogService;

    constructor (tokens: Record<string, Token>, wallet: WalletService, timer: TimeService, errors: ErrorService, logger: LogService) {

        this.cache = new Map(TypedObject.entries(tokens));

        this.wallet = wallet;
        this.timer = timer;
        this.errors = errors;
        this.logger = logger.group('token-service');
    }

    async fetch (address: string, connection?: Connection): Promise<Token> {

        if (!this.cache.has(address)) {

            this.logger.warn('fetch()... token ', address, ' not cached.');

            try {

                const { provider } = connection ?? await this.wallet.connect();
                const contract = new ERC20(address, provider);

                const [name, symbol, decimals] = await Promise.all([
                    contract.name(),
                    contract.symbol(),
                    contract.decimals(),
                ]);

                const image = symbol.toLowerCase();

                this.cache.set(address, {
                    address,
                    name,
                    symbol,
                    decimals,
                    image,
                });

            } catch (error) {

                throw this.errors.process(error);
            }
        }

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return this.cache.get(address)!;
    }

    async fetchBalance (address: string, owner: string, connection?: Connection): Promise<string> {

        try {

            const { provider } = connection ?? await this.wallet.connect();
            const contract = new ERC20(address, provider);

            return await contract.balanceOf(owner);

        } catch (error) {

            throw this.errors.process(error);
        }
    }

    async fetchTotalSupply (address: string, connection?: Connection): Promise<string> {

        try {

            const { provider } = connection ?? await this.wallet.connect();
            const contract = new ERC20(address, provider);

            return await contract.totalSupply();

        } catch (error) {

            throw this.errors.process(error);
        }
    }

    async allowance (address: string, owner: string, spender: string, connection?: Connection): Promise<string> {

        try {

            const { provider } = connection ?? await this.wallet.connect();
            const contract = new ERC20(address, provider);

            return await contract.allowance(owner, spender);

        } catch (error) {

            throw this.errors.process(error);
        }
    }

    async approve (address: string, spender: string, amount: BigNumberish, connection?: Connection): Promise<TransactionResponse> {

        try {

            const { signer } = connection ?? await this.wallet.connect();
            const contract = new ERC20(address, signer);

            return await contract.approve(spender, amount);

        } catch (error) {

            throw this.errors.process(error);
        }
    }

    async canFaucet (address: string, connection?: Connection): Promise<boolean> {

        try {

            if (!ENV.faucetAddress) {

                throw this.errors.process(ERRORS.SERVICES.FAUCET.UNAVAILABLE);
            }

            const { account, provider } = connection ?? await this.wallet.connect();
            const contract = new ethers.Contract(ENV.faucetAddress, FAUCET_ABI, provider) as ethers.Contract & FaucetContract;

            const lastDripped = await contract.lastReceived(account.address, address);

            return BigNumber.from(Math.floor(this.timer.time() / 1000)).sub(lastDripped).gt(BigNumber.from(SECONDS_PER_DAY));

        } catch (error) {

            throw this.errors.isProcessed(error) ? error : this.errors.process(error);
        }
    }

    async faucet (address: string, connection?: Connection): Promise<TransactionResponse> {

        try {

            if (!ENV.faucetAddress) {

                throw this.errors.process(ERRORS.SERVICES.FAUCET.UNAVAILABLE);
            }

            const { signer } = connection ?? await this.wallet.connect();
            const contract = new ethers.Contract(ENV.faucetAddress, FAUCET_ABI, signer) as ethers.Contract & FaucetContract;

            const response = await contract.drip(address);

            return response;

        } catch (error) {

            throw this.errors.isProcessed(error) ? error : this.errors.process(error);
        }
    }

    async fetchPrice (symbol: string, connection?: Connection): Promise<string> {

        try {

            const address = ENV.priceFeedAddress[symbol];

            if (!address) {

                // TODO: make a proper error for this - but don't throw it, we just return an empty price string
                this.errors.process(new Error(`Missing price feed address for token ${ symbol }.`));

                return '';
            }

            const { provider } = connection ?? await this.wallet.connect();
            const contract = new ethers.Contract(address, CHAINLINK_AGGREGATOR_ABI, provider) as ethers.Contract & ChainlinkAggregatorContract;

            const [decimals, answer] = await Promise.all([
                contract.decimals(),
                contract.latestAnswer(),
            ]);

            const scale = '1'.concat(Array(decimals).fill('0').join(''));
            const price = fixed(answer.toString()).divUnsafe(fixed(scale)).toString();

            return price;

        } catch (error) {

            throw this.errors.isProcessed(error) ? error : this.errors.process(error);
        }
    }
}
