import { parseIlluminateError } from '@swivel-finance/illuminate-js';
import { ERRORS, ERROR_CODES } from '../../constants';
import { LogService } from '../logger';
import { isErrorLike, isEthereumError, isEthersError, isResponse, ProcessedError } from './error';
import { ErrorLike, ErrorNotifier, ErrorService, EthereumError } from './interfaces';

const MESSAGE_END = /(\.\s*)?$/;

/**
 * The ErrorService implementation.
 */
export class NotifyingErrorService implements ErrorService {

    protected notifiers?: ErrorNotifier[];

    protected logger?: LogService;

    /**
     * Create an ErrorService instance.
     *
     * @param n - optional list of {@link ErrorNotifier}s to send errors to
     * @param l - optional logger to log errors to
     */
    constructor (n?: ErrorNotifier[], l?: LogService) {

        this.notifiers = n;
        this.logger = l;
    }

    /**
     * Creates a {@link ProcessedError} from the passed in error object which sanitizes
     * the error message and name, logs the error in testnet deployments and optionally
     * reports the error to bugsnag (via `ENV.bugsnag.enabled`).
     *
     * @param source - the error to process (can be a failed `Response`, an `Error` or an `ErrorLike`)
     * @param target - optional target error to override the source error message with
     * @param ignore - ignore reporting the error
     * @param merge - merge the source and target error messages instead of overriding with the target
     * @returns the {@link ProcessedError} instance
     */
    process (
        // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
        source: Response | EthereumError | ErrorLike | unknown,
        target?: ErrorLike,
        ignore = false,
        merge = false,
    ): ProcessedError {

        if (isResponse(source)) {

            const code = source.status.toString();
            const error = ERROR_CODES.HTTP[code as keyof typeof ERROR_CODES.HTTP]
                || ERROR_CODES.HTTP.DEFAULT;

            target = merge ? this.merge(target, error) : target ?? error;

        } else if (isEthereumError(source)) {

            // check if we have an Illuminate `Exception` (custom error)
            // this could be the case for MetaMaskProviderRpcErrors (e.g. reverted transactions)
            const customError = parseIlluminateError(source);
            let error: ErrorLike | undefined;

            if (customError) {

                // make the extracted custom error the source, backup the original error as cause
                customError.cause = { name: 'EthereumError', ...source };
                source = customError;
                error = customError;

            } else {

                const code = source.code.toString();
                error = ERROR_CODES.ETHEREUM.PROVIDER[code as keyof typeof ERROR_CODES.ETHEREUM.PROVIDER]
                    || ERROR_CODES.ETHEREUM.RPC[code as keyof typeof ERROR_CODES.ETHEREUM.RPC]
                    || ERRORS.ETHEREUM.RPC.UNKNOWN;
            }

            target = merge ? this.merge(target, error) : target ?? error;

        } else if (isEthersError(source)) {

            // check if we have an Illuminate `Exception` (custom error)
            // this could be the case for JsonRpcProviderErrors (e.g. estimateGas or callStatic error)
            const customError = parseIlluminateError(source);
            let error: ErrorLike | undefined;

            if (customError) {

                // make the extracted custom error the source, backup the original error as cause
                customError.cause = { name: 'EthersError', ...source };
                source = customError;
                error = customError;

            } else {

                const code = source.code;
                error = ERROR_CODES.ETHERS[code as keyof typeof ERROR_CODES.ETHERS]
                    || ERROR_CODES.ETHEREUM.TRANSACTION[code as keyof typeof ERROR_CODES.ETHEREUM.TRANSACTION]
                    || ERRORS.ETHEREUM.TRANSACTION.UNKNOWN;

                // a CALL_EXCEPTION error can happen during an actual call exception or during gas estimation as response
                // to a UNPREDICTABLE_GAS_LIMIT (gas estimation simulates a tx which can lead to a call exception)
                if (this.is(error, ERRORS.ETHEREUM.TRANSACTION.CALL_EXCEPTION)) {

                    // the original error message is simply 'Transaction failed.'
                    let message = error.message;

                    try {

                        // the UNPREDICTABLE_GAS_LIMIT source error has a nested error which contains the original
                        // rpc call exception as stringified JSON in its' body field - try getting that...
                        // we can be a bit messy here with type assertions, as we don't know if the source error
                        // will indeed be there and we catch and ignore any error that might occurr
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-unsafe-member-access
                        const sourceMessage = JSON.parse(source.error!.error!.body).error.message as string;

                        message = sourceMessage
                            ? sourceMessage.charAt(0).toUpperCase() + sourceMessage.slice(1) + '.'
                            : message;

                    } catch {

                        // if we fail to extract the original rpc call error message,
                        // we use the reason (this usually contains the revert string)
                        // or fall back to the default message
                        message = ((source.reason instanceof Array) ? source.reason.join('\n') : source.reason) || message;
                    }

                    error.message = message;
                }
            }

            target = merge ? this.merge(target, error) : target ?? error;

        } else if (isErrorLike(source)) {

            target = merge ? this.merge(target, source) : target ?? source;

        } else {

            target = target ?? ERRORS.DEFAULT;
        }

        const error = new ProcessedError(target, source);

        this.log(error);

        this.notify(error, ignore);

        return error;
    }

    /**
     * Compares two errors
     *
     * @param e - the error object to check
     * @param o - the other error object to compare to
     * @returns `true` if both error objects have the same `name`, `false` otherwise
     */
    is (e: unknown, o: ErrorLike): boolean {

        return (e as ErrorLike)?.name === o.name;
    }

    /**
     * Checks if an error object is a {@link ProcessedError} instance
     *
     * @param e - the error object to check
     */
    isProcessed (e: unknown): e is ProcessedError {

        return e instanceof ProcessedError;
    }

    /**
     * Combine the source's and target's messages, returning a new ErrorLike
     *
     * @param target - the {@link ErrorLike} to append the source's message to
     * @param source - the {@link ErrorLike} to append to the target's message
     * @returns a new {@link ErrorLike} with the combined messages
     */
    merge (target?: ErrorLike, source?: ErrorLike): ErrorLike {

        const merged = target
            ? source
                ? this.updateMessage(target, [[MESSAGE_END, `: ${ source.message }`]])
                : target
            : source ?? ERRORS.DEFAULT;

        return merged;
    }

    /**
     * Updates the message of an {@link ErrorLike} using `RegExp` patterns and `string` values
     *
     * @param e - the {@link ErrorLike} to update
     * @param u - the update patterns and values
     * @returns a new {@link ErrorLike} with the updated message
     */
    updateMessage (e: ErrorLike, u: [RegExp, string][]): ErrorLike {

        let message = e.message;

        u.forEach(([reg, rep]) => message = message.replace(reg, rep));

        return {
            ...e,
            message,
        };
    }

    /**
     * Log a processed error to the registered logger
     *
     * @param e
     */
    protected log (e: ProcessedError): void {

        this.logger?.error(e.source);
        this.logger?.warn(e);
    }

    /**
     * Notify a processed error to any registered {@link ErrorNotifier}s
     *
     * @remarks
     * Errors can be processed multiple times to change the error message before it
     * reaches the UI, i.e. a failed request will be processed by the http service,
     * but might be processed a second time by a state machine for a more useful
     * error message. In those cases, we don't want to report the error a second
     * time, so we filter out errors, whose source is itself a processed error.
     *
     * @param e - the error to notify
     * @param i - should the error be ignored
     */
    protected notify (e: ProcessedError, i = false): void {

        if (!i && !(e.source instanceof ProcessedError)) {

            this.notifiers?.forEach(notifier => notifier.notify(e));
        }
    }
}
