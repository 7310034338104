import { dispatch } from '@swivel-finance/ui/utils/events';
import { html, LitElement, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { positionAPR } from '../../core/positions';
import { maturityBar, rate, tokenBalance } from '../../shared/templates';
import { orchestrator, POSITION } from '../../state/orchestrator';
import { LendPosition } from '../../types';
import { PositionEvent } from './events';
import { lendPositionActions, lendPositionStatus, lendPositionTooltips } from './helpers';
import { POSITION_MODES, POSITION_TYPES } from './types';

const template = function (this: PositionDetailsLendElement): unknown {

    const position = this.position;
    const positionState = this.positionMachine.state;

    if (!position || !positionState.matches(POSITION.STATES.SUCCESS)) return nothing;

    const market = position.market;
    const token = position.market.token;

    if (!market || !token) return nothing;

    const status = lendPositionStatus(this.positionMachine.state.context, position, this.isLenderPaused);
    const tooltips = lendPositionTooltips(position);
    const effectiveRate = positionAPR(position, market);

    return html`

    ${ maturityBar(market, token, 'long') }

    <ul class="position-details">

        ${ !status.isRedeemed
            ? !status.isMatured
                ? html`
                <li>
                    <span class="label" aria-describedby="${ tooltips.valueAtMaturity.id }">
                        Value at Maturity
                        <ui-icon name="question"></ui-icon>
                    </span>
                    <ui-tooltip id="${ tooltips.valueAtMaturity.id }">
                        ${ tooltips.valueAtMaturity.content }
                    </ui-tooltip>
                    <span class="value">${ tokenBalance(position.iptBalance, token) }</span>
                </li>
                `
                : html`
                <li>
                    <span class="label" aria-describedby="${ tooltips.redemptionValue.id }">
                        Redemption Value
                        <ui-icon name="question"></ui-icon>
                    </span>
                    <ui-tooltip id="${ tooltips.redemptionValue.id }">
                        ${ tooltips.redemptionValue.content }
                    </ui-tooltip>
                    <span class="value">${ tokenBalance(position.currentPositionValue, token) }</span>
                </li>
                `
            : nothing
        }

        ${ !status.isMatured
            ? html`
            <li>
                <span class="label" aria-describedby="${ tooltips.currentValue.id }">
                    Current Value
                    <ui-icon name="question"></ui-icon>
                </span>
                <ui-tooltip id="${ tooltips.currentValue.id }">
                    ${ tooltips.currentValue.content }
                </ui-tooltip>
                <span class="value">${ tokenBalance(position.currentPositionValue, token) }</span>
            </li>
            `
            : nothing
        }

        ${ !status.isVirtual
            ? html`
            <li>
                <span class="label" aria-describedby="${ tooltips.interestEarned.id }">
                    Interest Earned
                    <ui-icon name="question"></ui-icon>
                </span>
                <ui-tooltip id="${ tooltips.interestEarned.id }">
                    ${ tooltips.interestEarned.content }
                </ui-tooltip>
                <span class="value">${ tokenBalance(position.interestEarned, token) }</span>
            </li>
            `
            : nothing
        }

        ${ status.isMatured && !status.isVirtual
            ? html`
            <li>
                <span class="label" aria-describedby="${ tooltips.effectiveRate.id }">
                    Effective Rate
                    <ui-icon name="question"></ui-icon>
                </span>
                <ui-tooltip id="${ tooltips.effectiveRate.id }">
                    ${ tooltips.effectiveRate.content }
                </ui-tooltip>
                <span class="value">${ rate(effectiveRate, '%', false) }</span>
            </li>
            `
            : nothing
        }
    </ul>

    ${ !status.isRedeemed
        ? lendPositionActions(position, status, tooltips, this.lend.bind(this), this.exit.bind(this))
        : nothing
    }
    `;
};

@customElement('ill-position-details-lend')
export class PositionDetailsLendElement extends LitElement {

    protected positionMachine = orchestrator.position;

    @property()
    isLenderPaused = false;

    @property()
    position?: LendPosition;

    protected createRenderRoot (): Element | ShadowRoot {

        return this;
    }

    protected render (): unknown {

        return template.apply(this);
    }

    protected lend (position: LendPosition): void {

        dispatch(this, new PositionEvent({ type: POSITION_TYPES.LEND, mode: POSITION_MODES.ENTER, position }));
    }

    protected exit (position: LendPosition): void {

        dispatch(this, new PositionEvent({ type: POSITION_TYPES.LEND, mode: POSITION_MODES.EXIT, position }));
    }
}
