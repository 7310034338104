import { TransactionResponse } from '@ethersproject/abstract-provider';
import { Pool } from '../../types';
import { emptyOrZero, fixed, trim } from '../amount';
import { ERRORS } from '../constants';
import { compareMarkets, matured } from '../markets';
import { ErrorLike, LOG_SERVICE, serviceLocator } from '../services';
import { DEFAULT_SETTINGS } from '../services/settings';
import { AddState, Transaction, TransactionTopic, TRANSACTION_STATUS } from '../services/transaction';
import { Connection } from '../services/wallet';
import * as PoolMath from './pool-math';
import { PoolInfo, POOL_SERVICE } from './pool-service';
import * as StrategyMath from './strategy-math';

export interface RemoveLiquidityPreview {
    strategyTokensBurned: string;
    lpTokensBurned: string;
    tradeToBase: boolean;
    baseOut: string;
    fyTokenOut: string;
    baseBalance: string;
    sharesBalance: string;
    fyTokenBalance: string;
    realFYTokenBalance: string;
    totalSupply: string;
    totalLiquidity: string;
    ratio: string;
    share: string;
}

export interface RemoveLiquidityState {
    /**
     * The selected pool for pooling
     */
    pool: Pool;
    /**
     * The pool info associated with the pool
     */
    info: PoolInfo;
    /**
     * The selected LP token amount for burning (contracted)
     */
    lpAmount: string;
    /**
     * The max amount of LP tokens that can be burned
     */
    lpMax: string;
    /**
     * The selected underlying amount to redeem (contracted)
     */
    underlyingAmount: string;
    /**
     * The max amount of underlying that can be redeemed
     */
    underlyingMax: string;
    /**
     * When burning LP tokens, immediately trade the returned iPT for underlying and only return underlying
     */
    burnForUnderlying: boolean;
    /**
     * A preview of providing liquidity with the current underlying and ipt amounts
     */
    preview: RemoveLiquidityPreview;
}

const RESET_STATE: Partial<RemoveLiquidityState> = {
    pool: undefined,
    info: undefined,
    lpAmount: undefined,
    lpMax: undefined,
    underlyingAmount: undefined,
    underlyingMax: undefined,
    burnForUnderlying: undefined,
    preview: undefined,
};

const POOL_UPDATE_THRESHOLD = 60000;

export const REMOVE_LIQUIDITY_STATUS = {
    ...TRANSACTION_STATUS,
    APPROVING: 'APPROVING',
} as const;

export type RemoveLiquidityStatus = typeof REMOVE_LIQUIDITY_STATUS[keyof typeof REMOVE_LIQUIDITY_STATUS];

export class RemoveLiquidityTransaction extends Transaction<RemoveLiquidityState, RemoveLiquidityStatus, TransactionTopic> {

    static type = 'Remove Liquidity';

    protected poolService = serviceLocator.get(POOL_SERVICE);

    protected logService = serviceLocator.get(LOG_SERVICE).group('remove-liquidity-transaction');

    protected lastUpdate = 0;

    isPending (): boolean {

        return super.isPending() || this.status === REMOVE_LIQUIDITY_STATUS.APPROVING;
    }

    canUpdate (state?: Partial<RemoveLiquidityState>): state is AddState<Partial<RemoveLiquidityState>, 'pool'> {

        return !!(state ?? this.state).pool;
    }

    canPreview (state?: Partial<RemoveLiquidityState>): state is AddState<Partial<RemoveLiquidityState>, 'pool' | 'info'> {

        state = state ?? this.state;

        return this.canUpdate(state) && !!state.info;
    }

    canRemoveLiquidity (state?: Partial<RemoveLiquidityState>): state is RemoveLiquidityState {

        state = state ?? this.state;

        return this.canPreview(state) && !!state.preview;
    }

    setPool (pool: Pool | undefined): void {

        if (compareMarkets(pool, this.state.pool)) return;

        // with strategies, after maturity we can only redeem underlying
        // so we can set the default value to always true
        const burnForUnderlying = true;

        this.updateState({
            ...RESET_STATE,
            pool,
            burnForUnderlying,
        });

        this.updateStatus(REMOVE_LIQUIDITY_STATUS.INITIAL);

        void this.update();
    }

    async setLPAmount (amount: string | undefined): Promise<void> {

        if (amount === this.state.lpAmount) return;

        // refresh the pool data when changing inputs, so we're always up-to-date
        await this.update();

        this.updateState({
            lpAmount: !emptyOrZero(amount) ? amount : undefined,
            preview: undefined,
        });

        if (!this.state.lpAmount) return;

        try {

            if (this.state.burnForUnderlying) {

                const preview = this.previewBurnForUnderlying();

                this.updateState({
                    lpAmount: preview.lpTokensBurned,
                    preview,
                });

            } else {

                const preview = this.previewBurn();

                this.updateState({
                    lpAmount: preview.lpTokensBurned,
                    preview,
                });
            }

        } catch (error) {

            this._error = error as ErrorLike;

            this.updateStatus(REMOVE_LIQUIDITY_STATUS.ERROR);
        }
    }

    async setUnderlyingAmount (amount: string | undefined): Promise<void> {

        if (amount === this.state.underlyingAmount) return;

        // refresh the pool data when changing inputs, so we're always up-to-date
        await this.update();

        this.updateState({
            underlyingAmount: !emptyOrZero(amount) ? amount : undefined,
            preview: undefined,
        });

        if (!this.state.underlyingAmount) return;

        const preview = this.previewBurnDivested();

        this.updateState({ preview });
    }

    async setBurnForUnderlying (burnForUnderlying: boolean): Promise<void> {

        // enforce that burnForUnderlying can only be turned off for active pools
        if (this.state.pool && matured(this.state.pool)) burnForUnderlying = true;

        if (burnForUnderlying === this.state.burnForUnderlying) return;

        this.updateState({
            burnForUnderlying,
            preview: undefined,
        });

        // refresh the pool data when changing inputs, so we're always up-to-date
        await this.update();

        if (!this.state.lpAmount) return;

        try {

            if (this.state.burnForUnderlying) {

                const preview = this.previewBurnForUnderlying();

                this.updateState({
                    lpAmount: preview.lpTokensBurned,
                    preview,
                });

            } else {

                const preview = this.previewBurn();

                this.updateState({
                    lpAmount: preview.lpTokensBurned,
                    preview,
                });
            }

        } catch (error) {

            this._error = error as ErrorLike;

            this.updateStatus(REMOVE_LIQUIDITY_STATUS.ERROR);
        }
    }

    async update (): Promise<void> {

        if (!this.canUpdate(this.state)) return;

        const { pool } = this.state;

        this.updateStatus(REMOVE_LIQUIDITY_STATUS.UPDATING);

        // update is triggered on every input change to ensure we have the freshest data
        // and the max amounts are adjusted accordingly
        // we don't want to fetch on every single input change however, so we introduce
        // threshold of 1 minute and only fetch if data is older than the threshold
        const now = Date.now();
        const fetch = (now - this.lastUpdate) >= POOL_UPDATE_THRESHOLD;

        this.lastUpdate = now;

        try {

            if (fetch) {

                const info = await this.poolService.fetchInfo(pool, this.connection);

                this.updateState({ info });
            }

            const [lpMax, underlyingMax] = this.getMaxAmounts();

            this.updateState({
                lpMax,
                underlyingMax,
            });

            this.updateStatus(REMOVE_LIQUIDITY_STATUS.COMPLETE);

        } catch (error) {

            this._error = this.errorService?.process(error) ?? error as ErrorLike;

            this.updateStatus(REMOVE_LIQUIDITY_STATUS.ERROR);
        }
    }

    protected async performTransaction (connection?: Connection): Promise<TransactionResponse> {

        if (!this.canRemoveLiquidity(this.state)) return this.throw(ERRORS.SERVICES.TRANSACTION.STATUS.INCOMPLETE);

        const { pool, preview } = this.state;

        const ratioSlippage = this.settingsService?.get().transactions.pool.ratioSlippage ?? DEFAULT_SETTINGS.transactions.pool.ratioSlippage;

        const [minRatio, maxRatio] = this.poolService.slippageToRatios(ratioSlippage, this.state.info.yieldSpaceInfo);

        connection = connection ?? this.connection;

        const allowance = await this.poolService.checkAllowance(pool, preview, connection);

        if (!allowance) {

            this.updateStatus(REMOVE_LIQUIDITY_STATUS.APPROVING);

            const approval = await this.poolService.approve(pool, preview, connection);

            await this.service?.confirm(approval);
        }

        this.updateStatus(REMOVE_LIQUIDITY_STATUS.PENDING);

        return await this.poolService.removeLiquidity(pool, preview, minRatio, maxRatio, connection);
    }

    protected getMaxAmounts (): [string, string] {

        const state = this.state;

        if (!this.canPreview(state)) return this.throw(ERRORS.SERVICES.TRANSACTION.REMOVE_LIQUIDITY.PREVIEW.NO_UPDATE);

        const lpMax = state.info.lpBalance.balance ?? '0';
        const underlyingMax = state.info.strategyBalanceInUnderlying ?? '0';

        this.logService.log([
            'getMaxAmount()... ',
            `lp max:   ${ lpMax }`,
            `base max: ${ underlyingMax }`,
        ].join('\n'));

        return [lpMax, underlyingMax];
    }

    protected previewBurnForUnderlying (): RemoveLiquidityPreview {

        const state = this.state;

        if (!this.canPreview(state)) return this.throw(ERRORS.SERVICES.TRANSACTION.REMOVE_LIQUIDITY.PREVIEW.NO_UPDATE);

        if (!state.lpAmount) return this.throw(ERRORS.SERVICES.TRANSACTION.REMOVE_LIQUIDITY.PREVIEW.NO_LP);

        // get all the data needed from the state
        const poolInfo = state.info;
        const yieldSpaceInfo = poolInfo.yieldSpaceInfo;
        const strategyInfo = poolInfo.strategyInfo;
        const lpTokensBurned = fixed(state.lpAmount);
        const fyTokenBalance = fixed(yieldSpaceInfo.fyTokenBalance);
        const sharesBalance = fixed(yieldSpaceInfo.sharesBalance);
        const sharesPrice = fixed(yieldSpaceInfo.sharesPrice);
        const totalSupply = fixed(yieldSpaceInfo.totalSupply);
        const lpBalance = fixed(poolInfo.lpBalance.balance);

        // calculate the amount of fyTokens and shares returned for the provided amount of lpTokens burned
        let fyTokenOut = PoolMath.fyTokenOutForLPTokenIn(lpTokensBurned, fyTokenBalance, totalSupply).floor();
        let sharesOut = PoolMath.sharesOutForLPTokenIn(lpTokensBurned, sharesBalance, totalSupply).floor();

        // perform a virtual sell of the fyTokens returned
        // TODO: in yieldspace contract, amounts are multiplied with the scaleFactor and the result is divided by the scaleFactor...
        // investigate if that's actually needed...
        const sharesForFYTokens = PoolMath.sellFYTokenPreviewShares(
            fyTokenOut,
            sharesBalance.subUnsafe(sharesOut),
            fyTokenBalance.subUnsafe(fyTokenOut),
            yieldSpaceInfo,
        ).floor();

        // adjust fyTokenOut and sharesOut after the virtual sell
        fyTokenOut = fixed('0');
        sharesOut = sharesOut.addUnsafe(sharesForFYTokens);

        const baseOut = PoolMath.sharesToBase(sharesOut, sharesPrice).floor();

        // calculate the state of the pool after the transaction to preview it
        const sharesBalanceAfter = sharesBalance.subUnsafe(sharesOut);
        const fyTokenBalanceAfter = fyTokenBalance.subUnsafe(fyTokenOut).subUnsafe(lpTokensBurned);
        const baseBalanceAfter = PoolMath.sharesToBase(sharesBalanceAfter, sharesPrice);
        const totalSupplyAfter = totalSupply.subUnsafe(lpTokensBurned);
        const realFYTokenBalanceAfter = PoolMath.realFYTokenBalance(fyTokenBalanceAfter, totalSupplyAfter);
        const totalLiquidityAfter = PoolMath.liquidity(baseBalanceAfter, sharesBalanceAfter, fyTokenBalanceAfter, totalSupplyAfter, yieldSpaceInfo);
        const ratioAfter = PoolMath.ratio(baseBalanceAfter, fyTokenBalanceAfter);
        const shareAfter = lpBalance.subUnsafe(lpTokensBurned).divUnsafe(totalSupplyAfter);

        const strategyTokensBurned = StrategyMath.strategyTokensToBurn(
            lpTokensBurned,
            strategyInfo.poolBalance,
            strategyInfo.totalSupply,
        ).ceiling();

        const preview: RemoveLiquidityPreview = {
            strategyTokensBurned: trim(strategyTokensBurned.toString()),
            lpTokensBurned: trim(lpTokensBurned.toString()),
            tradeToBase: true,
            baseOut: trim(baseOut.toString()),
            fyTokenOut: trim(fyTokenOut.toString()),
            baseBalance: trim(baseBalanceAfter.floor().toString()),
            sharesBalance: trim(sharesBalanceAfter.floor().toString()),
            fyTokenBalance: trim(fyTokenBalanceAfter.floor().toString()),
            realFYTokenBalance: trim(realFYTokenBalanceAfter.floor().toString()),
            totalSupply: trim(totalSupplyAfter.floor().toString()),
            totalLiquidity: trim(totalLiquidityAfter.floor().toString()),
            ratio: ratioAfter.toString(),
            share: shareAfter.toString(),
        };

        this.logService.log('previewBurnForUnderlying()... ', preview);

        return preview;
    }

    protected previewBurn (): RemoveLiquidityPreview {

        const state = this.state;

        if (!this.canPreview(state)) return this.throw(ERRORS.SERVICES.TRANSACTION.REMOVE_LIQUIDITY.PREVIEW.NO_UPDATE);

        if (!state.lpAmount) return this.throw(ERRORS.SERVICES.TRANSACTION.REMOVE_LIQUIDITY.PREVIEW.NO_LP);

        // get all the data needed from the state
        const poolInfo = state.info;
        const yieldSpaceInfo = poolInfo.yieldSpaceInfo;
        const strategyInfo = poolInfo.strategyInfo;
        const lpTokensBurned = fixed(state.lpAmount);
        const fyTokenBalance = fixed(yieldSpaceInfo.fyTokenBalance);
        const sharesBalance = fixed(yieldSpaceInfo.sharesBalance);
        const sharesPrice = fixed(yieldSpaceInfo.sharesPrice);
        const totalSupply = fixed(yieldSpaceInfo.totalSupply);
        const lpBalance = fixed(poolInfo.lpBalance.balance);

        // calculate the amount of fyTokens and shares returned for the provided amount of lpTokens burned
        const fyTokenOut = PoolMath.fyTokenOutForLPTokenIn(lpTokensBurned, fyTokenBalance, totalSupply).floor();
        const sharesOut = PoolMath.sharesOutForLPTokenIn(lpTokensBurned, sharesBalance, totalSupply).floor();
        const baseOut = PoolMath.sharesToBase(sharesOut, sharesPrice).floor();

        // calculate the state of the pool after the transaction to preview it
        const sharesBalanceAfter = sharesBalance.subUnsafe(sharesOut);
        const fyTokenBalanceAfter = fyTokenBalance.subUnsafe(fyTokenOut).subUnsafe(lpTokensBurned);
        const baseBalanceAfter = PoolMath.sharesToBase(sharesBalanceAfter, sharesPrice);
        const totalSupplyAfter = totalSupply.subUnsafe(lpTokensBurned);
        const realFYTokenBalanceAfter = PoolMath.realFYTokenBalance(fyTokenBalanceAfter, totalSupplyAfter);
        const totalLiquidityAfter = PoolMath.liquidity(baseBalanceAfter, sharesBalanceAfter, fyTokenBalanceAfter, totalSupplyAfter, yieldSpaceInfo);
        const ratioAfter = PoolMath.ratio(baseBalanceAfter, fyTokenBalanceAfter);
        const shareAfter = lpBalance.subUnsafe(lpTokensBurned).divUnsafe(totalSupplyAfter);

        const strategyTokensBurned = StrategyMath.strategyTokensToBurn(
            lpTokensBurned,
            strategyInfo.poolBalance,
            strategyInfo.totalSupply,
        ).ceiling();

        const preview: RemoveLiquidityPreview = {
            strategyTokensBurned: trim(strategyTokensBurned.toString()),
            lpTokensBurned: trim(lpTokensBurned.toString()),
            tradeToBase: false,
            baseOut: trim(baseOut.toString()),
            fyTokenOut: trim(fyTokenOut.toString()),
            baseBalance: trim(baseBalanceAfter.floor().toString()),
            sharesBalance: trim(sharesBalanceAfter.floor().toString()),
            fyTokenBalance: trim(fyTokenBalanceAfter.floor().toString()),
            realFYTokenBalance: trim(realFYTokenBalanceAfter.floor().toString()),
            totalSupply: trim(totalSupplyAfter.floor().toString()),
            totalLiquidity: trim(totalLiquidityAfter.floor().toString()),
            ratio: ratioAfter.toString(),
            share: shareAfter.toString(),
        };

        this.logService.warn('previewBurn()... ', preview);

        return preview;
    }

    protected previewBurnDivested (): RemoveLiquidityPreview {

        const state = this.state;

        if (!this.canPreview(state)) return this.throw(ERRORS.SERVICES.TRANSACTION.REMOVE_LIQUIDITY.PREVIEW.NO_UPDATE);

        if (!state.underlyingAmount) return this.throw(ERRORS.SERVICES.TRANSACTION.REMOVE_LIQUIDITY.PREVIEW.NO_UNDERLYING);

        // get all the data needed from the state
        const poolInfo = state.info;
        const strategyInfo = poolInfo.strategyInfo;
        const baseOut = fixed(state.underlyingAmount);

        const strategyTokensBurned = StrategyMath.strategyTokensToBurnDivested(
            baseOut,
            strategyInfo.baseBalance,
            strategyInfo.totalSupply,
        ).ceiling();

        const preview: RemoveLiquidityPreview = {
            baseOut: trim(baseOut.toString()),
            tradeToBase: true,
            strategyTokensBurned: trim(strategyTokensBurned.toString()),
            // all the other properties are no longer applicable, as the
            // strategy has already redeemed all lp tokens for base
            baseBalance: '0',
            sharesBalance: '0',
            fyTokenOut: '0',
            fyTokenBalance: '0',
            realFYTokenBalance: '0',
            lpTokensBurned: '0',
            ratio: '0',
            share: '0',
            totalLiquidity: '0',
            totalSupply: '0',
        };

        return preview;
    }
}
