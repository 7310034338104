import { html, nothing } from 'lit';
import { ENV } from '../../core/env';

/**
 * Creates a template for a link to a transaction's Etherscan record.
 *
 * @param h - the transaction hash
 * @param l - an optional label
 * @param t - an tail length (number of letters shown in the part behind the ellipsis, default: 6)
 */
export const transactionLink = (h?: string, l = '', t = 6) => {

    const length = h?.length ?? 0;
    const start = length > t ? h?.substring(0, h.length - t) : '';
    const end = length > t ? h?.substring(h.length - t) : h;

    return h
        ? html`
        <span class="transaction-link">
            ${ l
            ? html`<span class="label">${ l }:</span>`
            : nothing
            }
            <a href="${ ENV.etherscanUrl.replace('<TX_ID>', h) }" target="_blank" rel="noopener">
                <span class="hash">
                    <span class="hash-start">${ start }</span>
                    <span class="hash-end">${ end }</span>
                </span>
                <ui-icon name="etherscan"></ui-icon>
            </a>
        </span>
        `
        : nothing;
};
