import { MarketLike, Quote, QuoteResponse, Token } from '../../types';
import { expandAmount } from '../amount';
import { serviceLocator } from '../services';
import { API_SERVICE } from '../services/api';

const withoutErrors = (q: QuoteResponse[]): QuoteResponse[] => q.filter(quote => {

    // TODO: the `quote.pt.meta.info` test is temporarily used to filter out unavailable principals
    // the backend currently returns them, but will filter them out eventually
    return !quote.error && !quote.pt.meta?.info;
});

/**
 * Get quotes for all active markets.
 *
 * @remarks
 * The rate and/or returned iPT token amount is based on a quote for 1 underlying in each market.
 */
export const fetchQuotes = async (): Promise<Quote[]> => {

    const api = serviceLocator.get(API_SERVICE);

    const quotes = await api.getQuotes();

    return withoutErrors(quotes);
};

/**
 * Get quotes for all markets with a specified underlying
 *
 * @remarks
 * The amount will be expanded to the full token decimals by this method. As each market can
 * have a different amount of token decimals, we need to specify a contracted amount and let
 * this method expand it for each market's token decimals.
 *
 * @param t - the underlying token to fetch quotes for
 * @param a - the amount to lend (in contracted format, i.e. for 1 USDC specify '1' instead of '1000000')
 */
export const fetchQuotesByUnderlying = async (t: Token, a: string): Promise<Quote[]> => {

    const api = serviceLocator.get(API_SERVICE);

    const quotes = await api.getQuotes(t.address, expandAmount(a, t));

    return withoutErrors(quotes);
};

/**
 * Get quotes for a single market
 *
 * @remarks
 * The amount will be expanded to the full token decimals by this method. This is to align the
 * interface with the `fetchQuotes` method, which fetches quotes for multiple markets and therefore
 * has to expand the token amount per market (as markets can have different token decimals).
 *
 * @param m - the market to get a quote for
 * @param t - the market's underlying token
 * @param a - the amount to lend (in contracted format, i.e. for 1 USDC specify '1' instead of '1000000')
 */
export const fetchQuotesByMarket = async (m: MarketLike, t: Token, a: string): Promise<Quote[]> => {

    const api = serviceLocator.get(API_SERVICE);

    const quotes = await api.getQuotes(m.underlying, m.maturity, expandAmount(a, t));

    return withoutErrors(quotes);
};
