import { html } from 'lit';
import type { AppElement } from '../app';

export const poolTemplate = function (this: AppElement) {

    return html`
    <main class="pools">
        <div class="column">
            <ill-pool-page></ill-pool-page>
        </div>
    </main>
    `;
};
