import { Subscriber } from '@swivel-finance/consent';
import { Settings } from '../settings';
import { TransactionStorage } from '../transaction';
import { EthereumProviderIdentifier } from '../wallet';

/**
 * The Preferences interface
 *
 * @remarks
 * The keys of this interface are used as topics for the {@link PreferencesService}.
 * The types are mapped to the service's `set`, `get` and `subscribe` methods.
 * Furthermore, the keys of this interface are stored in localStorage if the consent
 * service grants the `ConsentCategory.PREFERENCES` permission.
 */
export interface Preferences {
    theme: 'light' | 'dark' | 'default' | null;
    autoConnect: boolean | null;
    walletIdentifier: EthereumProviderIdentifier | null;
    firstVisit: boolean | null;
    lendFirstVisit: boolean | null;
    poolFirstVisit: boolean | null;
    positionsFirstVisit: boolean | null;
    settings: Settings | null;
    transactions: TransactionStorage;
}

export type PreferencesTopic = keyof Preferences;

export const PREFERENCES_TOPICS: PreferencesTopic[] = [
    'theme',
    'autoConnect',
    'walletIdentifier',
    'firstVisit',
    'lendFirstVisit',
    'poolFirstVisit',
    'positionsFirstVisit',
    'transactions',
    'settings',
];

export const PREFERENCES_PREFIX = 'preferences.';

export interface PreferencesService {
    readonly enabled: boolean;
    enable (): void;
    disable (): void;
    subscribe<K extends PreferencesTopic = PreferencesTopic, T = Preferences[K]> (s: Subscriber<T, K>, t?: K | K[]): void;
    unsubscribe<K extends PreferencesTopic = PreferencesTopic, T = Preferences[K]> (s: Subscriber<T, K>, t?: K | K[]): boolean;
    unsubscribeAll (): void;
    clear (): void;
    delete (key: PreferencesTopic): void;
    get<K extends PreferencesTopic = PreferencesTopic, T extends Preferences[K] = Preferences[K]> (key: K): T | null;
    set<K extends PreferencesTopic = PreferencesTopic, T extends Preferences[K] = Preferences[K]> (key: K, value: T): void;
}
