import { OpenChangeEvent } from '@swivel-finance/ui/behaviors/overlay';
import { PopupConfig, PopupElement, POPUP_CONFIG_DEFAULT } from '@swivel-finance/ui/elements/popup';
import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { createRef, ref } from 'lit/directives/ref.js';
import { trim } from '../../core/amount';
import { serviceLocator } from '../../core/services';
import { Settings, SETTINGS_SERVICE } from '../../core/services/settings';
import { DEFAULT_SETTINGS } from '../../core/services/settings/constants';
import { iPT } from '../../core/services/token';
import { tokenSymbol, unit } from '../../shared/templates';

const SETTINGS_POPUP_CONFIG: PopupConfig = {
    ...POPUP_CONFIG_DEFAULT,
    // NOTE: we leave this here for development/debugging purposes...
    // use the `overlay` setting to keep the popup open when loosing focus
    // overlay: {
    //     ...POPUP_CONFIG_DEFAULT.overlay,
    //     closeOnFocusLoss: false,
    // },
    focus: {
        ...POPUP_CONFIG_DEFAULT.focus,
        trapFocus: true,
        wrapFocus: true,
    },
    position: {
        ...POPUP_CONFIG_DEFAULT.position,
        alignment: {
            origin: {
                horizontal: 'end',
                vertical: 'end',
            },
            target: {
                horizontal: 'end',
                vertical: 'start',
            },
        },
    },
};

const SETTINGS_INPUTS = {
    LEND: {
        SLIPPAGE: 'lend-slippage',
        DEADLINE: 'lend-deadline',
    },
    POOL: {
        RATIO_SLIPPAGE: 'pool-ratio-slippage',
        PRICE_SLIPPAGE: 'pool-price-slippage',
        SHARES_PRICE_SLIPPAGE: 'pool-shares-price-slippage',
    },
} as const;

type SettingsKey = typeof SETTINGS_INPUTS['LEND'][keyof typeof SETTINGS_INPUTS['LEND']]
    | typeof SETTINGS_INPUTS['POOL'][keyof typeof SETTINGS_INPUTS['POOL']];

export const SETTINGS_TYPE = {
    LEND: 'LEND',
    POOL: 'POOL',
} as const;

export type SettingsType = typeof SETTINGS_TYPE[keyof typeof SETTINGS_TYPE];

/**
 * Format a float value as percentage
 *
 * @remarks
 * We solely need this because of JavaScript's number inprecision, e.g.:
 * 0.035 * 100 = 3.5000000000000004 but should be 3.5
 */
const formatPercentage = (value: number): string => trim((value * 100).toFixed(6));

const lendTemplate = function (this: TransactionSettingsElement) {

    const settings = this.settings.get().transactions.lend;
    const defaults = DEFAULT_SETTINGS.transactions.lend;

    return html`
    <div class="settings-group settings-lend">
        <div class="settings-item">
            <label for="settings-${ SETTINGS_INPUTS.LEND.SLIPPAGE }">Price Slippage</label>
            <span class="settings-item-input">
                <input
                    type="text"
                    name="slippage"
                    id="settings-${ SETTINGS_INPUTS.LEND.SLIPPAGE }"
                    placeholder="${ formatPercentage(defaults.slippage) }"
                    .value=${ formatPercentage(settings.slippage) }
                    @input=${ (event: InputEvent) => this.handleInput(event, SETTINGS_INPUTS.LEND.SLIPPAGE) }
                    @change=${ (event: InputEvent) => this.handleChange(event, SETTINGS_INPUTS.LEND.SLIPPAGE) }
                    @focus=${ (event: FocusEvent) => this.handleFocus(event, SETTINGS_INPUTS.LEND.SLIPPAGE) }
                    ${ ref(this.lendSlippage) }>
                ${ unit('%') }
            </span>
            <span class="settings-item-description">
                <!-- TODO: ask feedback -->
                The amount of tolerated slippage when purchasing iPTs (lending) or selling iPTs (exiting early).
                <!-- The amount of tolerated slippage for a lending protocol's returned principal tokens (when lending) or underlying (when redeeming). -->
            </span>
        </div>
        <div class="settings-item">
            <label for="settings-${ SETTINGS_INPUTS.LEND.DEADLINE }">Transaction Deadline</label>
            <span class="settings-item-input">
                <input
                    type="text"
                    name="deadline"
                    id="settings-${ SETTINGS_INPUTS.LEND.DEADLINE }"
                    placeholder="${ defaults.deadline }"
                    .value=${ settings.deadline }
                    @input=${ (event: InputEvent) => this.handleInput(event, SETTINGS_INPUTS.LEND.DEADLINE) }
                    @change=${ (event: InputEvent) => this.handleChange(event, SETTINGS_INPUTS.LEND.DEADLINE) }
                    @focus=${ (event: FocusEvent) => this.handleFocus(event, SETTINGS_INPUTS.LEND.DEADLINE) }
                    ${ ref(this.lendDeadline) }>
                ${ unit('sec') }
            </span>
            <span class="settings-item-description">
                The duration for which the transaction is valid.
                <br>
                <em>Not all integrated protocols support this setting.</em>
            </span>
        </div>
    </div>
    `;
};

const poolTemplate = function (this: TransactionSettingsElement) {

    const settings = this.settings.get().transactions.pool;
    const defaults = DEFAULT_SETTINGS.transactions.pool;

    return html`
    <div class="settings-group settings-pool">
        <div class="settings-item">
            <label for="settings-${ SETTINGS_INPUTS.POOL.RATIO_SLIPPAGE }">Ratio Slippage</label>
            <span class="settings-item-input">
                <input
                    type="text"
                    name="ratio-slippage"
                    id="settings-${ SETTINGS_INPUTS.POOL.RATIO_SLIPPAGE }"
                    placeholder="${ formatPercentage(defaults.ratioSlippage) }"
                    .value=${ formatPercentage(settings.ratioSlippage) }
                    @input=${ (event: InputEvent) => this.handleInput(event, SETTINGS_INPUTS.POOL.RATIO_SLIPPAGE) }
                    @change=${ (event: InputEvent) => this.handleChange(event, SETTINGS_INPUTS.POOL.RATIO_SLIPPAGE) }
                    @focus=${ (event: FocusEvent) => this.handleFocus(event, SETTINGS_INPUTS.POOL.RATIO_SLIPPAGE) }
                    ${ ref(this.poolRatioSlippage) }>
                ${ unit('%') }
            </span>
            <span class="settings-item-description">
                The amount of tolerated slippage for the pool's ratio.
            </span>
        </div>
        <div class="settings-item">
            <label for="settings-${ SETTINGS_INPUTS.POOL.PRICE_SLIPPAGE }">Price Slippage</label>
            <span class="settings-item-input">
                <input
                    type="text"
                    name="price-slippage"
                    id="settings-${ SETTINGS_INPUTS.POOL.PRICE_SLIPPAGE }"
                    placeholder="${ formatPercentage(defaults.priceSlippage) }"
                    .value=${ formatPercentage(settings.priceSlippage) }
                    @input=${ (event: InputEvent) => this.handleInput(event, SETTINGS_INPUTS.POOL.PRICE_SLIPPAGE) }
                    @change=${ (event: InputEvent) => this.handleChange(event, SETTINGS_INPUTS.POOL.PRICE_SLIPPAGE) }
                    @focus=${ (event: FocusEvent) => this.handleFocus(event, SETTINGS_INPUTS.POOL.PRICE_SLIPPAGE) }
                    ${ ref(this.poolPriceSlippage) }>
                ${ unit('%') }
            </span>
            <span class="settings-item-description">
                <!-- TODO: ask feedback -->
                The amount of tolerated slippage when purchasing iPTs (adding liquidity) or selling iPTs (removing liquidity).
                <!-- The amount of tolerated slippage for the pool's ${ tokenSymbol(iPT()) } price. This setting has no effect when using owned ${ tokenSymbol(iPT()) }. -->
                <br>
                <em>This setting has no effect when using owned iPTs.</em>
            </span>
        </div>
        <div class="settings-item">
            <label for="settings-${ SETTINGS_INPUTS.POOL.SHARES_PRICE_SLIPPAGE }">Shares Price Slippage</label>
            <span class="settings-item-input">
                <input
                    type="text"
                    name="shares-price-slippage"
                    id="settings-${ SETTINGS_INPUTS.POOL.SHARES_PRICE_SLIPPAGE }"
                    placeholder="${ formatPercentage(defaults.sharesPriceSlippage) }"
                    .value=${ formatPercentage(settings.sharesPriceSlippage) }
                    @input=${ (event: InputEvent) => this.handleInput(event, SETTINGS_INPUTS.POOL.SHARES_PRICE_SLIPPAGE) }
                    @change=${ (event: InputEvent) => this.handleChange(event, SETTINGS_INPUTS.POOL.SHARES_PRICE_SLIPPAGE) }
                    @focus=${ (event: FocusEvent) => this.handleFocus(event, SETTINGS_INPUTS.POOL.SHARES_PRICE_SLIPPAGE) }
                    ${ ref(this.poolSharesPriceSlippage) }>
                ${ unit('%') }
            </span>
            <span class="settings-item-description">
                <!-- TODO: ask feedback -->
                The amount of tolerated slippage when purchasing shares tokens (when converting underlying to shares) or selling shares tokens (when converting shares to underlying).
                <br>
                <em>This setting only has effect when using owned iPTs.</em>
                <!-- The amount of tolerated slippage for the pool's shares price. This setting only has effect when using owned ${ tokenSymbol(iPT()) }. -->
            </span>
        </div>
    </div>
    `;
};

const template = function (this: TransactionSettingsElement) {

    return html`
    <ui-popup .config=${ SETTINGS_POPUP_CONFIG } ${ ref(this.popupRef) }>
        <button class="button-icon" data-part="trigger" aria-label="transaction settings">
            <ui-icon name="settings"></ui-icon>
        </button>
        <div class="settings-popup" data-part="overlay" @ui-open-changed=${ (event: OpenChangeEvent) => this.handleOpenChange(event) }>
            <h1>
                Transaction Settings
                <button class="button-icon settings-close" aria-label="close settings" @click=${ () => this.close() }>
                    <ui-icon name="cross-circle"></ui-icon>
                </button>
            </h1>
            ${ this.type === SETTINGS_TYPE.LEND
                ? lendTemplate.apply(this)
                : poolTemplate.apply(this)
            }
        </div>
    </ui-popup>
    `;
};

@customElement('ill-transaction-settings')
export class TransactionSettingsElement extends LitElement {

    protected settings = serviceLocator.get(SETTINGS_SERVICE);

    protected popupRef = createRef<PopupElement>();

    protected lendSlippage = createRef<HTMLInputElement>();

    protected lendDeadline = createRef<HTMLInputElement>();

    protected poolRatioSlippage = createRef<HTMLInputElement>();

    protected poolPriceSlippage = createRef<HTMLInputElement>();

    protected poolSharesPriceSlippage = createRef<HTMLInputElement>();

    protected lastValue = '';

    @property({
        reflect: true,
    })
    type: SettingsType = SETTINGS_TYPE.LEND;

    constructor () {

        super();

        this.handleSettingsChange = this.handleSettingsChange.bind(this);
    }

    connectedCallback (): void {

        super.connectedCallback();

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.settings.subscribe(this.handleSettingsChange);
    }

    disconnectedCallback (): void {

        this.close();

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.settings.unsubscribe(this.handleSettingsChange);

        super.disconnectedCallback();
    }

    close (): void {

        void this.popupRef.value?.hide();
    }

    protected createRenderRoot (): Element | ShadowRoot {

        return this;
    }

    protected render (): unknown {

        return template.apply(this);
    }

    /**
     * Handle input events and block invalid input
     */
    protected handleInput (event: InputEvent, id: SettingsKey): void {

        const input = event.target as HTMLInputElement;

        const isValid = this.validate(input.value);

        if (isValid) {

            this.lastValue = input.value;

        } else {

            event.preventDefault();
            event.stopPropagation();

            // reset the input's value
            this.refreshInput(id, this.lastValue);
        }
    }

    /**
     * Handle change events and update the settings service
     */
    protected handleChange (event: InputEvent, id: SettingsKey): void {

        const input = event.target as HTMLInputElement;

        // we spread the settings object into a new one, so we don't override existing object references
        const settings: Settings = {
            transactions: {
                lend: {
                    ...this.settings.get().transactions.lend,
                },
                pool: {
                    ...this.settings.get().transactions.pool,
                },
            },
        };

        const value = this.parse(input.value);

        // if the input is empty, we reset it to the default value
        const isEmpty = input.value === '';
        // we consider the input valid if it's a valid number or empty
        const isValid = !isNaN(value) || isEmpty;

        if (isValid) {

            switch (id) {

                case SETTINGS_INPUTS.LEND.DEADLINE:
                    settings.transactions.lend.deadline = isEmpty
                        ? DEFAULT_SETTINGS.transactions.lend.deadline
                        : value;
                    break;

                case SETTINGS_INPUTS.LEND.SLIPPAGE:
                    settings.transactions.lend.slippage = isEmpty
                        ? DEFAULT_SETTINGS.transactions.lend.slippage
                        : value / 100;
                    break;

                case SETTINGS_INPUTS.POOL.RATIO_SLIPPAGE:
                    settings.transactions.pool.ratioSlippage = isEmpty
                        ? DEFAULT_SETTINGS.transactions.pool.ratioSlippage
                        : value / 100;
                    break;

                case SETTINGS_INPUTS.POOL.PRICE_SLIPPAGE:
                    settings.transactions.pool.priceSlippage = isEmpty
                        ? DEFAULT_SETTINGS.transactions.pool.priceSlippage
                        : value / 100;
                    break;

                case SETTINGS_INPUTS.POOL.SHARES_PRICE_SLIPPAGE:
                    settings.transactions.pool.sharesPriceSlippage = isEmpty
                        ? DEFAULT_SETTINGS.transactions.pool.sharesPriceSlippage
                        : value / 100;
                    break;
            }

            // store the updated settings
            this.settings.set(settings);

        } else {

            event.preventDefault();
            event.stopPropagation();

            // reset the input's value if invalid
            this.refreshInput(id);
        }
    }

    protected handleFocus (event: FocusEvent, id: SettingsKey): void {

        // when an input gains focus prime the lastValue property with its current value
        this.handleInput(event as unknown as InputEvent, id);
    }

    protected handleOpenChange (event: OpenChangeEvent): void {

        // we ignore close events...
        if (!event.detail.open) return;

        this.refreshInputs();

        this.requestUpdate();
    }

    protected handleSettingsChange (): void {

        this.refreshInputs();

        this.requestUpdate();
    }

    protected refreshInputs (): void {

        this.refreshInput(SETTINGS_INPUTS.LEND.SLIPPAGE);
        this.refreshInput(SETTINGS_INPUTS.LEND.DEADLINE);
        this.refreshInput(SETTINGS_INPUTS.POOL.RATIO_SLIPPAGE);
        this.refreshInput(SETTINGS_INPUTS.POOL.PRICE_SLIPPAGE);
        this.refreshInput(SETTINGS_INPUTS.POOL.SHARES_PRICE_SLIPPAGE);
    }

    /**
     * Refresh an input's value with the corresponding value from the settings or the provided value
     *
     * @remarks
     * Re-rendering the template with a `.value` binding won't override the user's input.
     * We need to manually set the value of the input we want to refresh.
     */
    protected refreshInput (id: SettingsKey, value?: string): void {

        const settings = this.settings.get().transactions;

        let input: HTMLInputElement | undefined;

        switch (id) {

            case SETTINGS_INPUTS.LEND.DEADLINE:
                input = this.lendDeadline.value;
                value = value ?? settings.lend.deadline.toString();
                break;

            case SETTINGS_INPUTS.LEND.SLIPPAGE:
                input = this.lendSlippage.value;
                value = value ?? formatPercentage(settings.lend.slippage);
                break;

            case SETTINGS_INPUTS.POOL.RATIO_SLIPPAGE:
                input = this.poolRatioSlippage.value;
                value = value ?? formatPercentage(settings.pool.ratioSlippage);
                break;

            case SETTINGS_INPUTS.POOL.PRICE_SLIPPAGE:
                input = this.poolPriceSlippage.value;
                value = value ?? formatPercentage(settings.pool.priceSlippage);
                break;

            case SETTINGS_INPUTS.POOL.SHARES_PRICE_SLIPPAGE:
                input = this.poolSharesPriceSlippage.value;
                value = value ?? formatPercentage(settings.pool.sharesPriceSlippage);
                break;
        }

        input && (input.value = value);
    }

    /**
     * Checks if an input's value is generally valid
     *
     * @remarks
     * We allow empty strings, numbers and a decimal point
     */
    protected validate (value: string): boolean {

        return /^$|^\d*\.?\d*$/.test(value);
    }

    /**
     * Parses an input's value to a float and returns NaN for invalid values
     */
    protected parse (value: string): number {

        if (!this.validate(value)) return NaN;

        return parseFloat(value);
    }
}
