import { Settings } from './interfaces';

/**
 * The slippage amount for principal tokens returned from lending protocols
 */
export const LEND_SLIPPAGE = 0.025;

/**
 * The duration a lend transaction is valid for
 */
export const LEND_DEADLINE = 3600;

/**
 * The slippage amount for a pool's min- and maxRatio
 */
export const POOL_RATIO_SLIPPAGE = 0.025;

/**
 * The slippage amount for a pool's fy token price
 */
export const POOL_PRICE_SLIPPAGE = 0.0025;

/**
 * The slippage amount for a pool's shares price
 */
export const POOL_SHARES_PRICE_SLIPPAGE = 0.00005;

export const DEFAULT_SETTINGS: Settings = {
    transactions: {
        lend: {
            slippage: LEND_SLIPPAGE,
            deadline: LEND_DEADLINE,
        },
        pool: {
            ratioSlippage: POOL_RATIO_SLIPPAGE,
            priceSlippage: POOL_PRICE_SLIPPAGE,
            sharesPriceSlippage: POOL_SHARES_PRICE_SLIPPAGE,
        },
    },
};
