import { FixedNumber } from 'ethers';
import { ensureFixed, fixed } from '../amount';

const ZERO = fixed('0');

/**
 * Calculates the amount of strategy shares tokens minted for a given amount of lp tokens added
 *
 * @remarks
 * This can only be used for strategies in INVESTED state.
 *
 * sharesMinted = lpTokensAdded / (poolCached + lpTokensAdded) * totalSupply;
 *
 * https://github.com/Swivel-Finance/yield-space-pool/blob/main/src/roller/Strategy.sol#L436
 *
 * @param lpTokensAdded - the amount of lp tokens added to the strategy
 * @param poolBalance - the strategy's lp token balance (before adding the lp tokens)
 * @param totalSupply - the strategy's total (strategy token) supply
 */
export function strategyTokensMinted (
    lpTokensAdded: string | FixedNumber,
    poolBalance: string | FixedNumber,
    totalSupply: string | FixedNumber,
): FixedNumber {

    lpTokensAdded = ensureFixed(lpTokensAdded);
    poolBalance = ensureFixed(poolBalance).addUnsafe(lpTokensAdded);

    return poolBalance.isZero()
        ? ZERO
        : lpTokensAdded.divUnsafe(poolBalance).mulUnsafe(ensureFixed(totalSupply));
}

/**
 * Calculates the amount of lp tokens obtained from burning a given amount of strategy share tokens
 *
 * @remarks
 * This can only be used for strategies in INVESTED state.
 *
 * poolTokensObtained = sharesBurnt / totalSupply * poolCached
 *
 * https://github.com/Swivel-Finance/yield-space-pool/blob/main/src/roller/Strategy.sol#L460
 *
 * @param strategyTokensBurnt - the amount of strategy share tokens to burn
 * @param poolBalance - the strategy's lp token balance
 * @param totalSupply - the strategy's total (strategy token) supply
 */
export function lpTokensObtained (
    strategyTokensBurnt: string | FixedNumber,
    poolBalance: string | FixedNumber,
    totalSupply: string | FixedNumber,
): FixedNumber {

    totalSupply = ensureFixed(totalSupply);

    return totalSupply.isZero()
        ? ZERO
        : ensureFixed(strategyTokensBurnt).divUnsafe(totalSupply).mulUnsafe(ensureFixed(poolBalance));
}

/**
 * Calculates the amount of strategy tokens that need to be burned to "redeem"/obtain a given amount of lp tokens
 *
 * @remarks
 * This can only be used for strategies in INVESTED state. The formula is obtained by solving the `poolTokensObtained`
 * formula for `sharesBurnt`.
 *
 * poolTokensObtained = sharesBurnt / totalSupply * poolCached
 * sharesBurnt = poolTokensObtained / poolCached * totalSupply
 *
 * @param lpTokensRemoved - the amount of lp tokens to "redeem" from the strategy
 * @param poolBalance - the strategy's lp token balance (before removing the lp tokens)
 * @param totalSupply - the strategy's total (strategy token) supply
 * @returns
 */
export function strategyTokensToBurn (
    lpTokensRemoved: string | FixedNumber,
    poolBalance: string | FixedNumber,
    totalSupply: string | FixedNumber,
): FixedNumber {

    poolBalance = ensureFixed(poolBalance);

    return poolBalance.isZero()
        ? ZERO
        : ensureFixed(lpTokensRemoved).divUnsafe(poolBalance).mulUnsafe(ensureFixed(totalSupply));
}

/**
 * Calculates the amount of base obtained from burning a given amount of strategy share tokens
 *
 * @remarks
 * This can only be used for strategies in DIVESTED state.
 *
 * baseObtained = sharesBurnt / totalSupply * baseCached
 *
 * https://github.com/Swivel-Finance/yield-space-pool/blob/main/src/roller/Strategy.sol#L506
 *
 * @param strategyTokensBurnt - the amount of strategy share tokens to burn
 * @param baseBalance - the strategy's base balance
 * @param totalSupply - the strategy's total (strategy token) supply
 * @returns
 */
export function baseObtained (
    strategyTokensBurnt: string | FixedNumber,
    baseBalance: string | FixedNumber,
    totalSupply: string | FixedNumber,
): FixedNumber {

    totalSupply = ensureFixed(totalSupply);

    return totalSupply.isZero()
        ? ZERO
        : ensureFixed(strategyTokensBurnt).divUnsafe(totalSupply).mulUnsafe(ensureFixed(baseBalance));
}

/**
 * Calculates the amount of strategy tokens that need to be burned to "redeem"/obtain a given amount of base
 *
 * @remarks
 * This can only be used for strategies in DIVESTED state. The formula is obtained by solving the `baseObtained`
 * formula for `sharesBurnt`.
 *
 * baseObtained = sharesBurnt / totalSupply * baseCached
 * sharesBurnt = baseObtained / baseCached * totalSupply
 *
 * @param baseRemoved - the amount of underlying to "redeem" from the strategy
 * @param baseBalance - the strategy's base balance (before redeeming the base)
 * @param totalSupply - the strategy's total (strategy token) supply
 * @returns
 */
export function strategyTokensToBurnDivested (
    baseRemoved: string | FixedNumber,
    baseBalance: string | FixedNumber,
    totalSupply: string | FixedNumber,
): FixedNumber {

    baseBalance = ensureFixed(baseBalance);

    return baseBalance.isZero()
        ? ZERO
        : ensureFixed(baseRemoved).divUnsafe(baseBalance).mulUnsafe(ensureFixed(totalSupply));
}

/**
 * Calculates how many lp tokens 1 strategy token is worth.
 *
 * @remarks
 * This can only be used for strategies in INVESTED state.
 *
 * poolCached <=> totalSupply: 1 share (in lp tokens) = poolCached / totalSupply
 *
 * @param poolBalance - the strategy's lp token balance
 * @param totalSupply - the strategy's total (strategy token) supply
 */
export function lpPerStrategyToken (
    poolBalance: string | FixedNumber,
    totalSupply: string | FixedNumber,
): FixedNumber {

    totalSupply = ensureFixed(totalSupply);

    return totalSupply.isZero()
        ? ZERO
        : ensureFixed(poolBalance).divUnsafe(totalSupply);
}

/**
 * Calculates how many base tokens 1 strategy token is worth.
 *
 * @remarks
 * This can only be used for strategies in DIVESTED state.
 *
 * baseCached <=> totalSupply: 1 share (in base) = baseCached / totalSupply
 *
 * @param baseBalance - the strategy's base balance
 * @param totalSupply - the strategy's total (strategy token) supply
 */
export function basePerStrategyToken (
    baseBalance: string | FixedNumber,
    totalSupply: string | FixedNumber,
): FixedNumber {

    totalSupply = ensureFixed(totalSupply);

    return totalSupply.isZero()
        ? ZERO
        : ensureFixed(baseBalance).divUnsafe(totalSupply);
}

/**
 * Calculates how many lp tokens a given amount of strategy tokens is worth.
 *
 * @remarks
 * This can only be used for strategies in INVESTED state.
 *
 * @param strategyTokenAmount - the amount of strategy tokens to convert to lp tokens
 * @param poolBalance - the strategy's lp token balance
 * @param totalSupply - the strategy's total (strategy token) supply
 */
export function lpForStrategyTokens (
    strategyTokenAmount: string | FixedNumber,
    poolBalance: string | FixedNumber,
    totalSupply: string | FixedNumber,
): FixedNumber {

    return ensureFixed(strategyTokenAmount).mulUnsafe(lpPerStrategyToken(poolBalance, totalSupply));
}

/**
 * Calculates how many base tokens a given amount of strategy tokens is worth.
 *
 * @remarks
 * This can only be used for strategies in DIVESTED state.
 *
 * @param strategyTokenAmount - the amount of strategy tokens to convert to base
 * @param baseBalance - the strategy's base balance
 * @param totalSupply - the strategy's total (strategy token) supply
 */
export function baseForStrategyTokens (
    strategyTokenAmount: string | FixedNumber,
    baseBalance: string | FixedNumber,
    totalSupply: string | FixedNumber,
): FixedNumber {

    return ensureFixed(strategyTokenAmount).mulUnsafe(basePerStrategyToken(baseBalance, totalSupply));
}
