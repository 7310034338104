import type { ethers } from 'ethers';

// Goerli Faucet

export const FAUCET_ABI = [
    'function lastReceived(address user, address token) public view returns (uint256)',
    'function drip(address token) public',
];

export interface FaucetContract {
    lastReceived (user: string, token: string): Promise<ethers.BigNumber>;
    drip (token: string): Promise<ethers.providers.TransactionResponse>;
}
