import { html, nothing, TemplateResult } from 'lit';

export const message = (m: string | TemplateResult, i?: string) =>
    html`<div class="message-box">${ i ? html`<ui-icon name="${ i }"></ui-icon>` : nothing }${ m }</div>`;

export const errorMessage = (m: string | TemplateResult, i?: string) =>
    html`<div class="message-box error">${ i ? html`<ui-icon name="${ i }"></ui-icon>` : nothing }${ m }</div>`;

export const infoMessage = (m: string | TemplateResult, i?: string) =>
    html`<div class="message-box info">${ i ? html`<ui-icon name="${ i }"></ui-icon>` : nothing }${ m }</div>`;
