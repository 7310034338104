import { ERRORS } from '../../constants';
import { ErrorService } from '../errors';
import { LogService } from '../logger';
import { DEFAULT_REQUEST_INIT } from './constants';
import { GetPayload, HttpService, PostPayload, RequestMethod, RequestOptions, RequestPayload } from './interfaces';

export class WebHttpService implements HttpService {

    protected errors: ErrorService;

    protected logger: LogService;

    constructor (errorService: ErrorService, logService: LogService) {

        this.errors = errorService;
        this.logger = logService.group('http');
    }

    /**
     * Performs a 'GET' request and returns the parsed response data.
     *
     * @param u - the request url
     * @param p - an optional request payload
     * @param i - an optional `RequestOptions` object
     */
    async get<T> (u: string, p?: GetPayload, i?: RequestOptions): Promise<T> {

        let res: Response;

        const req = this.request(u, 'GET', p, i);

        try {

            res = await fetch(req);

        } catch (error) {

            // fetch will only reject on network errors (e.g. no connection, DNS errors)
            throw this.errors.process(ERRORS.HTTP.NETWORK);
        }

        return await this.response(res, req);
    }

    /**
     * Performs a 'POST' request and returns the parsed response data.
     *
     * @param u - the request url
     * @param p - an optional request payload
     * @param i - an optional `RequestOptions` object
     */
    async post<T> (u: string, p?: PostPayload, i?: RequestOptions): Promise<T> {

        let res: Response;

        const req = this.request(u, 'POST', p, i);

        try {

            res = await fetch(req);

        } catch (error) {

            // fetch will only reject on network errors (e.g. no connection, DNS errors)
            throw this.errors.process(ERRORS.HTTP.NETWORK);
        }

        return await this.response(res, req);
    }

    /**
     * Creates a new `Request` instance for usage with `fetch()`.
     *
     * @remarks
     * `request()` provides a simpler interface for the `fetch()` API by creating `Request` instances
     * with predefined default headers, which can be passed directly to `fetch()`. It also allows us
     * to type request payloads depending on the request method and to encode those payloads either
     * as query parameters or stringified JSON bodies. Additional `RequestInit` properties can be
     * provided as well, e.g. an `AbortSignal`.
     *
     * @param u - the request url
     * @param m - the request method
     * @param p - an optional request payload
     * @param i - an optional `RequestOptions` object
     */
    protected request (u: string, m: 'GET', p?: GetPayload, i?: RequestOptions): Request;
    protected request (u: string, m: 'POST', p?: PostPayload, i?: RequestOptions): Request;
    protected request (u: string, m: RequestMethod, p?: RequestPayload, i?: RequestOptions): Request {

        const url = new URL(u);
        const init = {
            ...DEFAULT_REQUEST_INIT[m],
            ...i,
        };

        if (m === 'GET') {

            if (p) {

                // TypeScript only allows Record<string, string>, however Record<string, string | number | boolean>
                // is implicitly converted and can be used just fine
                url.search = new URLSearchParams(p as string | string[][] | Record<string, string> | URLSearchParams).toString();
            }

        } else {

            if (p !== undefined) {

                init.body = JSON.stringify(p);
            }
        }

        return new Request(url.toString(), init);
    }

    /**
     * Parses a `Response` instance, handles errors and returns the response data.
     *
     * @remarks
     * `response()` simplifies the handling of `Response` instances received from `fetch()`.
     * It checks the response status and throws appropriate errors if necessary. It also
     * checks response headers to determine how to parse the response data and skips empty
     * response bodies.
     *
     * @param res - the `Response` instance
     * @param req - the `Request` instance creating the response (optional)
     */
    protected async response<T> (res: Response, req?: Request): Promise<T> {

        if (!res.ok) {

            if (res.status >= 400) {

                throw this.errors.process(res);
            }
        }

        try {

            const contentLength = res.headers.get('content-length');
            const contentType = res.headers.get('content-type') || req?.headers.get('accept');

            // check `204 No Content` status and content length to prevent parsing errors of empty responses
            if (res.status === 204 || contentLength === '0') {

                return undefined as unknown as T;
            }

            if (contentType) {

                if (/application\/json/.test(contentType)) {

                    return await res.json() as T;
                }

                if (/text\//.test(contentType)) {

                    return await res.text() as unknown as T;
                }
            }

            this.logger.warn(`response parsing skipped: content-type '${ contentType || 'null' }' not supported`);

            return undefined as unknown as T;

        } catch (error) {

            throw this.errors.process(ERRORS.HTTP.BAD_RESPONSE);
        }
    }
}
