import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { showConsentElement } from '../../services/consent';

const template = function (this: CookiePolicyElement) {

    return html`
    <h2>Cookie Policy</h2>

    <h3>What are cookies?</h3>
    <p>
        A cookie is a small text file that a website stores on your computer or mobile device when you visit the site.
        Cookies are widely used to enable a website to remember your preferences or to establish statistics about the
        browsing experience on a website.
    </p>

    <h3>How do we use cookies?</h3>
    <p>
        Swivel relies solely on "first-party cookies". These cookies are set and controlled by Swivel and cannot be read by
        another service or website. This way Swivel Finance ensures that your usage of our services cannot be tracked by
        third-party services.
    </p>
    <p>
        Swivel uses the following categories of first-party cookies:
    </p>

    <h4>Preferences cookies</h4>
    <p>
        Allows us to store your preferences on your device to improve your experience. Stored preferences include your
        cookie consent choices, your preferred user interface theme, your last selected market, etc.
    </p>

    <h4>Statistics cookies</h4>
    <p>
        Allows us to collect anonymised statistics data to improve our service. The collected information helps us
        understand how our service performs, how visitors interact with our service, and whether there may be technical
        issues.
    </p>

    <h3>How can you manage cookies?</h3>

    <p>
        You can manage your cookie settings for ${ this.domain } in our
        <a href="#" @click=${ (event: Event) => this.showConsentManager(event) }>consent manager</a>.
    </p>

    <h4>Managing site-specific cookies</h4>
    <p>
        For more detailed control over site-specific cookies, check the privacy and cookie settings in your preferred
        browser.
    </p>

    <h4>Removing cookies from your device</h4>
    <p>
        You can delete all cookies that are already on your device by clearing the browsing history of your browser.
        This will remove all cookies from all websites you have visited.
    </p>
    <p>
        Be aware though that you may also lose some saved information (e.g. saved login details, site preferences).
    </p>

    <h4>Blocking cookies</h4>
    <p>
        You can set most modern browsers to prevent any cookies being placed on your device, but you may then have to
        manually adjust some preferences every time you visit a site/page. And some services and functionalities may not
        work properly at all (e.g. profile logging-in).
    </p>
    `;
};

@customElement('ill-cookie-policy')
export class CookiePolicyElement extends LitElement {

    @property()
    domain = window.location.host;

    createRenderRoot () {

        return this;
    }

    render () {

        return template.call(this);
    }

    showConsentManager (event: Event) {

        event.preventDefault();

        showConsentElement();
    }
}
