import { cancel } from '@swivel-finance/ui/utils/events';
import { html, LitElement, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { ENV } from '../../core/env';

const round = (value: number): number => {

    return Math.round(value * 10000) / 10000;
};

const percentage = (value: number): string => {

    return (round(value) * 100).toFixed(2);
};

const template = function (this: ShareRateElement) {

    if (!this.tweetUrl || !this.shareRateUrl || !this.rate || !this.underlying) return nothing;

    const rate = round(this.rate).toString();
    const underlying = this.underlying;
    const ratePercentage = percentage(this.rate);

    // we create the picasso url which will generate the preview page and preview image for the twitter card
    const shareUrl = new URL(this.shareRateUrl.replace('<RATE_TYPE>', this.type));
    shareUrl.searchParams.set('rate', rate);
    shareUrl.searchParams.set('underlying', underlying);

    // we create the tweet url which will pre-populate the tweet text, url, hashtags and account
    const tweetUrl = new URL(this.tweetUrl);
    const tweetText = `Ask me about my ${ ratePercentage }% ${ this.type === 'lend' ? 'FIXED ' : '' }YIELD ON ${ underlying }\n`;
    tweetUrl.searchParams.set('text', tweetText);
    tweetUrl.searchParams.set('url', shareUrl.toString());
    tweetUrl.searchParams.set('hashtags', this.hashtags.join(','));
    tweetUrl.searchParams.set('via', this.account);

    return html`
    <a href="${ tweetUrl.toString() }" target="_blank" rel="noopener">
        Share
        <ui-icon name="twitter"></ui-icon>
    </a>
    `;
};

@customElement('ill-share-rate')
export class ShareRateElement extends LitElement {

    protected tweetUrl = ENV.tweetUrl;

    protected shareRateUrl = ENV.shareRateUrl;

    @property({ attribute: false })
    account = 'IlluminateFi';

    @property({ attribute: false })
    hashtags = [
        'DeFiFixed',
    ];

    @property({ attribute: false })
    rate?: number;

    @property({ attribute: false })
    underlying?: string;

    @property({ attribute: false })
    type: 'lend' | 'pool' = 'lend';

    protected createRenderRoot (): Element | ShadowRoot {

        return this;
    }

    protected render (): unknown {

        return template.apply(this);
    }

    protected handleClick (event: Event): void {

        cancel(event);
    }
}
