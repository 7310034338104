import { html, LitElement, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { ETH } from '../../core/constants';
import { balance, status, tokenImage, tokenSymbol } from '../../shared/templates';
import { ACCOUNT, orchestrator } from '../../state/orchestrator';
import { Token } from '../../types';

const template = function (this: AccountBalanceElement) {

    const state = this.accountMachine.state;
    const context = state.context;

    const isFetching = state.matches(ACCOUNT.STATES.FETCHING);
    const isConnecting = state.matches(ACCOUNT.STATES.CONNECTING) || state.matches(ACCOUNT.STATES.DISCONNECTING);
    const isConnected = state.matches(ACCOUNT.STATES.CONNECTED) || isFetching;

    const token = context.balances.get(this.token.address);
    const tokenBalance = token?.balance;

    return isConnecting
        ? status('loading')
        : isConnected
            ? html`
            ${ tokenImage(token) }
            ${ tokenSymbol(token) }
            ${ isFetching
                ? status('loading')
                : balance(tokenBalance, token, this.decimals)
            }
            `
            : nothing;
};

@customElement('ill-account-balance')
export class AccountBalanceElement extends LitElement {

    protected accountMachine = orchestrator.account;

    @property()
    token: Token = ETH;

    @property({
        attribute: true,
        reflect: true,
        type: Number,
    })
    decimals = 2;

    constructor () {

        super();

        this.handleTransition = this.handleTransition.bind(this);
    }

    connectedCallback () {

        super.connectedCallback();

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.accountMachine.onTransition(this.handleTransition);
    }

    disconnectedCallback () {

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.accountMachine.off(this.handleTransition);

        super.disconnectedCallback();
    }

    protected createRenderRoot (): Element | ShadowRoot {

        return this;
    }

    protected render (): unknown {

        return template.apply(this);
    }

    protected updated () {

        const root = this.renderRoot as HTMLElement;

        if (this.accountMachine.state.matches(ACCOUNT.STATES.CONNECTED)) {

            root.classList.add('connected');

        } else {

            root.classList.remove('connected');
        }

        if (this.accountMachine.state.matches(ACCOUNT.STATES.FETCHING)) {

            root.classList.add('fetching', 'connected');

        } else {

            root.classList.remove('fetching');
        }
    }

    protected handleTransition () {

        this.requestUpdate();
    }
}
