import { PanelChangeEvent } from '@swivel-finance/ui/elements/panel-container';
import { html, LitElement } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { ERRORS, NOTIFICATIONS } from '../../core/constants';
import { AddLiquidityTransaction, ADD_LIQUIDITY_STATUS } from '../../core/pools';
import { ERROR_SERVICE, LOG_SERVICE, serviceLocator } from '../../core/services';
import { TransactionMessage, TransactionTopic, TRANSACTION_SERVICE, TRANSACTION_TOPIC } from '../../core/services/transaction';
import { WALLET_SERVICE } from '../../core/services/wallet';
import { POOL_ROUTE, RouteMatch, router } from '../../routes';
import { createNotification, notifications, NOTIFICATION_TIMEOUTS } from '../../services/notification';
import { ACCOUNT, orchestrator, POOL } from '../../state/orchestrator';

const template = function (this: PoolPageElement) {

    const isPending = this.transaction?.isPending();

    return html`
    <ui-wizard .current=${ this.step } @ui-panel-changed=${ (event: PanelChangeEvent) => this.handleStepChange(event) }>

        <nav class="step-navigation" aria-label="Add Liquidity Steps">
            <ul class="step-list" data-part="triggers">
                <li class="step-link"><a data-part="trigger" href="#">Select a Currency</a></li>
                <li class="step-link"><a data-part="trigger" href="#" aria-disabled=${ this.step < POOL_STEPS.MATURITY }>Select a Maturity</a></li>
                <li class="step-link"><a data-part="trigger" href="#" aria-disabled=${ this.step < POOL_STEPS.PREVIEW }>Add Liquidity Preview</a></li>
                <li class="step-link"><a data-part="trigger" href="#" aria-disabled=${ this.step < POOL_STEPS.RESULT }>Add Liquidity Result</a></li>
            </ul>
        </nav>

        <div class="step-container" data-part="panels">

            <div class="step-panel" data-part="panel">
                <ill-pool-token></ill-pool-token>
            </div>

            <div class="step-panel" data-part="panel">
                <ill-pool-maturity></ill-pool-maturity>
            </div>

            <div class="step-panel" data-part="panel">
                <ill-add-liquidity-transaction-preview .transaction=${ this.transaction }></ill-add-liquidity-transaction-preview>
            </div>

            <div class="step-panel" data-part="panel">
                ${ isPending
                    ? html`<ill-add-liquidity-transaction-status .transaction=${ this.transaction }></ill-add-liquidity-transaction-status>`
                    : html`<ill-add-liquidity-transaction-result .transaction=${ this.transaction }></ill-add-liquidity-transaction-result>`
                }
            </div>

        </div>

    </ui-wizard>
    `;
};

const enum POOL_STEPS {
    TOKEN,
    MATURITY,
    PREVIEW,
    RESULT,
}

@customElement('ill-pool-page')
export class PoolPageElement extends LitElement {

    protected logger = serviceLocator.get(LOG_SERVICE).group('pool-page');

    protected errors = serviceLocator.get(ERROR_SERVICE);

    protected router = router();

    protected accountMachine = orchestrator.account;

    protected poolMachine = orchestrator.pool;

    protected transactionService = serviceLocator.get(TRANSACTION_SERVICE);

    protected walletService = serviceLocator.get(WALLET_SERVICE);

    protected notification?: string;

    @state()
    protected transaction?: AddLiquidityTransaction;

    @state()
    protected step = POOL_STEPS.TOKEN;

    constructor () {

        super();

        this.handleRouteChange = this.handleRouteChange.bind(this);
        this.handlePoolTransition = this.handlePoolTransition.bind(this);
        this.handleTransactionChange = this.handleTransactionChange.bind(this);
    }

    connectedCallback (): void {

        super.connectedCallback();

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.router.subscribe(this.handleRouteChange);

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.poolMachine.onTransition(this.handlePoolTransition);

        this.poolMachine.send(POOL.model.events[POOL.EVENTS.FETCH]());
    }

    disconnectedCallback (): void {

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.router.unsubscribe(this.handleRouteChange);

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.poolMachine.off(this.handlePoolTransition);

        this.disposeTransaction();

        super.disconnectedCallback();

        this.step = POOL_STEPS.TOKEN;
    }

    protected createRenderRoot (): Element | ShadowRoot {

        return this;
    }

    protected render (): unknown {

        return template.apply(this);
    }

    protected async createTransaction (): Promise<void> {

        if (!this.accountMachine.state.matches(ACCOUNT.STATES.CONNECTED)) return;
        if (!this.poolMachine.state.matches(POOL.STATES.COMPLETE)) return;

        const connection = this.walletService.state.connection;
        const { pools, selectedPool } = this.poolMachine.state.context;

        this.logger.log('createTransation()... ', pools, selectedPool);

        const pool = pools.get(selectedPool);

        if (!pool) {

            throw this.errors.process(ERRORS.COMPONENTS.POOL.POOL);
        }

        this.disposeTransaction();

        // we create transactions through a service - this allows other components to detect when a transaction is created
        // and subscribe to it for status changes (e.g. globally notify on failed transactions)
        // and it allows the transaction service to serialize/parse transaction into/from local storage and associate
        // transaction constructors with the serialized data
        this.transaction = this.transactionService.create(AddLiquidityTransaction, {
            state: {
                pool,
            },
            connection,
        });

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.transaction.subscribe(TRANSACTION_TOPIC.STATUS, this.handleTransactionChange);
        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.transaction.subscribe(TRANSACTION_TOPIC.STATE, this.handleTransactionChange);

        this.logger.log('createTransation()... ', this.transaction);

        this.requestUpdate();

        await this.updateComplete;
    }

    protected disposeTransaction (): void {

        this.logger.log('disposeTransation()... ', this.transaction);

        // we first want to dispose of ongoing notifications for the current transaction
        this.disposeNotifications();

        // when a call is made to dispose of a transaction, we always want to unsubscribe from future updates,
        // as the lend-page is done with this transaction (it might be further observed by the transaction service)

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.transaction?.unsubscribe(TRANSACTION_TOPIC.STATE, this.handleTransactionChange);
        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.transaction?.unsubscribe(TRANSACTION_TOPIC.STATUS, this.handleTransactionChange);

        // don't dispose of transactions which are submitted to the chain...
        if (this.transaction?.isPending() || this.transaction?.isFinal()) return;

        this.transaction?.dispose();

        this.transaction = undefined;
    }

    protected disposeNotifications (): void {

        if (!this.notification) return;

        if (this.transaction?.isPending()) {

            notifications.update(this.notification, createNotification({
                id: this.notification,
                type: 'info',
                content: NOTIFICATIONS.POOL_ENTER.TRANSACTION_DISPOSED,
            }));

        } else {

            // ensure the notification will dismiss automatically
            notifications.update(this.notification, { dismissable: true, timeout: NOTIFICATION_TIMEOUTS.info });
        }
    }

    protected handlePoolTransition (state: typeof orchestrator.pool.state, event: POOL.Event): void {

        // ignore state changes that happen on different routes
        if (router().activeRoute?.route !== POOL_ROUTE) return;

        this.logger.log(`handlePoolTransition()... ${ event.type } --> ${ state.value.toString() }: `, state.context);

        if (state.matches(POOL.STATES.FETCHING) || state.matches(POOL.STATES.SELECT_TOKEN) || (this.step === POOL_STEPS.TOKEN && state.matches(POOL.STATES.ERROR))) {

            this.step = POOL_STEPS.TOKEN;
        }

        if (state.matches(POOL.STATES.SELECT_POOL) || (this.step === POOL_STEPS.MATURITY && state.matches(POOL.STATES.ERROR))) {

            this.step = POOL_STEPS.MATURITY;
        }

        if (state.matches(POOL.STATES.COMPLETE) && this.step === POOL_STEPS.MATURITY) {

            this.step = POOL_STEPS.PREVIEW;
            void this.createTransaction().then(() => this.step = POOL_STEPS.PREVIEW);
        }

        this.requestUpdate();
    }

    protected handleTransactionChange (message: TransactionMessage<AddLiquidityTransaction, TransactionTopic>): void {

        this.logger.log('handleTransactionChange()... ', message);

        if (message.topic === TRANSACTION_TOPIC.STATUS) {

            switch (message.transaction.status) {

                case ADD_LIQUIDITY_STATUS.APPROVING:

                    this.notification = notifications.show({
                        type: 'progress',
                        content: NOTIFICATIONS.POOL_ENTER.APPROVAL_PENDING,
                        dismissable: false,
                    });

                    this.step = POOL_STEPS.RESULT;

                    break;

                case ADD_LIQUIDITY_STATUS.PENDING:

                    if (this.notification) {

                        notifications.update(this.notification, createNotification({
                            id: this.notification,
                            type: 'success',
                            content: NOTIFICATIONS.POOL_ENTER.APPROVAL_SUCCESS,
                        }));
                    }

                    this.step = POOL_STEPS.RESULT;

                    break;

                case ADD_LIQUIDITY_STATUS.DISPOSED:

                    // transaction is terminal and won't change any longer
                    // eslint-disable-next-line @typescript-eslint/unbound-method
                    message.transaction.unsubscribe(TRANSACTION_TOPIC.STATUS, this.handleTransactionChange);
                    // eslint-disable-next-line @typescript-eslint/unbound-method
                    message.transaction.unsubscribe(TRANSACTION_TOPIC.STATE, this.handleTransactionChange);

                    break;

                case ADD_LIQUIDITY_STATUS.SUCCESS:
                case ADD_LIQUIDITY_STATUS.FAILURE:

                    // transaction is terminal and won't change any longer
                    // eslint-disable-next-line @typescript-eslint/unbound-method
                    message.transaction.unsubscribe(TRANSACTION_TOPIC.STATUS, this.handleTransactionChange);
                    // eslint-disable-next-line @typescript-eslint/unbound-method
                    message.transaction.unsubscribe(TRANSACTION_TOPIC.STATE, this.handleTransactionChange);

                    // if we're coming from APPROVING to FAILURE
                    if (message.transaction.status === ADD_LIQUIDITY_STATUS.FAILURE && message.detail === ADD_LIQUIDITY_STATUS.APPROVING) {

                        if (this.notification) {

                            notifications.update(this.notification, createNotification({
                                id: this.notification,
                                type: 'failure',
                                content: NOTIFICATIONS.POOL_ENTER.APPROVAL_FAILURE(message.transaction.error?.message ?? 'Unknown error.'),
                            }));

                        } else {

                            this.notification = notifications.show({
                                type: 'failure',
                                content: NOTIFICATIONS.POOL_ENTER.APPROVAL_FAILURE(message.transaction.error?.message ?? 'Unknown error.'),
                            });
                        }
                    }

                    this.step = POOL_STEPS.RESULT;

                    break;
            }
        }

        this.requestUpdate();
    }

    protected handleStepChange (event: PanelChangeEvent): void {

        this.logger.log('handleStepChange()... ', event);

        const step = event.detail.panel as POOL_STEPS;

        if (step === this.step) return;

        this.step = step;

        switch (step) {

            case POOL_STEPS.TOKEN:

                this.poolMachine.send(POOL.model.events[POOL.EVENTS.FETCH]());
                this.disposeTransaction();
                break;

            case POOL_STEPS.MATURITY:

                this.poolMachine.send(POOL.model.events[POOL.EVENTS.SET_TOKEN](this.poolMachine.state.context.selectedToken));
                this.disposeTransaction();
                break;

            case POOL_STEPS.PREVIEW:

                void this.createTransaction();
                break;

            case POOL_STEPS.RESULT:

                // nothing for now
                break;
        }
    }

    protected handleRouteChange (match: RouteMatch): void {

        if (match.route === POOL_ROUTE) {

            // we can simply hijack the step change handler and reset the step
            this.handleStepChange(new PanelChangeEvent({ target: this, panel: POOL_STEPS.TOKEN }));
        }
    }
}
