import { TooltipElement } from '@swivel-finance/ui/elements/tooltip/tooltip';
import { task } from '@swivel-finance/ui/utils/async';
import { dispatch } from '@swivel-finance/ui/utils/events';
import { html, LitElement, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { ERRORS } from '../../core/constants';
import { LOG_SERVICE, serviceLocator } from '../../core/services';
import { STATUS_SERVICE } from '../../core/services/status';
import { iPT, lpT } from '../../core/services/token';
import { errorMessage, maturityBar, message, status, tokenBalance, tokenImage } from '../../shared/templates';
import { orchestrator, POSITION } from '../../state/orchestrator';
import { LendPosition, PoolPosition } from '../../types';
import { PositionEvent } from './events';
import { lendPositionActions, lendPositionStatus, lendPositionTooltips, poolPositionActions, poolPositionStatus, poolPositionTooltips } from './helpers';
import { PositionStatus, POSITION_MODES, POSITION_TYPES } from './types';

const logger = serviceLocator.get(LOG_SERVICE).group('positions');



const positionStatusTemplate = function (this: PositionsElement, status: PositionStatus) {

    const hasStatus = status.isRedeemed || status.isPaused || status.isDivesting;

    return hasStatus
        ? html`
        <div class="position-status">
            ${ status.isRedeemed
                ? html`
                <span class="status-redeemed"><ui-icon name="check"></ui-icon>Redeemed</span>
                `
                : nothing
            }
            ${ status.isPaused
                ? html`
                <ui-icon name="pause" aria-describedby="${ status.pausedTooltipId }"></ui-icon>
                <ui-tooltip id="${ status.pausedTooltipId }">${ status.pausedTooltip }</ui-tooltip>
                `
                : nothing
            }
            ${ status.isDivesting
                ? html`
                <ui-icon name="clock" aria-describedby="${ status.pausedTooltipId }"></ui-icon>
                <ui-tooltip id="${ status.pausedTooltipId }">${ status.pausedTooltip }</ui-tooltip>
                `
                : nothing
            }
        </div>
        `
        : nothing;
};

const lendPositionTemplate = function (this: PositionsElement, position: LendPosition): unknown {

    const positionState = this.positionMachine.state;

    if (!positionState.matches(POSITION.STATES.SUCCESS)) return nothing;

    const market = position.market;
    const token = position.market.token;
    const ipt = iPT(market);

    if (!market || !token) return nothing;

    const status = lendPositionStatus(this.positionMachine.state.context, position, this.isLenderPaused);
    const tooltips = lendPositionTooltips(position);

    const classes = {
        matured: status.isMatured,
        redeemed: status.isRedeemed,
        archived: status.isArchived,
        paused: status.isPaused,
        virtual: status.isVirtual,
    };

    return html`
    <ui-collapsible class="${ classMap({ position: true, ...classes }) }">
        <h3 data-part="header">
            ${ tokenImage(token) }
            <span class="position-type">
                ${ status.isVirtual ? 'iPT' : 'LEND' }
            </span>
            <span class="position-size" aria-describedby="${ tooltips.valueAtMaturity.id }">
                ${ tokenBalance(position.iptBalance, ipt) }
                <ui-tooltip id="${ tooltips.valueAtMaturity.id }">
                    ${ tooltips.valueAtMaturity.content }
                </ui-tooltip>
            </span>
            ${ maturityBar(market) }
            ${ lendPositionActions(position, status, tooltips, this.lend.bind(this), this.exit.bind(this)) }
            ${ positionStatusTemplate.call(this, status) }
            <button data-part="trigger" class="button-icon">
                <ui-icon name="back-circle"></ui-icon>
            </button>
        </h3>
        <div data-part="region">
            <ill-position-details-lend .position=${ position } .isLenderPaused=${ this.isLenderPaused }></ill-position-details-lend>
        </div>
        <ui-tooltip id="${ tooltips.exit.id }">
            ${ tooltips.exit.content }
        </ui-tooltip>
        <ui-tooltip id="${ tooltips.enter.id }">
            ${ tooltips.enter.content }
        </ui-tooltip>
    </ui-collapsible>
    `;
};

const poolPositionTemplate = function (this: PositionsElement, position: PoolPosition): unknown {

    const positionState = this.positionMachine.state;

    if (!positionState.matches(POSITION.STATES.SUCCESS)) return nothing;

    const pool = position.pool;
    const info = position.info;
    const token = position.pool.token;

    if (!pool || !token) return nothing;

    const status = poolPositionStatus(this.positionMachine.state.context, position);
    const tooltips = poolPositionTooltips(position);
    const classes = {
        matured: status.isMatured,
        redeemed: status.isRedeemed,
        archived: status.isArchived,
        paused: status.isPaused,
        virtual: status.isVirtual,
        divesting: status.isDivesting,
        divested: status.isDivested,
    };

    const lpt = lpT(pool);

    return html`
    <ui-collapsible class="${ classMap({ position: true, ...classes }) }">
        <h3 data-part="header">
            <ill-token-symbol .name=${ token.image } .ipt=${ true }></ill-token-symbol>
            <span class="position-type">POOL</span>
            ${ status.isDivested || status.isExited
                ? tokenBalance(position.currentPositionValue, token)
                : tokenBalance(info.strategyBalanceInLpTokens, lpt)
            }
            ${ maturityBar(pool) }
            ${ poolPositionActions(position, status, tooltips, this.addLiquidity.bind(this), this.removeLiquidity.bind(this)) }
            ${ positionStatusTemplate.call(this, status) }
            <button data-part="trigger" class="button-icon">
                <ui-icon name="back-circle"></ui-icon>
            </button>
        </h3>
        <div data-part="region">
            <ill-position-details-pool .position=${ position } .isLenderPaused=${ this.isLenderPaused }></ill-position-details-pool>
        </div>
        <ui-tooltip id="${ tooltips.exit.id }">
            ${ tooltips.exit.content }
        </ui-tooltip>
        <ui-tooltip id="${ tooltips.enter.id }">
            ${ tooltips.enter.content }
        </ui-tooltip>
    </ui-collapsible>
    `;
};

const template = function (this: PositionsElement): unknown {

    const state = this.positionMachine.state;

    const isReady = state.matches(POSITION.STATES.SUCCESS);
    const isFetching = state.matches(POSITION.STATES.FETCHING);
    const hasError = state.matches(POSITION.STATES.ERROR);

    const { activeLendPositions, activePoolPositions, maturedLendPositions, maturedPoolPositions, archivedLendPositions, archivedPoolPositions } = state.context;

    const hasActive = !!activeLendPositions && activeLendPositions?.length > 0 || !!activePoolPositions && activePoolPositions.length > 0;
    const hasMatured = !!maturedLendPositions && maturedLendPositions?.length > 0 || !!maturedPoolPositions && maturedPoolPositions.length > 0;
    const hasArchived = !!archivedLendPositions && archivedLendPositions?.length > 0 || !!archivedPoolPositions && archivedPoolPositions.length > 0;

    return hasError
        ? (state.context.error === ERRORS.STATE.POSITIONS.NO_POSITIONS.message)
            ? message(state.context.error)
            : errorMessage(state.context.error)
        : isFetching
            ? status('loading')
            : isReady
                ? html`
                <ui-accordion class="positions">
                    ${ this.status === 'ACTIVE'
                        ? hasActive
                            ? [
                                ...(activeLendPositions ?? []).map(position => lendPositionTemplate.apply(this, [position])),
                                ...(activePoolPositions ?? []).map(position => poolPositionTemplate.apply(this, [position])),
                            ]
                            : message('You don\'t have any active positions.')
                        : this.status === 'MATURED'
                            ? hasMatured
                                ? [
                                    ...(maturedLendPositions ?? []).map(position => lendPositionTemplate.apply(this, [position])),
                                    ...(maturedPoolPositions ?? []).map(position => poolPositionTemplate.apply(this, [position])),
                                ]
                                : message('You don\'t have any matured positions.')
                            : hasArchived
                                ? [
                                    ...(archivedLendPositions ?? []).map(position => lendPositionTemplate.apply(this, [position])),
                                    ...(archivedPoolPositions ?? []).map(position => poolPositionTemplate.apply(this, [position])),
                                ]
                                : message('You don\'t have any archived positions.')
                    }
                </ui-accordion>
                `
                : nothing;
};

@customElement('ill-positions')
export class PositionsElement extends LitElement {

    protected statusService = serviceLocator.get(STATUS_SERVICE);

    protected positionMachine = orchestrator.position;

    @state()
    isLenderPaused = false;

    @property({ attribute: false })
    status: 'ACTIVE' | 'MATURED' | 'ARCHIVED' = 'ACTIVE';

    constructor () {

        super();

        this.handlePositionTransition = this.handlePositionTransition.bind(this);

        this.statusService.getStatus().then(
            status => { this.isLenderPaused = status.illuminatePaused; },
            () => { /* we ignore this for now... */ },
        );
    }

    connectedCallback (): void {

        super.connectedCallback();

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.positionMachine.onTransition(this.handlePositionTransition);
    }

    disconnectedCallback (): void {

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.positionMachine.off(this.handlePositionTransition);

        super.disconnectedCallback();
    }

    protected createRenderRoot (): Element | ShadowRoot {

        return this;
    }

    protected render (): unknown {

        return template.apply(this);
    }

    protected updated (): void {

        // we need to defer updating the tooltips, as lit-element updates seem to trigger
        // before child elements have rendered...
        task(() => {

            const tooltips = this.renderRoot.querySelectorAll<TooltipElement>('ui-tooltip');

            tooltips.forEach(tooltip => tooltip.connectTooltip());
        });
    }

    protected handlePositionTransition (state: typeof orchestrator.position.state, event: POSITION.Event): void {

        logger.log(`handlePositionTransition... ${ state.value.toString() }: `, event, state.context);

        this.requestUpdate();
    }

    protected lend (position: LendPosition): void {

        dispatch(this, new PositionEvent({ type: POSITION_TYPES.LEND, mode: POSITION_MODES.ENTER, position }));
    }

    protected exit (position: LendPosition): void {

        dispatch(this, new PositionEvent({ type: POSITION_TYPES.LEND, mode: POSITION_MODES.EXIT, position }));
    }

    protected addLiquidity (position: PoolPosition): void {

        dispatch(this, new PositionEvent({ type: POSITION_TYPES.POOL, mode: POSITION_MODES.ENTER, position }));
    }

    protected removeLiquidity (position: PoolPosition): void {

        dispatch(this, new PositionEvent({ type: POSITION_TYPES.POOL, mode: POSITION_MODES.EXIT, position }));
    }
}
