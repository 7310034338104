import { PRINCIPAL_NAMES } from '@swivel-finance/illuminate-js';
import { html, nothing } from 'lit';
import { Principals } from '../../types';

const PRINCIPAL_ICONS: Record<Principals, string> = {
    [Principals.Illuminate]: 'illuminate',
    [Principals.Swivel]: 'swivel',
    [Principals.Yield]: 'yield',
    [Principals.Pendle]: 'pendle',
    [Principals.Apwine]: 'apwine',
    [Principals.Notional]: 'notional',
    [Principals.Exactly]: 'exactly',
    [Principals.Term]: 'term',
};

/**
 * Creates a template to display the principal token protocol (using a human readable name and optional icon).
 *
 * @param p - a principal
 * @param i - show the protocol icon (default: true)
 */
export const principal = (p: Principals, i = true) => html`
<span class="principal">
    ${ i
        ? html`<ill-token-symbol .name=${ PRINCIPAL_ICONS[p] }></ill-token-symbol>`
        : nothing
    }
    <span class="name">${ PRINCIPAL_NAMES[p] }</span>
</span>`;
