import { TransactionResponse } from '@ethersproject/abstract-provider';
import { BigNumber, constants } from 'ethers';
import { LendPosition, Market, Principals } from '../../types';
import { fixed, trim } from '../amount';
import { ERRORS } from '../constants';
import { ErrorLike, ErrorService, IlluminateService, LogService, ServiceIdentifier } from '../services';
import { Connection } from '../services/wallet';
import { marketKey, matured } from './helpers';

export interface RedeemPreview {
    /**
     * The pool's iPT address
     */
    iptAddress: string;
    /**
     * The pool address
     */
    poolAddress: string;
    /**
     * The amount returned when redeeming
     */
    amountReturned: string;
}

export class RedeemService {

    protected cache = new Map<string, { iptAddress: string; poolAddress: string; }>();

    protected logger: LogService;

    protected errors: ErrorService;

    protected illuminate: IlluminateService;

    constructor (illuminate: IlluminateService, errors: ErrorService, logger: LogService) {

        this.illuminate = illuminate;
        this.errors = errors;
        this.logger = logger.group('redeem-service');
    }

    async fetchPreview (market: Market, position: LendPosition, amount: string, connection?: Connection): Promise<RedeemPreview> {

        try {

            const key = marketKey(market);

            // we cache the pool info for a market, as it won't change
            if (!this.cache.get(key)) {

                const [iptAddress, poolAddress] = await Promise.all([
                    this.illuminate.marketPlace.markets(position.underlying, position.maturity, Principals.Illuminate, connection),
                    this.illuminate.marketPlace.pools(position.underlying, position.maturity, connection),
                ]);

                this.cache.set(key, {
                    iptAddress,
                    poolAddress,
                });
            }

            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const pool = this.cache.get(key)!;

            // ideally we only need to re-fetch the preview sale when the amount changes
            const amountReturned = matured(market)
                ? await this.illuminate.redeemer.redeemPreview(position, amount, connection)
                : await this.illuminate.marketPlace.sellPrincipalTokenPreview(position.underlying, position.maturity, amount, pool.poolAddress, connection);

            const preview: RedeemPreview = {
                ...pool,
                amountReturned,
            };

            this.logger.log('fetchPreview()... ', preview);

            return preview;

        } catch (error) {

            // TODO: make this somehow more ergonomic...
            const detail = (error as ErrorLike).message;

            const override = detail
                ? this.errors.updateMessage(ERRORS.STATE.REDEEM.FETCH, [[/\.$/, `: ${ detail }`]])
                : ERRORS.STATE.REDEEM.FETCH;

            throw this.errors.process(error, override);
        }
    }

    /**
     * Get the minimum token amount a redeem operation needs to return
     *
     * @remarks
     * This is the return amount minus the slippage.
     */
    slippageToMin (slippage: string | number, amount: string): string {

        const slippageAmount = fixed(amount).mulUnsafe(fixed(slippage));

        const minAmount = trim(fixed(amount).subUnsafe(slippageAmount).floor().toString());

        this.logger.log('slippageToMin()... slippage: %s, amount: %s, slippageAmount: %s, minAmount: %s ', slippage, amount, slippageAmount, minAmount);

        return minAmount;
    }

    async checkAllowance (position: LendPosition, preview: RedeemPreview, amount: string, connection?: Connection): Promise<boolean> {

        const allowance = matured(position)
            // the redeemer contract doesn't need allowance or approval to redeem
            ? constants.MaxUint256
            : await this.illuminate.marketPlace.allowance(preview.iptAddress, connection);

        return BigNumber.from(amount).lte(allowance);
    }

    async approve (position: LendPosition, preview: RedeemPreview, connection?: Connection): Promise<TransactionResponse | undefined> {

        return matured(position)
            // the redeemer contract doesn't need allowance or approval to redeem
            ? undefined
            : await this.illuminate.marketPlace.approve(preview.iptAddress, connection);
    }

    async redeem (market: Market, position: LendPosition, amount: string, min: string, connection?: Connection): Promise<TransactionResponse> {

        return matured(market)
            ? await this.illuminate.redeemer.redeem(position, connection)
            : await this.illuminate.marketPlace.sellPrincipalToken(
                position.underlying,
                position.maturity,
                amount,
                min,
                connection,
            );
    }
}

export const REDEEM_SERVICE = ServiceIdentifier.get(RedeemService);
