import { Pool } from '../../types';
import { matured } from '../markets';
import { StrategyState } from '../services/strategy';
import type { AddLiquidityPreview } from './add-liquidity-transaction';
import type { RemoveLiquidityPreview } from './remove-liquidity-transaction';

/**
 * Reduces a map of pools to a map of token address keys and the respective best pool for each token.
 *
 * @param pools - a map of pools
 * @returns a map of best pools keyed by token address and sorted by APR
 */
export const bestPoolsByToken = (pools: Map<string, Pool>): Map<string, Pool> => {

    const poolsByToken = new Map<string, Pool>();

    [...pools.values()].forEach(pool => {

        const { underlying, apr } = pool;
        const current = poolsByToken.get(underlying);

        // don't store pools in the poolsByToken map which are matured, paused or whose strategy is not in the required state
        if (isStrategyValid(pool) && !matured(pool) && !pool.paused && (!current || current.apr < apr)) {

            poolsByToken.set(underlying, pool);
        }
    });

    // sort the map entries by APR
    return new Map([...poolsByToken.entries()].sort((a, b) => sortByAPR(a[1], b[1])));
};

/**
 * A sort function to sort pools by APR in descending order
 *
 * @remarks
 * If a pool is paused or it's strategy is in unexpected state, we sort it to the and of the list.
 *
 * @param a - a pool
 * @param b - a pool to compare to
 */
export const sortByAPR = (a: Pool, b: Pool): number => {

    const aprA = a.apr ?? 0;
    const aprB = b.apr ?? 0;

    return a.paused || !isStrategyValid(a)
        ? 1
        : b.paused || !isStrategyValid(b)
            ? -1
            : aprB - aprA;
};

/**
 * Turns a map of pools keyed by market key into a map of pools keyed by pool address
 *
 * @param pools - a map of pools
 * @returns a map of pools keyed by pool address
 */
export const poolsByAddress = (pools: Map<string, Pool>): Map<string, Pool> => {

    return new Map([...pools.values()].map(pool => [pool.address, pool]));
};

/**
 * Checks if a pool's strategy is in a valid state (either INVESTED or DIVESTED)
 *
 * @param pool - the pool to check
 * @param exit - checks if the strategy is valid for removing liquidity, rather than adding liquidity
 */
export const isStrategyValid = (pool: Pool, exit = false): boolean => {

    const strategyState = pool.strategyState as StrategyState;

    return exit
        ? strategyState === StrategyState.INVESTED || strategyState === StrategyState.DIVESTED
        : strategyState === StrategyState.INVESTED;
};

export const isAddLiquidityPreview = (preview: AddLiquidityPreview | RemoveLiquidityPreview): preview is AddLiquidityPreview => {

    return typeof (preview as AddLiquidityPreview).lpTokensMinted === 'string'
        || typeof (preview as AddLiquidityPreview).strategyTokensMinted === 'string';
};

export const isRemoveLiquidityPreview = (preview: AddLiquidityPreview | RemoveLiquidityPreview): preview is RemoveLiquidityPreview => {

    return typeof (preview as RemoveLiquidityPreview).lpTokensBurned === 'string'
        || typeof (preview as RemoveLiquidityPreview).strategyTokensBurned === 'string';
};
