import { Lender } from '@swivel-finance/illuminate-js';
import { buildApproxParams, buildTokenInput } from '@swivel-finance/illuminate-js/constants/abi';
import { constants, ethers } from 'ethers';
import { ExactlyQuoteMeta, IlluminateQuoteMeta, Market, PendleQuoteMeta, Principals, Quote, SwivelQuoteMeta, YieldQuoteMeta } from '../../../../types';
import { fixed } from '../../../amount';
import { ERRORS } from '../../../constants';
import { ENV } from '../../../env';
import { isETHMarket } from '../../../markets';
import { ErrorService } from '../../errors';
import { LogService } from '../../logger';
import { TokenService } from '../../token';
import { Connection, WalletService } from '../../wallet';
import { IlluminateLenderService } from '../interfaces';

export class ChainIlluminateLenderService implements IlluminateLenderService {

    protected wallet: WalletService;

    protected token: TokenService;

    protected logger: LogService;

    protected errors: ErrorService;

    constructor (wallet: WalletService, token: TokenService, errors: ErrorService, logger: LogService) {

        this.wallet = wallet;
        this.token = token;
        this.errors = errors;
        this.logger = logger.group('illuminate-lender');
    }

    async allowance (underlying: string, connection?: Connection): Promise<string> {

        try {

            connection = connection ?? await this.wallet.connection();

            const allowance = await this.token.allowance(underlying, connection.account.address, ENV.lenderAddress, connection);

            this.logger.log('allowance: ', allowance);

            return allowance;

        } catch (error) {

            throw this.errors.isProcessed(error)
                ? error
                : this.errors.process(error, ERRORS.SERVICES.ILLUMINATE.ALLOWANCE);
        }
    }

    async approve (underlying: string, connection?: Connection): Promise<ethers.providers.TransactionResponse> {

        try {

            connection = connection ?? await this.wallet.connection();

            const response = await this.token.approve(underlying, ENV.lenderAddress, ethers.constants.MaxUint256, connection);

            this.logger.log('approval: ', response);

            return response;

        } catch (error) {

            throw this.errors.isProcessed(error)
                ? error
                : this.errors.process(error, ERRORS.SERVICES.ILLUMINATE.APPROVAL);
        }
    }

    async paused (principal: Principals, connection?: Connection): Promise<boolean> {

        connection = connection ?? await this.wallet.connection();
        const contract = new Lender(ENV.lenderAddress, connection.provider);

        try {

            return await contract.paused(principal);

        } catch (error) {

            throw this.errors.process(error);
        }
    }

    async halted (connection?: Connection): Promise<boolean> {

        connection = connection ?? await this.wallet.connection();
        const contract = new Lender(ENV.lenderAddress, connection.provider);

        try {

            return await contract.halted();

        } catch (error) {

            throw this.errors.process(error);
        }
    }

    async fee (maturity: string, connection?: Connection): Promise<string> {

        connection = connection ?? await this.wallet.connection();
        const contract = new Lender(ENV.lenderAddress, connection.provider);

        try {

            const feenominator = await contract.feenominator(maturity);

            return fixed(1).divUnsafe(fixed(feenominator)).toString();

        } catch (error) {

            throw this.errors.process(error);
        }
    }

    async lend (market: Market, quote: Quote, min: string, deadline: string, connection?: Connection): Promise<ethers.providers.TransactionResponse> {

        connection = connection ?? await this.wallet.connection();
        const contract = new Lender(ENV.lenderAddress, connection.signer);

        const lendETH = isETHMarket(market);
        const lendETHParams: [string, string] | undefined = lendETH
            ? [
                quote.pt.meta?.lst ?? constants.AddressZero,
                quote.pt.meta?.swapMinimum ?? '0',
            ]
            : undefined;

        try {

            const meta = quote.pt.meta;

            let slippage: number;
            let response: ethers.providers.TransactionResponse;

            switch (quote.principal) {

                case Principals.Illuminate:

                    if (!meta) throw this.errors.process(ERRORS.SERVICES.ILLUMINATE.LENDER.META);

                    response = await contract.lend(
                        quote.principal,
                        market.underlying,
                        market.maturity,
                        quote.amount,
                        [
                            (meta as IlluminateQuoteMeta).poolAddress,
                            min,
                        ],
                        lendETHParams,
                    );

                    break;

                case Principals.Swivel:

                    if (!meta) throw this.errors.process(ERRORS.SERVICES.ILLUMINATE.LENDER.META);

                    response = await contract.lend(
                        quote.principal,
                        market.underlying,
                        market.maturity,
                        (meta as SwivelQuoteMeta).fillPreview.orders.map(order => order.meta.previewFill),
                        [
                            (meta as SwivelQuoteMeta).fillPreview.orders.map(order => order.order),
                            (meta as SwivelQuoteMeta).fillPreview.orders.map(order => order.meta.signature),
                            (meta as SwivelQuoteMeta).poolAddress,
                            // NOTE: the min amount for Swivel has to be calculated from the `quote.pt.meta.iPTAmount`
                            min,
                            (meta as SwivelQuoteMeta).eFlag,
                        ],
                        lendETHParams,
                    );

                    break;

                case Principals.Yield:

                    if (!meta) throw this.errors.process(ERRORS.SERVICES.ILLUMINATE.LENDER.META);

                    response = await contract.lend(
                        quote.principal,
                        market.underlying,
                        market.maturity,
                        quote.amount,
                        [
                            (meta as YieldQuoteMeta).poolAddress,
                            min,
                        ],
                        lendETHParams,
                    );

                    break;

                case Principals.Pendle:

                    if (!meta) throw this.errors.process(ERRORS.SERVICES.ILLUMINATE.LENDER.META);

                    // we could access the settings service here to obtain the slippage, or we can infer it
                    // from the quote amount and the min value to minimize this service's dependencies
                    // we know that:
                    // min = amount * (1 - slippage)
                    // min = amount - amount * slippage
                    // min / amount = 1 - slippage
                    // slippage = 1 - min / amount
                    slippage = fixed(1).subUnsafe(fixed(min).divUnsafe(fixed(quote.amount))).toUnsafeFloat();

                    response = await contract.lend(
                        quote.principal,
                        market.underlying,
                        market.maturity,
                        quote.amount,
                        [
                            min,
                            (meta as PendleQuoteMeta).marketAddress,
                            buildApproxParams(quote.pt.amount, slippage),
                            lendETH
                                ? buildTokenInput(quote.pt.meta?.swapMinimum ?? '0', quote.pt.meta?.lst ?? constants.AddressZero)
                                : buildTokenInput(quote.amount, market.underlying),
                        ],
                        lendETHParams,
                    );

                    break;

                case Principals.Apwine:

                    if (!meta) throw this.errors.process(ERRORS.SERVICES.ILLUMINATE.LENDER.META);

                    // response = await contract.lend(
                    //     quote.principal,
                    //     market.underlying,
                    //     market.maturity,
                    //     quote.amount,
                    //     min,
                    //     deadline,
                    //     (meta as APWineQuoteMeta).ammAddress,
                    // );

                    // FIXME: add implementation when ready...
                    throw new Error('APWine lending is not yet supported.');

                    break;

                case Principals.Notional:

                    response = await contract.lend(
                        quote.principal,
                        market.underlying,
                        market.maturity,
                        quote.amount,
                        [],
                        lendETHParams,
                    );
                    break;

                case Principals.Exactly:

                    if (!meta) throw this.errors.process(ERRORS.SERVICES.ILLUMINATE.LENDER.META);

                    response = await contract.lend(
                        quote.principal,
                        market.underlying,
                        market.maturity,
                        quote.amount,
                        [
                            (meta as ExactlyQuoteMeta).exactlyMaturity,
                            min,
                        ],
                        lendETHParams,
                    );
                    break;

                // we do not support lending on Term, but we do support redeeming positions with Term PTs
                case Principals.Term:

                    throw new Error('Term lending is not supported.');
                    break;
            }

            this.logger.log('lend: ', response);

            return response;

        } catch (error) {

            const processedError = this.errors.process(error);

            // TODO: make this somehow more ergonomic...
            const detail = processedError.message;

            const override = detail
                ? this.errors.updateMessage(ERRORS.SERVICES.ILLUMINATE.LEND, [[/\.$/, `: ${ detail }`]])
                : ERRORS.SERVICES.ILLUMINATE.LEND;

            throw this.errors.process(error, override);
        }
    }
}
