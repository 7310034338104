import { markActiveRoute } from './mark-active';
import { Route, RouteMatch } from './router';

export const COOKIE_POLICY_ROUTE: Route = {
    id: 'cookie-policy',
    url: '/cookie-policy',
    title: 'Cookie Policy',
    pattern: /^\/cookie-policy\/?(?:\?[^#]*)?(?:#.*)?$/,
    enter: markActiveRoute,
};

export const PRIVACY_POLICY_ROUTE: Route = {
    id: 'privacy-policy',
    url: '/privacy-policy',
    title: 'Privacy Policy',
    pattern: /^\/privacy-policy\/?(?:\?[^#]*)?(?:#.*)?$/,
    enter: markActiveRoute,
};

export const POSITIONS_ROUTE: Route = {
    id: 'positions',
    url: '/positions',
    title: 'Illuminate Positions',
    pattern: /^\/positions\/?(\?[^#]*)?(?:#.*)?$/,
    enter: (match: RouteMatch) => {
        markActiveRoute(match);
        // TODO: we'll probably need to deal with the state machines here...
    },
};

export const POOL_ROUTE: Route = {
    id: 'pool',
    url: '/pool',
    title: 'Illuminate Pool',
    pattern: /^\/pool\/?(?:\?[^#]*)?(?:#.*)?$/,
    enter: (match: RouteMatch) => {
        markActiveRoute(match);
        // TODO: we'll probably need to deal with the state machines here...
    },
};

export const LEND_ROUTE: Route = {
    id: 'lend',
    url: '/lend',
    title: 'Illuminate Lend',
    pattern: /^\/lend\/?(?:\?[^#]*)?(?:#.*)?$/,
    enter: (match: RouteMatch) => {
        markActiveRoute(match);
        // TODO: we'll probably need to deal with the state machines here...
    },
};

export const HOME_ROUTE: Route = {
    id: 'home',
    url: '/',
    title: 'Illuminate',
    pattern: /^\/?(?:\?[^#]*)?(?:#.*)?$/,
    redirect: '/lend',
};

export const NOT_FOUND_ROUTE: Route = {
    id: '404',
    url: '/404',
    title: '404',
    pattern: /.*/,
    redirect: '/',
};

export const ROUTES = [
    COOKIE_POLICY_ROUTE,
    PRIVACY_POLICY_ROUTE,
    POSITIONS_ROUTE,
    POOL_ROUTE,
    LEND_ROUTE,
    HOME_ROUTE,
    NOT_FOUND_ROUTE,
];
