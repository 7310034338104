import { html, nothing, TemplateResult } from 'lit';
import { SECONDS_PER_MONTH } from '../../core/constants';
import { matured, maturityDate, maturityDays, MATURITY_DATE_FORMAT } from '../../core/markets';
import { serviceLocator } from '../../core/services';
import { TIME_SERVICE } from '../../core/services/time';
import { Token } from '../../types';
import { tokenSymbol } from './amounts';
import { progressBar } from './progress';

export const maturityBar = (market?: { maturity: string; created: string; }, token?: Token, format: 'short' | 'long' = 'short'): TemplateResult | typeof nothing => {

    if (!market) return nothing;

    const timeService = serviceLocator.get(TIME_SERVICE);
    const duration = Math.round((parseInt(market.maturity) - parseInt(market.created)) / SECONDS_PER_MONTH);
    const isMatured = matured(market);

    return html`
    <span class="maturity-bar">

        <span class="maturity-date">
        ${ format === 'short'
            ? maturityDate(market.maturity, MATURITY_DATE_FORMAT.MEDIUM)
            : html`${ duration } month ${ token ? html`${ tokenSymbol(token) } market` : 'market' } ${ isMatured ? 'matured' : 'maturing' } ${ maturityDate(market.maturity, MATURITY_DATE_FORMAT.LONG) }`
        }
        </span>

        ${ progressBar(timeService.time() / 1000, parseInt(market.created), parseInt(market.maturity)) }

        <span class="maturity-days">
        ${ isMatured
            ? format === 'short' ? nothing : 'matured'
            : html`${ maturityDays(market.maturity) } ${ format === 'short' ? 'd' : 'days remaining' }`
        }
        </span>
    </span>
    `;
};
