import '@swivel-finance/ui/elements/checkbox/checkbox';
import '@swivel-finance/ui/elements/collapsible/accordion';
import '@swivel-finance/ui/elements/collapsible/collapsible';
import '@swivel-finance/ui/elements/dialog/dialog';
import '@swivel-finance/ui/elements/icon/icon';
import '@swivel-finance/ui/elements/listbox/listbox';
import '@swivel-finance/ui/elements/listitem/listitem';
import '@swivel-finance/ui/elements/popup/popup';
import '@swivel-finance/ui/elements/select/select';
import '@swivel-finance/ui/elements/toggle/toggle';
import '@swivel-finance/ui/elements/tooltip/tooltip';
import '@swivel-finance/ui/elements/panel-container/panel-container';
import '@swivel-finance/ui/elements/tabs/tabs';
import '@swivel-finance/ui/elements/wizard/wizard';

import { html, LitElement, TemplateResult } from 'lit';
import { customElement } from 'lit/decorators.js';
import { ENV } from '../../core/env';
import { COOKIE_POLICY_ROUTE, HOME_ROUTE, LEND_ROUTE, POOL_ROUTE, POSITIONS_ROUTE, PRIVACY_POLICY_ROUTE, RouteMatch, router, RouterService, ROUTES } from '../../routes';
import { MARKET, orchestrator } from '../../state/orchestrator';
import { cookiePolicyTemplate, lendTemplate, poolTemplate, positionsTemplate, privacyPolicyTemplate } from './templates';

import '../../services/notification/notification';
import '../../services/notification/notification-outlet';
import '../account/account-connect';
import '../account/account-popup';
import '../account/account-balance';
import '../amount/currency-animation';
import '../amount/portfolio';
import '../deployment/deployment-select';
import '../lend/lend-page';
import '../lend/lend-token';
import '../lend/lend-market';
import '../lend/lend-transaction-preview';
import '../lend/lend-transaction-status';
import '../lend/lend-transaction-result';
import '../pool/pool-page';
import '../pool/pool-token';
import '../pool/pool-maturity';
import '../pool/add-liquidity-transaction-preview';
import '../pool/add-liquidity-transaction-status';
import '../pool/add-liquidity-transaction-result';
import '../pool/remove-liquidity-transaction-preview';
import '../pool/remove-liquidity-transaction-status';
import '../pool/remove-liquidity-transaction-result';
import '../positions/positions-page';
import '../positions/positions';
import '../positions/position-details-lend';
import '../positions/position-details-pool';
import '../redeem/redeem-transaction-preview';
import '../redeem/redeem-transaction-status';
import '../redeem/redeem-transaction-result';
import '../preview/preview-item';
import '../token-input/token-input';
import '../token/symbol';
import '../transaction-settings/transaction-settings';
import '../policies/cookie-policy';
import '../policies/privacy-policy';
import '../twitter/share-rate';
import '../quotes/rate-overview';

const template = function (this: AppElement) {

    let routeTemplate: (this: AppElement) => TemplateResult;

    switch (this.activeRoute?.route.id) {

        case COOKIE_POLICY_ROUTE.id:
            routeTemplate = cookiePolicyTemplate;
            break;
        case PRIVACY_POLICY_ROUTE.id:
            routeTemplate = privacyPolicyTemplate;
            break;
        case POSITIONS_ROUTE.id:
            routeTemplate = positionsTemplate;
            break;
        case POOL_ROUTE.id:
            routeTemplate = poolTemplate;
            break;
        default:
            routeTemplate = lendTemplate;
            break;
    }

    return html`
    <header>
        <nav>
            <h1><a href=${ HOME_ROUTE.url } data-route=${ HOME_ROUTE.id }><ui-icon name="illuminate"></ui-icon>illuminate</a></h1>
            <a href=${ LEND_ROUTE.url } data-route=${ LEND_ROUTE.id } data-label="Lend">Lend</a>
            <a href=${ POOL_ROUTE.url } data-route=${ POOL_ROUTE.id } data-label="Pool">Pool</a>
            <a href=${ POSITIONS_ROUTE.url } data-route=${ POSITIONS_ROUTE.id } data-label="Positions">Positions</a>
        </nav>
        <div class="account">
            <ill-account-popup></ill-account-popup>
        </div>
    </header>
    ${ routeTemplate.apply(this) }
    <ill-notification-outlet></ill-notification-outlet>
    <footer>
        <nav>
            <a aria-label="discord" aria-describedby="nav-discord" href="${ ENV.discordUrl }" target="_blank" rel="noopener"><ui-icon name="discord"></ui-icon></a>
            <!--
            <a aria-label="governance" aria-describedby="nav-governance" href="https://governance.illuminate.finance" target="_blank" rel="noopener"><ui-icon name="gavel"></ui-icon></a>
            -->
            <a aria-label="docs" aria-describedby="nav-docs" href="${ ENV.docsUrl }" target="_blank" rel="noopener"><ui-icon name="docs"></ui-icon></a>
            <a aria-label="code" aria-describedby="nav-code" href="${ ENV.codeUrl }" target="_blank" rel="noopener"><ui-icon name="code"></ui-icon></a>

            <ill-deployment-select></ill-deployment-select>
        </nav>
        <ui-tooltip id="nav-discord">
            <p>Visit us on discord</p>
        </ui-tooltip>
        <ui-tooltip id="nav-governance">
            <p>Governance</p>
        </ui-tooltip>
        <ui-tooltip id="nav-docs">
            <p>Read our docs</p>
        </ui-tooltip>
        <ui-tooltip id="nav-code">
            <p>See our code</p>
        </ui-tooltip>
    </footer>
    `;
};

@customElement('ill-app')
export class AppElement extends LitElement {

    protected orchestrator = orchestrator;

    protected router!: RouterService;

    protected routes = ROUTES;

    protected activeRoute?: RouteMatch;

    constructor () {

        super();

        this.handleRouteChange = this.handleRouteChange.bind(this);
        this.handleAccountTransition = this.handleAccountTransition.bind(this);
        this.handleMarketTransition = this.handleMarketTransition.bind(this);

        this.router = router({
            routes: this.routes,
        });
    }

    connectedCallback () {

        super.connectedCallback();

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.router.subscribe(this.handleRouteChange);
        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.orchestrator.account.onTransition(this.handleAccountTransition);
        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.orchestrator.market.onTransition(this.handleMarketTransition);

        this.router.start();

        this.orchestrator.start();
    }

    disconnectedCallback () {

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.router.unsubscribe(this.handleRouteChange);
        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.orchestrator.account.off(this.handleAccountTransition);
        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.orchestrator.market.off(this.handleMarketTransition);

        this.router.stop();

        this.orchestrator.stop();

        super.disconnectedCallback();
    }

    protected createRenderRoot (): Element | ShadowRoot {

        return this;
    }

    protected render (): unknown {

        return template.apply(this);
    }

    protected handleRouteChange (m: RouteMatch) {

        this.activeRoute = m;

        this.requestUpdate();
    }

    protected handleAccountTransition () {

        this.requestUpdate();
    }

    // TODO: why are we doing this?
    protected handleMarketTransition (state: typeof orchestrator.market.state, event: MARKET.Event) {

        if (event.type === MARKET.EVENTS.SET_TOKEN) {

            this.requestUpdate();
        }
    }
}
