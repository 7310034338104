import { TransactionResponse } from '@ethersproject/abstract-provider';
import { LendPosition, Market } from '../../types';
import { emptyOrZero } from '../amount';
import { ErrorLike, ERROR_SERVICE, ILLUMINATE_SERVICE, LOG_SERVICE, serviceLocator } from '../services';
import { DEFAULT_SETTINGS } from '../services/settings';
import { AddState, Transaction, TransactionTopic, TRANSACTION_STATUS } from '../services/transaction';
import { Connection } from '../services/wallet';
import { RedeemPreview, RedeemService } from './redeem-service';

export interface RedeemState {
    /**
     * The market for redeeming
     */
    market: Market;
    /**
     * The user's position for redeeming
     */
    position: LendPosition;
    /**
     * The selected amount for redeeming
     */
    amount: string;
    /**
     * The address of the market's illuminate principal token
     */
    iptAddress: string;
    /**
     * The address of the market's YieldSpace pool
     */
    poolAddress: string;
    /**
     * The amount returned by redeeming
     */
    amountReturned: string;
}

export const REDEEM_STATUS = {
    ...TRANSACTION_STATUS,
    APPROVING: 'APPROVING',
} as const;

export type RedeemStatus = typeof REDEEM_STATUS[keyof typeof REDEEM_STATUS];

export class RedeemTransaction extends Transaction<RedeemState, RedeemStatus, TransactionTopic> {

    static type = 'Redeem';

    protected redeemService = new RedeemService(
        serviceLocator.get(ILLUMINATE_SERVICE),
        serviceLocator.get(ERROR_SERVICE),
        serviceLocator.get(LOG_SERVICE),
    );

    isPending (): boolean {

        return super.isPending() || this.status === REDEEM_STATUS.APPROVING;
    }

    canUpdate (state?: Partial<RedeemState>): state is AddState<Partial<RedeemState>, 'market' | 'position' | 'amount'> {

        return !!(state ?? this.state).market
            && !!(state ?? this.state).amount
            && !emptyOrZero((state ?? this.state).amount);
    }

    canRedeem (state?: Partial<RedeemState>): state is RedeemState {

        return !!(state ?? this.state).market
            && !!(state ?? this.state).position
            && !!(state ?? this.state).amount
            && !emptyOrZero((state ?? this.state).amount)
            && !!(state ?? this.state).iptAddress
            && !!(state ?? this.state).poolAddress
            && !!(state ?? this.state).amountReturned;
    }

    setPosition (position: LendPosition | undefined): void {

        if (position === this.state.position) return;

        this._error = undefined;

        this.updateState({
            position,
            iptAddress: undefined,
            poolAddress: undefined,
            amountReturned: undefined,
        });

        this.updateStatus(REDEEM_STATUS.INITIAL);

        void this.update();
    }

    setAmount (amount: string | undefined): void {

        if (amount === this.state.amount) return;

        this._error = undefined;

        this.updateState({
            amount,
            amountReturned: undefined,
        });

        this.updateStatus(REDEEM_STATUS.INITIAL);

        void this.update();
    }

    async update (): Promise<void> {

        if (!this.canUpdate(this.state)) return;

        const { market, position, amount } = this.state;

        this.updateStatus(TRANSACTION_STATUS.UPDATING);

        try {

            const preview = await this.redeemService.fetchPreview(market, position, amount, this.connection);

            this.updateState(preview);

            this.updateStatus(TRANSACTION_STATUS.COMPLETE);

        } catch (error) {

            this._error = this.errorService?.process(error) ?? error as ErrorLike;

            this.updateStatus(TRANSACTION_STATUS.ERROR);
        }
    }

    protected async performTransaction (connection?: Connection): Promise<TransactionResponse> {

        // TODO: create proper error...
        if (!this.canRedeem(this.state)) throw new Error('Redeem transaction is not complete.');

        const { market, position, amount, amountReturned, iptAddress, poolAddress } = this.state;

        const min = this.redeemService.slippageToMin(
            this.settingsService?.get().transactions.lend.slippage ?? DEFAULT_SETTINGS.transactions.lend.slippage,
            amountReturned,
        );

        const preview: RedeemPreview = {
            iptAddress,
            poolAddress,
            amountReturned,
        };

        connection = connection ?? this.connection;

        const allowance = await this.redeemService.checkAllowance(position, preview, amount, connection);

        if (!allowance) {

            this.updateStatus(REDEEM_STATUS.APPROVING);

            const approval = await this.redeemService.approve(position, preview, connection);

            approval && await this.service?.confirm(approval);
        }

        this.updateStatus(REDEEM_STATUS.PENDING);

        return await this.redeemService.redeem(market, position, amount, min, connection);
    }
}
