import { ERRORS } from '../core/constants';
import { ENV } from '../core/env';
import { ErrorNotifier, isErrorLike, isEthereumError, isEthersError, isResponse, ProcessedError } from '../core/services/errors';
import { bugsnag } from './consent';

/**
 * A list of error names that we don't want to report to bugsnag.
 */
const IGNORED_ERRORS = [
    ERRORS.HTTP.UNAUTHORIZED,
    ERRORS.HTTP.FORBIDDEN,
    ERRORS.ETHEREUM.METAMASK.CHAIN,
    ERRORS.ETHEREUM.PROVIDER.REJECTED,
].map(error => error.name);

/**
 * An {@link ErrorNotifier} for bugsnag.
 */
export class BugsnagNotifier implements ErrorNotifier {

    notify (e: ProcessedError): void {

        if (!ENV.bugsnag.enabled || IGNORED_ERRORS.includes(e.name)) return;

        bugsnag.notify(e, event => {

            const source = e.source;
            const isError = isResponse(source)
                || isEthereumError(source)
                || isEthersError(source)
                || isErrorLike(source);

            // add the source error as metadata to the event (so we have the original error codes, etc.)
            event.addMetadata('source', isError ? source : { reason: source });
        });
    }
}
