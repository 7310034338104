import { TransactionResponse } from '@ethersproject/abstract-provider';
import { Market, Principals, Quote, SwivelQuoteMeta } from '../../types';
import { emptyOrZero } from '../amount';
import { ErrorLike, ERROR_SERVICE, ILLUMINATE_SERVICE, LOG_SERVICE, serviceLocator } from '../services';
import { DEFAULT_SETTINGS } from '../services/settings';
import { TIME_SERVICE } from '../services/time';
import { AddState, Transaction, TransactionTopic, TRANSACTION_STATUS } from '../services/transaction';
import { Connection } from '../services/wallet';
import { LendService } from './lend-service';

export interface LendState {
    /**
     * The selected market for lending
     */
    market: Market;
    /**
     * The cached apr for the selected market
     */
    apr: string;
    /**
     * The selected amount for lending (contracted)
     */
    amount: string;
    /**
     * The current best quote for the selected market and amount
     */
    quote: Quote;
    /**
     * All current quotes for the selected market and amount
     */
    quotes: Quote[];
}

export const LEND_STATUS = {
    ...TRANSACTION_STATUS,
    APPROVING: 'APPROVING',
} as const;

export type LendStatus = typeof LEND_STATUS[keyof typeof LEND_STATUS];

export class LendTransaction extends Transaction<LendState, LendStatus, TransactionTopic> {

    static type = 'Lend';

    protected lendService = new LendService(
        serviceLocator.get(ILLUMINATE_SERVICE),
        serviceLocator.get(TIME_SERVICE),
        serviceLocator.get(ERROR_SERVICE),
        serviceLocator.get(LOG_SERVICE),
    );

    isPending (): boolean {

        return super.isPending() || this.status === LEND_STATUS.APPROVING;
    }

    canUpdate (state?: Partial<LendState>): state is AddState<Partial<LendState>, 'market' | 'amount'> {

        return !!(state ?? this.state).market && !!(state ?? this.state).amount && !emptyOrZero((state ?? this.state).amount);
    }

    canLend (state?: Partial<LendState>): state is AddState<Partial<LendState>, 'market' | 'quote'> {

        return !!(state ?? this.state).market && !!(state ?? this.state).quote;
    }

    setAmount (amount: string | undefined): void {

        if (amount === this.state.amount) return;

        // reset the quotes and fee from any previous amount
        this.updateState({
            amount,
            quote: undefined,
            quotes: undefined,
        });

        this.updateStatus(LEND_STATUS.INITIAL);

        void this.update();
    }

    async update (): Promise<void> {

        if (!this.canUpdate(this.state)) return;

        const { market, amount } = this.state;

        this.updateStatus(TRANSACTION_STATUS.UPDATING);

        try {

            const preview = await this.lendService.fetchPreview(market, amount);

            this.updateState(preview);

            this.updateStatus(TRANSACTION_STATUS.COMPLETE);

        } catch (error) {

            this._error = this.errorService?.process(error) ?? error as ErrorLike;

            this.updateStatus(TRANSACTION_STATUS.ERROR);
        }
    }

    protected async performTransaction (connection?: Connection): Promise<TransactionResponse> {

        // TODO: create proper error...
        if (!this.canLend(this.state)) throw new Error('Lend transaction is not complete.');

        const { market, quote } = this.state;

        // for lending on Swivel, the min amount refers to the premium being pooled, if the e flag is true
        const amount = (quote.principal === Principals.Swivel)
            ? (quote.pt.meta as SwivelQuoteMeta).iPTAmount ?? '0'
            : quote.amount;

        const min = this.lendService.slippageToMin(
            this.settingsService?.get().transactions.lend.slippage ?? DEFAULT_SETTINGS.transactions.lend.slippage,
            amount,
        );

        const deadline = this.lendService.deadline(
            this.settingsService?.get().transactions.lend.deadline ?? DEFAULT_SETTINGS.transactions.lend.deadline,
        );

        connection = connection ?? this.connection;

        const allowance = await this.lendService.checkAllowance(market, quote, connection);

        if (!allowance) {

            this.updateStatus(LEND_STATUS.APPROVING);

            const approval = await this.lendService.approve(market, connection);

            await this.service?.confirm(approval);
        }

        this.updateStatus(LEND_STATUS.PENDING);

        return await this.lendService.lend(market, quote, min, deadline, connection);
    }
}
