import { LendPosition, LendPositionResponse, Market, Pool, PoolPosition, PoolPositionResponse } from '../../types';
import { ERRORS } from '../constants';
import { marketKey } from '../markets';
import { PoolInfo, poolsByAddress, POOL_SERVICE } from '../pools';
import { ERROR_SERVICE, LOG_SERVICE, serviceLocator } from '../services';
import { API_SERVICE } from '../services/api';
import { iPT, TOKEN_SERVICE } from '../services/token';
import { positionValue } from './helpers';

/**
 * Fetch a user's lend and pool positions across all markets
 *
 * @remarks
 * For pool positions we need to accumulate data from different sources:
 * - we get the actual bare position via api (this just tells us for which pool the user has a position and when it was initiated)
 * - we get the static pool data from the api (this has been fetched already and can be retrieved from the pool state machine;
 *   to prevent dependency cycles, we initialize the position machine with the market and pool data and pass markets and pools here)
 * - we get the dynamic pool information via the pool service (this data comes from the chain)
 *
 * @param address - the user's account/wallet address
 * @param markets - a map of markets keyed by market key
 * @param pools - a map of pools keyed by market key
 */
export const fetchPositions = async (
    address: string,
    markets: Map<string, Market>,
    pools: Map<string, Pool>,
): Promise<{ lending: LendPosition[]; pooling: PoolPosition[]; }> => {

    const api = serviceLocator.get(API_SERVICE);
    const logger = serviceLocator.get(LOG_SERVICE).group('fetchPositions');

    const result = await api.getPositions(address);

    const [lending, pooling] = await Promise.all([
        result.lending ? parseLendPositions(result.lending, markets, address) : [] as LendPosition[],
        result.pooling ? parsePoolPositions(result.pooling, pools) : [] as PoolPosition[],
    ]);

    logger.log('lending: ', lending);
    logger.log('pooling: ', pooling);

    return {
        lending,
        pooling,
    };
};

/**
 * Assembles lend positions from the api data and on-chain data
 */
const parseLendPositions = async (
    lendPositions: Record<string, Record<string, LendPositionResponse>>,
    markets: Map<string, Market>,
    account: string,
): Promise<LendPosition[]> => {

    const tokens = serviceLocator.get(TOKEN_SERVICE);
    const errors = serviceLocator.get(ERROR_SERVICE);
    const logger = serviceLocator.get(LOG_SERVICE).group('parseLendPositions()...');

    const parsedPositions = [] as LendPosition[];

    Object.keys(lendPositions).forEach(underlying => {

        Object.keys(lendPositions[underlying]).forEach(maturity => {

            const position = lendPositions[underlying][maturity];
            const market = markets.get(marketKey({ underlying, maturity }));

            if (!market) throw errors.process(ERRORS.STATE.POSITIONS.NO_MARKET);

            parsedPositions.push({
                ...position,
                currentPositionValue: '',
                iptBalance: '',
                underlying,
                maturity,
                market,
            });
        });
    });

    try {

        // fetch the dynamic position info via the marketplace and token service
        const positionInfo = await Promise.all(
            parsedPositions.map(async position => {

                // fetch the user's current iPT balance for the lend position
                // we can only redeem the user's iPT balance, NOT the calculated `totalReturn`
                const iptBalance = await tokens.fetchBalance(iPT(position.market).address, account);

                const currentPositionValue = await positionValue({ ...position, iptBalance });

                return {
                    iptBalance,
                    currentPositionValue,
                };
            }),
        );

        logger.log('position information: ', positionInfo);

        // update the positions' info
        parsedPositions.forEach((position, index) => {

            position.iptBalance = positionInfo[index].iptBalance;
            position.currentPositionValue = positionInfo[index].currentPositionValue;
        });

    } catch (error) {

        errors.process(error);
    }

    return parsedPositions;
};

/**
 * Assembles pool positions from the api data and on-chain data
 */
const parsePoolPositions = async (
    poolPositions: Record<string, Record<string, PoolPositionResponse>>,
    pools: Map<string, Pool>,
): Promise<PoolPosition[]> => {

    const poolService = serviceLocator.get(POOL_SERVICE);
    const errors = serviceLocator.get(ERROR_SERVICE);
    const logger = serviceLocator.get(LOG_SERVICE).group('parsePoolPositions()...');

    const parsedPositions = [] as PoolPosition[];
    pools = poolsByAddress(pools);

    Object.keys(poolPositions).forEach(underlying => {

        Object.keys(poolPositions[underlying]).forEach(maturity => {

            const position = poolPositions[underlying][maturity];
            const pool = pools.get(position.poolAddress);

            if (!pool) throw errors.process(ERRORS.STATE.POSITIONS.NO_POOL);

            const historicPools = position.pools.map(address => {

                const pool = pools.get(address);

                if (!pool) throw errors.process(ERRORS.STATE.POSITIONS.NO_POOL);

                return pool;

            }).reverse();

            parsedPositions.push({
                created: position.created,
                exited: position.exited,
                underlying,
                maturity,
                pool,
                pools: historicPools,
                currentPositionValue: '',
                info: {} as PoolInfo,
            });
        });
    });

    try {

        // fetch the dynamic position info via the pool service
        const positionInfo = await Promise.all(
            parsedPositions.map(async position => {

                const info = await poolService.fetchInfo(position.pool);

                const currentPositionValue = await positionValue({ ...position, info });

                return {
                    info,
                    currentPositionValue,
                };
            }),
        );

        logger.log('position information: ', positionInfo);

        // update the positions' info
        parsedPositions.forEach((position, index) => {

            position.info = positionInfo[index].info;
            position.currentPositionValue = positionInfo[index].currentPositionValue;
        });

    } catch (error) {

        errors.process(error);
    }

    return parsedPositions;
};
