import { ServiceIdentifier } from '../service-locator';
import { MessageService } from './interfaces';

export * from './constants';
export * from './interfaces';

/**
 * The service locator identifier for the MessageService
 */
export const MESSAGE_SERVICE = ServiceIdentifier.get<MessageService>('MessageService');
