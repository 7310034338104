import { ServiceIdentifier } from '../service-locator';
import { TransactionService } from './interfaces';

export * from './interfaces';
export * from './transaction';

/**
 * The service locator identifier for the TransactionService
 */
export const TRANSACTION_SERVICE = ServiceIdentifier.get<TransactionService>('TransactionService');
