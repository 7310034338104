import { defer, TypedObject } from '../../../shared/helpers';
import { ERRORS, SECONDS_PER_DAY } from '../../constants';
import { ErrorService } from '../errors';
import { LogService } from '../logger';
import { PreferencesService } from '../preferences';
import { SettingsService } from '../settings';
import { WalletService } from '../wallet';
import { TransactionConfirmer } from './confirmation';
import { SerializedTransaction, Subscriber, TransactionConstructor, TransactionFactory, TransactionMessage, TransactionOptions, TransactionService, TransactionServiceMessage, TransactionServiceTopic, TransactionStorage, TRANSACTION_SERVICE_TOPIC, TRANSACTION_STATUS, TRANSACTION_TOPIC } from './interfaces';
import { Transaction } from './transaction';

/**
 * The maximum age of transactions in the storage after which they will be purged
 *
 * @remarks
 * We store transactions for 3 days, to allow longer (low gas) pending transactions.
 * We store the time in milliseconds.
 */
const STORAGE_EXPIRY = SECONDS_PER_DAY * 3 * 1000;

/**
 * @remarks
 * This implementation of a TransactionService targets browser-based environments where a preferences
 * service is available via localStroage or similar.
 */
export class BrowserTransactionService implements TransactionService {

    protected registry = new Map<string, TransactionFactory<Transaction>>();

    protected transactions = new Map<number, Transaction>();

    protected subscribers = new Map<TransactionServiceTopic, Set<Subscriber<TransactionServiceMessage>>>();

    protected preferences?: PreferencesService;

    protected wallet?: WalletService;

    protected settings?: SettingsService;

    protected errors?: ErrorService;

    protected logger?: LogService;

    protected isSyncing = false;

    confirm: TransactionConfirmer;

    constructor (
        confirmer: TransactionConfirmer,
        preferences?: PreferencesService,
        wallet?: WalletService,
        settings?: SettingsService,
        errors?: ErrorService,
        logger?: LogService,
    ) {

        this.confirm = confirmer;
        this.preferences = preferences;
        this.wallet = wallet;
        this.settings = settings;
        this.errors = errors;
        this.logger = logger?.group('transaction-service');

        this.logger && (this.logger.disabled = true);

        this.handleStateChange = this.handleStateChange.bind(this);
        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.handleStorageChange = this.handleStorageChange.bind(this);
        this.handleConnectionChange = this.handleConnectionChange.bind(this);

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.preferences?.subscribe(this.handleStorageChange, 'transactions');

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.wallet?.listen('connect', this.handleConnectionChange);
        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.wallet?.listen('disconnect', this.handleConnectionChange);
    }

    register<T extends Transaction> (type: TransactionConstructor<T> | string, factory?: TransactionFactory<T>): void {

        const identifier = (typeof type === 'string')
            ? type
            : type.type;

        const creator = (typeof type === 'string')
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            ? factory!
            : (
                transaction?: TransactionOptions<T>,
                service?: TransactionService,
                settingsService?: SettingsService,
                errorService?: ErrorService,
            ) => new type(transaction, service, settingsService, errorService);

        this.registry.set(identifier, creator);
    }

    has<T extends Transaction> (type: TransactionConstructor<T> | string): boolean {

        const identifier = (typeof type === 'string') ? type : type.type;

        return this.registry.has(identifier);
    }

    create<T extends Transaction> (type: TransactionConstructor<T> | string, transaction?: TransactionOptions<T>): T {

        const identifier = (typeof type === 'string') ? type : type.type;

        const instance = this.createTransaction<T>(identifier, transaction);

        this.updateTransaction(instance);

        this.syncStorage();

        // dispatch the create event after returning the instance
        defer(() => this.notify(TRANSACTION_SERVICE_TOPIC.CREATED, instance));

        return instance;
    }

    get<T extends Transaction> (id: number): T | undefined {

        return this.transactions.get(id) as T;
    }

    getAll (): Transaction[] {

        // sort transactions by creation date descending (newest first)
        return [...this.transactions.values()].sort((a, b) => b.id - a.id);
    }

    subscribe<K extends TransactionServiceTopic> (topic: K, subscriber: Subscriber<TransactionServiceMessage<K>>): void {

        if (!this.subscribers.has(topic)) this.subscribers.set(topic, new Set());

        this.subscribers.get(topic)?.add(subscriber as Subscriber<TransactionServiceMessage>);
    }

    unsubscribe<K extends TransactionServiceTopic> (topic: K, subscriber: Subscriber<TransactionServiceMessage<K>>): void {

        this.subscribers.get(topic)?.delete(subscriber as Subscriber<TransactionServiceMessage>);
    }

    protected notify<K extends TransactionServiceTopic> (topic: K, detail: TransactionServiceMessage<K>['detail']): void {

        this.subscribers.get(topic)?.forEach(subscriber => {

            subscriber({
                topic,
                detail,
            } as TransactionServiceMessage);
        });
    }

    protected handleStatusChange (message: TransactionMessage<Transaction>): void {

        this.logger?.log('handleStatusChange()... ', message);

        const transaction = message.transaction;

        const topic = (transaction.status === TRANSACTION_STATUS.SUCCESS)
            ? TRANSACTION_SERVICE_TOPIC.SUCCESS
            : (transaction.status === TRANSACTION_STATUS.FAILURE)
                ? TRANSACTION_SERVICE_TOPIC.FAILURE
                : undefined;

        if (topic) {

            this.notify(topic, transaction);
        }

        this.updateTransaction(transaction);

        this.syncStorage();

        this.notify(TRANSACTION_SERVICE_TOPIC.LOADED, this.getAll());
    }

    protected handleStateChange (message: TransactionMessage<Transaction>): void {

        this.updateTransaction(message.transaction);

        this.syncStorage();

        this.notify(TRANSACTION_SERVICE_TOPIC.LOADED, this.getAll());
    }

    protected handleStorageChange (storage: TransactionStorage | null): void {

        this.logger?.log('handleStorageChange()... storage %o, syncing: %o', storage, this.isSyncing);

        // this storage change event (most likely) originated from the `syncStorage` method
        // we already have updated the storage and synced it with the memory, so we can
        // ignore this event (it's essentially a replay from storing the updated storage)
        if (this.isSyncing) {

            // mark the service as not syncing storage (we're done syncing when this event is caught)
            this.isSyncing = false;
            // and return early (we don't need to sync another time and events are dispatched)
            return;
        }

        this.syncStorage(storage ?? {});

        this.notify(TRANSACTION_SERVICE_TOPIC.LOADED, this.getAll());
    }

    /**
     * Handle changes to the wallet connection
     *
     * @remarks
     * Transactions belong to a specific wallet connection - we identify them by network and account.
     * If the connection changes, we need to update the transaction map.
     */
    protected handleConnectionChange (): void {

        this.logger?.log('handleConnectionChange()... ', this.wallet?.state);

        // unsubscribe all currently observed transactions
        this.transactions.forEach(transaction => this.unobserveTransaction(transaction));
        // and clear the transaction map
        this.transactions = new Map();

        this.syncStorage();

        this.notify(TRANSACTION_SERVICE_TOPIC.LOADED, this.getAll());
    }

    /**
     * Create a transaction instance for memory storage
     */
    protected createTransaction<T extends Transaction> (identifier: string, transaction?: TransactionOptions<T>): T {

        const factory = this.registry.get(identifier) as TransactionFactory<T> | undefined;

        if (!factory) {

            throw this.errors?.process({
                ...ERRORS.SERVICES.TRANSACTION.FACTORY,
                message: ERRORS.SERVICES.TRANSACTION.FACTORY.message.replace(/\.$/, `: ${ identifier }.`),
            });
        }

        return factory(transaction, this, this.settings, this.errors);
    }

    /**
     * Observe a transaction's changes
     */
    protected observeTransaction<T extends Transaction> (transaction: T): void {

        // eslint-disable-next-line @typescript-eslint/unbound-method
        transaction.subscribe(TRANSACTION_TOPIC.STATE, this.handleStateChange);
        // eslint-disable-next-line @typescript-eslint/unbound-method
        transaction.subscribe(TRANSACTION_TOPIC.STATUS, this.handleStatusChange);
    }

    /**
     * Unobserve a transaction's changes
     */
    protected unobserveTransaction<T extends Transaction> (transaction: T): void {

        // eslint-disable-next-line @typescript-eslint/unbound-method
        transaction.unsubscribe(TRANSACTION_TOPIC.STATE, this.handleStateChange);
        // eslint-disable-next-line @typescript-eslint/unbound-method
        transaction.unsubscribe(TRANSACTION_TOPIC.STATUS, this.handleStatusChange);
    }

    /**
     * Validate a transaction
     *
     * @remarks
     * Checks if a transaction is 'valid' as in 'should stay in storage'. Disposed and expired
     * transactions should be removed from in-memory storage and preferences storage, while
     * lost transactions should be removed from preferences storage (without a transaction hash
     * we can't resume or link out to these transactions). In memory transactions are valid without
     * a hash until they are finalized and removed during the sync process.
     * A transaction can be lost when reloading the page while the transaction is pending and has
     * not yet received a `TransactionResponse` with a hash.
     */
    protected validateTransaction<T extends Transaction> (transaction: T | SerializedTransaction<T>): boolean {

        const threshold = Date.now() - STORAGE_EXPIRY;

        const isDisposed = transaction.status === TRANSACTION_STATUS.DISPOSED;
        const isExpired = transaction.id < threshold;
        const isStored = !(transaction instanceof Transaction);
        const isFinal = transaction.status === TRANSACTION_STATUS.SUCCESS || transaction.status === TRANSACTION_STATUS.FAILURE;
        const isLost = (isStored || isFinal) && !transaction.hash;

        return !isDisposed && !isExpired && !isLost;
    }

    /**
     * Update a memory transaction
     *
     * @remarks
     * Ensures that a transaction is added or removed from memory based on its validity. Additionally,
     * the transaction will be observed or unobserved depending on its status.
     *
     * @returns `true` if the transaction was added or removed from memory, `false` otherwise
     */
    protected updateTransaction<T extends Transaction> (transaction: T): boolean {

        const isValid = this.validateTransaction(transaction);

        if (isValid) {

            if (!this.transactions.has(transaction.id)) {

                this.transactions.set(transaction.id, transaction);

                if (!transaction.isFinal()) {

                    this.observeTransaction(transaction);
                }

                return true;

            } else {

                if (transaction.isFinal()) {

                    this.unobserveTransaction(transaction);
                }
            }

        } else {

            if (this.transactions.has(transaction.id)) {

                this.unobserveTransaction(transaction);

                this.transactions.delete(transaction.id);

                return true;
            }
        }

        return false;
    }

    /**
     * Synchronizes the memory and preferences storage of transactions
     *
     * @remarks
     * Ensures that transactions in memory and in preferences storage are synced and up-to-date.
     * Purges invalid transactions both from memory and preferences storage and handles different
     * paths if preferences are disabled or when an update comes from a memory transaction vs from
     * the preferences storage (in which case the storage argument should be provided).
     */
    protected syncStorage (storage?: TransactionStorage): [boolean, TransactionStorage | undefined] {

        let hasChanged = false;

        if (this.preferences?.enabled) {

            [hasChanged, storage] = this.purgeStorage(storage ?? this.preferences.get('transactions') ?? {});

            hasChanged = this.purgeMemory() || hasChanged;

            if (storage) {

                hasChanged = this.syncStorageToMemory(storage) || hasChanged;
                hasChanged = this.syncMemoryToStorage(storage) || hasChanged;

            } else {

                hasChanged = this.syncMemoryToStorage(storage) || hasChanged;
                hasChanged = this.syncStorageToMemory(storage) || hasChanged;
            }

            if (hasChanged) {

                // mark the service as syncing storage, so we know where storage changes originate from
                this.isSyncing = true;

                this.preferences?.set('transactions', storage);
            }

        } else {

            hasChanged = this.purgeMemory();

            // with preferences disabled, we can sync an empty storage back to the memory
            hasChanged = this.syncStorageToMemory({}) || hasChanged;
        }

        return [hasChanged, storage];
    }

    /**
     * Sync transactions from memory to storage
     *
     * @remarks
     * This method is called by `syncStorage` and should not be invoked otherwise.
     * The implementation assumes, that memory and storage have been purged of
     * invalid transactions before this method is invoked.
     *
     * @returns `true` if a transaction from memory has been written to storage, `false` otherwise
     */
    protected syncMemoryToStorage (storage: TransactionStorage): boolean {

        let hasChanged = false;

        this.transactions.forEach(transaction => {

            // we only store transactions in storage if they have been submitted to the chain
            if (!transaction.isPending() && !transaction.isFinal()) return;

            // this should not happen, we log if it does
            if (!transaction.connection) {

                this.logger?.warn('syncMemoryToStorage()... unable to store transaction: missing connection data', transaction);

                return;
            }

            const { account, network } = transaction.connection;

            if (!storage[network.chainId]) storage[network.chainId] = {};

            if (!storage[network.chainId][account.address]) storage[network.chainId][account.address] = [];

            const transactions = storage[network.chainId][account.address];

            const index = transactions.findIndex(stored => stored.id === transaction.id) ?? -1;

            if (index < 0) {

                transactions?.push(transaction.toJSON());

                this.logger?.log('syncMemoryToStorage()... adding new transaction ', transaction);

            } else {

                transactions[index] = transaction.toJSON();

                this.logger?.log('syncMemoryToStorage()... updating existing transaction ', transaction);
            }

            hasChanged = true;
        });

        return hasChanged;
    }

    /**
     * Sync transactions from storage to memory
     *
     * @remarks
     * This method is called by `syncStorage` and should not be invoked otherwise.
     * The implementation assumes, that memory and storage have been purged of
     * invalid transactions before this method is invoked.
     *
     * @returns `true` if a transaction from storage has been added to memory, `false` otherwise
     */
    protected syncStorageToMemory (storage: TransactionStorage): boolean {

        let hasChanged = false;

        const connection = this.wallet?.state.connection;

        // if we're not connected, we don't have a network and account to read transactions from storage
        const transactions = connection
            ? storage[connection.network.chainId]?.[connection.account.address] ?? []
            : [];

        transactions.forEach(transaction => {

            if (!this.transactions.has(transaction.id)) {

                // transactions from the storage which are not present in memory are instantiated
                const instance = this.createTransaction(transaction.type, { ...transaction, connection });

                // and added to the memory
                this.updateTransaction(instance);

                hasChanged = true;

                this.logger?.log('syncStorageToMemory()... adding new transaction', instance);
            }

            // transactions which are already in memory are observed and will update automatically
            // there are only pending or final transactions in the storage
        });

        this.transactions.forEach(transaction => {

            const isFinal = transaction.isFinal();
            const isStored = transactions.some(stored => stored.id === transaction.id);

            // final transactions which are not in the storage are most likely lost or expired
            // and should be removed from memory
            if (isFinal && !isStored) {

                this.unobserveTransaction(transaction);

                this.transactions.delete(transaction.id);

                hasChanged = true;

                this.logger?.log('syncStorageToMemory()... removing transaction', transaction);
            }
        });

        return hasChanged;
    }

    /**
     * Removes invalid transactions from the in-memory transaction storage
     */
    protected purgeMemory (): boolean {

        let hasChanged = false;

        this.transactions.forEach(transaction => {

            const isValid = this.validateTransaction(transaction);

            if (!isValid) {

                this.unobserveTransaction(transaction);
                this.transactions.delete(transaction.id);
                hasChanged = true;
            }
        });

        this.logger?.log('purgeMemory()... ', hasChanged);

        return hasChanged;
    }

    /**
     * Removes invalid transactions from the preferences transaction storage
     *
     * @remarks
     * We purge the storage for all networks and accounts, not only the connected one,
     * to ensure that the storage stays clean even if an account and network hasn't been
     * connected for a longer time.
     */
    protected purgeStorage (storage: TransactionStorage): [boolean, TransactionStorage] {

        // an empty storage object which will be filled with valid transactions from the storage
        const purgedStorage = {} as TransactionStorage;

        let hasChanged = false;

        TypedObject.keys(storage).forEach(network => {

            const accounts = {} as Record<string, SerializedTransaction[]>;

            TypedObject.keys(storage[network]).forEach(account => {

                const transactions = storage[network][account].filter(transaction => this.validateTransaction(transaction));

                if (transactions.length) {

                    accounts[account] = transactions;
                }

                if (transactions.length !== storage[network][account].length) {

                    hasChanged = true;
                }
            });

            if (TypedObject.keys(accounts).length) {

                purgedStorage[network] = accounts;
            }
        });

        this.logger?.log('purgeStorage()... ', hasChanged, purgedStorage);

        return [hasChanged, purgedStorage];
    }
}
