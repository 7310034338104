import { html, nothing } from 'lit';
import { customElement } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { LEND_STATUS, maturityDate } from '../../core/markets';
import { errorMessage, loadingBar, tokenBalance, tokenImage } from '../../shared/templates';
import { LendTransactionBaseElement } from './lend-transaction-base';

const template = function (this: LendTransactionStatusElement) {

    if (!this.transaction) return;

    const isPending = this.transaction?.status === LEND_STATUS.PENDING || this.transaction?.status === LEND_STATUS.APPROVING;
    const isFetching = this.transaction.status === LEND_STATUS.UPDATING;
    const hasError = this.transaction?.status === LEND_STATUS.ERROR || this.transaction?.status === LEND_STATUS.FAILURE;

    if (!isFetching && !isPending && !hasError) return nothing;

    const { market, quote } = this.transaction.state;

    if (!market || !quote) return nothing;

    return html`
    <div class=${ classMap({ widget: true, fetching: isFetching, pending: isPending, error: hasError }) }>

        <div class="widget-header">
            <label>
                ${ tokenImage(market.token) }
                ${ maturityDate(market.maturity) }
            </label>
        </div>

        <div class="widget-main">

            ${ hasError
                ? errorMessage(this.transaction.error?.message ?? 'An unknown error occurred.', 'exclamation')
                : isFetching
                    ? html`
                    <div class="preview">
                        ${ loadingBar('Finding best fixed rate...') }
                    </div>
                    `
                    : html`
                    <div class="preview">
                        <div class="preview-item preview-item-big">
                            <span class="label">Lending</span>
                            ${ tokenBalance(quote.amount, market.token) }
                        </div>
                        ${ loadingBar('Transaction pending...') }
                    </div>
                    `
            }

        </div>
    </div>
    `;
};

@customElement('ill-lend-transaction-status')
export class LendTransactionStatusElement extends LendTransactionBaseElement {

    protected createRenderRoot (): Element | ShadowRoot {

        return this;
    }

    protected render (): unknown {

        return template.apply(this);
    }
}
