import { ValueChangeEvent } from '@swivel-finance/ui/elements/input';
import { ListBoxElement } from '@swivel-finance/ui/elements/listbox';
import { PanelDirection, PanelNavigationEvent } from '@swivel-finance/ui/elements/panel-container';
import { SelectElement } from '@swivel-finance/ui/elements/select/select';
import { dispatch } from '@swivel-finance/ui/utils/events';
import { html, LitElement, nothing } from 'lit';
import { customElement } from 'lit/decorators.js';
import { createRef, ref } from 'lit/directives/ref.js';
import { ERRORS, NOTIFICATIONS, TOOLTIPS } from '../../core/constants';
import { marketKey } from '../../core/markets';
import { isStrategyValid } from '../../core/pools';
import { notifications } from '../../services/notification';
import { balance, errorMessage, infoMessage, maturityBar, rate, status, tokenSymbol } from '../../shared/templates';
import { ACCOUNT, orchestrator, POOL } from '../../state/orchestrator';
import { Pool, Token } from '../../types';

const template = function (this: PoolMaturityElement) {

    const isConnected = this.accountMachine.state.matches(ACCOUNT.STATES.CONNECTED);
    const { balances } = this.accountMachine.state.context;

    const state = this.poolMachine.state;
    const context = state.context;

    const isFetching = state.matches(POOL.STATES.FETCHING);
    const isValid = state.matches(POOL.STATES.SELECT_POOL) || state.matches(POOL.STATES.COMPLETE) || isFetching;
    const hasError = state.matches(POOL.STATES.ERROR);

    const { tokens, selectedToken, poolsByToken, activePoolsByAPR, activeTokensByAPR } = context;

    const hasPools = activePoolsByAPR.length > 0;

    const token = selectedToken ? tokens.get(selectedToken) : undefined;

    return html`
    <div class="widget">

        <div class="widget-header">
            <button class="button-icon" @click=${ () => dispatch(this, new PanelNavigationEvent({ target: this, panel: PanelDirection.PREVIOUS })) }>
                <ui-icon name="back-circle"></ui-icon>
            </button>
            <label id="maturities-label" aria-describedby="pool-select-maturity-header-tooltip">Select a Maturity</label>
            <ui-tooltip id="pool-select-maturity-header-tooltip">
                ${ TOOLTIPS.POOL.SELECT_MATURITY.HEADER() }
            </ui-tooltip>
        </div>

        <div class="widget-inputs">
            <ui-select
                @ui-value-changed=${ (event: ValueChangeEvent<Token>) => this.handleTokenChange(event) }
                ${ ref(this.selectRef) }>
                <button class="ui-select-trigger" data-part="trigger">
                    <span class="ui-select-trigger-label">
                        <ill-token-symbol .name=${ token?.image } .ipt=${ true }></ill-token-symbol>
                        ${ token?.symbol }
                    </span>
                    <ui-icon class="ui-select-trigger-toggle" name="triangle"></ui-icon>
                </button>
                <ui-listbox class="tokens-listbox" data-part="overlay">
                    ${ tokens
                        ? activeTokensByAPR.map(token => html`
                        <ui-listitem .value=${ token } aria-selected="${ token.address === selectedToken }" aria-disabled="${ !poolsByToken.has(token.address) }">
                            <ill-token-symbol .name=${ token.image } .ipt=${ true }></ill-token-symbol>
                            ${ token.symbol }
                            <span class="account-balance ${ isConnected ? 'connected' : '' }">
                                ${ isConnected
                                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                    ? balances.has(token.address) && balance(balances.get(token.address)!.balance, token, 2) || status('loading')
                                    : html`Connect to see balances.`
                                }
                            </span>
                        </ui-listitem>
                        `)
                        : nothing
                    }
                </ui-listbox>
            </ui-select>
        </div>

        <div class="widget-main">

            ${ isValid && hasPools
                ? html`
                <ui-tooltip id="pool-rate-preview-tooltip">
                    ${ TOOLTIPS.POOL.RATE() }
                </ui-tooltip>

                <ui-tooltip id="pool-paused-tooltip">
                    <p>${ TOOLTIPS.PAUSED.POOL() }</p>
                    <p>${ TOOLTIPS.PAUSED.MORE_INFO() }</p>
                </ui-tooltip>

                <ui-listbox
                    class="neo-list market-list"
                    aria-labelledby="maturities-label"
                    @ui-value-changed=${ (event: ValueChangeEvent<Pool>) => this.handlePoolChange(event) }
                    ${ ref(this.listboxRef) }>
                        ${ activePoolsByAPR.map(pool => html`
                        <ui-listitem class="neo-item" .value=${ pool } aria-disabled="${ pool.paused || !isStrategyValid(pool) }">
                            <div class="neo-item-content">
                                <span class="token">
                                    <ill-token-symbol .name=${ pool.token.image } .ipt=${ true }></ill-token-symbol>
                                    ${ tokenSymbol(pool.token) }
                                </span>
                                ${ maturityBar(pool) }
                                <!-- NB: we need to manually set the tabindex to "-1" as the tooltip will add a
                                    tabindex of "0" to tooltip triggers by default (for accessibility)
                                    however, these tooltips are children of a focusable list item and, as such,
                                    they shouldn't be focusable -->
                                ${ pool.paused || !isStrategyValid(pool)
                                    ? html`
                                    <ui-icon name="pause" aria-describedby="pool-paused-tooltip" tabindex="-1"></ui-icon>
                                    `
                                    : html`
                                    <span class="rate" aria-describedby="pool-rate-preview-tooltip" tabindex="-1">
                                        ${ isFetching
                                            ? status('loading')
                                            : rate(pool.apr)
                                        }
                                    </span>
                                    `
                                }
                            </div>
                        </ui-listitem>
                        `) }
                </ui-listbox>
                `
                : isFetching
                    ? status('loading')
                    : hasError || !hasPools
                        ? html`
                        ${ hasError
                            ? errorMessage(state.context.error, 'exclamation')
                            : infoMessage(ERRORS.STATE.POOL.NO_ACTIVE_POOLS.message, 'info')
                        }
                        <button class="primary" @click=${ () => this.retry() }>Retry</button>
                        `
                        : nothing
            }

        </div>

    </div>
    `;
};

@customElement('ill-pool-maturity')
export class PoolMaturityElement extends LitElement {

    protected accountMachine = orchestrator.account;

    protected poolMachine = orchestrator.pool;

    protected selectRef = createRef<SelectElement>();

    protected listboxRef = createRef<ListBoxElement>();

    constructor () {

        super();

        this.handleAccountTransition = this.handleAccountTransition.bind(this);
        this.handlePoolTransition = this.handlePoolTransition.bind(this);
    }

    connectedCallback (): void {

        super.connectedCallback();

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.accountMachine.onTransition(this.handleAccountTransition);
        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.poolMachine.onTransition(this.handlePoolTransition);
    }

    disconnectedCallback (): void {

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.accountMachine.off(this.handleAccountTransition);
        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.poolMachine.off(this.handlePoolTransition);

        super.disconnectedCallback();
    }

    protected createRenderRoot (): Element | ShadowRoot {
        return this;
    }

    protected render (): unknown {

        return template.apply(this);
    }

    protected handleAccountTransition (): void {

        this.requestUpdate();
    }

    protected handlePoolTransition (state: typeof orchestrator.pool.state): void {

        // when entering the select pool state we want to reset the maturity listbox
        if (state.matches(POOL.STATES.SELECT_POOL)) {

            this.listboxRef.value?.reset();
        }

        this.requestUpdate();
    }

    protected handleTokenChange (event: ValueChangeEvent<Token>): void {

        const { current, change } = event.detail;

        if (!change) return;

        // we wait for the token select to close before sending the event the state machine
        this.selectRef.value?.addEventListener('ui-open-changed', () => {

            this.poolMachine.send(POOL.model.events[POOL.EVENTS.SET_TOKEN](current.address));
            this.listboxRef.value?.reset();

        }, { once: true });

        this.requestUpdate();
    }

    protected handlePoolChange (event: ValueChangeEvent<Pool>): void {

        const { current, change } = event.detail;

        if (!change) return;

        if (!this.accountMachine.state.matches(ACCOUNT.STATES.CONNECTED)) {

            notifications.show({
                type: 'info',
                content: NOTIFICATIONS.LEND.CONNECT,
            });

            this.listboxRef.value?.reset();

            return;
        }

        this.poolMachine.send(POOL.model.events[POOL.EVENTS.SET_POOL](marketKey(current)));
    }

    protected retry (): void {

        this.poolMachine.send(POOL.model.events[POOL.EVENTS.FETCH]());
    }
}
