import { TransactionResponse } from '@ethersproject/abstract-provider';
import { FixedNumber } from 'ethers';
import { Pool } from '../../types';
import { contractAmount, emptyOrZero, fixed, max, min, trim } from '../amount';
import { ERRORS } from '../constants';
import { compareMarkets } from '../markets';
import { ErrorLike, LOG_SERVICE, serviceLocator } from '../services';
import { DEFAULT_SETTINGS } from '../services/settings';
import { AddState, Transaction, TransactionTopic, TRANSACTION_STATUS } from '../services/transaction';
import { Connection } from '../services/wallet';
import * as PoolMath from './pool-math';
import { PoolInfo, POOL_SERVICE } from './pool-service';
import * as StrategyMath from './strategy-math';

export interface AddLiquidityPreview {
    baseIn: string;
    baseToSell: string;
    fyTokenIn: string;
    fyTokenToBuy: string;
    strategyTokensMinted: string;
    lpTokensMinted: string;
    baseBalance: string;
    sharesBalance: string;
    fyTokenBalance: string;
    realFYTokenBalance: string;
    totalSupply: string;
    totalLiquidity: string;
    ratio: string;
    share: string;
}

export interface AddLiquidityState {
    /**
     * The selected pool for pooling
     */
    pool: Pool;
    /**
     * The pool info associated with the pool
     */
    info: PoolInfo;
    /**
     * The selected underlying amount for pooling (contracted)
     */
    underlyingAmount: string;
    /**
     * The max amount of underlying that can be used for providing liquidity (depends on the useIPT flag)
     */
    underlyingMax: string;
    /**
     * The selected iPT amount for pooling (contracted)
     */
    iPTAmount: string;
    /**
     * The max amount of iPT that can be used for providing liquidity (depends on the useIPT flag)
     */
    iptMax: string;
    /**
     * Use iPTs from the user's iPT balance (instead of buying iPTs from a proportional amount of underlying)
     */
    useIPT: boolean;
    /**
     * A preview of providing liquidity with the current underlying and ipt amounts
     */
    preview: AddLiquidityPreview;
}

const RESET_STATE: Partial<AddLiquidityState> = {
    pool: undefined,
    info: undefined,
    underlyingAmount: undefined,
    underlyingMax: undefined,
    iPTAmount: undefined,
    iptMax: undefined,
    useIPT: undefined,
    preview: undefined,
};

const POOL_UPDATE_THRESHOLD = 60000;

export const ADD_LIQUIDITY_STATUS = {
    ...TRANSACTION_STATUS,
    APPROVING: 'APPROVING',
} as const;

export type AddLiquidityStatus = typeof ADD_LIQUIDITY_STATUS[keyof typeof ADD_LIQUIDITY_STATUS];

export class AddLiquidityTransaction extends Transaction<AddLiquidityState, AddLiquidityStatus, TransactionTopic> {

    static type = 'Add Liquidity';

    protected poolService = serviceLocator.get(POOL_SERVICE);

    protected logService = serviceLocator.get(LOG_SERVICE).group('add-liquidity-transaction');

    protected lastUpdate = 0;

    isPending (): boolean {

        return super.isPending() || this.status === ADD_LIQUIDITY_STATUS.APPROVING;
    }

    canUpdate (state?: Partial<AddLiquidityState>): state is AddState<Partial<AddLiquidityState>, 'pool'> {

        return !!(state ?? this.state).pool;
    }

    canPreview (state?: Partial<AddLiquidityState>): state is AddState<Partial<AddLiquidityState>, 'pool' | 'info'> {

        state = state ?? this.state;

        return this.canUpdate(state) && !!state.info;
    }

    canAddLiquidity (state?: Partial<AddLiquidityState>): state is AddLiquidityState {

        state = state ?? this.state;

        return this.canPreview(state) && !!state.preview;
    }

    setPool (pool: Pool | undefined): void {

        if (compareMarkets(pool, this.state.pool)) return;

        this.updateState({
            ...RESET_STATE,
            pool,
        });

        this.updateStatus(ADD_LIQUIDITY_STATUS.INITIAL);

        void this.update();
    }

    async setUnderlyingAmount (amount: string | undefined): Promise<void> {

        if (amount === this.state.underlyingAmount) return;

        // refresh the pool data when changing inputs, so we're always up-to-date
        await this.update();

        // if max amounts have been updated during the update, we want to limit the input amount
        if (amount && !emptyOrZero(amount) && this.state.underlyingMax) {

            amount = trim(min(amount, this.state.underlyingMax).toString());
        }

        const ipt = amount && !emptyOrZero(amount)
            ? this.getIPTFromUnderlying(amount)
            : undefined;

        this.updateState({
            underlyingAmount: !emptyOrZero(amount) ? amount : undefined,
            iPTAmount: ipt,
            preview: undefined,
        });

        if (!this.state.underlyingAmount) return;

        if (this.state.useIPT) {

            const preview = this.previewMint();

            this.updateState({
                underlyingAmount: preview.baseIn,
                iPTAmount: preview.fyTokenIn,
                preview,
            });

        } else {

            const preview = this.previewMintWithUnderlying();

            this.updateState({
                underlyingAmount: preview.baseIn,
                iPTAmount: preview.fyTokenToBuy,
                preview,
            });
        }
    }

    async setIPTAmount (amount: string | undefined): Promise<void> {

        if (amount === this.state.iPTAmount) return;

        // refresh the pool data when changing inputs, so we're always up-to-date
        await this.update();

        // if max amounts have been updated during the update, we want to limit the input amount
        if (amount && !emptyOrZero(amount) && this.state.iptMax) {

            amount = trim(min(amount, this.state.iptMax).toString());
        }

        const underlying = amount && !emptyOrZero(amount)
            ? this.getUnderlyingFromIPT(amount)
            : undefined;

        this.updateState({
            underlyingAmount: underlying,
            iPTAmount: !emptyOrZero(amount) ? amount : undefined,
            preview: undefined,
        });

        if (!this.state.iPTAmount) return;

        if (this.state.useIPT) {

            const preview = this.previewMint();

            this.updateState({
                underlyingAmount: preview.baseIn,
                iPTAmount: preview.fyTokenIn,
                preview,
            });

        } else {

            const preview = this.previewMintWithUnderlying();

            this.updateState({
                underlyingAmount: preview.baseIn,
                iPTAmount: preview.fyTokenToBuy,
                preview,
            });
        }
    }

    async setUseIPT (use: boolean): Promise<void> {

        if (use === this.state.useIPT) return;

        this.updateState({
            useIPT: use,
            preview: undefined,
        });

        // refresh the pool data when changing inputs, so we're always up-to-date
        await this.update();

        if (!this.state.underlyingAmount || !this.state.iPTAmount) return;

        if (this.state.useIPT) {

            const preview = this.previewMint();

            this.updateState({
                underlyingAmount: preview.baseIn,
                iPTAmount: preview.fyTokenIn,
                preview,
            });

        } else {

            const preview = this.previewMintWithUnderlying();

            this.updateState({
                underlyingAmount: preview.baseIn,
                iPTAmount: preview.fyTokenToBuy,
                preview,
            });
        }
    }

    async update (): Promise<void> {

        if (!this.canUpdate(this.state)) return;

        const { pool } = this.state;

        this.updateStatus(ADD_LIQUIDITY_STATUS.UPDATING);

        // update is triggered on every input change to ensure we have the freshest data
        // and the max amounts are adjusted accordingly
        // we don't want to fetch on every single input change however, so we introduce
        // threshold of 1 minute and only fetch if data is older than the threshold
        const now = Date.now();
        const fetch = (now - this.lastUpdate) >= POOL_UPDATE_THRESHOLD;

        this.lastUpdate = now;

        try {

            if (fetch) {

                const info = await this.poolService.fetchInfo(pool, this.connection);

                this.updateState({ info });
            }

            const [underlyingMax, iptMax] = this.getMaxAmounts();

            this.updateState({
                underlyingMax,
                iptMax,
            });

            this.updateStatus(ADD_LIQUIDITY_STATUS.COMPLETE);

        } catch (error) {

            this._error = this.errorService?.process(error) ?? error as ErrorLike;

            this.updateStatus(ADD_LIQUIDITY_STATUS.ERROR);
        }
    }

    protected async performTransaction (connection?: Connection): Promise<TransactionResponse> {

        if (!this.canAddLiquidity(this.state)) return this.throw(ERRORS.SERVICES.TRANSACTION.STATUS.INCOMPLETE);

        const { pool, preview } = this.state;

        const ratioSlippage = this.settingsService?.get().transactions.pool.ratioSlippage ?? DEFAULT_SETTINGS.transactions.pool.ratioSlippage;

        const [minRatio, maxRatio] = this.poolService.slippageToRatios(ratioSlippage, this.state.info.yieldSpaceInfo);

        connection = connection ?? this.connection;

        const allowance = await this.poolService.checkAllowance(pool, preview, connection);

        if (!allowance) {

            this.updateStatus(ADD_LIQUIDITY_STATUS.APPROVING);

            const approval = await this.poolService.approve(pool, preview, connection);

            await this.service?.confirm(approval);
        }

        this.updateStatus(ADD_LIQUIDITY_STATUS.PENDING);

        return await this.poolService.addLiquidity(pool, preview, minRatio, maxRatio, connection);
    }

    protected getMaxAmounts (): [string, string] {

        const state = this.state;

        if (!this.canPreview(state)) return this.throw(ERRORS.SERVICES.TRANSACTION.ADD_LIQUIDITY.PREVIEW.NO_UPDATE);

        const useIPT = !!state.useIPT;
        const underlyingBalance = state.info.underlyingBalance?.balance ?? '0';
        const iptBalance = state.info.iPTBalance?.balance ?? '0';
        const maxBaseIn = state.info.yieldSpaceInfo.maxBaseIn;
        const maxFYTokenIn = state.info.yieldSpaceInfo.maxFYTokenIn;
        const maxFYTokenOut = state.info.yieldSpaceInfo.maxFYTokenOut;
        const realFYTokenBalance = PoolMath.realFYTokenBalance(
            state.info.yieldSpaceInfo.fyTokenBalance,
            state.info.yieldSpaceInfo.totalSupply,
        );

        // get the initial max amounts for underlying and ipt based on user balances and pool settings
        let underlyingMax = useIPT
            ? trim(underlyingBalance.toString())
            : trim(underlyingBalance.toString());
        let iptMax = useIPT
            ? trim(iptBalance.toString())
            // we encountered a bug on goerli, where a pool would report a higher `maxFYTokenOut` than its available `realFYTokenBalance`
            // this will cause issues in `PoolMath.lpTokensMinted()`, as one cannot buy more fy tokens than the `realFYTokenBalance`
            // in fact, one cannot buy the entire `realFYTokenBalance` either, as that would create an infinite amount of LP tokens
            // that's why we decrease the `realFYTokenBalance` by 1 but make sure it doesn't become negative
            : trim(min(maxFYTokenOut, max(realFYTokenBalance.subUnsafe(fixed(1)), '0')).toString());

        this.logService.log(
            'getMaxAmounts()... \nunderlyingBalance: %s\niptBalance: %s\nmaxBaseIn: %s\nmaxFYTokenIn: %s\nmaxFYTokenOut: %s\nunderlyingMax: %s\niptMax: %s',
            contractAmount(underlyingBalance, state.pool.token),
            contractAmount(iptBalance, state.pool.token),
            contractAmount(maxBaseIn, state.pool.token),
            contractAmount(maxFYTokenIn, state.pool.token),
            contractAmount(maxFYTokenOut, state.pool.token),
            contractAmount(underlyingMax, state.pool.token),
            contractAmount(iptMax, state.pool.token),
        );

        if (useIPT) {

            // get the amount of base required to provide the max iPT amount
            const baseIn = this.getUnderlyingFromIPT(iptMax);

            // if the max underlying is smaller than the required base amount,
            // we limit the max ipt amount to what the max underlying can provide
            if (fixed(underlyingMax).subUnsafe(fixed(baseIn)).isNegative()) {

                iptMax = this.getIPTFromUnderlying(underlyingMax);

                this.logService.log('getMaxAmounts()... [useIPT, cap at underlying] ', underlyingMax, iptMax);

            } else {

                underlyingMax = baseIn;

                this.logService.log('getMaxAmounts()... [useIPT, cap at ipt] ', underlyingMax, iptMax);
            }

        } else {

            // get the amount of base required to provide the max iPT amount
            const baseIn = this.getUnderlyingFromIPT(iptMax);

            // if the max underlying is smaller than the required base amount,
            // we limit the max ipt amount to what the max underlying can provide
            if (fixed(underlyingMax).subUnsafe(fixed(baseIn)).isNegative()) {

                iptMax = this.getIPTFromUnderlying(underlyingMax);

                this.logService.log('getMaxAmounts()... [buyIPT, cap at underlying] ', underlyingMax, iptMax);

            } else {

                underlyingMax = baseIn;

                this.logService.log('getMaxAmounts()... [buyIPT, cap at ipt] ', underlyingMax, iptMax);
            }
        }

        return [underlyingMax, iptMax];
    }

    getIPTFromUnderlying (underlying: string | FixedNumber): string {

        const state = this.state;

        if (!this.canPreview(state)) return this.throw(ERRORS.SERVICES.TRANSACTION.ADD_LIQUIDITY.PREVIEW.NO_UPDATE);

        const useIPT = !!this.state.useIPT;

        let ipt: FixedNumber;

        if (useIPT) {

            const priceSlippage = fixed(
                this.settingsService?.get().transactions.pool.sharesPriceSlippage
                ?? DEFAULT_SETTINGS.transactions.pool.sharesPriceSlippage,
            );

            const sharesPrice = fixed(state.info.yieldSpaceInfo.sharesPrice)
                .mulUnsafe(fixed('1').addUnsafe(priceSlippage));

            const { fyTokenBalance, sharesBalance, totalSupply } = state.info.yieldSpaceInfo;

            ipt = PoolMath.fyTokenIn(underlying, fyTokenBalance, sharesBalance, totalSupply, sharesPrice);

        } else {

            const priceSlippage = fixed(
                this.settingsService?.get().transactions.pool.priceSlippage
                ?? DEFAULT_SETTINGS.transactions.pool.priceSlippage,
            );

            const yieldSpaceInfo = state.info.yieldSpaceInfo;
            const { fyTokenBalance, sharesBalance, totalSupply } = state.info.yieldSpaceInfo;

            ipt = PoolMath.fyTokenToBuy(underlying, fyTokenBalance, sharesBalance, totalSupply, priceSlippage, yieldSpaceInfo);
        }

        this.logService.log('getIPTFromUnderlying()... ', ipt.toString());

        return trim(ipt.floor().toString());
    }

    getUnderlyingFromIPT (ipt: string | FixedNumber): string {

        const state = this.state;

        if (!this.canPreview(state)) return this.throw(ERRORS.SERVICES.TRANSACTION.ADD_LIQUIDITY.PREVIEW.NO_UPDATE);

        const useIPT = !!this.state.useIPT;

        let underlying: FixedNumber;

        if (useIPT) {

            const priceSlippage = fixed(
                this.settingsService?.get().transactions.pool.sharesPriceSlippage
                ?? DEFAULT_SETTINGS.transactions.pool.sharesPriceSlippage,
            );

            const sharesPrice = fixed(state.info.yieldSpaceInfo.sharesPrice)
                .mulUnsafe(fixed('1').addUnsafe(priceSlippage));

            const { fyTokenBalance, sharesBalance, totalSupply } = state.info.yieldSpaceInfo;

            const fyTokenToBuy = undefined;
            const baseToSell = undefined;
            const lpTokensMinted = PoolMath.lpTokensMinted(fyTokenBalance, totalSupply, fyTokenToBuy, ipt);
            underlying = PoolMath.baseIn(sharesBalance, totalSupply, lpTokensMinted, sharesPrice, baseToSell);

        } else {

            const yieldSpaceInfo = state.info.yieldSpaceInfo;
            const { fyTokenBalance, sharesBalance, sharesPrice, totalSupply } = state.info.yieldSpaceInfo;

            const fyTokenToBuy = ipt;
            const baseToSell = PoolMath.buyFYTokenPreview(fyTokenToBuy, sharesBalance, fyTokenBalance, yieldSpaceInfo);
            const lpTokensMinted = PoolMath.lpTokensMinted(fyTokenBalance, totalSupply, fyTokenToBuy);
            underlying = PoolMath.baseIn(sharesBalance, totalSupply, lpTokensMinted, sharesPrice, baseToSell);
        }

        this.logService.log('getUnderlyingFromIPT()... ', underlying.toString());

        return trim(underlying.ceiling().toString());
    }

    protected previewMintWithUnderlying (): AddLiquidityPreview {

        const state = this.state;

        if (!this.canPreview(state)) return this.throw(ERRORS.SERVICES.TRANSACTION.ADD_LIQUIDITY.PREVIEW.NO_UPDATE);

        if (!state.underlyingAmount) return this.throw(ERRORS.SERVICES.TRANSACTION.ADD_LIQUIDITY.PREVIEW.NO_UNDERLYING);

        const priceSlippage = fixed(
            this.settingsService?.get().transactions.pool.priceSlippage
            ?? DEFAULT_SETTINGS.transactions.pool.priceSlippage,
        );

        // get all the data needed from the state
        const poolInfo = state.info;
        const yieldSpaceInfo = poolInfo.yieldSpaceInfo;
        const strategyInfo = poolInfo.strategyInfo;
        const baseIn = fixed(state.underlyingAmount);
        const fyTokenIn = fixed('0');
        const fyTokenBalance = fixed(yieldSpaceInfo.fyTokenBalance);
        const sharesBalance = fixed(yieldSpaceInfo.sharesBalance);
        const sharesPrice = fixed(yieldSpaceInfo.sharesPrice);
        const totalSupply = fixed(yieldSpaceInfo.totalSupply);
        const lpBalance = fixed(poolInfo.lpBalance.balance);

        // calculate how many fyToken we can buy, how much base we have to sell and how many lpTokens would be minted
        const fyTokenToBuy = PoolMath.fyTokenToBuy(baseIn, fyTokenBalance, sharesBalance, totalSupply, priceSlippage, yieldSpaceInfo).floor();
        const baseToSell = PoolMath.buyFYTokenPreview(fyTokenToBuy, sharesBalance, fyTokenBalance, yieldSpaceInfo).ceiling();
        const lpTokensMinted = PoolMath.lpTokensMinted(fyTokenBalance, totalSupply, fyTokenToBuy).floor();

        // we do a quick check here if the base needed is within the amount the user has provided
        const baseNeeded = PoolMath.baseIn(sharesBalance, totalSupply, lpTokensMinted, sharesPrice, baseToSell);

        if (baseIn.subUnsafe(baseNeeded).isNegative()) {

            // TODO: handle this error...
            this.logService.warn('previewMintWithUnderlying()... baseNeeded exceeds baseIn: ', baseNeeded.toString(), baseIn.toString());
        }

        this.logService.log([
            'previewMintWithUnderlying()...',
            `baseToSell:   ${ baseToSell.toString() }`,
            `fyTokenToBuy: ${ fyTokenToBuy.toString() }`,
            `baseNeeded:   ${ baseNeeded.toString() }`,
            `baseIn:       ${ baseIn.toString() }`,
        ].join('\n'));

        // calculate the state of the pool after the transaction to preview it
        const sharesBalanceAfter = sharesBalance.addUnsafe(PoolMath.baseToShares(baseIn, sharesPrice));
        const baseBalanceAfter = PoolMath.sharesToBase(sharesBalanceAfter, sharesPrice);
        const fyTokenBalanceAfter = fyTokenBalance.addUnsafe(fyTokenIn).addUnsafe(lpTokensMinted);
        const totalSupplyAfter = totalSupply.addUnsafe(lpTokensMinted);
        const realFYTokenBalanceAfter = PoolMath.realFYTokenBalance(fyTokenBalanceAfter, totalSupplyAfter);
        const totalLiquidityAfter = PoolMath.liquidity(baseBalanceAfter, sharesBalanceAfter, fyTokenBalanceAfter, totalSupplyAfter, yieldSpaceInfo);
        const ratioAfter = PoolMath.ratio(baseBalanceAfter, fyTokenBalanceAfter);
        const shareAfter = lpBalance.addUnsafe(lpTokensMinted).divUnsafe(totalSupplyAfter);

        // calculate the amount of strategy tokens minted from the lp tokens minted
        const strategyTokensMinted = StrategyMath.strategyTokensMinted(
            lpTokensMinted,
            strategyInfo.poolBalance,
            strategyInfo.totalSupply,
        ).floor();

        const preview: AddLiquidityPreview = {
            baseIn: trim(baseIn.toString()),
            baseToSell: trim(baseToSell.toString()),
            fyTokenIn: trim(fyTokenIn.toString()),
            fyTokenToBuy: trim(fyTokenToBuy.toString()),
            strategyTokensMinted: trim(strategyTokensMinted.toString()),
            lpTokensMinted: trim(lpTokensMinted.toString()),
            baseBalance: trim(baseBalanceAfter.floor().toString()),
            sharesBalance: trim(sharesBalanceAfter.floor().toString()),
            fyTokenBalance: trim(fyTokenBalanceAfter.floor().toString()),
            realFYTokenBalance: trim(realFYTokenBalanceAfter.floor().toString()),
            totalSupply: trim(totalSupplyAfter.floor().toString()),
            totalLiquidity: trim(totalLiquidityAfter.floor().toString()),
            ratio: ratioAfter.toString(),
            share: shareAfter.toString(),
        };

        this.logService.log('previewMintWithUnderlying()... ', preview);

        return preview;
    }

    protected previewMint (): AddLiquidityPreview {

        const state = this.state;

        if (!this.canPreview(state)) return this.throw(ERRORS.SERVICES.TRANSACTION.ADD_LIQUIDITY.PREVIEW.NO_UPDATE);

        if (!state.iPTAmount) return this.throw(ERRORS.SERVICES.TRANSACTION.ADD_LIQUIDITY.PREVIEW.NO_IPT);

        const priceSlippage = fixed(
            this.settingsService?.get().transactions.pool.sharesPriceSlippage
            ?? DEFAULT_SETTINGS.transactions.pool.sharesPriceSlippage,
        );

        // get all the data needed from the state
        const poolInfo = state.info;
        const yieldSpaceInfo = poolInfo.yieldSpaceInfo;
        const strategyInfo = poolInfo.strategyInfo;
        const fyTokenIn = fixed(state.iPTAmount);
        const fyTokenToBuy = fixed('0');
        const baseToSell = fixed('0');
        const fyTokenBalance = fixed(yieldSpaceInfo.fyTokenBalance);
        const sharesBalance = fixed(yieldSpaceInfo.sharesBalance);
        const sharesPrice = fixed(yieldSpaceInfo.sharesPrice);
        const totalSupply = fixed(yieldSpaceInfo.totalSupply);
        const lpBalance = fixed(poolInfo.lpBalance.balance);

        // calculate how much underlying we need for the provided fyTokens and how many lpTokens would be minted
        const lpTokensMinted = PoolMath.lpTokensMinted(fyTokenBalance, totalSupply, fyTokenToBuy, fyTokenIn).floor();
        const sharesIn = PoolMath.sharesIn(sharesBalance, totalSupply, lpTokensMinted);
        // apply the shares price slippage when previewing the baseIn amount
        const baseIn = PoolMath.sharesToBase(sharesIn, sharesPrice.mulUnsafe(fixed('1').addUnsafe(priceSlippage))).ceiling();

        // calculate the state of the pool after the transaction to preview it
        const sharesBalanceAfter = sharesBalance.addUnsafe(sharesIn);
        const baseBalanceAfter = PoolMath.sharesToBase(sharesBalanceAfter, sharesPrice);
        const fyTokenBalanceAfter = fyTokenBalance.addUnsafe(fyTokenIn).addUnsafe(lpTokensMinted);
        const totalSupplyAfter = totalSupply.addUnsafe(lpTokensMinted);
        const realFYTokenBalanceAfter = PoolMath.realFYTokenBalance(fyTokenBalanceAfter, totalSupplyAfter);
        const totalLiquidityAfter = PoolMath.liquidity(baseBalanceAfter, sharesBalanceAfter, fyTokenBalanceAfter, totalSupplyAfter, yieldSpaceInfo);
        const ratioAfter = PoolMath.ratio(baseBalanceAfter, fyTokenBalanceAfter);
        const shareAfter = lpBalance.addUnsafe(lpTokensMinted).divUnsafe(totalSupplyAfter);

        // calculate the amount of strategy tokens minted from the lp tokens minted
        const strategyTokensMinted = StrategyMath.strategyTokensMinted(
            lpTokensMinted,
            strategyInfo.poolBalance,
            strategyInfo.totalSupply,
        ).floor();


        const preview: AddLiquidityPreview = {
            baseIn: trim(baseIn.toString()),
            baseToSell: trim(baseToSell.toString()),
            fyTokenIn: trim(fyTokenIn.toString()),
            fyTokenToBuy: trim(fyTokenToBuy.toString()),
            strategyTokensMinted: trim(strategyTokensMinted.toString()),
            lpTokensMinted: trim(lpTokensMinted.toString()),
            baseBalance: trim(baseBalanceAfter.floor().toString()),
            sharesBalance: trim(sharesBalanceAfter.floor().toString()),
            fyTokenBalance: trim(fyTokenBalanceAfter.floor().toString()),
            realFYTokenBalance: trim(realFYTokenBalanceAfter.floor().toString()),
            totalSupply: trim(totalSupplyAfter.floor().toString()),
            totalLiquidity: trim(totalLiquidityAfter.floor().toString()),
            ratio: ratioAfter.toString(),
            share: shareAfter.toString(),
        };

        this.logService.warn('previewMint()... ', preview);

        return preview;
    }

    protected async handleSettingsChange (): Promise<void> {

        this.logService.log('handleSettingsChange()...');

        // update the pool data
        await this.update();

        // if we currently have a preview, we refresh it with the new settings
        if (this.state.preview) {

            const preview = this.state.useIPT
                ? this.previewMint()
                : this.previewMintWithUnderlying();

            const iPTAmount = this.state.useIPT
                ? preview.fyTokenIn
                : preview.fyTokenToBuy;

            this.updateState({
                underlyingAmount: preview.baseIn,
                iPTAmount,
                preview,
            });
        }
    }
}
