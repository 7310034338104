import { PanelDirection, PanelNavigationEvent } from '@swivel-finance/ui/elements/panel-container';
import { dispatch } from '@swivel-finance/ui/utils/events';
import { html, nothing } from 'lit';
import { customElement } from 'lit/decorators.js';
import { TOOLTIPS } from '../../core/constants';
import { LEND_STATUS, maturityDate, MATURITY_DATE_FORMAT } from '../../core/markets';
import { iPTPurchase, rateAfterFees, yieldAtMaturity } from '../../core/quotes';
import { iPT } from '../../core/services/token';
import { date, errorMessage, rate, tokenBalance, tokenImage, transactionLink } from '../../shared/templates';
import { LendTransactionBaseElement } from './lend-transaction-base';

const template = function (this: LendTransactionResultElement) {

    if (!this.transaction) return nothing;

    const isReady = this.transaction?.status === LEND_STATUS.SUCCESS;
    const isPending = this.transaction?.status === LEND_STATUS.PENDING;
    const hasError = this.transaction?.status === LEND_STATUS.ERROR || this.transaction?.status === LEND_STATUS.FAILURE;

    if (!isReady && !isPending && !hasError) return nothing;

    const { market, quote } = this.transaction.state;

    if (!market || !quote) return nothing;

    return html`
    <div class="widget">

        <div class="widget-header">
            <label>
                ${ tokenImage(market.token) }
                ${ maturityDate(market.maturity) }
            </label>
            <button class="button-icon" @click=${ () => dispatch(this, new PanelNavigationEvent({ target: this, panel: PanelDirection.FIRST })) }>
                <ui-icon name="cross-circle"></ui-icon>
            </button>
        </div>

        <div class="widget-main">

            ${ hasError
                ? errorMessage(this.transaction.error?.message ?? 'An unknown error occurred.', 'exclamation')
                : html`
                <div class="preview">

                    <div class="preview-headline">
                        Lent Successfully
                    </div>

                    <ill-preview-item
                        .label=${ 'Lent:' }
                        .current=${ tokenBalance(quote.amount, market.token) }
                        .loading=${ isPending }></ill-preview-item>

                    <ill-preview-item
                        .label=${ 'iPTs Purchased:' }
                        .current=${ tokenBalance(iPTPurchase(quote), iPT(market)) }
                        .tooltip=${ TOOLTIPS.LEND.IPT_PURCHASED(market?.token) }
                        .loading=${ isPending }></ill-preview-item>

                    <ill-preview-item
                        .label=${ 'Rate:' }
                        .current=${ rate(rateAfterFees(quote), '% APR', false) }
                        .tooltip=${ TOOLTIPS.LEND.RESULT.RATE() }
                        .loading=${ isPending }></ill-preview-item>

                    <ill-preview-item
                        .label=${ 'Yield at Maturity:' }
                        .current=${ tokenBalance(yieldAtMaturity(quote), market.token) }
                        .tooltip=${ TOOLTIPS.LEND.YIELD_AT_MATURITY() }
                        .loading=${ isPending }></ill-preview-item>

                    <ill-preview-item
                        class="preview-maturity"
                        .label=${ 'Matures:' }
                        .current=${ date(market.maturity, MATURITY_DATE_FORMAT.LONG) }
                        .tooltip=${ TOOLTIPS.LEND.MATURES(quote && iPTPurchase(quote), market?.token) }>
                    </ill-preview-item>

                    <div class="preview-item preview-transaction">
                        ${ transactionLink(this.transaction.response?.hash) }
                    </div>

                    <div class="preview-item share-rate">
                        <ill-share-rate .rate=${ rateAfterFees(quote) } .underlying=${ market.token.symbol } .type=${ 'lend' }></ill-share-rate>
                    </div>

                </div>
                `
            }

        </div>
    </div>
    `;
};

@customElement('ill-lend-transaction-result')
export class LendTransactionResultElement extends LendTransactionBaseElement {

    protected createRenderRoot (): Element | ShadowRoot {

        return this;
    }

    protected render (): unknown {

        return template.apply(this);
    }
}
