import JazzIcon from '@metamask/jazzicon';
import { OpenChangeEvent } from '@swivel-finance/ui/behaviors/overlay';
import { CollapsibleElement } from '@swivel-finance/ui/elements/collapsible/collapsible';
import { ValueChangeEvent } from '@swivel-finance/ui/elements/input';
import { PopupConfig, PopupElement, POPUP_CONFIG_DEFAULT } from '@swivel-finance/ui/elements/popup';
import { isEmpty } from '@swivel-finance/ui/utils';
import { BigNumber, providers } from 'ethers';
import { html, LitElement, nothing, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { createRef, ref } from 'lit/directives/ref.js';
import { contractAmount, fixed } from '../../core/amount';
import { fetchTokenPrices } from '../../core/balance';
import { ERRORS, ETH, TOKENS, TOOLTIPS, isETHToken } from '../../core/constants';
import { ENV } from '../../core/env';
import { REDEEMED_THRESHOLD } from '../../core/positions';
import { ErrorLike, LOG_SERVICE, PREFERENCES_SERVICE, serviceLocator } from '../../core/services';
import { isIPT, TOKEN_SERVICE } from '../../core/services/token';
import { Transaction, TransactionMessage, TransactionServiceMessage, TRANSACTION_SERVICE, TRANSACTION_SERVICE_TOPIC, TRANSACTION_TOPIC } from '../../core/services/transaction';
import { EIP6963ProviderDetail, WALLET_SERVICE, getEthereumProvider, getEthereumProviderIdentifier, getEthereumProviders } from '../../core/services/wallet';
import { createNotification, notifications } from '../../services/notification';
import { balance, errorMessage, status, tokenBalance, tokenImage, tokenSymbol } from '../../shared/templates';
import { ACCOUNT, orchestrator } from '../../state/orchestrator';
import { Balance, Token } from '../../types';
import { ToggleRequestEvent } from './events';
import { copyAddress, displayAddress } from './helpers';

import './account-transactions';

type AccountState = typeof orchestrator.account.state;

const ACCOUNT_POPUP_CONFIG: PopupConfig = {
    ...POPUP_CONFIG_DEFAULT,
    // NOTE: we leave this here for development/debugging purposes...
    // use the `overlay` setting to keep the popup open when loosing focus
    // overlay: {
    //     ...POPUP_CONFIG_DEFAULT.overlay,
    //     closeOnFocusLoss: false,
    // },
    focus: {
        ...POPUP_CONFIG_DEFAULT.focus,
        trapFocus: true,
        wrapFocus: true,
        initialFocus: 'ui-collapsible [data-part=trigger]',
    },
    position: {
        ...POPUP_CONFIG_DEFAULT.position,
        alignment: {
            origin: {
                horizontal: 'end',
                vertical: 'start',
            },
            target: {
                horizontal: 'end',
                vertical: 'start',
            },
        },
    },
};

const IDENTICON_SIZE = 96;

// our ETH markets have an underlying token that's an ETH derivative, like WETH (currently only WETH)
// we show these simply as ETH in the UI and lending on ETH markets is done with ETH as well,
// so we don't want these tokens to show up in the balances list
const ETH_LIKE_TOKENS = Object.values(TOKENS).filter(token => isETHToken(token));

const iPTDetails = (token: Token) => {

    const parts = token.name.split(' ');
    const underlying = parts[parts.length - 2];
    const maturity = parts[parts.length - 1];

    return html`${ underlying } ${ maturity }`;
};

const faucetTemplate = function (this: AccountPopupElement, balance: Balance): TemplateResult | typeof nothing {

    const { state } = this.accountMachine;

    const isFetching = state.matches(ACCOUNT.STATES.FETCHING);

    return this.faucetingEnabled && !isFetching
        ? this.isFauceting(balance.address)
            ? status('loading')
            : html`
            <button class="faucet"
                .disabled=${ !this.canFaucet(balance.address) }
                @click=${ () => this.handleFaucet(balance.address) }>
                Faucet
            </button>
            `
        : nothing;
};

const template = function (this: AccountPopupElement): TemplateResult {

    const { state } = this.accountMachine;

    const ethBalance = state.context.balances.get(ETH.address);
    const isFetching = state.matches(ACCOUNT.STATES.FETCHING);
    const pending = this.pendingTransactions();

    return state.matches(ACCOUNT.STATES.CONNECTED) || state.matches(ACCOUNT.STATES.FETCHING)
        ? html`
        <ui-popup class="${ pending.length > 0 ? 'pending' : '' }" .config=${ ACCOUNT_POPUP_CONFIG } ${ ref(this.popupRef) }>
            <button class="account-popup-trigger" data-part="trigger" ${ ref(this.triggerRef) }>
                ${ pending.length > 0
                    ? html`
                    <span class="account-pending">
                        ${ status('loading') } ${ pending.length } Pending
                    </span>
                    `
                    : html`
                    <span class="account-hash">
                        ${ state.context.ens || state.context.account }
                    </span>
                    `
                }
            </button>
            <button class="button-icon account-popup-toggle" @click=${ () => this.handleToggle() }>
                <ui-icon class="account-icon-closed" name="ethereum"></ui-icon>
                <ui-icon class="account-icon-opened" name="times"></ui-icon>
            </button>
            <div class="account-popup-overlay" data-part="overlay" @ui-open-changed=${ (event: OpenChangeEvent) => this.handleOpenChange(event) } ${ ref(this.overlayRef) }>
                <div class="account-overview">
                    <div class="account-image" ${ ref(this.imageRef) }>
                    </div>
                    <div class="account-details">
                        <div class="account-balance">
                            ${ tokenImage(ETH) }
                            ${ isFetching
                                ? status('loading')
                                : ethBalance && !isEmpty(ethBalance.balance)
                                    ? tokenBalance(ethBalance.balance, ETH)
                                    : html`<span class="amount">- -</span>`
                            }
                        </div>
                        <span class="account-hash">
                            ${ state.context.ens || displayAddress(state.context.account) }
                            <button class="button-icon" aria-labelledby="copy-address-tooltip" @click=${ () => this.copyAddress() }>
                                <ui-icon name="copy"></ui-icon>
                            </button>
                        </span>
                    </div>
                </div>
                <div class="account-settings">
                    <ui-accordion>
                        <ui-collapsible class="account-balances">
                            <h3 data-part="header">
                                <button data-part="trigger">Balances <ui-icon name="chevron"></ui-icon></button>
                            </h3>
                            <div data-part="region">
                                <ul>
                                    <!-- TODO: refactor balances into separate element (like transactions) -->
                                    <!-- TODO: we might want to replace each item with an 'ill-account-balance' element -->
                                    <!-- ETH balance goes first -->
                                    <li>
                                        ${ tokenImage(ETH) }
                                        ${ tokenSymbol(ETH) }
                                        ${ isFetching
                                            ? status('loading')
                                            : ethBalance && !isEmpty(ethBalance.balance)
                                                ? balance(ethBalance.balance, ETH)
                                                : html`<span class="amount">- -</span>`
                                        }
                                    </li>
                                    ${ state.context.error
                                        ? errorMessage(ERRORS.STATE.ACCOUNT.BALANCES.message, 'exclamation')
                                        : [...state.context.balances.values()]
                                            // exclude eth-like tokens - they are displayed as ETH
                                            .filter(b => !ETH_LIKE_TOKENS.some(t => t.address === b.address))
                                            .map(
                                                // exclude ETH balance - we already rendered it
                                                b => (b.address !== ETH.address)
                                                    ? html`
                                                    <li>
                                                        ${ tokenImage(b) }
                                                        ${ tokenSymbol(b) }
                                                        ${ isIPT(b) ? iPTDetails(b) : nothing }
                                                        ${ !isIPT(b)
                                                            ? faucetTemplate.call(this, b)
                                                            : nothing
                                                        }
                                                        ${ isFetching
                                                            ? status('loading')
                                                            : !isEmpty(b.balance)
                                                                ? balance(b.balance, b)
                                                                : nothing
                                                        }
                                                    </li>
                                                    `
                                                    : nothing,
                                            )
                                    }
                                </ul>
                            </div>
                        </ui-collapsible>

                        <ui-collapsible class="account-transactions">
                            <h3 data-part="header">
                                <button data-part="trigger">Transactions <ui-icon name="chevron"></ui-icon></button>
                            </h3>
                            <div data-part="region">
                                <ill-account-transactions></ill-account-transactions>
                            </div>
                        </ui-collapsible>
                    </ui-accordion>
                    <button class="account-disconnect" @click=${ () => this.disconnect() }>
                        Disconnect Wallet <ui-icon name="cloud-off"></ui-icon>
                    </button>
                </div>
            </div>
        </ui-popup>
        <ui-tooltip id="copy-address-tooltip">${ TOOLTIPS.ACCOUNT.COPY_ADDRESS }</ui-tooltip>
        <ui-tooltip id="disconnect-wallet-tooltip">${ TOOLTIPS.ACCOUNT.DISCONNECT }</ui-tooltip>
        `
        : state.matches(ACCOUNT.STATES.CONNECTING)
            ? status('loading')
            : this.providers.length <= 1
                ? html`
                <button class="ill-account-popup-connect primary" ?disabled=${ this.disabled } @click=${ () => this.connect() }>
                    Connect Wallet
                </button>
                `
                : html`
                <ui-select
                    class="account-popup-wallet-select"
                    .placeholder=${ 'Connect Wallet' }
                    @ui-value-changed=${ (event: ValueChangeEvent<EIP6963ProviderDetail>) => this.handleSelectProvider(event) }>
                    <button type="button" data-part="trigger" class="ui-select-trigger primary">
                        <span class="ui-select-trigger-label">
                            Connect Wallet
                        </span>
                        <ui-icon class="ui-select-trigger-toggle" name="triangle"></ui-icon>
                    </button>
                    <ui-listbox data-part="overlay" class="account-popup-wallets">
                    ${
                        this.providers.map(provider => html`
                        <ui-listitem class="account-popup-wallet" .value=${ provider }>
                            <img class="icon" src="${ provider.info.icon }" alt="${ provider.info.name }" />
                            <span class="name">${ provider.info.name }</span>
                        </ui-listitem>
                        `)
                    }
                    </ui-listbox>
                </ui-select>
                `;
};

@customElement('ill-account-popup')
export class AccountPopupElement extends LitElement {

    protected accountMachine = orchestrator.account;

    protected logService = serviceLocator.get(LOG_SERVICE).group('account-popup');

    protected tokenService = serviceLocator.get(TOKEN_SERVICE);

    protected transactionService = serviceLocator.get(TRANSACTION_SERVICE);

    protected preferencesService = serviceLocator.get(PREFERENCES_SERVICE);

    protected walletService = serviceLocator.get(WALLET_SERVICE);

    protected providers: EIP6963ProviderDetail[] = [];

    protected selectedProvider?: EIP6963ProviderDetail;

    protected imageRef = createRef<HTMLElement>();

    protected popupRef = createRef<PopupElement>();

    protected triggerRef = createRef<HTMLButtonElement>();

    protected overlayRef = createRef<HTMLDivElement>();

    protected transactions = [] as Transaction[];

    protected notification?: string;

    protected previousVersionNotification?: string;

    protected fauceting = new Map<string, Promise<unknown>>();

    protected faucetingAllowed = new Map<string, boolean>();

    // only enable fauceting on environments which have a faucetAddress
    protected faucetingEnabled = !!ENV.faucetAddress;

    protected identiconSeed?: number;

    protected identicon?: HTMLElement;

    @property({
        attribute: true,
        reflect: true,
        type: Boolean,
    })
    disabled = false;

    constructor () {

        super();

        this.handleTransition = this.handleTransition.bind(this);
        this.handleTransactionsChange = this.handleTransactionsChange.bind(this);
        this.handleTransactionChange = this.handleTransactionChange.bind(this);
        this.handleToggleRequest = this.handleToggleRequest.bind(this);
    }

    connect () {

        if (this.accountMachine.state.matches(ACCOUNT.STATES.CONNECTING)) return;

        this.accountMachine.send(ACCOUNT.model.events[ACCOUNT.EVENTS.CONNECT]({
            walletIdentifier: getEthereumProviderIdentifier(this.selectedProvider),
        }));
    }

    disconnect () {

        if (!this.accountMachine.state.matches(ACCOUNT.STATES.CONNECTED)) return;

        void this.popupRef.value?.hide()
            .finally(() => this.accountMachine.send(ACCOUNT.model.events[ACCOUNT.EVENTS.DISCONNECT]()));
    }

    copyAddress () {

        if (!this.accountMachine.state.matches(ACCOUNT.STATES.CONNECTED)) return;

        void copyAddress();
    }

    connectedCallback (): void {

        super.connectedCallback();

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.accountMachine.onTransition(this.handleTransition);
        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.transactionService.subscribe(TRANSACTION_SERVICE_TOPIC.LOADED, this.handleTransactionsChange);
        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.transactionService.subscribe(TRANSACTION_SERVICE_TOPIC.SUCCESS, this.handleTransactionsChange);
        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.transactionService.subscribe(TRANSACTION_SERVICE_TOPIC.FAILURE, this.handleTransactionsChange);
        // eslint-disable-next-line @typescript-eslint/unbound-method
        document.body.addEventListener('ill-toggle-request', this.handleToggleRequest);

        void this.updateProviders();
    }

    disconnectedCallback (): void {

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.accountMachine.off(this.handleTransition);
        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.transactionService.unsubscribe(TRANSACTION_SERVICE_TOPIC.LOADED, this.handleTransactionsChange);
        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.transactionService.unsubscribe(TRANSACTION_SERVICE_TOPIC.SUCCESS, this.handleTransactionsChange);
        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.transactionService.unsubscribe(TRANSACTION_SERVICE_TOPIC.FAILURE, this.handleTransactionsChange);
        // eslint-disable-next-line @typescript-eslint/unbound-method
        document.body.removeEventListener('ill-toggle-request', this.handleToggleRequest);

        super.disconnectedCallback();
    }

    protected pendingTransactions (): Transaction[] {

        return this.transactions.filter(transaction => transaction.isPending());
    }

    protected isFauceting (address: string): boolean {

        return this.fauceting.has(address);
    }

    protected canFaucet (address: string): boolean {

        return this.faucetingAllowed.get(address) === true;
    }

    protected updated (): void {

        if (this.accountMachine.state.matches(ACCOUNT.STATES.CONNECTED)) {

            if (this.identicon && this.imageRef.value && this.identicon.parentElement !== this.imageRef.value) {

                this.imageRef.value.appendChild(this.identicon);
            }
        }
    }

    protected createRenderRoot (): Element | ShadowRoot {

        return this;
    }

    protected render (): unknown {

        return template.apply(this);
    }

    protected async updateProviders (): Promise<void> {

        // get the available browser wallet providers
        this.providers = await getEthereumProviders();

        // if we have don't have multiple providers, use the one installed
        if (this.providers.length <= 1) {

            this.selectedProvider = this.providers[0];

        } else {

            // get the last connected browser wallet provider
            const identifier = this.selectedProvider
                ? getEthereumProviderIdentifier(this.selectedProvider)
                : this.preferencesService.get('walletIdentifier') ?? undefined;

            // check if the last connected browser wallet provider is still available
            this.selectedProvider = getEthereumProvider(this.providers, identifier);
        }

        this.requestUpdate();
    }

    protected handleSelectProvider (event: ValueChangeEvent<EIP6963ProviderDetail>): void {

        this.selectedProvider = event.detail.current;

        this.connect();

        this.requestUpdate();
    }

    protected handleTransition (state: AccountState, event: ACCOUNT.Event): void {

        this.handleAccountError(state, event);

        void this.handleAccountChange(state);

        if (event.type as unknown === ACCOUNT.EVENTS.FETCH_SUCCESS
            || event.type as unknown === ACCOUNT.EVENTS.FETCH_FAILURE) {

            void this.handleBalanceChange();

            void this.checkPreviousVersionBalances();
        }

        this.requestUpdate();
    }

    protected handleAccountError (state: AccountState, event: ACCOUNT.Event): void {

        let message: string | undefined = undefined;

        if (state.matches(ACCOUNT.STATES.ERROR)) {

            message = ERRORS.STATE.ACCOUNT.CONNECT.message.replace(/\.$/, `: ${ state.context.error }`);

        } else if (event.type as unknown === ACCOUNT.EVENTS.FETCH_FAILURE) {

            message = ERRORS.STATE.ACCOUNT.BALANCES.message.replace(/\.$/, `: ${ state.context.error ?? '.' }`);
        }

        if (message) {

            if (!this.notification) {

                this.notification = notifications.show({
                    type: 'failure',
                    content: message,
                });

            } else {

                notifications.update(this.notification, {
                    type: 'failure',
                    content: message,
                });
            }

        } else if (this.notification) {

            notifications.dismiss(this.notification);

            this.notification = undefined;
        }
    }

    protected async handleAccountChange (state: AccountState): Promise<void> {

        if (state.matches(ACCOUNT.STATES.CONNECTED)) {

            const { account, ens, provider } = state.context;

            let avatar: string | undefined;

            if (ens) {

                avatar = await (provider as providers.BaseProvider).getAvatar?.(ens) ?? undefined;
            }

            // we take the first 32 bit of the address and convert it to an int (seed)
            // in hex, each character is 4 bit: 0 to f - or 0 to 15 - which is 2^4 = 16
            // that means the first 8 characters of the address are 8 * 4 bit = 32 bit
            const seed = parseInt(account.slice(2, 10), 16);

            // if the seed hasn't change return
            if (this.identiconSeed === seed) return;

            this.identicon?.remove();
            this.identiconSeed = seed;

            if (avatar) {

                this.identicon = document.createElement('img');
                this.identicon.setAttribute('src', avatar);

            } else {

                this.identicon = JazzIcon(IDENTICON_SIZE, seed);
            }

        } else {

            this.identicon?.remove();
            this.identiconSeed = undefined;
            this.identicon = undefined;
        }

        this.requestUpdate();
    }

    protected async handleBalanceChange (): Promise<void> {

        if (!this.faucetingEnabled) return;

        if (!this.accountMachine.state.matches(ACCOUNT.STATES.CONNECTED)) return;

        const accountState = this.accountMachine.state.context;
        const connection = this.walletService.state.connection;

        const tokenAddresses = [...accountState.balances.keys()].filter(address => address !== ETH.address);

        const canFaucet = await Promise.all(tokenAddresses.map(token => this.tokenService.canFaucet(token, connection)));

        this.faucetingAllowed = new Map(tokenAddresses.map((address, index) => ([address, canFaucet[index]])));

        this.requestUpdate();
    }

    protected handleTransactionsChange (message: TransactionServiceMessage): void {

        switch (message.topic) {

            case TRANSACTION_SERVICE_TOPIC.SUCCESS:
            case TRANSACTION_SERVICE_TOPIC.FAILURE:

                // update balances after a transaction succeeds or fails
                this.accountMachine.send(ACCOUNT.model.events[ACCOUNT.EVENTS.FETCH]([]));

                // fallthrough...

            case TRANSACTION_SERVICE_TOPIC.LOADED:

                // eslint-disable-next-line @typescript-eslint/unbound-method
                this.transactions.forEach(tx => tx.unsubscribe(TRANSACTION_TOPIC.STATUS, this.handleTransactionChange));

                this.transactions = this.transactionService.getAll();

                // eslint-disable-next-line @typescript-eslint/unbound-method
                this.transactions.forEach(tx => tx.subscribe(TRANSACTION_TOPIC.STATUS, this.handleTransactionChange));
                break;
        }

        this.requestUpdate();
    }

    protected handleTransactionChange (message: TransactionMessage): void {

        switch (message.topic) {

            case TRANSACTION_TOPIC.STATUS:

                this.requestUpdate();
                break;
        }
    }

    protected handleOpenChange (event: OpenChangeEvent): void {

        // we ignore close events...
        if (!event.detail.open) return;

        // get a reference to the collapsibles in the popup
        const collapsibles = Array.from(this.overlayRef.value?.querySelectorAll<CollapsibleElement>('ui-collapsible') ?? []);

        // we ignore OpenChangeEvents dispatched by the collapsibles...
        if (collapsibles.includes(event.detail.target as CollapsibleElement)) return;

        // update the height calculation on the collapsibles
        collapsibles.forEach(collapsible => collapsible.updateHeight());

        // when we have pending transactions, expand the transactions view
        if (this.pendingTransactions().length > 0) {

            void (this.overlayRef.value?.querySelector('.account-transactions') as CollapsibleElement)?.toggle(true);
        }
    }

    protected handleToggle (): void {

        if (!this.popupRef.value || !this.triggerRef.value) return;

        if (this.triggerRef.value.getAttribute('aria-expanded') === 'false') {

            void this.popupRef.value.show(true);

        } else {

            void this.popupRef.value.hide(true);
        }
    }

    protected handleToggleRequest (event: ToggleRequestEvent): void {

        if (event.detail.element !== 'ill-account-popup') return;

        if (!this.popupRef.value) return;

        const show = event.detail.show ?? this.popupRef.value.hidden;

        show
            ? void this.popupRef.value.show(true)
            : void this.popupRef.value.hide(true);
    }

    protected async handleFaucet (address: string): Promise<void> {

        if (!this.faucetingEnabled) return;

        if (!this.accountMachine.state.matches(ACCOUNT.STATES.CONNECTED)) return;

        if (this.isFauceting(address)) return;

        const connection = this.walletService.state.connection!;

        const notification = notifications.show({
            type: 'progress',
            content: 'Fauceting...',
        });

        try {

            // get the balance before fauceting
            const [tokenInstance, previousBalance] = await Promise.all([
                this.tokenService.fetch(address, connection),
                this.tokenService.fetchBalance(address, connection.account.address, connection),
            ]);

            notifications.update(notification, {
                content: () => html`Fauceting ${ tokenSymbol(tokenInstance) } ...`,
            });

            const transaction = this.tokenService.faucet(address, connection);

            this.fauceting.set(address, transaction);

            this.popupRef.value?.focusInitial();

            this.requestUpdate();

            const response = await transaction;

            await this.transactionService.confirm(response);

            // update balances after a faucet transaction
            this.accountMachine.send(ACCOUNT.model.events[ACCOUNT.EVENTS.FETCH]([tokenInstance]));

            this.requestUpdate();

            // show a notification with the fauceted amount
            const currentBalance = await this.tokenService.fetchBalance(address, connection.account.address, connection);

            const fauceted = BigNumber.from(currentBalance).sub(BigNumber.from(previousBalance)).toString();

            notifications.update(notification, createNotification({
                id: notification,
                type: 'success',
                content: () => html`Fauceted ${ tokenBalance(fauceted, tokenInstance) }`,
            }));

        } catch (error) {

            notifications.update(notification, createNotification({
                id: notification,
                type: 'failure',
                content: () => html`Failed to faucet: ${ (error as ErrorLike).message }`,
            }));
        }

        this.fauceting.delete(address);

        this.requestUpdate();
    }

    // TODO: this will only work for the upgrade from guarded launch to auto-rollover launch
    // after that, users no longer have lp token balances and this will need to change
    protected async checkPreviousVersionBalances (): Promise<void> {

        if (!this.accountMachine.state.matches(ACCOUNT.STATES.CONNECTED)) return;

        const connection = this.walletService.state.connection!;

        const previousVersionIPT = ENV.previousVersionIPT;
        const previousVersionLP = ENV.previousVersionLP;

        if (!previousVersionIPT?.length && !previousVersionLP?.length) return;

        const prices = await fetchTokenPrices();

        const previousVersionIPTBalances = previousVersionIPT?.length
            ? (await Promise.all(
                previousVersionIPT.map(async address => await Promise.all([
                    this.tokenService.fetch(address, connection),
                    this.tokenService.fetchBalance(address, connection.account.address, connection),
                ])),
            ))
                .map<Balance>(([token, balance]) => ({ ...token, balance }))
                .filter(balance => {

                    const underlyingSymbol = /^ipt(.*?)-.*?$/.exec(balance.symbol)?.[1];
                    const price = underlyingSymbol ? prices[underlyingSymbol] : '1';
                    const threshold = REDEEMED_THRESHOLD(price);
                    const redeemable = fixed(contractAmount(balance.balance, balance)).subUnsafe(fixed(threshold));

                    this.logService.log([
                        'previousVersionIPTBalances...',
                        `ipt:        ${ balance.symbol }`,
                        `balance:    ${ contractAmount(balance.balance, balance) }`,
                        `underlying: ${ underlyingSymbol ?? 'NOT FOUND' }`,
                        `price:      ${ price }`,
                        `threshold:  ${ threshold }`,
                        `redeemable: ${ redeemable.toString() }`,
                    ].join('\n'));

                    return !redeemable.isNegative() && !redeemable.isZero();

                })
            : [];

        const previousVersionLPBalances = previousVersionLP?.length
            ? (await Promise.all(
                previousVersionLP.map(async address => await Promise.all([
                    this.tokenService.fetch(address, connection),
                    this.tokenService.fetchBalance(address, connection.account.address, connection),
                ])),
            ))
                .map<Balance>(([token, balance]) => ({ ...token, balance }))
                .filter(balance => {
                    // lp token symbols are almost the same as ipt symbols, just end with 'LP'
                    const underlyingSymbol = /^ipt(.*?)-.*?$/.exec(balance.symbol)?.[1];
                    const price = underlyingSymbol ? prices[underlyingSymbol] : '1';
                    const threshold = REDEEMED_THRESHOLD(price);
                    const redeemable = fixed(contractAmount(balance.balance, balance)).subUnsafe(fixed(threshold));

                    this.logService.log([
                        'previousVersionLPBalances...',
                        `ipt:        ${ balance.symbol }`,
                        `balance:    ${ contractAmount(balance.balance, balance) }`,
                        `underlying: ${ underlyingSymbol ?? 'NOT FOUND' }`,
                        `price:      ${ price }`,
                        `threshold:  ${ threshold }`,
                        `redeemable: ${ redeemable.toString() }`,
                    ].join('\n'));

                    // lp tokens can be worth more than an equal amount of underlying, but we cannot
                    // easily get the lp token value here, so we just cheaply treat an lp as being at minimum 1 base
                    return !redeemable.isNegative() && !redeemable.isZero();
                })
            : [];

        const hasPreviousVersionIPT = previousVersionIPTBalances.length > 0;
        const hasPreviousVersionLP = previousVersionLPBalances.length > 0;
        const previousVersion = ENV.deployments.find(deployment => deployment.id === 'previous');

        if (hasPreviousVersionIPT || hasPreviousVersionLP) {

            if (this.previousVersionNotification) return;

            this.previousVersionNotification = notifications.show({
                content: () => html`
                <h1>You have positions in a previous version of Illuminate.</h1>
                <p>
                    Redeem your positions here:
                </p>
                <a href="${ previousVersion?.url }positions" target="_blank">${ previousVersion?.label }</a>
                `,
                timeout: 0,
            });
        }
    }
}
