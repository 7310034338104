import { DATE_FORMAT, formatDate } from '../../../../shared/helpers';
import { Pool, Token } from '../../../../types';

const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';

export const strT = (source?: Pool): Token => {

    const address = source?.strategyAddress ?? ZERO_ADDRESS;
    const underlying = source?.token;
    const maturity = source?.maturity;

    const name = ['Strategy Token', underlying?.symbol, maturity && formatDate(maturity, DATE_FORMAT.SHORT)].join(' ').trim();
    const decimals = underlying?.decimals ?? 18;

    return {
        address,
        decimals,
        name,
        symbol: 'STRAT',
        // TODO: add an image for strategy tokens
        image: 'mock',
    };
};

/**
 * Checks if a token is a strategy token
 *
 * @param token - the token to check
 */
export const isSTRT = (token: Token): boolean => token.symbol === 'STRAT';
