import { html } from 'lit';
import { DATE_FORMAT, formatDate } from '../helpers/date';
import { FALLBACK } from './amounts';

/**
 * Creates a template for a formatted date
 *
 * @param timestamp - timestamp (if a string is passed we assume it's a unix timestamp)
 * @param format - optional `Intl.DateTimeFormat`
 * @param fallback - an optional fallback value if the timestamp is empty (defaults to `--`)
 */
export const date = (timestamp?: string | number | Date, format = DATE_FORMAT.LONG, fallback = FALLBACK) =>
    html`<span class="date">${ timestamp ? formatDate(timestamp, format) : fallback }</span>`;
