import { cancel, dispatch } from '@swivel-finance/ui/utils/events';
import { html } from 'lit';
import { ToggleRequestEvent } from '../../components/account/events';

export const NOTIFICATIONS = {
    ACCOUNT: {
        COPY_ADDRESS_SUCCESS: 'Address copied to clipboard.',
        COPY_ADDRESS_FAILURE: (error: string) => `Failed to copy address to clipboard: ${ error }`,
    },
    LEND: {
        CONNECT: 'Please connect your wallet to continue.',
        APPROVAL_PENDING: 'Waiting for approval...',
        APPROVAL_SUCCESS: 'Approval successful.',
        APPROVAL_FAILURE: (error: string) => `Approval failed: ${ error }`,
        TRANSACTION_DISPOSED: () => html`
            Follow the progress of your Lend transaction in the
            <a href="#" @click=${ (event: Event) => showAccountsPopup(event) }>accounts popup</a>.`,
    },
    REDEEM: {
        APPROVAL_PENDING: 'Waiting for approval...',
        APPROVAL_SUCCESS: 'Approval successful.',
        APPROVAL_FAILURE: (error: string) => `Approval failed: ${ error }`,
        TRANSACTION_DISPOSED: () => html`
            Follow the progress of your Exit transaction in the
            <a href="#" @click=${ (event: Event) => showAccountsPopup(event) }>accounts popup</a>.`,
    },
    POOL_ENTER: {
        CONNECT: 'Please connect your wallet to continue.',
        APPROVAL_PENDING: 'Waiting for approval...',
        APPROVAL_SUCCESS: 'Approval successful.',
        APPROVAL_FAILURE: (error: string) => `Approval failed: ${ error }`,
        TRANSACTION_DISPOSED: () => html`
            Follow the progress of your Add Liquidity transaction in the
            <a href="#" @click=${ (event: Event) => showAccountsPopup(event) }>accounts popup</a>.`,
    },
    POOL_EXIT: {
        CONNECT: 'Please connect your wallet to continue.',
        APPROVAL_PENDING: 'Waiting for approval...',
        APPROVAL_SUCCESS: 'Approval successful.',
        APPROVAL_FAILURE: (error: string) => `Approval failed: ${ error }`,
        TRANSACTION_DISPOSED: () => html`
            Follow the progress of your Remove Liquidity transaction in the
            <a href="#" @click=${ (event: Event) => showAccountsPopup(event) }>accounts popup</a>.`,
    },
} as const;

/**
 * Helper method to make a link-click open the accounts popup
 */
const showAccountsPopup = (event: Event) => {

    cancel(event);

    dispatch(document.body, new ToggleRequestEvent({
        element: 'ill-account-popup',
        show: true,
    }));
};
