import { ValueChangeEvent } from '@swivel-finance/ui/elements/input';
import { SelectConfig } from '@swivel-finance/ui/elements/select/config';
import { DeepPartial } from '@swivel-finance/ui/utils';
import { html, LitElement, nothing } from 'lit';
import { customElement } from 'lit/decorators.js';
import { ERRORS } from '../../core/constants';
import { Deployment, ENV } from '../../core/env';
import { ERROR_SERVICE, serviceLocator } from '../../core/services';
import { capitalize } from '../../shared/helpers';

const errors = serviceLocator.get(ERROR_SERVICE);

/**
 * Creates a label for a non-configured deployment, like a local deployment or preview deploy
 */
const deploymentLabel = (): string => {

    return capitalize(ENV.networkName);
};

/**
 * Creates a version for a non-configured deployment, like a local deployment or preview deploy
 */
const deploymentVersion = (): string => {

    const location = /127\.0\.0\.1|localhost/.test(document.location.hostname)
        ? 'local'
        : /.*?\.vercel\.app/.test(document.location.hostname)
            ? 'preview'
            : '';

    const mode = ENV.appMode === 'development'
        ? '(dev)'
        : '(prod)';

    const version = `v${ ENV.appVersion }`;

    return [location, mode, version].join(' ');
};

/**
 * The current deployment
 *
 * @remarks
 * The current deployment is inferred from the environment. A deployment is not necessarily bound
 * to a particular version of the codebase and can be freely configured through the environment.
 * E.g. It is possible to have multiple separate deployments of the same codebase version, but
 * with different contracts or other environment settings.
 * If the current deployment is not properly configured, we create a placeholder deployment.
 */
const DEPLOYMENT = ENV.deployments.find(deployment => deployment.id === ENV.deploymentId) ?? {
    id: ENV.appVersion,
    url: document.location.origin,
    label: deploymentLabel(),
    version: deploymentVersion(),
    description: 'No information available.',
};

/**
 * The available deployments of Illuminate
 *
 * @remarks
 * If the `DEPLOYMENT_ID` of the current deploy is not found in the `DEPLOYMENTS` list, a placeholder
 * deployment is added to the list and an error is logged to
 */
const DEPLOYMENTS = ENV.deployments.includes(DEPLOYMENT)
    ? ENV.deployments
    : ((): Deployment[] => {
        // log an error if the current DEPLOYMENT_ID is not configured in the DEPLOYMENTS environment variable
        errors.process(ERRORS.COMPONENTS.DEPLOYMENT.MISSING);
        // add the placeholder deployment to the deployments list
        return ENV.deployments.concat(DEPLOYMENT);
    })();

/**
 * Custom `SelectConfig`
 */
const CONFIG: DeepPartial<SelectConfig> = {
    position: {
        width: undefined,
        height: undefined,
        alignment: {
            origin: {
                horizontal: 'end',
                vertical: 'end',
            },
            target: {
                horizontal: 'end',
                vertical: 'end',
            },
        },
        safeZone: {
            horizontal: 'var(--grid-size-xl)',
            vertical: 'var(--grid-size)',
        },
    },
};



const template = function (this: DeploymentSelectElement) {

    return (DEPLOYMENTS.length > 1)
        ? html`
        <ui-select
            .config=${ CONFIG }
            .placeholder=${ '' }
            .overlayClasses=${ ['deployment-select-overlay'] }
            @ui-value-changed=${ (event: ValueChangeEvent<Deployment>) => this.handleValueChange(event) }>
            <button class="ui-select-trigger" data-part="trigger">
                <span class="ui-select-trigger-label">
                    <span class="label">${ this.deployment.label }</span>
                    <span class="version">${ this.deployment.version || `v${ ENV.appVersion }` }</span>
                </span>
                <ui-icon class="ui-select-trigger-toggle" name="triangle"></ui-icon>
            </button>
            <ui-listbox data-part="overlay" aria-label="Select Illuminate Deployment">
                ${ DEPLOYMENTS.map(deployment => itemTemplate.call(this, deployment)) }
            </ui-listbox>
        </ui-select>`
        : html`
        <span class="label">${ this.deployment.label }</span>
        <span class="version">${ this.deployment.version || `v${ ENV.appVersion }` }</span>
        `;
};

const itemTemplate = function (this: DeploymentSelectElement, deployment: Deployment) {

    return html`
    <ui-listitem .value=${ deployment } aria-selected="${ this.deployment === deployment }">
        <span class="label">
            ${ deployment.label }
            ${ this.deployment === deployment
                ? html`<span class="version">${ deployment.version || `v${ ENV.appVersion }` }</span>`
                : nothing
            }
        </span>
        <span class="description">${ deployment.description }</span>
    </ui-listitem>`;
};

@customElement('ill-deployment-select')
export class DeploymentSelectElement extends LitElement {

    protected deployment: Deployment = DEPLOYMENT;

    protected createRenderRoot (): Element | ShadowRoot {

        return this;
    }

    protected render (): unknown {

        return template.apply(this);
    }

    protected handleValueChange (event: ValueChangeEvent<Deployment>): void {

        this.deployment = event.detail.current;

        window.location.href = this.deployment.url;
    }
}
