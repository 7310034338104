import { TransactionReceipt, TransactionResponse } from '@ethersproject/providers';
import { ERRORS } from '../../../constants';
import { ERROR_SERVICE } from '../../errors';
import { serviceLocator } from '../../service-locator';
import { Connection, WALLET_SERVICE } from '../../wallet';
import { TransactionConfirmer, isTransactionReplacedError } from './interfaces';

/**
 * Wait for a transaction to be successfully mined.
 *
 * @remarks
 * Waits for a transaction to be mined and resolves with the {@link TransactionReceipt}
 * if the transaction was successful. Will reject with an error if the transaction
 * fails or is cancelled/replaced.
 *
 * @param transaction - a transaction response or transaction hash to await confirmation for
 * @param connection - optional ethereum connection
 */
export const confirm: TransactionConfirmer = async (
    transaction: TransactionResponse | string,
    connection?: Connection,
): Promise<TransactionReceipt> => {

    const errors = serviceLocator.get(ERROR_SERVICE);
    const wallet = serviceLocator.get(WALLET_SERVICE);

    connection = connection ?? await wallet.connect();

    try {

        const receipt = (typeof transaction === 'string')
            ? await connection.provider.waitForTransaction(transaction)
            : await transaction.wait();

        // a transaction receipt will be returned succesfully for failed transactions
        // we need to inspect the status field of the receipt to ensure the transaction
        // was successful (status === 1) and throw otherwise
        if (receipt.status !== 1) {

            throw errors.process(ERRORS.ETHEREUM.TRANSACTION.REVERTED);
        }

        return receipt;

    } catch (error) {

        if (isTransactionReplacedError(error)) {

            // a tx is 'repriced' if only the gas amount has changed (e.g. to speed up the tx)
            if (error.reason === 'repriced') {

                // we await the repriced tx receipt in that case...
                return await confirm(error.replacement);

            } else {

                throw errors.process(error, ERRORS.ETHEREUM.TRANSACTION.CANCELLED);
            }

        } else {

            throw errors.process(error);
        }
    }
};
