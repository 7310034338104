import { IDGenerator } from '@swivel-finance/ui/utils/dom';
import { html, LitElement, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { status } from '../../shared/templates';

const idGenerator = new IDGenerator('preview-item-');

const render = (value: unknown): unknown => ((typeof value === 'function') ? value() : value) ?? nothing;

const template = function (this: PreviewItemElement) {

    const current = render(this.current);
    const preview = render(this.preview);

    const hasCurrent = current !== nothing;
    const hasPreview = preview !== nothing;
    const hasChange = hasCurrent && hasPreview;

    return html`
    <span class="label">
        ${ render(this.label) }
        ${ this.tooltip
            ? html`
            <ui-icon name="question" aria-labelledby="${ this.id + '-tooltip' }"></ui-icon>
            <ui-tooltip id="${ this.id + '-tooltip' }">${ render(this.tooltip) }</ui-tooltip>
            `
            : nothing
        }
    </span>
    ${ this.loading
        ? status('loading')
        : html`
        <span class="value-wrapper">
            ${ hasCurrent ? html`<span class="value-current">${ current }</span>` : nothing }
            ${ hasChange ? html`<ui-icon name="arrow"></ui-icon>` : nothing }
            ${ hasPreview ? html`<span class="value-preview">${ preview }</span>` : nothing }
        </span>
        `
    }
    `;
};

@customElement('ill-preview-item')
export class PreviewItemElement extends LitElement {

    @property({
        attribute: true,
        reflect: true,
    })
    id = idGenerator.getNext();

    @property({
        attribute: false,
    })
    label?: unknown;

    @property({
        attribute: false,
    })
    tooltip?: unknown;

    @property({
        attribute: false,
    })
    current?: unknown;

    @property({
        attribute: false,
    })
    preview?: unknown;

    @property({
        attribute: true,
        reflect: true,
    })
    loading = false;

    @property({
        attribute: true,
        reflect: true,
    })
    highlight = false;

    protected createRenderRoot (): Element | ShadowRoot {

        return this;
    }

    protected render (): unknown {

        return template.apply(this);
    }

    protected updated (): void {

        this.classList.add('preview-item');

        this.highlight
            ? this.classList.add('preview-highlight')
            : this.classList.remove('preview-highlight');

        this.loading
            ? this.classList.add('preview-loading')
            : this.classList.remove('preview-loading');

        this.current !== undefined && this.preview !== undefined
            ? this.classList.add('preview-change')
            : this.classList.remove('preview-change');
    }
}
