import { StrategyState } from '@swivel-finance/illuminate-js';
import { html, LitElement } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { toUSD } from '../../core/balance';
import { SECONDS_PER_YEAR } from '../../core/constants';
import { matured } from '../../core/markets';
import { isLendPosition, isPoolPosition } from '../../core/positions';
import { serviceLocator } from '../../core/services';
import { TimeService, TIME_SERVICE } from '../../core/services/time';
import { orchestrator, POSITION } from '../../state/orchestrator';
import { Position } from '../../types';

const template = function (this: PortfolioElement) {

    return html`
    <header>
        <h2>Your Portfolio</h2>
        <button class="button-icon" @click=${ () => this.handlePrivacyToggle() }>
            <ui-icon name="${ this.private ? 'visible' : 'invisible' }"></ui-icon>
        </button>
    </header>

    <ill-currency-animation .value=${ this.value } .decimals=${ this.decimals } .private=${ this.private }></ill-currency-animation>
    `;
};

@customElement('ill-portfolio')
export class PortfolioElement extends LitElement {

    protected positionMachine = orchestrator.position;

    protected positions = [] as Position[];

    protected interval?: number;

    protected frequency = 10000;

    protected timeService: TimeService;

    protected time: number;

    @state()
    protected value = 0;

    @state()
    protected decimals = 4;

    @state()
    protected private = false;

    constructor () {

        super();

        this.timeService = serviceLocator.get(TIME_SERVICE);

        this.time = this.currentTime();

        this.handlePositionsTransition = this.handlePositionsTransition.bind(this);
    }

    connectedCallback (): void {

        super.connectedCallback();

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.positionMachine.onTransition(this.handlePositionsTransition);

        this.interval = window.setInterval(() => {

            requestAnimationFrame(() => this.calculatePortfolioValue());

        }, this.frequency);
    }

    disconnectedCallback (): void {

        window.clearInterval(this.interval);

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.positionMachine.off(this.handlePositionsTransition);

        super.disconnectedCallback();
    }

    protected createRenderRoot (): Element | ShadowRoot {

        return this;
    }

    protected render (): unknown {

        return template.apply(this);
    }

    protected handlePositionsTransition (state: typeof orchestrator.position.state): void {

        if (state.matches(POSITION.STATES.FETCHING)) {

            // we could show a loading indicator here...
        }

        if (state.matches(POSITION.STATES.ERROR)) {

            // should we handle errors here?
        }

        // when positions are updated, we need to recalculate the portfolio value
        if (state.matches(POSITION.STATES.SUCCESS)) {

            const { activeLendPositions, maturedLendPositions, activePoolPositions, maturedPoolPositions } = state.context;

            this.positions = [
                ...activeLendPositions,
                ...maturedLendPositions,
                ...activePoolPositions,
                ...maturedPoolPositions,
            ];

            this.updateTime();

            this.calculatePortfolioValue();
        }

        this.requestUpdate();
    }

    protected handlePrivacyToggle (): void {

        this.private = !this.private;
    }

    protected calculatePortfolioValue (): void {

        this.value = this.positions.reduce((total, position) => {

            return total + this.calculatePositionValue(position);

        }, 0);
    }

    protected calculatePositionValue (position: Position): number {

        // the amount of seconds that have passed since the last time we updated the positions
        const delta = this.currentTime() - this.time;

        const lastTime = this.time;
        const maturityTime = parseInt(position.maturity);

        if (isLendPosition(position)) {

            const lastValue = parseFloat(toUSD(
                position.currentPositionValue || '0',
                position.market.token,
            ));

            const maturityValue = parseFloat(toUSD(
                (matured(position) ? position.currentPositionValue : position.iptBalance) || '0',
                position.market.token,
            ));

            const isMatured = lastTime >= maturityTime || delta >= maturityTime - lastTime;

            if (isMatured) return maturityValue;

            // for lending positions we use linear interpolation:
            // y = (y2 - y1) / (x2 - x1) * (x - x1) + y1
            // x1 is the last time we updated the positions
            // x2 is the maturity time
            // y1 is the last value we had
            // y2 is the value at maturity
            // x is the current time
            // y is the current value

            return (maturityValue - lastValue) / (maturityTime - lastTime) * delta + lastValue;
        }

        if (isPoolPosition(position)) {

            const lastValue = parseFloat(toUSD(position.currentPositionValue || '0', position.pool.token));
            const apr = position.pool.apr;

            const isMatured = lastTime >= maturityTime || delta >= maturityTime - lastTime;
            const isDivested = position.pool.strategyState as StrategyState === StrategyState.DIVESTED;

            if (isMatured || isDivested) return lastValue;

            // for pool positions, we use the pool's apr:
            // we add a fraction of the apr to the last value
            // the fraction of the apr represents the time passed since we last updated positions

            return lastValue * (1 + apr / SECONDS_PER_YEAR * delta);
        }

        return 0;
    }

    protected currentTime (): number {

        return Math.round(this.timeService.time() / 1000);
    }

    protected updateTime (): void {

        this.time = this.currentTime();
    }
}
