import { NotificationType } from './types';

export const NOTIFICATION_ICONS: Record<NotificationType, string | undefined> = {
    'info': 'info',
    'success': 'check',
    'failure': 'exclamation',
    'progress': undefined,
};

export const NOTIFICATION_TIMEOUTS: Record<NotificationType, number> = {
    'info': 5,
    'success': 5,
    'failure': 10,
    'progress': 0,
};
