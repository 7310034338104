import type { ethers } from 'ethers';

// Chainlink Aggregator v3 (Price Feed)

export const CHAINLINK_AGGREGATOR_ABI = [
    'function decimals() public view returns (uint8)',
    'function latestAnswer() public view returns (uint256)',
];

export interface ChainlinkAggregatorContract {
    decimals (): Promise<number>;
    latestAnswer (): Promise<ethers.BigNumber>;
}
