import { StrategyState } from '@swivel-finance/illuminate-js';
import { PanelDirection, PanelNavigationEvent } from '@swivel-finance/ui/elements/panel-container';
import { ToggleChangeEvent } from '@swivel-finance/ui/elements/toggle/events';
import { cancel, dispatch } from '@swivel-finance/ui/utils/events';
import { BigNumber } from 'ethers';
import { html, nothing, PropertyValues } from 'lit';
import { customElement } from 'lit/decorators.js';
import { contractAmount, expandAmount } from '../../core/amount';
import { ROLLOVER_TIME, TOOLTIPS } from '../../core/constants';
import { RemoveLiquidityTransaction, REMOVE_LIQUIDITY_STATUS } from '../../core/pools';
import { serviceLocator } from '../../core/services';
import { TIME_SERVICE } from '../../core/services/time';
import { iPT, lpT } from '../../core/services/token';
import { DATE_FORMAT, debounce, formatDate } from '../../shared/helpers';
import { date, errorMessage, infoMessage, rate, tokenBalance } from '../../shared/templates';
import { SETTINGS_TYPE } from '../transaction-settings/transaction-settings';
import { PoolTransactionBaseElement } from './pool-transaction-base';

const invalidStateTemplate = function (this: RemoveLiquidityTransactionPreviewElement) {

    const { pool } = this.transaction?.state ?? {};

    return html`
    <div class="widget">

        <div class="widget-header">
            <button class="button-icon" @click=${ () => dispatch(this, new PanelNavigationEvent({ target: this, panel: PanelDirection.PREVIOUS })) }>
                <ui-icon name="back-circle"></ui-icon>
            </button>
            <label>
                <ill-token-symbol .name=${ pool?.token.image } .ipt=${ true }></ill-token-symbol>
                ${ date(pool?.maturity) }
            </label>
        </div>

        <div class="widget-main">

            ${ errorMessage('No liquidity can be removed from this pool at this time.', 'exclamation') }

            <button class="primary" ?disabled=${ true }>Remove Liquidity</button>

        </div>

    </div>
    `;
};

const previewInvestedTemplate = function (this: RemoveLiquidityTransactionPreviewElement) {

    const isFetching = this.transaction?.status === REMOVE_LIQUIDITY_STATUS.UPDATING;

    const { pool, info: poolInfo, burnForUnderlying, preview } = this.transaction?.state ?? {};
    const { yieldSpaceInfo, lpBalance, lpShare, totalLiquidity } = poolInfo ?? {};
    const { baseBalance, realFYTokenBalance } = yieldSpaceInfo ?? {};

    const ipToken = pool ? iPT(pool) : undefined;
    const lpToken = pool ? lpT(pool) : undefined;

    return html`
    <div class="preview">

        <ill-preview-item class="preview-return"
            .label=${ 'Returned:' }
            .current=${ tokenBalance(preview?.baseOut, pool?.token) }
            .loading=${ isFetching }
            .highlight=${ true }></ill-preview-item>

        ${ burnForUnderlying || preview?.tradeToBase
            ? nothing
            : html`
            <ill-preview-item class="preview-return"
                .label=${ `${ ipToken ? ipToken.symbol : 'iPT' } Returned:` }
                .current=${ tokenBalance(preview?.fyTokenOut, ipToken) }
                .loading=${ isFetching }
                .highlight=${ true }></ill-preview-item>
            `
        }

        <ill-preview-item
            .label=${ 'Pool Share:' }
            .current=${ rate(lpShare || 0, '%', false) }
            .preview=${ preview ? rate(preview.share || 0, '%', false) : undefined }
            .tooltip=${ TOOLTIPS.POOL.SHARE() }
            .loading=${ isFetching }></ill-preview-item>

        <ill-preview-item
            .label=${ 'LP Tokens:' }
            .current=${ tokenBalance(lpBalance?.balance ?? '0', lpToken) }
            .preview=${ preview ? tokenBalance(BigNumber.from(lpBalance?.balance ?? '0').sub(BigNumber.from(preview.lpTokensBurned)).toString(), lpToken) : undefined }
            .loading=${ isFetching }></ill-preview-item>

        <ui-collapsible class="preview-details">
            <h3 data-part="header">
                <button class="ghost" data-part="trigger">Details <ui-icon name="chevron"></ui-icon></button>
            </h3>
            <div data-part="region">

                <ill-preview-item
                    .label=${ 'Total Liquidity:' }
                    .current=${ tokenBalance(totalLiquidity, pool?.token) }
                    .preview=${ preview ? tokenBalance(preview.totalLiquidity, pool?.token) : undefined }
                    .loading=${ isFetching }></ill-preview-item>

                <ill-preview-item
                    .label=${ 'Underlying Supply:' }
                    .current=${ tokenBalance(baseBalance, pool?.token) }
                    .preview=${ preview ? tokenBalance(preview.baseBalance, pool?.token) : undefined }
                    .loading=${ isFetching }></ill-preview-item>

                <ill-preview-item
                    .label=${ 'iPT Supply:' }
                    .current=${ tokenBalance(realFYTokenBalance, ipToken) }
                    .preview=${ preview ? tokenBalance(preview.realFYTokenBalance, ipToken) : undefined }
                    .loading=${ isFetching }></ill-preview-item>

            </div>
        </ui-collapsible>

    </div>
    `;
};

const previewDivestedTemplate = function (this: RemoveLiquidityTransactionPreviewElement) {

    const isFetching = this.transaction?.status === REMOVE_LIQUIDITY_STATUS.UPDATING;

    const { pool, preview } = this.transaction?.state ?? {};

    const maturity = pool ? parseInt(pool.maturity) : this.timer.time() / 1000;
    const rollover = (maturity + ROLLOVER_TIME) * 1000;

    return html`
    <div class="preview">

        <ill-preview-item class="preview-return"
            .label=${ 'Returned:' }
            .current=${ tokenBalance(preview?.baseOut, pool?.token) }
            .loading=${ isFetching }
            .highlight=${ true }></ill-preview-item>

        ${ infoMessage(
            html`
            <p>
                Your position rolls over on ${ formatDate(rollover, DATE_FORMAT.MEDIUM) }.
                Redeem your position now or allow a rollover to maximize your yield.
            </p>`,
            'info',
        ) }

    </div>
    `;
};

const template = function (this: RemoveLiquidityTransactionPreviewElement) {

    const isReady = !!this.transaction?.canRemoveLiquidity();
    const hasError = this.transaction?.status === REMOVE_LIQUIDITY_STATUS.ERROR;

    const { pool, info, lpAmount, lpMax, underlyingAmount, underlyingMax, burnForUnderlying } = this.transaction?.state ?? {};
    const { lpBalance, strategyBalanceInUnderlying } = info ?? {};

    const lpToken = pool ? lpT(pool) : undefined;
    const isDivested = !!pool && !!info && info.strategyInfo.state === StrategyState.DIVESTED;

    const inputToken = !isDivested ? lpToken : pool.token;
    const inputAmount = !isDivested ? lpAmount : underlyingAmount;
    const inputBalance = !isDivested ? lpBalance?.balance : strategyBalanceInUnderlying;
    const inputMax = !isDivested ? lpMax : underlyingMax;

    return html`
    <div class="widget">

        <div class="widget-header">
            <button class="button-icon" @click=${ () => dispatch(this, new PanelNavigationEvent({ target: this, panel: PanelDirection.PREVIOUS })) }>
                <ui-icon name="back-circle"></ui-icon>
            </button>
            <label>
                <ill-token-symbol .name=${ pool?.token.image } .ipt=${ true }></ill-token-symbol>
                ${ date(pool?.maturity) }
            </label>
            <ill-transaction-settings .type=${ SETTINGS_TYPE.POOL }></ill-transaction-settings>
        </div>

        <div class="widget-inputs">

            ${ !isDivested
                ? html`
                <div class="burn-for-underlying">
                    <label class="burn-for-underlying">
                        <ui-toggle
                            .checked=${ burnForUnderlying }
                            .disabled=${ isDivested }
                            @ui-toggle-changed=${ (event: ToggleChangeEvent) => this.handleBurnForUnderlyingChange(event) }>
                        </ui-toggle>
                        Burn for underlying
                    </label>
                    <ui-icon name="question" aria-describedby="burn-for-underlying-tooltip"></ui-icon>
                    <ui-tooltip id="burn-for-underlying-tooltip">
                        ${ TOOLTIPS.POOL.BURN_FOR_UNDERLYING() }
                    </ui-tooltip>
                </div>
                `
                : nothing
            }

            <ill-token-input
                .value=${ inputAmount && inputToken ? contractAmount(inputAmount, inputToken) : '' }
                .token=${ inputToken }
                .balance=${ inputBalance && inputToken ? contractAmount(inputBalance, inputToken) : '0' }
                .max=${ inputMax && inputToken ? contractAmount(inputMax, inputToken) : '0' }
                .disabled=${ this.transaction?.isPending() || this.transaction?.isFinal() || false }
                @change=${ (event: CustomEvent<{ value: string; }>) => this.handleAmountChange(event) }>
            </ill-token-input>

            <div class="max-amount">
                <a href="#" @click=${ (event: MouseEvent) => this.handleMaxAmount(event) }>
                    Max amount: ${ tokenBalance(inputMax, inputToken) }
                </a>
            </div>

        </div>

        <div class="widget-main">

            ${ hasError
                ? errorMessage(this.transaction?.error?.message ?? 'An unknown error occurred.', 'exclamation')
                : nothing
            }

            ${ !isDivested
                ? previewInvestedTemplate.call(this)
                : previewDivestedTemplate.call(this)
            }

            <button class="primary" ?disabled=${ !isReady } @click=${ () => this.removeLiquidity() }>Remove Liquidity</button>

        </div>

    </div>
    `;
};

@customElement('ill-remove-liquidity-transaction-preview')
export class RemoveLiquidityTransactionPreviewElement extends PoolTransactionBaseElement<RemoveLiquidityTransaction> {

    protected timer = serviceLocator.get(TIME_SERVICE);

    constructor () {

        super();

        this.handleAmountChange = debounce(this.handleAmountChange.bind(this), 500);
    }

    protected createRenderRoot (): Element | ShadowRoot {

        return this;
    }

    protected render (): unknown {

        const strategy = this.transaction?.state.info?.strategyInfo;
        const validState = !strategy || strategy?.state === StrategyState.INVESTED || strategy?.state === StrategyState.DIVESTED;

        return !validState
            ? invalidStateTemplate.apply(this)
            : template.apply(this);
    }

    protected updated (changes: PropertyValues<RemoveLiquidityTransactionPreviewElement>): void {

        if (changes.has('transaction')) {

            void this.transaction?.update();
        }
    }

    protected handleBurnForUnderlyingChange (event: ToggleChangeEvent): void {

        cancel(event);

        if (this.transaction?.isFinal() || this.transaction?.isPending()) return;

        void this.transaction?.setBurnForUnderlying(event.detail.checked);
    }

    protected handleAmountChange (event: InputEvent | CustomEvent<{ value: string; }>): void {

        if (this.transaction?.isFinal() || this.transaction?.isPending()) {

            cancel(event);
            return;
        }

        const amount = ((event as CustomEvent<{ value: string; }>).detail.value !== undefined)
            ? (event as CustomEvent<{ value: string; }>).detail.value
            : (event.target as HTMLInputElement).value;

        const pool = this.transaction?.state.pool;
        const strategy = this.transaction?.state.info?.strategyInfo;

        if (!pool || !strategy) return;

        strategy.state === StrategyState.DIVESTED
            ? this.transaction?.setUnderlyingAmount(expandAmount(amount, pool.token))
            : this.transaction?.setLPAmount(expandAmount(amount, lpT(pool)));
    }

    protected handleMaxAmount (event: MouseEvent): void {

        cancel(event);

        if (this.transaction?.isFinal() || this.transaction?.isPending()) return;

        const pool = this.transaction?.state.pool;
        const strategy = this.transaction?.state.info?.strategyInfo;

        if (!pool || !strategy) return;

        const isDivested = strategy.state === StrategyState.DIVESTED;

        const max = isDivested
            ? this.transaction?.state.underlyingMax
            : this.transaction?.state.lpMax;

        isDivested
            ? this.transaction?.setUnderlyingAmount(max)
            : this.transaction?.setLPAmount(max);
    }

    protected retry (): void {

        void this.transaction?.update();
    }

    protected removeLiquidity (): void {

        void this.transaction?.send();
    }
}
