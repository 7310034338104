import { ERRORS } from '../../constants';
import { ErrorLike, EthereumError, EthersError, ResponseLike } from './interfaces';

export class ProcessedError extends Error {

    source: unknown;

    /**
     * @param e - an {@link ErrorLike} to configure the instance
     * @param s - an optional source (can be an error or rejection reason)
     */
    constructor (e?: ErrorLike, s?: unknown) {

        const { name, message } = e ?? ERRORS.DEFAULT;

        super(message);

        this.name = name;
        this.source = s;
    }
}

export const isResponse = (r: unknown): r is Response => {

    return typeof (r as ResponseLike).ok === 'boolean'
        && typeof (r as ResponseLike).status === 'number';
};

export const isEthereumError = (e: unknown): e is EthereumError => {

    return typeof (e as EthereumError).code === 'number'
        && typeof (e as EthereumError).message === 'string';
};

export const isEthersError = (e: unknown): e is EthersError => {

    return typeof (e as EthereumError).code === 'string'
        && typeof (e as EthereumError).message === 'string';
};

export const isErrorLike = (e: unknown): e is ErrorLike => {

    return typeof (e as ErrorLike).name === 'string'
        && typeof (e as ErrorLike).message === 'string';
};
