import { PanelDirection, PanelNavigationEvent } from '@swivel-finance/ui/elements/panel-container';
import { dispatch } from '@swivel-finance/ui/utils/events';
import { BigNumber } from 'ethers';
import { html, nothing } from 'lit';
import { customElement } from 'lit/decorators.js';
import { TOOLTIPS } from '../../core/constants';
import { maturityDate, MATURITY_DATE_FORMAT } from '../../core/markets';
import { RemoveLiquidityTransaction, REMOVE_LIQUIDITY_STATUS } from '../../core/pools';
import { StrategyState } from '../../core/services/strategy';
import { iPT, lpT } from '../../core/services/token';
import { date, errorMessage, rate, tokenBalance, transactionLink } from '../../shared/templates';
import { PoolTransactionBaseElement } from './pool-transaction-base';

const resultInvestedTemplate = function (this: RemoveLiquidityTransactionResultElement) {

    if (!this.transaction) return nothing;

    const isPending = this.transaction?.status === REMOVE_LIQUIDITY_STATUS.PENDING;

    const { pool, preview } = this.transaction.state;
    const { lpBalance, lpShare } = this.transaction.state.info ?? {};

    if (!pool || !preview) return nothing;

    const ipToken = iPT(pool);
    const lpToken = lpT(pool);

    const lpRemaining = BigNumber.from(lpBalance?.balance ?? '0').sub(BigNumber.from(preview.lpTokensBurned)).toString();

    return html`
    <div class="preview">

        <div class="preview-headline">
            Removed Liquidity Successfully
        </div>

        <ill-preview-item class="preview-return"
            .label=${ 'Returned:' }
            .current=${ tokenBalance(preview?.baseOut, pool?.token) }
            .loading=${ isPending }></ill-preview-item>

        ${ preview?.tradeToBase
            ? nothing
            : html`
            <ill-preview-item class="preview-return"
                .label=${ `${ ipToken ? ipToken.symbol : 'iPT' } Returned:` }
                .current=${ tokenBalance(preview?.fyTokenOut, ipToken) }
                .loading=${ isPending }></ill-preview-item>
            `
        }

        <ill-preview-item
            .label=${ 'Pool Share:' }
            .current=${ rate(lpShare || 0, '%', false) }
            .preview=${ preview ? rate(preview.share || 0, '%', false) : undefined }
            .tooltip=${ TOOLTIPS.POOL.SHARE() }
            .loading=${ isPending }></ill-preview-item>

        <ill-preview-item
            .label=${ 'LP Tokens:' }
            .current=${ tokenBalance(lpBalance?.balance ?? '0', lpToken) }
            .preview=${ preview ? tokenBalance(lpRemaining, lpToken) : undefined }
            .loading=${ isPending }></ill-preview-item>

        <ill-preview-item
            .label=${ 'Underlying Removed:' }
            .current=${ tokenBalance(preview?.baseOut, pool?.token) }
            .loading=${ isPending }></ill-preview-item>

        <ill-preview-item
            .label=${ 'iPT Removed:' }
            .current=${ tokenBalance(preview?.fyTokenOut, ipToken) }
            .loading=${ isPending }></ill-preview-item>

        <ill-preview-item
            .label=${ 'Matures:' }
            .current=${ maturityDate(pool.maturity, MATURITY_DATE_FORMAT.LONG) }></ill-preview-item>

        <div class="preview-item preview-transaction">
            ${ transactionLink(this.transaction.response?.hash) }
        </div>

    </div>
    `;
};

const resultDivestedTemplate = function (this: RemoveLiquidityTransactionResultElement) {

    if (!this.transaction) return nothing;

    const isPending = this.transaction?.status === REMOVE_LIQUIDITY_STATUS.PENDING;

    const { pool, preview, info } = this.transaction.state;

    if (!pool || !preview || !info) return nothing;

    const remaining = BigNumber.from(info.strategyBalanceInUnderlying).sub(BigNumber.from(preview.baseOut)).toString();

    return html`
    <div class="preview">

        <div class="preview-headline">
            Removed Liquidity Successfully
        </div>

        <ill-preview-item class="preview-return"
            .label=${ 'Returned:' }
            .current=${ tokenBalance(preview.baseOut, pool.token) }
            .loading=${ isPending }></ill-preview-item>

        <ill-preview-item
            .label=${ 'Remaining:' }
            .current=${ tokenBalance(remaining, pool.token) }
            .loading=${ isPending }></ill-preview-item>

        <ill-preview-item
            .label=${ 'Matured:' }
            .current=${ maturityDate(pool.maturity, MATURITY_DATE_FORMAT.LONG) }></ill-preview-item>

        <div class="preview-item preview-transaction">
            ${ transactionLink(this.transaction.response?.hash) }
        </div>

    </div>
    `;
};

const template = function (this: RemoveLiquidityTransactionResultElement) {

    if (!this.transaction) return nothing;

    const isReady = this.transaction?.status === REMOVE_LIQUIDITY_STATUS.SUCCESS;
    const isPending = this.transaction?.status === REMOVE_LIQUIDITY_STATUS.PENDING;
    const hasError = this.transaction?.status === REMOVE_LIQUIDITY_STATUS.ERROR || this.transaction?.status === REMOVE_LIQUIDITY_STATUS.FAILURE;

    if (!isReady && !isPending && !hasError) return nothing;

    const { pool, preview, info } = this.transaction.state;

    if (!pool || !preview || !info) return nothing;

    const isDivested = info.strategyInfo.state === StrategyState.DIVESTED;

    return html`
    <div class="widget">

        <div class="widget-header">
            <label>
                <ill-token-symbol .name=${ pool?.token.image } .ipt=${ true }></ill-token-symbol>
                ${ date(pool?.maturity) }
            </label>
            <button class="button-icon" @click=${ () => dispatch(this, new PanelNavigationEvent({ target: this, panel: PanelDirection.FIRST })) }>
                <ui-icon name="cross-circle"></ui-icon>
            </button>
        </div>

        <div class="widget-main">

            ${ hasError
                ? errorMessage(this.transaction.error?.message ?? 'An unknown error occurred.', 'exclamation')
                : !isDivested
                    ? resultInvestedTemplate.call(this)
                    : resultDivestedTemplate.call(this)
            }

        </div>
    </div>
    `;
};

@customElement('ill-remove-liquidity-transaction-result')
export class RemoveLiquidityTransactionResultElement extends PoolTransactionBaseElement<RemoveLiquidityTransaction> {

    protected createRenderRoot (): Element | ShadowRoot {

        return this;
    }

    protected render (): unknown {

        return template.apply(this);
    }
}
