// it's crucial to import config first - it configures our service locator
import './config';

// then we can import everything else - services can now be accessed via the service locator
import { checkConsent, consent } from './services/consent';
import './components/app/app';

// start the consent service
consent.start();

window.addEventListener('load', checkConsent);
