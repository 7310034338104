import { toggleVisibility } from '@swivel-finance/ui';
import { microtask } from '@swivel-finance/ui/utils/async';
import { html, LitElement, nothing, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { fixed } from '../../core/amount';
import { sortByAPR } from '../../core/quotes';
import { principal, rate } from '../../shared/templates';
import { Quote } from '../../types';

const template = function (this: RateComparisonElement) {

    if (!this.hasQuotes) return nothing;

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const quotes = this.quotes!.sort(sortByAPR).slice(0, this.maxItems);

    const diff = {
        rate: fixed(quotes[0].apr).subUnsafe(fixed(quotes[1].apr)).toString(),
        principal: quotes[1].principal,
    };

    return html`
    <ui-collapsible>
        <div data-part="header">
            <div class="summary" data-part="trigger">
                <ill-token-symbol name="illuminate"></ill-token-symbol>
                <span class="rate">
                    You're earning ${ rate(diff.rate, undefined, false) } more interest!
                </span>
                <span class="principal">
                    Compared to ${ principal(diff.principal) }
                </span>
                <ui-icon name="chevron"></ui-icon>
            </div>
        </div>
        <div class="entries" data-part="region">
            ${ quotes.map(quote => html`
            <div class="entry">
                <span class="label">${ principal(quote.principal) }:</span>
                <span class="value">${ rate(quote.apr ?? 0, '% APR', false) }</span>
            </div>
            `) }
        </div>
    </ui-collapsible>
    `;
};

@customElement('ill-rate-comparison')
export class RateComparisonElement extends LitElement {

    protected templateResult?: TemplateResult | typeof nothing;

    protected firstRender = true;

    protected visible = false;

    @property()
    quotes?: Quote[];

    @property()
    maxItems = 5;

    get hasQuotes (): boolean {

        return !!this.quotes && this.quotes.length > 1;
    }

    get isVisible (): boolean {

        return this.visible;
    }

    protected createRenderRoot (): Element | ShadowRoot {

        return this;
    }

    protected render (): unknown {

        if (this.firstRender) {

            void toggleVisibility(this, false, undefined, false);
        }

        const change = !this.isVisible && this.hasQuotes
            ? 'appear'
            : this.isVisible && !this.hasQuotes
                ? 'disappear'
                : 'none';

        switch (change) {

            case 'appear':

                this.visible = true;
                this.templateResult = template.apply(this);
                microtask(() => toggleVisibility(this, this.visible));
                break;

            case 'disappear':

                this.visible = false;
                // when the rate comparison element is about to disappear, we don't re-render the template
                // immediately, as that would empty the template; instead, we first schedule the visibility
                // animation and only after the element is visually hidden, we request an update to
                // re-render the template
                void microtask(() => toggleVisibility(this, this.visible)).done.then(() => {
                    // don't request a re-render, if the element has been toggled visible in the meantime
                    if (this.visible) return;
                    this.requestUpdate();
                });
                break;

            default:

                this.templateResult = template.apply(this);
                break;
        }

        this.firstRender = false;

        return this.templateResult;
    }
}
