import { ServiceIdentifier } from '../service-locator';
import { HttpService } from './interfaces';

export * from './constants';
export * from './interfaces';

/**
 * The service locator identifier for the HttpService interface.
 */
export const HTTP_SERVICE = ServiceIdentifier.get<HttpService>('HttpService');
