import { PanelDirection, PanelNavigationEvent } from '@swivel-finance/ui/elements/panel-container';
import { dispatch } from '@swivel-finance/ui/utils/events';
import { BigNumber } from 'ethers';
import { html, nothing } from 'lit';
import { customElement } from 'lit/decorators.js';
import { emptyOrZero } from '../../core/amount';
import { TOOLTIPS } from '../../core/constants';
import { maturityDate, MATURITY_DATE_FORMAT } from '../../core/markets';
import { AddLiquidityTransaction, ADD_LIQUIDITY_STATUS } from '../../core/pools';
import { iPT, lpT } from '../../core/services/token';
import { date, errorMessage, rate, tokenBalance, transactionLink } from '../../shared/templates';
import { PoolTransactionBaseElement } from './pool-transaction-base';

const template = function (this: AddLiquidityTransactionResultElement) {

    if (!this.transaction) return nothing;

    const isReady = this.transaction?.status === ADD_LIQUIDITY_STATUS.SUCCESS;
    const isPending = this.transaction?.status === ADD_LIQUIDITY_STATUS.PENDING;
    const hasError = this.transaction?.status === ADD_LIQUIDITY_STATUS.ERROR || this.transaction?.status === ADD_LIQUIDITY_STATUS.FAILURE;

    if (!isReady && !isPending && !hasError) return nothing;

    const { pool, preview } = this.transaction.state;
    const { lpBalance, lpShare } = this.transaction.state.info ?? {};

    if (!pool || !preview) return nothing;

    const ipToken = iPT(pool);
    const lpToken = lpT(pool);

    return html`
    <div class="widget">

        <div class="widget-header">
            <label>
                <ill-token-symbol .name=${ pool?.token.image } .ipt=${ true }></ill-token-symbol>
                ${ date(pool?.maturity) }
            </label>
            <button class="button-icon" @click=${ () => dispatch(this, new PanelNavigationEvent({ target: this, panel: PanelDirection.FIRST })) }>
                <ui-icon name="cross-circle"></ui-icon>
            </button>
        </div>

        <div class="widget-main">

            ${ hasError
                ? errorMessage(this.transaction.error?.message ?? 'An unknown error occurred.', 'exclamation')
                : html`
                <div class="preview">

                    <div class="preview-headline">
                        Added Liquidity Successfully
                    </div>

                    <ill-preview-item
                        class="preview-rate"
                        .label=${ 'Pool APR:' }
                        .current=${ rate(pool?.apr, '% APR') }
                        .tooltip=${ TOOLTIPS.POOL.RATE() }
                        .loading=${ isPending }></ill-preview-item>

                    <ill-preview-item
                        .label=${ 'Pool Share:' }
                        .current=${ rate(lpShare || 0, '%', false) }
                        .preview=${ preview ? rate(preview.share || 0, '%', false) : undefined }
                        .tooltip=${ TOOLTIPS.POOL.SHARE() }
                        .loading=${ isPending }></ill-preview-item>

                    <ill-preview-item
                        .label=${ 'LP Tokens:' }
                        .current=${ tokenBalance(lpBalance?.balance ?? '0', lpToken) }
                        .preview=${ preview ? tokenBalance(BigNumber.from(lpBalance?.balance ?? '0').add(BigNumber.from(preview.lpTokensMinted)).toString(), lpToken) : undefined }
                        .loading=${ isPending }></ill-preview-item>

                    <ill-preview-item
                        .label=${ 'Underlying Added:' }
                        .current=${ tokenBalance(preview.baseIn, pool.token) }
                        .loading=${ isPending }></ill-preview-item>

                    <ill-preview-item
                        .label=${ 'iPT Added:' }
                        .current=${ tokenBalance(emptyOrZero(preview.fyTokenToBuy) ? preview.fyTokenIn : preview.fyTokenToBuy, ipToken) }
                        .loading=${ isPending }></ill-preview-item>

                    <ill-preview-item
                        .label=${ 'Matures:' }
                        .current=${ maturityDate(pool.maturity, MATURITY_DATE_FORMAT.LONG) }></ill-preview-item>

                    <div class="preview-item preview-transaction">
                        ${ transactionLink(this.transaction.response?.hash) }
                    </div>

                    <div class="preview-item share-rate">
                        <ill-share-rate .rate=${ pool.apr } .underlying=${ pool.token.symbol } .type=${ 'pool' }></ill-share-rate>
                    </div>

                </div>
                `
            }

        </div>
    </div>
    `;
};

@customElement('ill-add-liquidity-transaction-result')
export class AddLiquidityTransactionResultElement extends PoolTransactionBaseElement<AddLiquidityTransaction> {

    protected createRenderRoot (): Element | ShadowRoot {

        return this;
    }

    protected render (): unknown {

        return template.apply(this);
    }
}
