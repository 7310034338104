import { IlluminateStatusResponse, MarketResponse, PoolResponse, PositionsResponse, QuoteResponse } from '../../../types';
import { ENV } from '../../env';
import { ErrorService } from '../errors';
import { GetPayload, HttpService } from '../http';
import { LogService } from '../logger';
import { APIService } from './interfaces';

/**
 * Payload for the quotes request.
 *
 * @internal
 */
interface QuotesPayload {
    underlying?: string;
    maturity?: string;
    amount?: string;
}

/**
 * An APIService using the illumigate API.
 */
export class IllumigateAPIService implements APIService {

    protected http: HttpService;

    protected errors: ErrorService;

    protected logger: LogService;

    constructor (h: HttpService, e: ErrorService, l: LogService) {

        this.http = h;
        this.errors = e;
        this.logger = l.group('api');
    }

    getStatus (): Promise<IlluminateStatusResponse> {

        const endpoint = `${ ENV.apiUrl }status`;

        return this.http.get(endpoint);
    }

    getMarkets (): Promise<MarketResponse[]> {

        const endpoint = `${ ENV.apiUrl }markets`;

        return this.http.get(endpoint);
    }

    getPools (): Promise<PoolResponse[]> {

        const endpoint = `${ ENV.apiUrl }pools`;

        return this.http.get(endpoint);
    }

    /**
     * Get a list of quotes for all markets.
     */
    getQuotes (): Promise<QuoteResponse[]>;

    /**
     * Get a list of quotes for all markets with the specified underlying.
     *
     * @param underlying - the underlying token address
     * @param amount - the optional amount of underlying to lend (default: '1' underlying)
     */
    getQuotes (underlying: string, amount?: string): Promise<QuoteResponse[]>;

    /**
     * Get a list of quotes for the specified market and amount.
     *
     * @param uunderlying - the market's underlying
     * @param maturity - the market's maturity
     * @param amount - the amount of underlying to lend
     */
    getQuotes (uunderlying: string, maturity: string, amount: string): Promise<QuoteResponse[]>;

    async getQuotes (underlying?: string, maturity?: string, amount?: string): Promise<QuoteResponse[]> {

        const endpoint = `${ ENV.apiUrl }quotes`;

        const payload = {} as QuotesPayload;

        if (underlying) payload.underlying = underlying;
        if (maturity && amount) payload.maturity = maturity;
        if (maturity && amount) payload.amount = amount;
        if (maturity && !amount) payload.amount = maturity;

        const response = await this.http.get<QuoteResponse[]>(endpoint, payload as GetPayload);

        const failed = response.filter(res => !!res.error);

        if (failed.length) {

            // TODO: handle response error? errors are probably filtered on BE
            this.logger.warn('failed quotes: ', failed);
        }

        return response;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async getPositions (account: string, type?: 'lending' | 'pooling'): Promise<PositionsResponse> {

        const endpoint = `${ ENV.apiUrl }users/${ account }/positions`;

        const response = await this.http.get<PositionsResponse>(endpoint, type && { type });

        this.logger.log(response);

        return response;
    }
}
