import { DATE_FORMAT, formatDate } from '../../../../shared/helpers';
import { Pool, Token } from '../../../../types';

const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';

/**
 * Get the LP token for a pool
 *
 * @param source - the pool for which to get the iPT
 */
export const lpT = (source?: Pool): Token => {

    const address = source?.address ?? ZERO_ADDRESS;
    const underlying = source?.token;
    const maturity = source?.maturity;

    const name = ['LP Token', underlying?.symbol, maturity && formatDate(maturity, DATE_FORMAT.SHORT)].join(' ').trim();
    const decimals = source?.token?.decimals ?? 18;

    return {
        address,
        decimals,
        name,
        symbol: 'LP',
        // TODO: add an image for LP tokens
        image: 'mock',
    };
};

/**
 * Checks if a token is an iPT
 *
 * @param token - the token to check
 */
export const isLPT = (token: Token): boolean => token.symbol === 'LP';
