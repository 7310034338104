import { Subscriber } from '../../../types';
import { InteractivityService, InteractivityState } from './interfaces';

export class BrowserInteractivityService implements InteractivityService {

    get focused (): boolean {

        return document.hasFocus();
    }

    get visible (): boolean {

        return document.visibilityState === 'visible';
    }

    get connected (): boolean {

        return window.navigator.onLine;
    }

    /**
     * Stores subscribers and prevents duplicate subscriptions
     */
    protected subscribers = new Set<Subscriber<InteractivityState>>();

    constructor () {

        this.handleFocusChange = this.handleFocusChange.bind(this);
        this.handleVisibilityChange = this.handleVisibilityChange.bind(this);
        this.handleConnectivityChange = this.handleConnectivityChange.bind(this);

        // eslint-disable-next-line @typescript-eslint/unbound-method
        document.addEventListener('visibilitychange', this.handleVisibilityChange);
        // eslint-disable-next-line @typescript-eslint/unbound-method
        window.addEventListener('offline', this.handleConnectivityChange);
        // eslint-disable-next-line @typescript-eslint/unbound-method
        window.addEventListener('online', this.handleConnectivityChange);
        // eslint-disable-next-line @typescript-eslint/unbound-method
        window.addEventListener('focus', this.handleFocusChange);
        // eslint-disable-next-line @typescript-eslint/unbound-method
        window.addEventListener('blur', this.handleFocusChange);
    }

    subscribe (subscriber: Subscriber<InteractivityState>): void {

        this.subscribers.add(subscriber);
    }

    unsubscribe (subscriber: Subscriber<InteractivityState>): boolean {

        return this.subscribers.delete(subscriber);
    }

    protected notify () {

        this.subscribers.forEach(subscriber => subscriber({
            focused: this.focused,
            visible: this.visible,
            connected: this.connected,
        }));
    }

    protected handleVisibilityChange () {

        this.notify();
    }

    protected handleConnectivityChange () {

        this.notify();
    }

    protected handleFocusChange () {

        this.notify();
    }
}
