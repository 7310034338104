import { TransactionReceipt, TransactionResponse } from '@ethersproject/providers';
import { BigNumber } from 'ethers';
import { Market, Quote } from '../../types';
import { fixed, trim } from '../amount';
import { ERRORS } from '../constants';
import { bestQuote, fetchQuotesByMarket } from '../quotes';
import { ErrorLike, ErrorService, IlluminateService, LogService, ServiceIdentifier } from '../services';
import { TimeService } from '../services/time';
import { Connection } from '../services/wallet';
import { isETHMarket } from './helpers';

/**
 * A transaction response that resolves immediately
 *
 * @remarks
 * This is used to bypass the approve transactions for ETH markets.
 */
const emptyTransactionResponse: TransactionResponse = {

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    wait: (confirmations?: number): Promise<TransactionReceipt> => {

        return Promise.resolve({
            confirmations: confirmations ?? 1,
            status: 1,
        } as TransactionReceipt);
    },

} as TransactionResponse;

export interface LendPreview {
    /**
     * The current best quote for the selected market and amount
     */
    quote: Quote;
    /**
     * All current quotes for the selected market and amount
     */
    quotes: Quote[];
}

export class LendService {

    protected logger: LogService;

    protected errors: ErrorService;

    protected timer: TimeService;

    protected illuminate: IlluminateService;

    constructor (illuminate: IlluminateService, timer: TimeService, errors: ErrorService, logger: LogService) {

        this.logger = logger.group('lend-service');
        this.errors = errors;
        this.timer = timer;
        this.illuminate = illuminate;
    }

    /**
     * Get the minimum token amount a lend operation needs to return
     *
     * @remarks
     * This is the lend amount minus the slippage.
     */
    slippageToMin (slippage: string | number, amount: string): string {

        const slippageAmount = fixed(amount).mulUnsafe(fixed(slippage));

        const minAmount = trim(fixed(amount).subUnsafe(slippageAmount).floor().toString());

        this.logger.log('slippageToMin()... slippage: %s, amount: %s, slippageAmount: %s, minAmount: %s ', slippage, amount, slippageAmount, minAmount);

        return minAmount;
    }

    /**
     * Get the deadline timestamp by which a lend operation needs to finish
     */
    deadline (deadline: string | number): string {

        deadline = (typeof deadline === 'number') ? deadline : parseFloat(deadline);

        const timestamp = (Math.round(this.timer.time() / 1000) + deadline).toString();

        this.logger.log('deadline()... deadline: %s, timestamp: %s: ', deadline, timestamp);

        return timestamp;
    }

    async fetchPreview (market: Market, amount: string): Promise<LendPreview> {

        try {

            const quotes = await fetchQuotesByMarket(market, market.token, amount);

            if (quotes.length === 0) {

                throw this.errors.process(ERRORS.STATE.LEND.NO_QUOTES);
            }

            const quote = bestQuote(quotes);

            this.logger.log('fetchQuotes: ', { quote, quotes });

            return { quote, quotes };

        } catch (error) {

            // TODO: make this somehow more ergonomic...
            const detail = (error as ErrorLike).message;

            const override = detail
                ? this.errors.updateMessage(ERRORS.STATE.LEND.FETCH, [[/\.$/, `: ${ detail }`]])
                : ERRORS.STATE.LEND.FETCH;

            throw this.errors.process(error, override);
        }
    }

    async checkAllowance (market: Market, quote: Quote, connection?: Connection): Promise<boolean> {

        const allowance = await this.illuminate.lender.allowance(market.underlying, connection);

        return BigNumber.from(quote.amount).lte(allowance);
    }

    async approve (market: Market, connection?: Connection): Promise<TransactionResponse> {

        return isETHMarket(market)
            ? Promise.resolve(emptyTransactionResponse)
            : await this.illuminate.lender.approve(market.underlying, connection);
    }

    async lend (market: Market, quote: Quote, min: string, deadline: string, connection?: Connection): Promise<TransactionResponse> {

        return await this.illuminate.lender.lend(market, quote, min, deadline, connection);
    }
}

export const LEND_SERVICE = ServiceIdentifier.get(LendService);
