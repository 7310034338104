import { TransactionReceipt, TransactionResponse } from '@ethersproject/providers';
import { BigNumber } from 'ethers';
import { Balance, Pool } from '../../types';
import { divideSafe, emptyOrZero, expandAmount, fixed, trim } from '../amount';
import { fetchAccountBalance, fetchTokenBalance } from '../balance';
import { isETHMarket } from '../markets';
import { ErrorService, IlluminateService, LogService, ServiceIdentifier } from '../services';
import { StrategyInfo, StrategyService } from '../services/strategy';
import { iPT, lpT, strT } from '../services/token';
import { Connection } from '../services/wallet';
import { YieldSpaceInfo, YieldSpaceService } from '../services/yieldspace';
import type { AddLiquidityPreview } from './add-liquidity-transaction';
import { isAddLiquidityPreview } from './helpers';
import * as PoolMath from './pool-math';
import type { RemoveLiquidityPreview } from './remove-liquidity-transaction';
import * as StrategyMath from './strategy-math';

/**
 * A transaction response that resolves immediately
 *
 * @remarks
 * This is used to bypass the approve transactions for ETH markets.
 */
const emptyTransactionResponse: TransactionResponse = {

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    wait: (confirmations?: number): Promise<TransactionReceipt> => {

        return Promise.resolve({
            confirmations: confirmations ?? 1,
            status: 1,
        } as TransactionReceipt);
    },

} as TransactionResponse;

export interface PoolInfo {
    strategyInfo: StrategyInfo;
    yieldSpaceInfo: YieldSpaceInfo;
    underlyingBalance: Balance;
    iPTBalance: Balance;
    lpBalance: Balance;
    lpShare: string;
    strategyBalance: Balance;
    strategyShare: string;
    strategyBalanceInLpTokens: string;
    strategyBalanceInUnderlying: string;
    totalLiquidity: string;
}

export class PoolService {

    protected allowances = new Map<string, string>();

    protected logger: LogService;

    protected errors: ErrorService;

    protected illuminate: IlluminateService;

    protected strategy: StrategyService;

    protected yieldspace: YieldSpaceService;

    constructor (illuminate: IlluminateService, strategy: StrategyService, yieldspace: YieldSpaceService, errors: ErrorService, logger: LogService) {

        this.logger = logger.group('pool-service');
        this.errors = errors;
        this.illuminate = illuminate;
        this.strategy = strategy;
        this.yieldspace = yieldspace;
    }

    async fetchInfo (pool: Pool, connection?: Connection): Promise<PoolInfo> {

        const [strategyInfo, yieldSpaceInfo, underlyingBalance, iPTBalance, strategyBalance] = await Promise.all([
            this.strategy.getInfo(pool.strategyAddress, connection),
            this.yieldspace.getInfo(pool.address, connection),
            isETHMarket(pool)
                ? fetchAccountBalance(connection)
                : fetchTokenBalance(pool.token, connection),
            fetchTokenBalance(iPT(pool), connection),
            fetchTokenBalance(strT(pool), connection),
        ]);

        // the user no longer has an lp token balance (the strategy owns the lp tokens), but a strategy token balance
        // we can infer how many lp tokens a user's strategy token balance is worth
        const lpBalance = StrategyMath.lpForStrategyTokens(strategyBalance.balance, strategyInfo.poolBalance, strategyInfo.totalSupply);
        // fyTokenBalance can reach 0 past maturity, so we use safe division
        const lpShare = divideSafe(lpBalance, yieldSpaceInfo.totalSupply);
        const strategyShare = divideSafe(strategyBalance.balance, strategyInfo.totalSupply);
        const strategyBalanceInLpTokens = lpBalance;
        const strategyBalanceInUnderlying = StrategyMath.baseForStrategyTokens(strategyBalance.balance, strategyInfo.baseBalance, strategyInfo.totalSupply);

        let totalLiquidity: string;

        try {

            // the liquidity call may throw under certein circumstances that cause PoolMath to throw
            totalLiquidity = trim(
                PoolMath.liquidity(
                    yieldSpaceInfo.baseBalance,
                    yieldSpaceInfo.sharesBalance,
                    yieldSpaceInfo.fyTokenBalance,
                    yieldSpaceInfo.totalSupply,
                    yieldSpaceInfo,
                ).floor().toString(),
            );

        } catch (error) {

            this.errors.process(error);

            totalLiquidity = '';
        }

        const poolInfo: PoolInfo = {
            strategyInfo,
            yieldSpaceInfo,
            underlyingBalance,
            iPTBalance,
            lpBalance: {
                ...lpT(pool),
                balance: trim(lpBalance.floor().toString()),
            },
            lpShare: lpShare.toString(),
            strategyBalance,
            strategyShare: strategyShare.toString(),
            strategyBalanceInLpTokens: trim(strategyBalanceInLpTokens.floor().toString()),
            strategyBalanceInUnderlying: trim(strategyBalanceInUnderlying.floor().toString()),
            totalLiquidity,
        };

        this.logger.log('fetchInfo()...', pool, poolInfo);

        return poolInfo;
    }

    buyFYTokenPreviewStatic (amount: string, info: YieldSpaceInfo): string {

        const preview = PoolMath.buyFYTokenPreview(amount, info.sharesBalance, info.fyTokenBalance, info);

        // we want to round up the amount of base required, so we don't preview too little base
        return trim(preview.ceiling().toString());
    }

    sellFYTokenPreviewStatic (amount: string, info: YieldSpaceInfo): string {

        const preview = PoolMath.sellFYTokenPreview(amount, info.sharesBalance, info.fyTokenBalance, info);

        // we want to round down the amount of base returned, so we don't preview too much base
        return trim(preview.floor().toString());
    }

    buyFyTokenPrice (amount: string, info: YieldSpaceInfo): string {

        return PoolMath.buyFYTokenPrice(amount, info.sharesBalance, info.fyTokenBalance, info).toString();
    }

    sellFyTokenPrice (amount: string, info: YieldSpaceInfo): string {

        return PoolMath.sellFYTokenPrice(amount, info.sharesBalance, info.fyTokenBalance, info).toString();
    }

    slippageToRatios (ratioSlippage: string | number, info: YieldSpaceInfo): [string, string] {

        // the min- and maxRatio settings in yieldspace are based on the ratio of shares to tradeable fy tokens
        const realFYTokenBalance = PoolMath.realFYTokenBalance(fixed(info.fyTokenBalance), fixed(info.totalSupply));
        // fyTokenBalance can reach 0 past maturity, so we use safe division
        const ratio = divideSafe(info.sharesBalance, realFYTokenBalance);
        const slippage = fixed(ratioSlippage);

        this.logger.log('slippageToRatios()... current ratio: %s, slippage: %s', ratio.toString(), slippage.toString());

        // we calculate the min- and maxRatio by adding/subtracting the slippage percentage (ratios are a percentage of sorts)
        // we have to ensure the minRatio is not negative
        const minRatio = ratio.subUnsafe(slippage).isNegative() ? fixed(0) : ratio.subUnsafe(slippage);
        const maxRatio = ratio.addUnsafe(slippage);

        this.logger.log('slippageToRatios()... minRatio: %s, maxRatio: %s', minRatio.toString(), maxRatio.toString());

        // the min- and maxRatios are formatted as fp18 integers
        return [
            BigNumber.from(expandAmount(minRatio.toString(), 18)).toString(),
            BigNumber.from(expandAmount(maxRatio.toString(), 18)).toString(),
        ];
    }

    async buyFYTokenPreview (pool: Pool, amount: string, connection?: Connection): Promise<string> {

        const preview = await this.yieldspace.buyFYTokenPreview(pool.address, amount, connection);

        this.logger.log('buyFYTokenPreview()...', amount, preview);

        return preview;
    }

    async sellFYTokenPreview (pool: Pool, amount: string, connection?: Connection): Promise<string> {

        const preview = await this.yieldspace.sellFYTokenPreview(pool.address, amount, connection);

        this.logger.log('sellFYTokenPreview()...', amount, preview);

        return preview;
    }

    async checkAllowance (pool: Pool, preview: AddLiquidityPreview | RemoveLiquidityPreview, connection?: Connection): Promise<boolean> {

        if (isAddLiquidityPreview(preview)) {

            const [underlying, ipt] = await Promise.all([
                this.strategy.allowance(pool.underlying, pool, connection),
                this.strategy.allowance(pool.pt, pool, connection),
            ]);

            // cache the allowances, so we can check them in the approval call
            // we only need to cache the allowance value with the token address, as the
            // spender is always the strategy router and on user account change we reload
            this.allowances.set(pool.underlying, underlying);
            this.allowances.set(pool.pt, ipt);

            return BigNumber.from(preview.baseIn).lte(underlying)
                && BigNumber.from(preview.fyTokenIn).lte(ipt);

        } else {

            const shares = await this.strategy.allowance(pool.strategyAddress, pool, connection);

            this.allowances.set(pool.strategyAddress, shares);

            return BigNumber.from(preview.strategyTokensBurned).lte(shares);
        }
    }

    async approve (pool: Pool, preview: AddLiquidityPreview | RemoveLiquidityPreview, connection?: Connection): Promise<TransactionResponse> {

        if (isAddLiquidityPreview(preview)) {

            // get the cached allowances from the last allowance call
            const allowanceUnderlying = BigNumber.from(this.allowances.get(pool.underlying) ?? '0');
            const allowancePrincipal = BigNumber.from(this.allowances.get(pool.pt) ?? '0');

            const approveUnderlying = allowanceUnderlying.lt(preview.baseIn)
                ? isETHMarket(pool)
                    // for ETH markets we don't need to approve the underlying token - the user lends ETH directly
                    ? Promise.resolve(emptyTransactionResponse)
                    : this.strategy.approve(pool.underlying, pool, connection)
                : Promise.resolve();

            const approvePrincipal = !emptyOrZero(preview.fyTokenIn) && allowancePrincipal.lt(preview.fyTokenIn)
                ? this.strategy.approve(pool.pt, pool, connection)
                : Promise.resolve();

            const approvals = await Promise.all([
                approveUnderlying,
                approvePrincipal,
            ]);

            // one of the allowances will be too small if `approve` was called...
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            return (approvals[0] ?? approvals[1])!;

        } else {

            const approval = await this.strategy.approve(pool.strategyAddress, pool, connection);

            return approval;
        }
    }

    async addLiquidity (pool: Pool, preview: AddLiquidityPreview, minRatio: string, maxRatio: string, connection?: Connection): Promise<TransactionResponse> {

        return this.strategy.addLiquidity(pool, preview, minRatio, maxRatio, connection);
    }

    async removeLiquidity (pool: Pool, preview: RemoveLiquidityPreview, minRatio: string, maxRatio: string, connection?: Connection): Promise<TransactionResponse> {

        return this.strategy.removeLiquidity(pool, preview, minRatio, maxRatio, connection);
    }
}

export const POOL_SERVICE = ServiceIdentifier.get(PoolService);
