import { ConsentService, ConsentStatus, ConsentTopics, LocalStorageAdapter, StorageAdapter, STORAGE_TOPIC, Subscriber } from '@swivel-finance/consent';
import { LEND_ROUTE, POOL_ROUTE, POSITIONS_ROUTE, RouterService } from '../../../routes';
import { IlluminateConsentTopics } from '../../../services/consent';
import { Preferences, PreferencesService, PreferencesTopic, PREFERENCES_TOPICS } from './interfaces';

/**
 * The PreferencesService
 *
 * @remarks
 * The PreferencesService should be used to read/write user preferences as it will respect user
 * consent settings and clean up after itself when disabled (consent withdrawn).
 *
 * The preferences service works similarly to other consent-based services (e.g. BugsnagService).
 * It depends on the `ConsentService` and is enabled/disabled based on the `ConsentCategory.PREFERENCES`
 * permission. The PreferencesService uses local storage but can be configured to use a different
 * `StorageAdapter`.
 */
export class BrowserPreferencesService implements PreferencesService {

    protected consentService: ConsentService<IlluminateConsentTopics>;

    protected router?: RouterService;

    protected storage: StorageAdapter<unknown, PreferencesTopic>;

    protected _enabled = false;

    get enabled (): boolean {

        return this._enabled;
    }

    constructor (consentService: ConsentService<IlluminateConsentTopics>, router?: RouterService, storage?: StorageAdapter<unknown, PreferencesTopic>) {

        this.consentService = consentService;

        this.router = router;

        this.storage = storage ?? new LocalStorageAdapter(PREFERENCES_TOPICS, { prefix: 'preferences.' });

        // we subscribe to the consent service for future changes
        this.consentService.subscribe(this.handleConsentChange.bind(this), STORAGE_TOPIC);

        // we subscribe to the router service for future changes
        this.router?.subscribe(this.handleRouteChange.bind(this));
    }

    enable (): void {

        this._enabled = true;

        this.updatePreferences();
    }

    disable (): void {

        this.clear();

        this._enabled = false;
    }

    subscribe<K extends PreferencesTopic = PreferencesTopic, T = Preferences[K]> (s: Subscriber<T, K>, t?: K | K[]): void {

        this.storage.subscribe(s as Subscriber<unknown, PreferencesTopic>, t);
    }

    unsubscribe<K extends PreferencesTopic = PreferencesTopic, T = Preferences[K]> (s: Subscriber<T, K>, t?: K | K[]): boolean {

        return this.storage.unsubscribe(s as Subscriber<unknown, PreferencesTopic>, t);
    }

    unsubscribeAll (): void {

        this.storage.unsubscribeAll();
    }

    clear (): void {

        if (!this._enabled) return;

        PREFERENCES_TOPICS.forEach(topic => this.storage.delete(topic));
    }

    delete (key: PreferencesTopic): void {

        if (!this._enabled) return;

        this.storage.delete(key);
    }

    get<K extends PreferencesTopic = PreferencesTopic, T extends Preferences[K] = Preferences[K]> (key: K): T | null {

        // we can always try to read the storage, even if storage permissions are not yet set (we can only read previously allowed entries)
        // we just have to make sure not to store data when permission is not given
        return this.storage.get(key) as T;
    }

    set<K extends PreferencesTopic = PreferencesTopic, T extends Preferences[K] = Preferences[K]> (key: K, value: T): void {

        if (!this._enabled) return;

        this.storage.set(key, value);
    }

    /**
     * Update user preferences.
     *
     * @remarks
     * We track the overall first time visit and initialize the theme and transaction
     * preferences. We also set the page preferences for the currently active route.
     */
    protected updatePreferences () {

        if (this.consentService.read(STORAGE_TOPIC) !== ConsentStatus.GRANTED) return;

        this.set('firstVisit', this.get('firstVisit') === null ? true : false);

        this.set('theme', this.get('theme') ?? 'default');

        this.set('transactions', this.get('transactions') ?? {});

        this.set('settings', this.get('settings') ?? null);

        this.updatePagePreferences();
    }

    /**
     * Update preferences for currently vistited page.
     *
     * @remarks
     * We track the first time visit of a particular page, so we can show
     * each tour separately for first time visitors.
     */
    protected updatePagePreferences () {

        if (this.consentService.read(STORAGE_TOPIC) !== ConsentStatus.GRANTED) return;

        const currentRoute = this.router?.activeRoute?.route;

        if (!currentRoute) return;

        switch (currentRoute.id) {

            case LEND_ROUTE.id:
                this.set('lendFirstVisit', this.get('lendFirstVisit') === null ? true : false);
                break;

            case POOL_ROUTE.id:
                this.set('poolFirstVisit', this.get('poolFirstVisit') === null ? true : false);
                break;

            case POSITIONS_ROUTE.id:
                this.set('positionsFirstVisit', this.get('positionsFirstVisit') === null ? true : false);
                break;
        }
    }

    /**
     * Enable/disable the preferences service on consent change
     */
    protected handleConsentChange (status: ConsentStatus, topic?: ConsentTopics) {

        // ignore consent changes to topics other than 'storage'
        if (topic && topic !== STORAGE_TOPIC) return;

        if (status === ConsentStatus.GRANTED) {

            this.enable();

        } else {

            this.disable();
        }
    }

    /**
     * Update page preferences on route changes
     */
    protected handleRouteChange () {

        this.updatePagePreferences();
    }
}
