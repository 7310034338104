import { IDGenerator } from '@swivel-finance/ui/utils/dom';
import { html, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';
import { repeat } from 'lit/directives/repeat.js';
import { notifications } from './notification-service';
import { Notification, NotificationOptions } from './types';

const idGenerator = new IDGenerator('ill-notification-outlet-');

const notificationOutletTemplate = function (this: NotificationOutletElement) {

    const notifications = this.notifications.entries();

    return html`${ repeat(notifications, (entry) => entry[0], ([id, notification]) => html`
        <ill-notification id=${ id }
            class=${ notification.class }
            .icon=${ notification.icon }
            .type=${ notification.type }
            .timeout=${ notification.timeout }
            .dismissable=${ notification.dismissable }
            .content=${ notification.content }></ill-notification>`) }`;
};

@customElement('ill-notification-outlet')
export class NotificationOutletElement extends LitElement {

    protected notifications = new Map<string, Notification>();

    show (n: Notification): string {

        this.notifications.set(n.id, n);

        this.requestUpdate();

        return n.id;
    }

    dismiss (i: string): void {

        this.notifications.delete(i);

        this.requestUpdate();
    }

    refresh (i: string, n: NotificationOptions): void {

        const notification = this.notifications.get(i);

        if (notification) this.notifications.set(i, { ...notification, ...n });

        this.requestUpdate();
    }

    connectedCallback (): void {

        super.connectedCallback();

        this.id = this.id || idGenerator.getNext();

        notifications.connect(this);
    }

    disconnectedCallback (): void {

        notifications.disconnect(this);

        super.disconnectedCallback();
    }

    protected createRenderRoot (): Element | ShadowRoot {

        return this;
    }

    protected render (): unknown {

        return notificationOutletTemplate.apply(this);
    }
}
