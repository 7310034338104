import { Balance, Token } from '../../types';
import { contractAmount, fixed } from '../amount';
import { ETH, TOKEN_PRICES } from '../constants';
import { ENV } from '../env';
import { LOG_SERVICE, serviceLocator } from '../services';
import { ERROR_SERVICE } from '../services/errors';
import { TOKEN_SERVICE } from '../services/token';
import { WALLET_SERVICE, Connection } from '../services/wallet';

// preset the cached token prices with the known prices for stable coins
let TOKEN_PRICE_MAP: Record<string, string> = { ...TOKEN_PRICES };

const cacheTokenPrices = (prices: Record<string, string>): void => {

    // add the specified prices to the cached ones
    TOKEN_PRICE_MAP = {
        ...TOKEN_PRICE_MAP,
        ...prices,
    };
};

/**
 * Get the connected account's ETH balance.
 *
 * @param connection - optional ethereum provider connection
 */
export const fetchAccountBalance = async (connection?: Connection): Promise<Balance> => {

    const errors = serviceLocator.get(ERROR_SERVICE);

    if (!connection) {

        connection = await serviceLocator.get(WALLET_SERVICE).connection();
    }

    const { account, provider } = connection;

    try {

        const balance = (await provider.getBalance(account.address)).toString();

        return {
            ...ETH,
            balance,
        };

    } catch (error) {

        throw errors.isProcessed(error) ? error : errors.process(error);
    }
};

/**
 * Get the connected account's balance of an ERC20 token.
 *
 * @param token - the ERC20 token
 * @param connection - optional ethereum provider connection
 */
export const fetchTokenBalance = async (token: Token, connection?: Connection): Promise<Balance> => {

    const errors = serviceLocator.get(ERROR_SERVICE);

    if (!connection) {

        connection = await serviceLocator.get(WALLET_SERVICE).connection();
    }

    try {

        const balance = await serviceLocator.get(TOKEN_SERVICE).fetchBalance(token.address, connection.account.address, connection);

        return {
            ...token,
            balance,
        };

    } catch (error) {

        throw errors.isProcessed(error) ? error : errors.process(error);
    }
};

/**
 * Get the price of a token in USD.
 *
 * @param symbol - the token symbol
 * @param connection - optional ethereum provider connection
 */
export const fetchTokenPrice = async (symbol: string, connection?: Connection): Promise<string> => {

    const errors = serviceLocator.get(ERROR_SERVICE);

    if (!connection) {

        connection = await serviceLocator.get(WALLET_SERVICE).connection();
    }

    try {

        const price = await serviceLocator.get(TOKEN_SERVICE).fetchPrice(symbol, connection);

        return price;

    } catch (error) {

        throw errors.isProcessed(error) ? error : errors.process(error);
    }
};

/**
 * Get the price of all relevant tokens in USD.
 *
 * @param connection - optional ethereum provider connection
 * @returns a record of token prices in USD keyed by token symbol
 */
export const fetchTokenPrices = async (connection?: Connection): Promise<Record<string, string>> => {

    const errors = serviceLocator.get(ERROR_SERVICE);
    const logger = serviceLocator.get(LOG_SERVICE).group('balance');

    if (!connection) {

        connection = await serviceLocator.get(WALLET_SERVICE).connection();
    }

    try {

        // fetch prices for all tokens cpnfigured in ENV
        const priceList = await Promise.all(
            Object.keys(ENV.priceFeedAddress).map((symbol) => {

                try {

                    return fetchTokenPrice(symbol, connection);

                } catch (error) {

                    // allow the price feed requests to fail and return an empty string in that case
                    return '';
                }
            }),
        );

        // create a record of prices keyed by symbol
        const priceMap = Object.keys(ENV.priceFeedAddress).reduce((map, symbol, index) => {

            map[symbol] = priceList[index];

            return map;

        }, {} as Record<string, string>);

        // cache token prices
        cacheTokenPrices(priceMap);

        logger.log('fetchTokenPrices()... ', TOKEN_PRICE_MAP);

        return getTokenPrices();

    } catch (error) {

        throw errors.isProcessed(error) ? error : errors.process(error);
    }
};

/**
 * Get the cached prices of all relevant tokens in USD
 *
 * @remarks
 * This method returns a map of cached prices and is potentially incomplete or out of date.
 * We need it however in some methods, which are synchronous and can't wait to fetch prices.
 */
export const getTokenPrices = (): Record<string, string> => {

    return { ...TOKEN_PRICE_MAP };
};

/**
 * Convert an amount of tokens into a USD amount
 *
 * @param amount - the amount to convert
 * @param token - the token
 */
export const toUSD = (amount: string, token: Token): string => {

    const price = fixed(getTokenPrices()[token.symbol] ?? 1, token.decimals);
    const value = fixed(contractAmount(amount, token), token.decimals);

    const usd = value.mulUnsafe(price).round(6).toString();

    return usd;
};
