import { html, LitElement, nothing, PropertyValues } from 'lit';
import { customElement, property } from 'lit/decorators.js';

const currencyTemplate = (currency: string) => {

    return html`<div class="currency">${ currency }</div>`;
};

const separatorTemplate = (separator: string, type: string) => {

    return html`<div class="separator separator-${ type }"><span class="content">${ separator }</span></div>`;
};

const decimalTemplate = (decimal: string) => {

    return html`<div class="decimal"><span class="content">${ decimal }</span></div>`;
};

const template = function (this: CurrencyElement) {

    return html`${ this.parts.map(({ type, value }) => {

        switch (type) {

            case 'currency':
                return currencyTemplate(value);

            case 'decimal':
            case 'group':
                return separatorTemplate(value, type);

            case 'integer':
            case 'fraction':
                return value.split('').map(decimal => decimalTemplate(decimal));

            default:
                return nothing;
        }
    }) }`;
};

@customElement('ill-currency')
export class CurrencyElement extends LitElement {

    protected formatter!: Intl.NumberFormat;

    protected parts: Intl.NumberFormatPart[] = [];

    @property({
        attribute: true,
        type: String,
    })
    locale = navigator.languages?.[0] ?? navigator.language ?? 'en-US';

    @property({
        attribute: true,
        type: String,
    })
    currency = 'USD';

    @property({
        attribute: true,
        type: Number,
    })
    value = 0;

    @property({
        attribute: true,
        type: Number,
    })
    decimals = 2;

    @property({
        attribute: true,
        reflect: true,
        type: Boolean,
    })
    private = false;

    protected createRenderRoot (): Element | ShadowRoot {

        return this;
    }

    protected render (): unknown {

        return template.apply(this);
    }

    protected update (changes: PropertyValues<CurrencyElement>): void {

        const updateFormatter = changes.has('locale') || changes.has('currency') || changes.has('decimals');
        const updateParts = changes.has('value') || updateFormatter;

        if (updateFormatter) {
            this.formatter = this.createFormatter();
        }

        if (updateParts) {
            this.parts = this.format(this.value);
        }

        super.update(changes);
    }

    protected round (value: number): number {

        const base = 10 ** this.decimals;

        return Math.round(value * base) / base;
    }

    protected format (value?: number | bigint): Intl.NumberFormatPart[] {

        return this.formatter.formatToParts(value ?? 0);
    }

    protected createFormatter (): Intl.NumberFormat {

        return new Intl.NumberFormat(this.locale, {
            style: 'currency',
            minimumFractionDigits: this.decimals,
            maximumFractionDigits: this.decimals,
            currency: this.currency,
        });
    }
}
