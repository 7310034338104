import { html } from 'lit';
import { ACCOUNT } from '../../../state/orchestrator';
import type { AppElement } from '../app';

export const positionsTemplate = function (this: AppElement) {

    const accountMachine = this.orchestrator.account;

    const connected = accountMachine.state.matches(ACCOUNT.STATES.CONNECTED)
        || accountMachine.state.matches(ACCOUNT.STATES.FETCHING);

    return html`
    <main class="positions">
        <div class="column">
        ${ !connected
            ? html`<ill-account-connect></ill-account-connect>`
            : html`<ill-positions-page></ill-positions-page>`
        }
        </div>
    </main>
    `;
};
