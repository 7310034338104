import { PanelDirection, PanelNavigationEvent } from '@swivel-finance/ui/elements/panel-container';
import { cancel, dispatch } from '@swivel-finance/ui/utils/events';
import { html, nothing } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { compareAmounts, contractAmount, empty, emptyOrZero, fixed } from '../../core/amount';
import { TOOLTIPS } from '../../core/constants';
import { LEND_STATUS, MATURITY_DATE_FORMAT, maturityDays } from '../../core/markets';
import { feePercentage, iPTPrice, iPTPurchase, rateAfterFees, yieldAtMaturity } from '../../core/quotes';
import { iPT } from '../../core/services/token';
import { debounce } from '../../shared/helpers';
import { FALLBACK, balance, date, errorMessage, price, principal, rate, tokenBalance, tokenImage } from '../../shared/templates';
import { ACCOUNT } from '../../state/orchestrator';
import { Market } from '../../types';
import { SETTINGS_TYPE } from '../transaction-settings/transaction-settings';
import { LendTransactionBaseElement } from './lend-transaction-base';

import './rate-comparison';

const maturityTemplate = (market?: Market) => {

    const daysRemaining = market ? maturityDays(market.maturity) : undefined;

    return html`
    ${ date(market?.maturity, MATURITY_DATE_FORMAT.LONG) }
    (${ daysRemaining ?? FALLBACK } day${ daysRemaining !== 1 ? 's' : '' })
    `;
};

const template = function (this: LendTransactionPreviewElement) {

    const isValid = !this.validationError;
    const isReady = this.transaction?.canLend();
    const isFetching = this.transaction?.status === LEND_STATUS.UPDATING;
    const hasError = this.transaction?.status === LEND_STATUS.ERROR;

    const { market, apr, amount: selectedAmount, quote } = this.transaction?.state ?? {};

    const underlying = market
        ? this.accountMachine.state.context.balances.get(market.token.address)
        : undefined;

    const illuminateRate = quote
        ? rateAfterFees(quote)
        : undefined;

    const yieldAmount = quote
        ? yieldAtMaturity(quote)
        : undefined;

    const lossWarning = yieldAmount && fixed(yieldAmount).isNegative()
        ? html`
        <div class="content">
            <p class="header">
                The transaction you are about to perform will likely incurr a loss.
            </p>
            <p class="detail">
                We recommend you choose a different market with a longer time to maturity.
            </p>
        </div>
        `
        : undefined;

    const fee = quote && feePercentage(quote);

    return html`
    <div class="widget">

        <div class="widget-header">
            <button class="button-icon" @click=${ () => dispatch(this, new PanelNavigationEvent({ target: this, panel: PanelDirection.PREVIOUS })) }>
                <ui-icon name="back-circle"></ui-icon>
            </button>
            <label>
                ${ tokenImage(underlying) }
                ${ date(market?.maturity) }
            </label>
            <ill-transaction-settings .type=${ SETTINGS_TYPE.LEND }></ill-transaction-settings>
        </div>

        <div class="widget-inputs">
            <ill-token-input
                .value=${ selectedAmount ?? '' }
                .token=${ underlying }
                .balance=${ underlying ? contractAmount(underlying?.balance ?? '0', underlying) : '0' }
                .disabled=${ this.transaction?.isPending() || this.transaction?.isFinal() || false }
                @change=${ (event: CustomEvent<{ value: string; }>) => this.handleAmountChange(event) }>
            </ill-token-input>
        </div>

        <div class="widget-main">

            ${ hasError
                ? errorMessage(this.transaction?.error?.message ?? 'An unknown error occurred.', 'exclamation')
                : nothing
            }

            <div class="preview">

                <ill-preview-item
                    class="preview-rate"
                    .label=${ 'Best Rate:' }
                    .current=${ rate(quote?.apr ?? apr, '% APR', false) }
                    .tooltip=${ TOOLTIPS.LEND.PREVIEW.RATE_PREVIEW(illuminateRate) }
                    .loading=${ isFetching }
                    .highlight=${ true }></ill-preview-item>

                <ill-preview-item
                    .current=${ html`<ill-share-rate .rate=${ quote?.apr ?? apr } .underlying=${ market?.token.symbol } .type=${ 'lend' }></ill-share-rate>` }
                    .loading=${ isFetching }></ill-preview-item>

                <ill-preview-item
                    .label=${ 'Yield at Maturity:' }
                    .current=${ tokenBalance(yieldAmount, market?.token) }
                    .tooltip=${ TOOLTIPS.LEND.YIELD_AT_MATURITY() }
                    .loading=${ isFetching }></ill-preview-item>

                <ill-preview-item
                    class="preview-source"
                    .label=${ 'Source:' }
                    .current=${ quote ? principal(quote.principal) : '- -' }
                    .tooltip=${ TOOLTIPS.LEND.PREVIEW.SOURCE() }
                    .loading=${ isFetching }></ill-preview-item>

                <ui-collapsible class="preview-details">
                    <h3 data-part="header">
                        <button class="ghost" data-part="trigger">Details <ui-icon name="chevron"></ui-icon></button>
                    </h3>
                    <div data-part="region">

                        <ill-preview-item
                            .label=${ 'iPTs Purchased:' }
                            .current=${ balance(quote ? iPTPurchase(quote) : '', iPT(market)) }
                            .tooltip=${ TOOLTIPS.LEND.IPT_PURCHASED(market?.token) }
                            .loading=${ isFetching }></ill-preview-item>

                        <ill-preview-item
                            .label=${ 'iPT Price:' }
                            .current=${ price(quote ? iPTPrice(quote) : '') }
                            .tooltip=${ TOOLTIPS.LEND.PREVIEW.IPT_PRICE(market?.token) }
                            .loading=${ isFetching }></ill-preview-item>

                        <ill-preview-item
                            class="preview-maturity"
                            .label=${ 'Matures:' }
                            .current=${ maturityTemplate(market) }
                            .tooltip=${ TOOLTIPS.LEND.MATURES(quote && iPTPurchase(quote), market?.token) }
                            .loading=${ isFetching }></ill-preview-item>

                        <ill-preview-item
                            .label=${ 'Fee:' }
                            .current=${ tokenBalance(quote?.fee, market?.token) }
                            .tooltip=${ TOOLTIPS.LEND.FEE(fee) }
                            .loading=${ isFetching }></ill-preview-item>

                    </div>
                </ui-collapsible>
            </div>

            ${ lossWarning
                ? errorMessage(lossWarning, 'exclamation')
                : nothing
            }

            <button class="primary" ?disabled=${ !isReady || !isValid } @click=${ () => this.lend() }>
            ${ isValid
                ? html`Lend`
                : html`<ui-icon name="exclamation"></ui-icon>${ this.validationError }`
            }
            </button>

        </div>

    </div>

    <ill-rate-comparison .quotes=${ this.transaction?.state.quotes } hidden></ill-rate-comparison>
    `;
};

@customElement('ill-lend-transaction-preview')
export class LendTransactionPreviewElement extends LendTransactionBaseElement {

    @state()
    protected validationError?: string;

    constructor () {

        super();

        this.handleAmountChange = debounce(this.handleAmountChange.bind(this), 500);
    }

    protected createRenderRoot (): Element | ShadowRoot {

        return this;
    }

    protected render (): unknown {

        return template.apply(this);
    }

    protected handleAmountChange (event: InputEvent | CustomEvent<{ value: string; }>): void {

        if (this.transaction?.isFinal() || this.transaction?.isPending()) {

            cancel(event);
            return;
        }

        const amount = ((event as CustomEvent<{ value: string; }>).detail.value !== undefined)
            ? (event as CustomEvent<{ value: string; }>).detail.value
            : (event.target as HTMLInputElement).value;

        this.transaction?.setAmount(amount);

        this.validate();
    }

    protected validate (): void {

        const accountState = this.accountMachine.state;
        const transactionState = this.transaction?.state;

        if (!accountState.matches(ACCOUNT.STATES.CONNECTED)) return;
        if (!this.transaction?.canUpdate(transactionState)) return;

        const balances = accountState.context.balances;
        const market = transactionState.market;

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const balance = balances.get(market.token.address)!;
        const maxAmount = contractAmount(balance.balance, balance.decimals);
        const currAmount = transactionState.amount;

        const isValid = !empty(maxAmount) && !emptyOrZero(currAmount)
            ? compareAmounts(currAmount, maxAmount, balance.decimals) < 1
            : true;

        this.validationError = isValid
            ? undefined
            : 'Insufficient Balance';
    }

    protected retry (): void {

        void this.transaction?.update();
    }

    protected lend (): void {

        void this.transaction?.send();
    }
}
