/* eslint-disable @typescript-eslint/no-unused-vars */
import { createMachine, DoneInvokeEvent, EventFrom, MachineOptions, TransitionsConfig } from 'xstate';
import { createModel } from 'xstate/lib/model';
import { ERRORS } from '../core/constants';
import { matured } from '../core/markets';
import { bestPoolsByToken, isStrategyValid, sortByAPR } from '../core/pools/helpers';
import { fetchPoolsWithTokens, PoolsWithTokens } from '../core/pools/pools';
import { ERROR_SERVICE, serviceLocator } from '../core/services';
import { Pool, Token } from '../types';
import { AddContext, RemoveContext } from './helpers';

/**
 * This type is used internally for fetching the base state.
 *
 * @remarks
 * This type contains a map of pools, a map of unique tokens used by the pools
 * and a map of best pools for each token.
 */
type FetchResult = PoolsWithTokens & { poolsByToken: Map<string, Pool>; };

// ---------------
// machine context
// ---------------

export type Context = {
    /**
     * Pools are keyed by market key
     */
    pools: Map<string, Pool>;
    /**
     * Tokens are keyed by token address
     */
    tokens: Map<string, Token>;
    /**
     * Pools are keyed by token address
     */
    poolsByToken: Map<string, Pool>;
    /**
     * An array of tokens from active pools sorted by their respective pool APR
     */
    activeTokensByAPR: Token[];
    /**
     * An array of active pools sorted by their respective pool APR
     */
    activePoolsByAPR: Pool[];
    /**
     * The selected token is the token address
     */
    selectedToken?: string;
    /**
     * The selected pools are an array of pool keys
     */
    selectedPools?: string[];
    /**
     * The selected pool is the pool key
     */
    selectedPool?: string;
    error?: string;
};

const initialContext: Context = {
    pools: new Map(),
    tokens: new Map(),
    poolsByToken: new Map(),
    activeTokensByAPR: [],
    activePoolsByAPR: [],
    selectedToken: undefined,
    selectedPools: undefined,
    selectedPool: undefined,
    error: undefined,
};

// ---------------
// machine state
// ---------------

export const enum STATES {
    INITIAL = 'initial',
    FETCHING = 'fetching',
    SELECT_TOKEN = 'selectToken',
    SELECT_POOL = 'selectPool',
    COMPLETE = 'complete',
    ERROR = 'error',
}

export type State =
    {
        value: STATES.INITIAL;
        context: Context;
    }
    | {
        value: STATES.FETCHING;
        context: Context;
    }
    | {
        value: STATES.SELECT_TOKEN;
        context: Context;
    }
    | {
        value: STATES.SELECT_POOL;
        context: Context & AddContext<Context, 'selectedToken' | 'selectedPools'>;
    }
    | {
        value: STATES.COMPLETE;
        context: Context
        & AddContext<Context, 'selectedToken' | 'selectedPools' | 'selectedPool'>
        & RemoveContext<Context, 'error'>;
    }
    | {
        value: STATES.ERROR;
        context: Context & AddContext<Context, 'error'>;
    };

// ----------------------
// machine model & events
// ----------------------

export const enum EVENTS {
    INIT = 'POOL.INIT',
    FETCH = 'POOL.FETCH',
    SET_TOKEN = 'POOL.SET_TOKEN',
    SET_POOL = 'POOL.SET_POOL',
    // internal events from services
    FETCH_SUCCESS = 'done.invoke.fetch',
    FETCH_FAILURE = 'error.platform.fetch',
}

export const model = createModel(
    initialContext,
    {
        events: {
            [EVENTS.FETCH]: () => ({}),
            [EVENTS.SET_TOKEN]: (payload: string | undefined) => ({ payload }),
            [EVENTS.SET_POOL]: (payload: string | undefined) => ({ payload }),
        },
    },
);

export type Event = EventFrom<typeof model>;

// ---------------
// machine helpers
// ---------------

/**
 * Filters a map of pools by an underlying token and returns the filtered market keys.
 *
 * @param pools - a map of pools keyed by `marketKey`
 * @param underlying - an underlying address
 * @returns an array of non-matured market keys whose underlying is the specified underlying
 */
const filterSelectedPools = (pools: Map<string, Pool>, underlying: string): string[] => {

    return [...pools.entries()]
        .filter(([key, pool]) => !matured(pool) && isStrategyValid(pool) && pool.underlying === underlying)
        .map(([key, pool]) => key);
};

/**
 * Creates an array of all active tokens (tokens from non-matured pools) sorted by their respective best-pool APR.
 *
 * @remarks
 * This array is used to generate the token list view for the Pool page.
 * We want to view a list of active tokens sorted by the highest available APR within the pools belonging to each token.
 * Tokens without available pools should remain in the view, sorted to the bottom and marked as unavailable.
 *
 * @param pools - a map of all pools keyed by `marketKey`
 * @param tokens - a map of all tokens keyed by token address
 * @param poolsByToken - a map of the best pools per token keyed by token address
 */
const sortTokensByAPR = (pools: Map<string, Pool>, tokens: Map<string, Token>, poolsByToken: Map<string, Pool>): Token[] => {

    // filter all non-matured, valid pools
    const activePools = [...pools.values()].filter(pool => !matured(pool) && isStrategyValid(pool));

    // get all tokens from non-matured pools
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const activeTokens = activePools.map(pool => tokens.get(pool.underlying)!);

    // get all tokens from non-matured pools sorted by pool APR
    // NOTE: we merge the tokens from the sorted pools into a Set
    // with all active tokens - duplicates are ignored and we
    // preserve the intial sorting by pool APR
    const activeTokensByAPR = Array.from(new Set(
        [...poolsByToken.keys()]
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            .map(key => tokens.get(key)!)
            .concat(...activeTokens),
    ));

    return activeTokensByAPR;
};

/**
 * Creates an array of all active and selected pools sorted by their respective APR.
 *
 * @remarks
 * This array is used to generate the maturity list view for the Pool page.
 * We want to view a list of active pools for a selected token sorted by the highest available APR.
 * Pools without available quotes should remain in the view, sorted to the bottom and marked as unavailable.
 *
 * @param pools - a map of all pools keyed by `marketKey`
 * @param selectedPools - an array of selected `marketKey`s
 */
const sortPoolsByAPR = (pools: Map<string, Pool>, selectedPools: string[]): Pool[] => {

    // get all selected and non-matured pools sorted by quote APR
    const activePoolsByAPR = selectedPools
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .map(key => pools.get(key)!)
        .sort(sortByAPR);

    return activePoolsByAPR;
};

// ---------------
// machine actions
// ---------------

export const enum ACTIONS {
    SET_TOKEN = 'SET_TOKEN',
    SET_POOL = 'SET_POOL',
    FETCH_SUCCESS = 'FETCH_SUCCESS',
    FETCH_FAILURE = 'FETCH_FAILURE',
}

const actions = {
    [ACTIONS.SET_TOKEN]: model.assign(
        (context, event) => {

            const selectedToken = event.payload
                // don't allow selecting non-existent tokens
                ? context.tokens.has(event.payload)
                    ? event.payload
                    : context.selectedToken
                : undefined;

            // filter the pools which match the selected token
            const selectedPools = selectedToken
                ? filterSelectedPools(context.pools, selectedToken)
                : undefined;

            // generate the active pool by APR list
            const activePoolsByAPR = sortPoolsByAPR(context.pools, selectedPools ?? []);

            return {
                ...context,
                activePoolsByAPR,
                selectedToken,
                selectedPools,
                selectedPool: undefined,
            };
        },
        EVENTS.SET_TOKEN,
    ),
    [ACTIONS.SET_POOL]: model.assign(
        (context, event) => {

            const selectedPool = event.payload
                // don't allow selecting non-existent markets
                ? context.pools.has(event.payload)
                    ? event.payload
                    : context.selectedPool
                : undefined;

            return {
                ...context,
                selectedPool,
            };
        },
        EVENTS.SET_POOL,
    ),
    [ACTIONS.FETCH_SUCCESS]: model.assign(
        (context, event) => {

            const result = (event as DoneInvokeEvent<FetchResult>).data;

            const activeTokensByAPR = sortTokensByAPR(result.pools, result.tokens, result.poolsByToken);

            // preserve the selected token, if it exists in the result
            const selectedToken = context.selectedToken && result.tokens.has(context.selectedToken)
                ? context.selectedToken
                : [...result.tokens.keys()][0];

            // filter the markets which match the selected token
            const selectedPools = filterSelectedPools(result.pools, selectedToken);

            return {
                ...context,
                ...result,
                activeTokensByAPR,
                selectedToken,
                selectedPools,
                selectedPool: undefined,
                error: undefined,
            };
        },
    ),
    [ACTIONS.FETCH_FAILURE]: model.assign(
        (context, event) => ({
            ...context,
            pools: new Map(),
            tokens: new Map(),
            poolsByToken: new Map(),
            activeTokensByAPR: [],
            activePoolsByAPR: [],
            selectedToken: undefined,
            selectedPools: undefined,
            selectedPool: undefined,
            error: (event as DoneInvokeEvent<Error>).data.message,
        }),
    ),
};

// -------------------
// machine transitions
// -------------------

const enum TRANSITIONS {
    FETCH = 'FETCH',
    SET_TOKEN = 'SET_TOKEN',
    SET_POOL = 'SET_POOL',
}

const transitions: Record<TRANSITIONS, TransitionsConfig<Context, Event>> = {
    [TRANSITIONS.FETCH]: {
        [EVENTS.FETCH]: {
            target: STATES.FETCHING,
        },
    },
    [TRANSITIONS.SET_TOKEN]: {
        [EVENTS.SET_TOKEN]: {
            actions: ACTIONS.SET_TOKEN,
            target: STATES.SELECT_POOL,
        },
    },
    [TRANSITIONS.SET_POOL]: {
        [EVENTS.SET_POOL]: {
            actions: ACTIONS.SET_POOL,
            target: STATES.COMPLETE,
        },
    },
};

// ---------------
// machine config
// ---------------

export const options: Partial<MachineOptions<Context, Event>> = {
    services: {
        fetch: async (context): Promise<FetchResult> => {

            const errors = serviceLocator.get(ERROR_SERVICE);

            try {

                const poolsWithTokens = await fetchPoolsWithTokens();

                if (poolsWithTokens.pools.size === 0) {

                    throw errors.process(ERRORS.STATE.POOL.NO_POOLS);
                }

                const poolsByToken = bestPoolsByToken(poolsWithTokens.pools);

                return {
                    ...poolsWithTokens,
                    poolsByToken,
                };

            } catch (error) {

                throw errors.process(error, ERRORS.STATE.POOL.FETCH, false, true);
            }
        },
    },
    actions,
};

// ---------------
// machine
// ---------------

export const machine = createMachine<Context, Event, State>(
    {
        context: model.initialContext,
        initial: STATES.INITIAL,
        states: {
            [STATES.INITIAL]: {
                always: [
                    {
                        target: STATES.FETCHING,
                    },
                ],
            },
            [STATES.FETCHING]: {
                invoke: {
                    id: 'fetch',
                    src: 'fetch',
                    onDone: {
                        actions: ACTIONS.FETCH_SUCCESS,
                        target: STATES.SELECT_TOKEN,
                    },
                    onError: {
                        actions: ACTIONS.FETCH_FAILURE,
                        target: STATES.ERROR,
                    },
                },
            },
            [STATES.SELECT_TOKEN]: {
                on: {
                    ...transitions[TRANSITIONS.SET_TOKEN],
                    ...transitions[TRANSITIONS.FETCH],
                },
            },
            [STATES.SELECT_POOL]: {
                on: {
                    ...transitions[TRANSITIONS.SET_TOKEN],
                    ...transitions[TRANSITIONS.SET_POOL],
                    ...transitions[TRANSITIONS.FETCH],
                },
            },
            [STATES.COMPLETE]: {
                on: {
                    ...transitions[TRANSITIONS.FETCH],
                    ...transitions[TRANSITIONS.SET_TOKEN],
                    ...transitions[TRANSITIONS.SET_POOL],
                },
            },
            [STATES.ERROR]: {
                on: {
                    ...transitions[TRANSITIONS.FETCH],
                    ...transitions[TRANSITIONS.SET_TOKEN],
                    ...transitions[TRANSITIONS.SET_POOL],
                },
            },
        },
    },
    options,
);
