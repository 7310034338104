import { TransactionResponse } from '@ethersproject/abstract-provider';
import { ETHStrategyRouter, Strategy, StrategyRouter } from '@swivel-finance/illuminate-js';
import { PayableOverrides, ethers } from 'ethers';
import { Pool } from '../../../../types';
import { emptyOrZero } from '../../../amount';
import { ERRORS } from '../../../constants';
import { ENV } from '../../../env';
import { isETHMarket } from '../../../markets';
import type { AddLiquidityPreview, RemoveLiquidityPreview } from '../../../pools';
import { ErrorService } from '../../errors';
import { LogService } from '../../logger';
import { TokenService } from '../../token';
import type { Connection, WalletService } from '../../wallet';
import { StrategyInfo, StrategyService, StrategyState } from '../interfaces';

export class StrategyChainService implements StrategyService {

    protected wallet: WalletService;

    protected token: TokenService;

    protected errors: ErrorService;

    protected logger: LogService;

    constructor (wallet: WalletService, token: TokenService, errors: ErrorService, logger: LogService) {

        this.wallet = wallet;
        this.token = token;
        this.errors = errors;
        this.logger = logger.group('strategy-service');
    }

    async getInfo (strategy: string, connection?: Connection): Promise<StrategyInfo> {

        connection = connection ?? await this.wallet.connection();
        const contract = new Strategy(strategy, connection.signer);

        try {

            const [state, pool, base, fyToken, poolBalance, baseBalance, fyTokenBalance, maturity, totalSupply] = await Promise.all([
                contract.state(),
                contract.pool(),
                contract.base(),
                contract.fyToken(),
                contract.poolCached(),
                contract.baseCached(),
                contract.fyTokenCached(),
                contract.maturity(),
                contract.totalSupply(),
            ]);

            return {
                state,
                pool,
                base,
                fyToken,
                poolBalance,
                baseBalance,
                fyTokenBalance,
                maturity,
                totalSupply,
            };

        } catch (error) {

            throw this.errors.process(error, ERRORS.SERVICES.STRATEGY.INFO, false, true);
        }
    }

    async allowance (address: string, pool: Pool, connection?: Connection): Promise<string> {

        try {

            const spender = isETHMarket(pool) ? ENV.ethStrategyRouterAddress : ENV.strategyRouterAddress;

            connection = connection ?? await this.wallet.connection();

            const allowance = await this.token.allowance(address, connection.account.address, spender, connection);

            this.logger.log('allowance: ', allowance);

            return allowance;

        } catch (error) {

            throw this.errors.process(error, ERRORS.SERVICES.STRATEGY.ALLOWANCE, false, true);
        }
    }

    async approve (address: string, pool: Pool, connection?: Connection): Promise<TransactionResponse> {

        try {

            const spender = isETHMarket(pool) ? ENV.ethStrategyRouterAddress : ENV.strategyRouterAddress;

            connection = connection ?? await this.wallet.connection();

            const response = await this.token.approve(address, spender, ethers.constants.MaxUint256, connection);

            this.logger.log('approval: ', response);

            return response;

        } catch (error) {

            throw this.errors.process(error, ERRORS.SERVICES.STRATEGY.APPROVAL, false, true);
        }
    }

    async addLiquidity (
        pool: Pool,
        preview: AddLiquidityPreview,
        minRatio: string,
        maxRatio: string,
        connection?: Connection,
    ): Promise<TransactionResponse> {

        connection = connection ?? await this.wallet.connection();

        const isETHPool = isETHMarket(pool);

        const strategyContract = new Strategy(pool.strategyAddress, connection.signer);
        const routerContract = isETHPool
            ? new ETHStrategyRouter(ENV.ethStrategyRouterAddress, connection.signer)
            : new StrategyRouter(ENV.strategyRouterAddress, connection.signer);

        const overrides: PayableOverrides = isETHPool
            ? { value: preview.baseIn }
            : {};

        try {

            const state = await strategyContract.state();

            switch (state) {

                case StrategyState.INVESTED:

                    return emptyOrZero(preview.fyTokenIn)
                        ? await routerContract.mintWithUnderlying(
                            pool.strategyAddress,
                            preview.baseIn,
                            preview.fyTokenToBuy,
                            minRatio,
                            maxRatio,
                            overrides,
                        )
                        : await routerContract.mint(
                            pool.strategyAddress,
                            preview.baseIn,
                            preview.fyTokenIn,
                            minRatio,
                            maxRatio,
                            overrides,
                        );

                case StrategyState.DIVESTED:

                    // TODO: implement when needed
                    // we currently do not support adding liquidity to DIVESTED strategies via frontend
                    throw this.errors.process(ERRORS.SERVICES.STRATEGY.ADD_LIQUIDITY.INVALID_STATE);

                default:

                    throw this.errors.process(ERRORS.SERVICES.STRATEGY.ADD_LIQUIDITY.INVALID_STATE);
            }

        } catch (error) {

            // don't further process an INVALID_STATE error
            if (this.errors.is(error, ERRORS.SERVICES.STRATEGY.ADD_LIQUIDITY.INVALID_STATE)) throw error;

            throw this.errors.process(error, ERRORS.SERVICES.STRATEGY.ADD_LIQUIDITY.FAILURE, false, true);
        }
    }

    async removeLiquidity (
        pool: Pool,
        preview: RemoveLiquidityPreview,
        minRatio: string,
        maxRatio: string,
        connection?: Connection,
    ): Promise<TransactionResponse> {

        connection = connection ?? await this.wallet.connection();

        const isETHPool = isETHMarket(pool);

        const strategyContract = new Strategy(pool.strategyAddress, connection.signer);
        const routerContract = isETHPool
            ? new ETHStrategyRouter(ENV.ethStrategyRouterAddress, connection.signer)
            : new StrategyRouter(ENV.strategyRouterAddress, connection.signer);

        const state = await strategyContract.state();

        try {

            switch (state) {

                case StrategyState.INVESTED:

                    return preview.tradeToBase
                        ? await routerContract.burnForUnderlying(
                            pool.strategyAddress,
                            preview.strategyTokensBurned,
                            minRatio,
                            maxRatio,
                        )
                        : await routerContract.burn(
                            pool.strategyAddress,
                            preview.strategyTokensBurned,
                            minRatio,
                            maxRatio,
                        );

                case StrategyState.DIVESTED:

                    return await routerContract.burnDivested(pool.strategyAddress, preview.strategyTokensBurned);

                default:

                    throw this.errors.process(ERRORS.SERVICES.STRATEGY.REMOVE_LIQUIDITY.INVALID_STATE);
            }

        } catch (error) {

            // don't further process an INVALID_STATE error
            if (this.errors.is(error, ERRORS.SERVICES.STRATEGY.REMOVE_LIQUIDITY.INVALID_STATE)) throw error;

            throw this.errors.process(error, ERRORS.SERVICES.STRATEGY.REMOVE_LIQUIDITY.FAILURE, false, true);
        }
    }
}
