import { DoneInvokeEvent } from 'xstate';

/**
 * A helper type for empty contexts.
 */
export type EmptyContext<T> = {
    [K in keyof T]: undefined;
};

/**
 * A helper type to make optional context properties non-optional and non-undefined.
 */
export type AddContext<T, K extends keyof T> = {
    [P in K]-?: Exclude<T[P], undefined>;
};

/**
 * A helper type to make context properties optional and undefined.
 */
export type RemoveContext<T, K extends keyof T> = {
    [P in K]?: undefined;
};

/**
 * A type guard for {@link DoneInvokeEvent}s
 *
 * @param event - the event to test
 */
export function isDoneInvokeEvent<T = unknown> (event: unknown): event is DoneInvokeEvent<T> {

    return (event as DoneInvokeEvent<T>).type.startsWith('done.invoke.');
}
