import { BigNumber, ethers } from 'ethers';
import { divideSafe, fixed } from '../../../amount';
import { matured } from '../../../markets';
import { ErrorService } from '../../errors';
import { LogService } from '../../logger';
import { Connection, WalletService } from '../../wallet';
import { YieldSpaceInfo, YieldSpaceService } from '../interfaces';

const FEE_MULTIPLIER = '10000';

const BASE = '1000000000000000000';

const BIT_64 = Math.pow(2, 64);

const YIELDSPACE_POOL_ABI = [
    'function strategy() public view returns (address)',
    'function baseToken() public view returns (address)',
    'function baseDecimals() public view returns (uint256)',
    'function ts() public view returns (int128)',
    'function mu() public view returns (int128)',
    'function maturity() public view returns (uint32)',
    'function scaleFactor() public view returns (uint96)',
    'function totalSupply() public view returns (uint256)',
    'function getCache() external view returns (uint104, uint104, uint32, uint16)',
    'function getFYTokenBalance() public view returns (uint128)',
    'function getSharesBalance() external view returns (uint128)',
    'function getBaseBalance() external view returns (uint128)',
    'function getCurrentSharePrice() external view returns (uint256)',
    'function buyFYTokenPreview(uint128 fyTokenOut) external view returns (uint128)',
    'function sellFYTokenPreview(uint128 fyTokenIn) public view returns (uint128)',
    'function currentCumulativeRatio() external view returns (uint256, uint256)',
    'function maxFYTokenIn() public view returns (uint128)',
    'function maxFYTokenOut() public view returns (uint128)',
    'function maxBaseIn() public view returns (uint128)',
    'function maxBaseOut() public view returns (uint128)',
];

interface YieldSpacePoolContract {
    strategy (): Promise<string>;
    baseToken (): Promise<string>;
    baseDecimals (): Promise<BigNumber>;
    ts (): Promise<BigNumber>;
    mu (): Promise<BigNumber>;
    maturity (): Promise<number>;
    scaleFactor (): Promise<BigNumber>;
    totalSupply (): Promise<BigNumber>;
    getCache (): Promise<[BigNumber, BigNumber, number, number]>;
    getFYTokenBalance (): Promise<BigNumber>;
    getSharesBalance (): Promise<BigNumber>;
    getBaseBalance (): Promise<BigNumber>;
    getCurrentSharePrice (): Promise<BigNumber>;
    buyFYTokenPreview (fyTokenOut: BigNumber): Promise<BigNumber>;
    sellFYTokenPreview (fyTokenIn: BigNumber): Promise<BigNumber>;
    currentCumulativeRatio (): Promise<[BigNumber, BigNumber]>;
    maxFYTokenIn (): Promise<BigNumber>;
    maxFYTokenOut (): Promise<BigNumber>;
    maxBaseIn (): Promise<BigNumber>;
    maxBaseOut (): Promise<BigNumber>;
}

export class YieldSpaceChainService implements YieldSpaceService {

    protected wallet: WalletService;

    protected errors: ErrorService;

    protected logger: LogService;

    constructor (ethereum: WalletService, errors: ErrorService, logger: LogService) {

        this.wallet = ethereum;
        this.errors = errors;
        this.logger = logger.group('yieldspace-service');
    }

    async getInfo (pool: string, connection?: Connection): Promise<YieldSpaceInfo> {

        connection = connection ?? await this.wallet.connection();
        const contract = new ethers.Contract(pool, YIELDSPACE_POOL_ABI, connection.provider) as ethers.Contract & YieldSpacePoolContract;

        const [strategy, ts, mu, maturity, scaleFactor, balance, cache, supply, shPrice] = await Promise.all([
            contract.strategy(),
            contract.ts(),
            contract.mu(),
            contract.maturity(),
            contract.scaleFactor(),
            contract.getBaseBalance(),
            contract.getCache(),
            contract.totalSupply(),
            contract.getCurrentSharePrice(),
        ]);

        // post maturity, YieldMath calculations will generally throw as the pool can no longer trade assets
        // similarly, when a pool is drained and its total supply is 0, YieldMath will throw
        // fetching the pools max amounts on chain involve YieldMath calls, so we want to handle this
        // and set the max amounts to 0, which also ensures that our UI is blocking transactions
        const [baseIn, fyIn, fyOut] = await Promise.all(matured({ maturity: maturity.toString() }) || supply.isZero()
            ? [
                BigNumber.from(0),
                BigNumber.from(0),
                BigNumber.from(0),
            ]
            : [
                contract.maxBaseIn(),
                contract.maxFYTokenIn(),
                contract.maxFYTokenOut(),
            ]);

        const baseBalance = balance.toString();
        const sharesBalance = cache[0].toString();
        const fyTokenBalance = cache[1].toString();
        const totalSupply = supply.toString();
        const realFYTokenBalance = cache[1].sub(supply).toString();
        // normalize the sharesPrice using the scaleFactor
        const base = fixed(BASE).divUnsafe(fixed(scaleFactor.toString()));
        const sharesPrice = fixed(shPrice.toString()).divUnsafe(base).toString();
        const maxBaseIn = baseIn.toString();
        const maxFYTokenIn = fyIn.toString();
        const maxFYTokenOut = fyOut.toString();

        // the ratio of the pool is the shares balance divided by the fyToken balance (including virtual fyTokens)
        // we display the ratio in terms of underlying (base balance)
        // fyTokenBalance can reach 0 past maturity, so we use safe division
        const ratio = divideSafe(baseBalance, fyTokenBalance).toString();

        // calculate the g1 (buy FY token) and g2 (sell FY token) fee factors
        // see https://github.com/yieldprotocol/yieldspace-tv/blob/main/src/Pool/Pool.sol#L1337
        const g1 = fixed(cache[3]).divUnsafe(fixed(FEE_MULTIPLIER)).toString();
        const g2 = fixed(FEE_MULTIPLIER).divUnsafe(fixed(cache[3])).toString();

        // TODO: sharesPrice and c appear to be the same thing...
        // calculate c
        // see https://github.com/yieldprotocol/yieldspace-tv/blob/main/src/Pool/Pool.sol#L1408
        const c = fixed(shPrice.toString()).mulUnsafe(fixed(scaleFactor.toString())).divUnsafe(fixed(BASE)).toString();

        const result: YieldSpaceInfo = {
            strategy,
            baseBalance,
            sharesBalance,
            fyTokenBalance,
            realFYTokenBalance,
            totalSupply,
            sharesPrice,
            maxBaseIn,
            maxBaseOut: baseBalance,
            maxFYTokenIn,
            maxFYTokenOut,
            scaleFactor: scaleFactor.toString(),
            maturity: maturity.toString(),
            ratio,
            g1,
            g2,
            c,
            ts: fixed(ts.toString(), 27).divUnsafe(fixed(BIT_64, 27)).toString(),
            mu: fixed(mu.toString(), 27).divUnsafe(fixed(BIT_64, 27)).toString(),
        };

        this.logger.log('getInfo()... ', pool, result);

        return result;
    }

    async getStrategy (pool: string, connection?: Connection | undefined): Promise<string> {

        connection = connection ?? await this.wallet.connection();
        const contract = new ethers.Contract(pool, YIELDSPACE_POOL_ABI, connection.provider) as ethers.Contract & YieldSpacePoolContract;

        const result = await contract.strategy();

        this.logger.log('getStrategy()... ', pool, ' --> ', result);

        return result;
    }

    async buyFYTokenPreview (pool: string, amount: string, connection?: Connection): Promise<string> {

        connection = connection ?? await this.wallet.connection();
        const contract = new ethers.Contract(pool, YIELDSPACE_POOL_ABI, connection.provider) as ethers.Contract & YieldSpacePoolContract;

        const maturity = (await contract.maturity()).toString();

        // for matured pools the preview methods will fail
        // we know at maturity the fy token price is 1
        if (matured({ maturity })) return amount;

        try {

            const preview = (await contract.buyFYTokenPreview(BigNumber.from(amount))).toString();

            this.logger.log('buyFYTokenPreview()... ', pool, amount, preview);

            return preview;

        } catch (error) {

            throw this.errors.process(error);
        }
    }

    async sellFYTokenPreview (pool: string, amount: string, connection?: Connection): Promise<string> {

        connection = connection ?? await this.wallet.connection();
        const contract = new ethers.Contract(pool, YIELDSPACE_POOL_ABI, connection.provider) as ethers.Contract & YieldSpacePoolContract;

        const maturity = (await contract.maturity()).toString();

        // for matured pools the preview methods will fail
        // we know at maturity the fy token price is 1
        if (matured({ maturity })) return amount;

        try {

            const preview = (await contract.sellFYTokenPreview(BigNumber.from(amount))).toString();

            this.logger.log('sellFYTokenPreview()... ', pool, amount, preview);

            return preview;

        } catch (error) {

            throw this.errors.process(error);
        }
    }
}
