import { StrategyState, StrategyStateNames } from '@swivel-finance/illuminate-js';
import { BigNumber, FixedNumber } from 'ethers';
import { TypedObject } from '../../shared/helpers';
import { LendPosition, Market, PoolPosition, Position } from '../../types';
import { contractAmount, emptyOrZero, fixed, trim } from '../amount';
import { toUSD } from '../balance';
import { ARCHIVED_TIME, ERRORS, PRECISION, SECONDS_PER_YEAR } from '../constants';
import { ENV } from '../env';
import { matured } from '../markets';
import * as PoolMath from '../pools/pool-math';
import * as StrategyMath from '../pools/strategy-math';
import { ERROR_SERVICE, ILLUMINATE_SERVICE, LOG_SERVICE, serviceLocator } from '../services';
import { TIME_SERVICE } from '../services/time';

const BASE_18 = fixed('1000000000000000000');

/**
 * Calculate the APR for a lending position
 *
 * @remarks
 * The rate of an investment is defined as:
 * ```
 * rate = amountReturned / amountSpent - 1
 * ```
 * The price of the iPT is the amount of tokens spent per iPT received:
 * ```
 * price = amountSpent / amountReturned
 * ```
 * We can calculate the rate from the reciprocal of the price:
 * ```
 * (amountReturned / amountSpent) = 1 / (amountSpent / amountReturned) = 1 / price
 * rate = 1 / price - 1
 * ```
 *
 * @param p - the position
 * @param m - the market
 */
export const positionAPR = (p: LendPosition, m: Market): string => {

    // safeguard aganst invalid costBasis values
    const costBasis = parseFloat(p.costBasis);

    if (isNaN(costBasis) || costBasis === 0) return '0';

    // we can infer the rate from the cost basis (cost basis is the volume-weighted, average iPT price)
    const rate = 1 / parseFloat(p.costBasis) - 1;

    // we know the duration of a market
    const duration = (parseInt(m.maturity) - parseInt(m.created));

    // we can calculate the APR from the rate and duration
    const apr = rate * (SECONDS_PER_YEAR / duration);

    // there is no compounding happening, so the apr is equal to the apy in this case
    return apr.toFixed(PRECISION);
};

/**
 * Calculate the effective rate (APR) for an early position exit
 *
 * @remarks
 * The rate of an investment is defined as:
 * ```
 * rate = amountReturned / amountSpent - 1
 * ```
 * To calculate the `amountSpent`, we can use the position's current `costBasis`:
 * ```
 * price = amountSpent / amountReturned
 * amountSpent = price * amountReturned
 * ```
 *
 * @param a - amount of iPT to sell
 * @param r - amount of underlying returned
 * @param p - position
 * @param m - market
 */
export const effectiveAPR = (a: string, r: string, p: LendPosition, m: Market): string => {

    const timeService = serviceLocator.get(TIME_SERVICE);

    // safeguard aganst invalid costBasis values
    const costBasis = parseFloat(p.costBasis);

    if (isNaN(costBasis) || costBasis === 0) return '0';

    // the average price for the iPTs purchased
    const price = fixed(costBasis);
    // the amount of underlying spent on purchasing the iPTs we are now selling
    const spent = fixed(a).mulUnsafe(price);

    // the rate based on the amount of underlying returned per underlying spent
    const rate = parseFloat(fixed(r).divUnsafe(spent).subUnsafe(fixed('1')).toString());

    // the duration of the investment until now
    const duration = (Math.round(timeService.time() / 1000) - parseInt(m.created));

    // we can calculate the APR from the rate and duration
    const apr = rate * (SECONDS_PER_YEAR / duration);

    // there is no compounding happening, so the apr is equal to the apy in this case
    return apr.toFixed(PRECISION);
};

/**
 * Calculate the cost of exiting a position early
 *
 * @remarks
 * iPTs are redeemed 1-1 at maturity. The early exit cost is the difference in yield
 * with the current iPT price vs the the yield at maturity with a price of 1.
 *
 * @param a - amount of iPT to sell
 * @param r - amount of underlying returned
 */
export const earlyExitCost = (a: string, r: string): string => {

    const cost = BigNumber.from(a).sub(r);

    return cost.toString();
};

/**
 * Calculates the current value of a position in underlying
 *
 * @remarks
 * The value of a position depends on the position type, the maturity
 *
 * @param position - position to check
 */
export const positionValue = async (position: Position): Promise<string> => {

    if (isLendPosition(position)) {

        return await lendPositionCurrentValue(position);

    } else {

        return poolPositionCurrentValue(position);
    }
};

/**
 * Get the current value of a lend position.
 *
 * @param position - the lend position to get the current value for
 * @returns the amount of underlying received if all iPTs were to be redeemed now
 */
const lendPositionCurrentValue = async (position: LendPosition): Promise<string> => {

    const illuminate = serviceLocator.get(ILLUMINATE_SERVICE);
    const errors = serviceLocator.get(ERROR_SERVICE);
    const logger = serviceLocator.get(LOG_SERVICE).group('lendPositionCurrentValue()...');

    let currentPositionValue = '';

    try {

        // establish the current position value based on the position's maturity
        currentPositionValue = matured(position)
            // fetch the current positions value by preview-redeeming
            ? await illuminate.redeemer.redeemPreview(position, position.iptBalance)
            // fetch the current position value by preview-selling to the pool
            : await illuminate.marketPlace.sellPrincipalTokenPreview(
                position.underlying,
                position.maturity,
                position.iptBalance,
            );

    } catch (error) {

        // `sellPrincipalTokenPreview` may fail if there is insufficient liquidity in the pool
        // or if time to maturity is too big or too small - this is expected and we don't
        // consider it an error, instead we simply return an empty string

        logger.warn('unable to retrieve current position value for position: ', position);

        // ignore reporting the error
        errors.process(error, undefined, true);
    }

    return currentPositionValue;
};

/**
 * Get the current value of a pool position.
 *
 * @param position - the pool position to get the current value for
 * @returns the amount of underlying received if all strategy tokens were to be redeemed now
 */
const poolPositionCurrentValue = (position: PoolPosition): string => {

    const errors = serviceLocator.get(ERROR_SERVICE);
    const logger = serviceLocator.get(LOG_SERVICE).group('poolPositionCurrentValue()...');

    const yieldspace = position.info.yieldSpaceInfo;
    const strategy = position.info.strategyInfo;
    const strategyBalance = position.info.strategyBalance;

    // a position can be considered exited if it is marked as exited
    // or its strategy's pool is different from the position's pool
    // (the last case only applies to INVESTED strategies, all other states have zero-pool-addresses)
    const isExited = position.exited
        || strategy.state === StrategyState.INVESTED && strategy.pool !== position.pool.address;

    if (isExited) {

        return '0';
    }

    let lpBalance: FixedNumber;
    let baseOut: FixedNumber;
    let iPTOut: FixedNumber;
    let currentPositionValue = '';

    switch (position.info.strategyInfo.state) {

        // this state should only occurr for matured positions
        case StrategyState.DIVESTED:

            currentPositionValue = trim(StrategyMath.baseForStrategyTokens(
                strategyBalance.balance,
                strategy.baseBalance,
                strategy.totalSupply,
            ).floor().toString());

            break;

        // this case should mostly occurr for active positions, but can occurr for matured positions during a short window
        case StrategyState.INVESTED:

            lpBalance = StrategyMath.lpForStrategyTokens(strategyBalance.balance, strategy.poolBalance, strategy.totalSupply);

            if (matured(position.pool)) {

                // if the position's pool is already matured we cannot sell fy tokens,
                // instead they would need to be redeemed 1:1 for underlying
                // (the strategy will do this when divesting)
                [baseOut, iPTOut] = PoolMath.previewBurn(
                    lpBalance,
                    yieldspace.fyTokenBalance,
                    yieldspace.sharesBalance,
                    yieldspace.totalSupply,
                    yieldspace.sharesPrice,
                );

                // iPTs are now worth 1 underlying and we can simply add them to the base returned
                currentPositionValue = trim(baseOut.addUnsafe(iPTOut).floor().toString());

            } else {

                try {

                    currentPositionValue = trim(PoolMath.previewBurnForUnderlying(
                        lpBalance,
                        yieldspace.fyTokenBalance,
                        yieldspace.sharesBalance,
                        yieldspace.totalSupply,
                        yieldspace.sharesPrice,
                        yieldspace,
                    ).floor().toString());

                } catch (error) {

                    try {

                        const base = BASE_18.divUnsafe(fixed(yieldspace.scaleFactor)).divUnsafe(fixed(100));

                        currentPositionValue = trim(PoolMath.previewBurnForUnderlying(
                            base,
                            yieldspace.fyTokenBalance,
                            yieldspace.sharesBalance,
                            yieldspace.totalSupply,
                            yieldspace.sharesPrice,
                            yieldspace,
                        ).mulUnsafe(lpBalance.divUnsafe(base)).floor().toString());

                    } catch (error) {

                        errors.process(error, ERRORS.SERVICES.POSITION.CURRENT_POSITION_VALUE);
                    }
                }
            }

            break;

        default:

            // nothing we can do in these states...
            // we can only `burn` in `INVESTED` state and `burnDivested` in `DIVESTED` state
            logger.warn(
                `unable to retrieve current position value for position with strategy state '${ StrategyStateNames[strategy.state] }': `,
                position,
            );

            break;
    }

    return currentPositionValue;
};

/**
 * Checks if a position is a {@link LendPosition}
 *
 * @param position - the position to check
 */
export const isLendPosition = (position: Position): position is LendPosition => TypedObject.hasOwn(position as LendPosition, 'costBasis');

/**
 * Checks if a position is a {@link PoolPosition}
 *
 * @param position - the position to check
 */
export const isPoolPosition = (position: Position): position is PoolPosition => !isLendPosition(position);

/**
 * Get the redeemed threshold amount based on the USD price of the position's underlying asset
 *
 * @remarks
 * The redeemed threshold is used to mark iPT balances as dust if they are below the threshold. This
 * is reasonable considering that gas costs might be much higher than the redemption value, and it
 * allows us to mark positions as redeemed even if there are minimal amounts of dust left.
 * For stable coins, we can assume a fixed threshold, as their value translates 1:1 to USD, meaning
 * any threshold would be comparable to a value in USD.
 * For non stable coins, a value of 0.5 iPT could be worth hundreds of USD, thus the threshold is no
 * longer comparable. To fix this, we define the threshold in USD and consider the USD price of a
 * position's asset when calculating the threshold.
 *
 * @param price - the price of the position's underlying in USD
 */
export const REDEEMED_THRESHOLD = (price: string): string => {

    // we take the USD threshold amount from the ENV config
    const threshold = ENV.redeemedThreshold;

    return emptyOrZero(price)
        // if we don't have a USD price, we cannot calculate a threshold and any assumption on value would be unsafe
        // we return a threshold of '0' in this case, meaning any remaining balance in a position will mean "not redeemed"
        ? '0'
        // usd amount = ipt amount * price
        // ipt amount = usd amount / price
        : fixed(threshold).divUnsafe(fixed(price)).toString();
};

/**
 * Checks if a position is redeemable
 *
 * @remarks
 * A position is considered redeemable, when its iPT or strT balance is above the `ENV.redeemedThreshold`.
 *
 * @param position - the position to check
 * @param usdPrice - the price of the position's underlying in USD (for non stable coins)
 */
export const redeemable = (position: Position, usdPrice: string): boolean => {

    let balance: FixedNumber;

    const threshold = fixed(REDEEMED_THRESHOLD(usdPrice));

    if (isLendPosition(position)) {

        // for lend positions, getting the current position value might fail, as it requires on-chain reads
        // so we use the user's ipt balance as fallback
        balance = fixed(contractAmount(position.currentPositionValue || position.iptBalance, position.market.token)).subUnsafe(threshold);

    } else {

        // for pool positions, the current position value is calculated off-chain, but depending on the
        // strategy's state, it might not be available (e.g. DRAINED or EJECTED strategies)
        if (position.currentPositionValue) {

            balance = fixed(contractAmount(position.currentPositionValue, position.pool.token)).subUnsafe(threshold);

        } else {

            // if we don't have a current position value, we just look if the user has strategy tokens
            balance = fixed(contractAmount(position.info.strategyBalance.balance, position.info.strategyBalance));
        }
    }

    return (!balance.isNegative() && !balance.isZero());
};

/**
 * Checks if a position is redeemed
 *
 * @remarks
 * A position is considered redeemed, when it is matured and its iPT or LPT balance is below the `ENV.redeemedThreshold`.
 * We also consider pool positions redeemed, if their `exited` flag is true, or their current strategy is DIVESTED and
 * their strategy balance is 0.
 *
 * @param position - the position to check
 * @param usdPrice - the price of the position's underlying in USD (for non stable coins)
 */
export const redeemed = (position: Position, usdPrice: string): boolean => {

    const isExited = isPoolPosition(position) && position.exited;
    const isDivested = isPoolPosition(position) && position.info.strategyInfo.state === StrategyState.DIVESTED;
    const isMatured = matured(position);

    return isExited || (isDivested || isMatured) && !redeemable(position, usdPrice);
};

/**
 * Checks if a position is archived
 *
 * @remarks
 * A position is considered archived, when it is redeemed and older than the archival time threshold.
 * We also archive pool positions, which are still active, but have been exited - in such a case, the
 * strategy was most likely EJECTED and subsequently DIVESTED, allowing the user to exit it.
 *
 * @param position - the position to check
 * @param usdPrice - the price of the position's underlying in USD (for non stable coins)
 */
export const archived = (position: Position, usdPrice: string): boolean => {

    const timeService = serviceLocator.get(TIME_SERVICE);

    const now = Math.floor(timeService.time() / 1000);
    const maturity = parseInt(position.maturity);

    const isExited = !matured(position) && isPoolPosition(position) && position.exited;

    return isExited || redeemed(position, usdPrice) && (now - maturity >= ARCHIVED_TIME);
};

/**
 * A sort function to sort positions by value (in USD) in descending order
 *
 * @remarks
 * Positions have a `currentPositionValue` which represents their value in underlying if redeemed now.
 * This value is created during position parsing, but can be missing in some cases (blockchain read issues).
 * We use a fallback value that is 'somewhat' comparable if we don't have the current position value:
 *  - for lend positions we can use the ipt balance as it is equivalent to the underlying at maturity
 *  - for pool positions we only have the strategy balance which is only better than nothing
 *
 * With regards to the token used for determining the USD value of the position we can always use the
 * underlying token, as both ipt and strategy token have the same number of decimals as the underlying.
 *
 * Once we have established the position value in underlying, we convert it to USD to make it comparable.
 *
 * @param a - a position
 * @param b - a position to compare to
 */
export const sortByPositionValue = (a: Position, b: Position): number => {

    const valueA = a.currentPositionValue || (isLendPosition(a) ? a.iptBalance : a.info.strategyBalance.balance);
    const tokenA = isLendPosition(a) ? a.market.token : a.pool.token;
    const usdA = toUSD(valueA, tokenA);

    const valueB = b.currentPositionValue || (isLendPosition(b) ? b.iptBalance : b.info.strategyBalance.balance);
    const tokenB = isLendPosition(b) ? b.market.token : b.pool.token;
    const usdB = toUSD(valueB, tokenB);

    return parseFloat(usdB) - parseFloat(usdA);
};

/**
 * A sort function to sort positions by maturity in descending order
 *
 * @param a - a position
 * @param b - a position to compare to
 */
export const sortByPositionMaturity = (a: Position, b: Position): number => {

    const maturityA = parseInt(a.maturity);
    const maturityB = parseInt(b.maturity);

    // sort positions by value in descending order
    return maturityB - maturityA;
};
