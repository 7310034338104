import { html, nothing } from 'lit';
import { customElement } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { AddLiquidityTransaction, ADD_LIQUIDITY_STATUS } from '../../core/pools';
import { lpT } from '../../core/services/token';
import { date, errorMessage, loadingBar, tokenBalance } from '../../shared/templates';
import { PoolTransactionBaseElement } from './pool-transaction-base';

const template = function (this: AddLiquidityTransactionStatusElement) {

    if (!this.transaction) return;

    const isPending = this.transaction?.status === ADD_LIQUIDITY_STATUS.PENDING || this.transaction?.status === ADD_LIQUIDITY_STATUS.APPROVING;
    const isFetching = this.transaction.status === ADD_LIQUIDITY_STATUS.UPDATING;
    const hasError = this.transaction?.status === ADD_LIQUIDITY_STATUS.ERROR || this.transaction?.status === ADD_LIQUIDITY_STATUS.FAILURE;

    if (!isFetching && !isPending && !hasError) return nothing;

    const { pool, preview } = this.transaction.state;

    if (!pool || !preview) return nothing;

    const lpToken = lpT(pool);

    return html`
    <div class=${ classMap({ widget: true, fetching: isFetching, pending: isPending, error: hasError }) }>

        <div class="widget-header">
            <label>
                <ill-token-symbol .name=${ pool?.token.image } .ipt=${ true }></ill-token-symbol>
                ${ date(pool?.maturity) }
            </label>
        </div>

        <div class="widget-main">

            ${ hasError
                ? errorMessage(this.transaction.error?.message ?? 'An unknown error occurred.', 'exclamation')
                : isFetching
                    ? html`
                    <div class="preview">
                        ${ loadingBar('Updating pool information...') }
                    </div>
                    `
                    : html`
                    <div class="preview">
                        <div class="preview-item preview-item-big">
                            <span class="label">Adding Liquidity</span>
                            ${ tokenBalance(preview.lpTokensMinted, lpToken) }
                        </div>
                        ${ loadingBar('Transaction pending...') }
                    </div>
                    `
            }

        </div>
    </div>
    `;
};

@customElement('ill-add-liquidity-transaction-status')
export class AddLiquidityTransactionStatusElement extends PoolTransactionBaseElement<AddLiquidityTransaction> {

    protected createRenderRoot (): Element | ShadowRoot {

        return this;
    }

    protected render (): unknown {

        return template.apply(this);
    }
}
