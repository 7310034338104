import { createMachine, DoneInvokeEvent, EventFrom, MachineOptions, TransitionsConfig } from 'xstate';
import { createModel } from 'xstate/lib/model';
import { emptyOrZero } from '../core/amount';
import { fetchTokenPrices } from '../core/balance';
import { ERRORS } from '../core/constants';
import { marketKey, matured } from '../core/markets';
import { archived, fetchPositions, positionValue, redeemable, sortByPositionMaturity, sortByPositionValue } from '../core/positions';
import { ERROR_SERVICE, LOG_SERVICE, serviceLocator } from '../core/services';
import { LendPosition, Market, Pool, PoolPosition, Token, VirtualLendPosition } from '../types';
import { AddContext, RemoveContext } from './helpers';

const errors = serviceLocator.get(ERROR_SERVICE);
const logger = serviceLocator.get(LOG_SERVICE).group('positions state');

/**
 * This type is used internally for fetching the base state.
 *
 * @remarks
 * This type contains the user's positions as well as a record of USD prices for non stable coins, keyed by token symbol.
 */
type FetchResult = {
    lendPositions: Map<string, LendPosition>;
    poolPositions: Map<string, PoolPosition>;
    virtualPositions: Map<string, VirtualLendPosition>;
    prices: Map<string, string>;
};

// ---------------
// machine context
// ---------------

export type Context = {
    /**
     * The user's account address
     */
    account: string;
    /**
     * Markets are keyed by market key
     */
    markets: Map<string, Market>;
    /**
     * Pools are keyed by market key
     */
    pools: Map<string, Pool>;
    /**
     * Tokens are keyed by token address
     */
    tokens: Map<string, Token>;
    /**
     * Token prices in USD, keyed by token symbol
     */
    prices: Map<string, string>;
    /**
     * The user's virtual lending/iPT positions accross different markets, keyed by market key
     */
    virtualPositions: Map<string, VirtualLendPosition>;
    /**
     * The user's lending positions accross different markets, keyed by market key
     */
    lendPositions: Map<string, LendPosition>;
    /**
     * The user's active lending positions accross different markets
     */
    activeLendPositions: LendPosition[];
    /**
     * The user's mature lending positions accross different markets
     */
    maturedLendPositions: LendPosition[];
    /**
     * The user's archived lending positions accross different markets
     */
    archivedLendPositions: LendPosition[];
    /**
     * The user's pooling positions accross different markets, keyed by market key
     */
    poolPositions: Map<string, PoolPosition>;
    /**
     * The user's active pooling positions accross different markets
     */
    activePoolPositions: PoolPosition[];
    /**
     * The user's mature pooling positions accross different markets
     */
    maturedPoolPositions: PoolPosition[];
    /**
     * The user's archived pooling positions accross different markets
     */
    archivedPoolPositions: PoolPosition[];
    /**
     * An error message
     */
    error?: string;
};

const initialContext: Partial<Context> = {
    account: undefined,
    markets: undefined,
    pools: undefined,
    tokens: undefined,
    prices: undefined,
    virtualPositions: undefined,
    lendPositions: undefined,
    activeLendPositions: undefined,
    maturedLendPositions: undefined,
    archivedLendPositions: undefined,
    poolPositions: undefined,
    activePoolPositions: undefined,
    maturedPoolPositions: undefined,
    archivedPoolPositions: undefined,
    error: undefined,
};

// ---------------
// machine state
// ---------------

export const enum STATES {
    INITIAL = 'initial',
    FETCHING = 'fetching',
    SUCCESS = 'success',
    ERROR = 'error',
}

type AllPositions =
    'virtualPositions'
    | 'lendPositions'
    | 'activeLendPositions'
    | 'maturedLendPositions'
    | 'archivedLendPositions'
    | 'poolPositions'
    | 'activePoolPositions'
    | 'maturedPoolPositions'
    | 'archivedPoolPositions';

export type State =
    {
        value: STATES.INITIAL;
        context: Context & RemoveContext<Context, 'account' | 'markets' | 'pools' | 'tokens' | 'prices' | AllPositions>;
    }
    | {
        value: STATES.FETCHING;
        context: Context & RemoveContext<Context, 'prices' | AllPositions>;
    }
    | {
        value: STATES.SUCCESS;
        context: Context;
    }
    | {
        value: STATES.ERROR;
        context: Partial<Context> & AddContext<Context, 'error'>;
    };

// ----------------------
// machine model & events
// ----------------------

export const enum EVENTS {
    INIT = 'POSITIONS.INIT',
    FETCH = 'POSITIONS.FETCH',
    // internal events from services
    FETCH_SUCCESS = 'done.invoke.fetch',
    FETCH_FAILURE = 'error.platform.fetch',
}

export const model = createModel(
    initialContext,
    {
        events: {
            [EVENTS.INIT]: (payload: { account: string; markets: Map<string, Market>; pools: Map<string, Pool>; tokens: Map<string, Token>; }) => ({ payload }),
            [EVENTS.FETCH]: () => ({}),
        },
    },
);

export type Event = EventFrom<typeof model>;

// ---------------
// machine actions
// ---------------

export const enum ACTIONS {
    INIT = 'INIT',
    FETCH_SUCCESS = 'FETCH_SUCCESS',
    FETCH_FAILURE = 'FETCH_FAILURE',
}

const actions = {
    [ACTIONS.INIT]: model.assign(
        (context, event) => ({
            ...model.initialContext,
            ...event.payload,
        }),
        EVENTS.INIT,
    ),
    [ACTIONS.FETCH_SUCCESS]: model.assign(
        (context, event) => {

            const { lendPositions, poolPositions, virtualPositions, prices } = (event as unknown as DoneInvokeEvent<FetchResult>).data;

            const activeLendPositions = [] as LendPosition[];
            const maturedLendPositions = [] as LendPosition[];
            const archivedLendPositions = [] as LendPosition[];

            const activePoolPositions = [] as PoolPosition[];
            const maturedPoolPositions = [] as PoolPosition[];
            const archivedPoolPositions = [] as PoolPosition[];

            lendPositions.forEach(position => {

                archived(position, prices.get(position.market.token.symbol) ?? '')
                    ? archivedLendPositions.push(position)
                    : matured(position)
                        ? maturedLendPositions.push(position)
                        : activeLendPositions.push(position);
            });

            poolPositions.forEach(position => {

                archived(position, prices.get(position.pool.token.symbol) ?? '')
                    ? archivedPoolPositions.push(position)
                    : matured(position)
                        ? maturedPoolPositions.push(position)
                        : activePoolPositions.push(position);
            });

            // we add virtual positions to the lend position lists
            virtualPositions.forEach(position => {

                // we can ignore the archived state: virtual positions have an unredeemed iPT balance
                matured(position)
                    ? maturedLendPositions.push(position)
                    : activeLendPositions.push(position);
            });

            // sort all positions according to their state

            activeLendPositions.sort(sortByPositionValue);
            maturedLendPositions.sort(sortByPositionValue);
            archivedLendPositions.sort(sortByPositionMaturity);

            activePoolPositions.sort(sortByPositionValue);
            maturedPoolPositions.sort(sortByPositionValue);
            archivedPoolPositions.sort(sortByPositionMaturity);

            logger.log('FETCH_SUCCESS... positions: ', {
                virtual: virtualPositions,
                lend: lendPositions,
                pool: poolPositions,
            });

            return {
                ...context,
                prices,
                virtualPositions,
                lendPositions,
                activeLendPositions,
                maturedLendPositions,
                archivedLendPositions,
                poolPositions,
                activePoolPositions,
                maturedPoolPositions,
                archivedPoolPositions,
                error: undefined,
            };
        },
    ),
    [ACTIONS.FETCH_FAILURE]: model.assign(
        (context, event) => ({
            ...context,
            prices: undefined,
            virtualPositions: undefined,
            lendPositions: undefined,
            activeLendPositions: undefined,
            maturedLendPositions: undefined,
            archivedLendPositions: undefined,
            poolPositions: undefined,
            activePoolPositions: undefined,
            maturedPoolPositions: undefined,
            archivedPoolPositions: undefined,
            error: (event as unknown as DoneInvokeEvent<Error>).data.message,
        }),
    ),
};

// -------------------
// machine transitions
// -------------------

const enum TRANSITIONS {
    INIT = 'INIT',
    FETCH = 'FETCH',
}

const transitions: Record<TRANSITIONS, TransitionsConfig<Partial<Context>, Event>> = {
    [TRANSITIONS.INIT]: {
        [EVENTS.INIT]: {
            actions: ACTIONS.INIT,
            target: STATES.FETCHING,
        },
    },
    [TRANSITIONS.FETCH]: {
        [EVENTS.FETCH]: {
            target: STATES.FETCHING,
        },
    },
};

// ---------------
// machine config
// ---------------

export const options: Partial<MachineOptions<Partial<Context>, Event>> = {
    services: {
        fetch: async (context): Promise<FetchResult> => {

            try {

                const { account, markets, pools } = context as Context;

                const [positions, tokenPrices] = await Promise.all([
                    fetchPositions(account, markets, pools),
                    fetchTokenPrices(),
                ]);

                if (positions.lending.length === 0 && positions.pooling.length === 0) {

                    throw errors.process(ERRORS.STATE.POSITIONS.NO_POSITIONS);
                }

                const prices = new Map(Object.entries(tokenPrices));

                const virtualPositions = new Map<string, VirtualLendPosition>();
                const lendPositions = new Map(positions.lending.map(position => [marketKey(position), position]));
                const poolPositions = new Map(positions.pooling.map(position => [marketKey(position), position]));

                // check if we need to create a virtual lend position
                poolPositions.forEach((position, key) => {

                    // if we have a lend position for the pool's market we're good and iPTs from that pool can be redeemed...
                    if (!lendPositions.has(key)) {

                        // get the user's iPT balance for the pool
                        const iptBalance = position.info.iPTBalance.balance;

                        const hasBalance = !emptyOrZero(iptBalance);

                        // if we have a balance and no lend position, we create a virtual lend position to enable redemption
                        if (hasBalance) {

                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            const market = markets.get(key)!;
                            const { underlying, maturity } = market;

                            const virtualPosition: VirtualLendPosition = {
                                virtual: true,
                                market,
                                maturity,
                                underlying,
                                iptBalance,
                                // for virtual lend positions we don't have most of the remaining data
                                currentPositionValue: '',
                                interestEarned: '',
                                totalReturned: '',
                                totalSpent: '',
                                lastPrice: '',
                                costBasis: '',
                            };

                            const isRedeemable = redeemable(virtualPosition, prices.get(market.token.symbol) ?? '');

                            // if the iPT balance of the virtual position is above the REDEEMED_THRESHOLD we store it
                            if (isRedeemable) {

                                virtualPositions.set(key, virtualPosition);
                            }
                        }
                    }
                });

                // fetch the current position value for the virtual positions
                const virtualPositionValues = await Promise.all(
                    [...virtualPositions.values()].map(position => positionValue(position)),
                );

                // store the current position values in the virtual positions
                [...virtualPositions.values()].forEach((position, index) => {

                    position.currentPositionValue = virtualPositionValues[index];
                });

                virtualPositions.size && logger.log(
                    'virtual positions created: ',
                    virtualPositions,
                );

                return {
                    lendPositions,
                    poolPositions,
                    virtualPositions,
                    prices,
                };

            } catch (error) {

                // don't further process a NO_POSITIONS error
                if (errors.is(error, ERRORS.STATE.POSITIONS.NO_POSITIONS)) throw error;

                throw errors.process(error, ERRORS.STATE.POSITIONS.FETCH, false, true);
            }
        },
    },
    actions,
};

// ---------------
// machine
// ---------------

export const machine = createMachine<Partial<Context>, Event, State>(
    {
        context: model.initialContext,
        initial: STATES.INITIAL,
        states: {
            [STATES.INITIAL]: {
                on: {
                    ...transitions[TRANSITIONS.INIT],
                },
            },
            [STATES.FETCHING]: {
                invoke: {
                    id: 'fetch',
                    src: 'fetch',
                    onDone: {
                        actions: ACTIONS.FETCH_SUCCESS,
                        target: STATES.SUCCESS,
                    },
                    onError: {
                        actions: ACTIONS.FETCH_FAILURE,
                        target: STATES.ERROR,
                    },
                },
            },
            [STATES.SUCCESS]: {
                on: {
                    ...transitions[TRANSITIONS.INIT],
                    ...transitions[TRANSITIONS.FETCH],
                },
            },
            [STATES.ERROR]: {
                on: {
                    ...transitions[TRANSITIONS.INIT],
                    ...transitions[TRANSITIONS.FETCH],
                },
            },
        },
    },
    options,
);
