import { html } from 'lit';
import type { AppElement } from '../app';

export const lendTemplate = function (this: AppElement) {

    return html`
    <main class="lend">
        <div class="column">
            <ill-lend-page></ill-lend-page>
        </div>
    </main>
    `;
};
