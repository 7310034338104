import { FocusChangeEvent, FocusMonitor } from '@swivel-finance/ui/behaviors/focus';
import { EventManager } from '@swivel-finance/ui/utils/events';
import { html, LitElement, nothing, PropertyValues, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { status } from '../../shared/templates';
import { notifications } from './notification-service';
import { Notification, NotificationOptions, NotificationType } from './types';

const notificationTemplate = function (this: NotificationElement) {

    const content = (typeof this.content === 'function')
        ? this.content()
        : this.content ?? nothing;

    return html`
    <span class="notification-icon">
        ${ this.type === 'progress'
            ? status('loading')
            : nothing
        }
        ${ this.icon
            ? html`<ui-icon name=${ this.icon }></ui-icon>`
            : nothing
        }
    </span>
    <span class="notification-content">${ content }</span>
    <span class="notification-buttons">
        ${ this.dismissable
            ? html`<button class="dismiss" aria-label="dismiss" @click=${ () => this.dismiss() }><ui-icon name="times"></ui-icon></button>`
            : nothing
        }
    </span>
    `;
};

@customElement('ill-notification')
export class NotificationElement extends LitElement implements Notification {

    protected eventManager = new EventManager();

    protected focusMonitor = new FocusMonitor();

    protected dismissTimeout: number | undefined;

    @property({
        attribute: true,
        reflect: true,
        type: String,
    })
    type!: NotificationType;

    @property({
        attribute: true,
        reflect: true,
        type: String,
    })
    icon: string | undefined;

    @property({
        attribute: true,
        reflect: true,
        type: Number,
    })
    timeout!: number;

    @property({
        attribute: true,
        reflect: true,
        type: Boolean,
    })
    dismissable!: boolean;

    @property({
        attribute: false,
    })
    content: string | (() => TemplateResult) | undefined;

    refresh (n: NotificationOptions): void {

        Object.entries(n).forEach(([key, value]) => {

            if (value !== undefined && this[key as keyof this] !== value as unknown) {

                this[key as keyof this] = value as unknown as typeof this[keyof this];
            }
        });

        this.requestUpdate();
    }

    dismiss (): void {

        if (this.dismissable) {

            notifications.dismiss(this.id);
        }
    }

    updated (changedProperties: PropertyValues<NotificationElement>): void {

        if (changedProperties.has('timeout') || changedProperties.has('dismissable')) {

            this.clearTimeout();

            this.setTimeout();
        }
    }

    connectedCallback (): void {

        super.connectedCallback();

        this.focusMonitor.attach(this);

        // don't dismiss notifications when they are being hovered or focused
        this.eventManager.listen(this, 'mouseover', () => this.handleActive());
        this.eventManager.listen(this, 'mouseout', () => this.handleInactive());
        this.eventManager.listen(this, 'ui-focus-changed', ((event: FocusChangeEvent) => this.handleFocusChange(event)) as EventListener);
    }

    disconnectedCallback (): void {

        this.focusMonitor.detach();

        this.eventManager.unlistenAll();

        this.clearTimeout();

        super.disconnectedCallback();
    }

    protected createRenderRoot (): Element | ShadowRoot {

        return this;
    }

    protected render (): unknown {

        return notificationTemplate.apply(this);
    }

    protected handleFocusChange (event: FocusChangeEvent): void {

        event.detail.hasFocus
            ? this.handleActive()
            : this.handleInactive();
    }

    protected handleActive (): void {

        this.clearTimeout();
    }

    protected handleInactive (): void {

        this.setTimeout();
    }

    protected clearTimeout (): void {

        if (this.dismissTimeout) {

            window.clearTimeout(this.dismissTimeout);

            this.dismissTimeout = undefined;
        }
    }

    protected setTimeout (): void {

        if (this.dismissable && this.timeout) {

            this.dismissTimeout = window.setTimeout(() => this.dismiss(), this.timeout * 1000);
        }
    }
}
