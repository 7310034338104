import { IDGenerator } from '@swivel-finance/ui/utils/dom';
import { dispatch } from '@swivel-finance/ui/utils/events';
import { html, LitElement, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { createRef, ref } from 'lit/directives/ref.js';
import { QUOTE_PREVIEW_AMOUNT, QUOTE_PREVIEW_AMOUNT_ETHER } from '../../core/constants';
import { isETHMarket, marketKey, MATURITY_DATE_FORMAT } from '../../core/markets';
import { fetchQuotesByMarket, sortByAPR } from '../../core/quotes';
import { amount, date, principal, rate, status, tokenSymbol } from '../../shared/templates';
import { MARKET, orchestrator } from '../../state/orchestrator';
import { Market, Quote } from '../../types';

const idGenerator = new IDGenerator('rate-overview-');

const template = function (this: RateOverviewElement): unknown {

    const state = this.marketMachine.state;

    const hasError = state.matches(MARKET.STATES.ERROR);
    const isFetching = state.matches(MARKET.STATES.FETCHING) || state.matches(MARKET.STATES.FETCHING_QUOTES);

    const market = this.markets[this.current];

    return hasError
        ? nothing
        : html`
        <div class="container" ${ ref(this.containerRef) }>
            <h3>
                Top Rates
                <ui-icon name="question" aria-labelledby="${ this.id + '-tooltip' }"></ui-icon>
                <ui-tooltip id="${ this.id + '-tooltip' }">
                    The rates shown are quotes for lending ${ market ? amount(this.previewAmount, market.token.symbol) : '' }
                    to each respective protocol.
                </ui-tooltip>
            </h3>
            ${
                isFetching || !market
                    ? status('loading')
                    : html`
                    <div class="${ this.classNames.market }">
                        <ill-token-symbol .name=${ market.token.image }></ill-token-symbol>
                        ${ tokenSymbol(market.token) }
                        ${ date(market.maturity, MATURITY_DATE_FORMAT.LONG) }
                    </div>
                    <ul class="${ this.classNames.ratelist }">
                        ${ market.quotes.map(quote => html`
                        <li class="${ this.classNames.rateitem }">
                            <span class="label">${ principal(quote.principal) }</span>
                            <span class="value">${ rate(quote.apr ?? 0, '% APR', false) }</span>
                        </li>
                        `) }
                    </ul>
                    `
            }
        </div>
        `;
};

@customElement('ill-rate-overview')
export class RateOverviewElement extends LitElement {

    protected marketMachine = orchestrator.market;

    @property({
        attribute: true,
        reflect: true,
    })
    id = idGenerator.getNext();

    @property({ attribute: false })
    previewAmount = QUOTE_PREVIEW_AMOUNT;

    @property({ attribute: false })
    previewAmountEther = QUOTE_PREVIEW_AMOUNT_ETHER;

    @property({ attribute: false })
    maxItems = 4;

    @state()
    protected markets: (Market & { quotes: Quote[]; })[] = [];

    @state()
    protected current = 0;

    @state()
    protected interval = 7500;

    protected timeout?: number;

    protected containerRef = createRef<HTMLElement>();

    protected classNames = {
        best: 'best',
        market: 'market',
        ratelist: 'ratelist',
        rateitem: 'rateitem',
        visible: 'visible',
    };

    constructor () {

        super();

        this.handleMarketTransition = this.handleMarketTransition.bind(this);
    }

    connectedCallback (): void {

        super.connectedCallback();

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.marketMachine.onTransition(this.handleMarketTransition);
    }

    disconnectedCallback (): void {

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.marketMachine.off(this.handleMarketTransition);

        this.cancelUpdateCurrent();

        super.disconnectedCallback();
    }

    protected createRenderRoot (): Element | ShadowRoot {

        return this;
    }

    protected render (): unknown {

        return template.apply(this);
    }

    protected handleMarketTransition (): void {

        void this.updateQuotes();

        this.requestUpdate();
    }

    protected dispatchQuoteChange (): void {

        const quote = this.markets[this.current]?.quotes[0];

        if (!quote) return;

        dispatch(this, 'quote-change', quote);
    }

    protected async updateQuotes (): Promise<void> {

        const state = this.marketMachine.state;

        if (state.matches(MARKET.STATES.SELECT_TOKEN)) {

            this.markets = await this.updateQuotesForTokens();

        } else if (state.matches(MARKET.STATES.SELECT_MARKET)) {

            this.markets = await this.updateQuotesForMarkets();

        } else {

            this.markets = [];
        }

        this.current = 0;

        await this.updateComplete;

        void this.updateCurrent(0);

        if (this.markets.length > 1) {

            this.scheduleUpdateCurrent();

        } else {

            this.cancelUpdateCurrent();
        }
    }

    protected scheduleUpdateCurrent (): void {

        this.cancelUpdateCurrent();

        this.timeout = window.setTimeout(() => {

            const current = this.markets.length
                ? (this.current + 1) % this.markets.length
                : 0;

            void this.updateCurrent(current);

            this.scheduleUpdateCurrent();

        }, this.interval);
    }

    protected cancelUpdateCurrent (): void {

        if (this.timeout) window.clearTimeout(this.timeout);
    }

    protected async updateCurrent (current: number): Promise<void> {

        await this.animateOut();

        this.current = current;

        await this.updateComplete;

        await this.animateIn();

        this.dispatchQuoteChange();
    }

    protected async animateOut (): Promise<void> {

        this.containerRef.value?.classList.remove(this.classNames.visible);

        await this.animationsDone();

        const bestRate = this.querySelector(`.${ this.classNames.best }`);

        bestRate?.classList.remove(this.classNames.best);
    }

    protected async animateIn (): Promise<void> {

        this.containerRef.value?.classList.add(this.classNames.visible);

        await this.animationsDone();

        const bestRate = this.querySelector(`.${ this.classNames.rateitem }`);

        bestRate?.classList.add(this.classNames.best);
    }

    protected async animationsDone (): Promise<void> {

        await Promise.allSettled(
            this.containerRef.value?.getAnimations({ subtree: true })
                .map((animation) => animation.finished) ?? [],
        );
    }

    protected async updateQuotesForTokens (): Promise<(Market & { quotes: Quote[]; })[]> {

        const state = this.marketMachine.state;

        if (!state.matches(MARKET.STATES.SELECT_TOKEN)) return [];

        const context = state.context;

        return await Promise.all(
            [...context.quotesByToken.values()].map(async (quote) => {

                // get the market for this quote
                const key = marketKey({ underlying: quote.underlying.address, maturity: quote.maturity });

                // shallow copy and re-type the market so we can store the quotes inside
                const market = { ...context.markets.get(key) } as (Market & { quotes: Quote[]; });

                const previewAmount = isETHMarket(market)
                    ? this.previewAmountEther
                    : this.previewAmount;

                // fetch all quotes for this market
                const quotes = await fetchQuotesByMarket(market, market.token, previewAmount);

                // sort and store the quotes in the market
                market.quotes = quotes.sort(sortByAPR).slice(0, this.maxItems);

                return market;
            }),
        );
    }

    protected async updateQuotesForMarkets (): Promise<(Market & { quotes: Quote[]; })[]> {

        const state = this.marketMachine.state;

        if (!state.matches(MARKET.STATES.SELECT_MARKET)) return [];

        const context = state.context;

        return await Promise.all(
            context.selectedMarkets
                // some of the active markets might be paused or otherwise unavailable
                .filter(key => context.quotesByMarket.has(key))
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                .map(key => context.quotesByMarket.get(key)!)
                .map(async (quote) => {

                    // get the market for this quote
                    const key = marketKey({ underlying: quote.underlying.address, maturity: quote.maturity });

                    // shallow copy and re-type the market so we can store the quotes inside
                    const market = { ...context.markets.get(key) } as (Market & { quotes: Quote[]; });

                    const previewAmount = isETHMarket(market)
                        ? this.previewAmountEther
                        : this.previewAmount;

                    // fetch all quotes for this market
                    const quotes = await fetchQuotesByMarket(market, market.token, previewAmount);

                    // sort and store the quotes in the market
                    market.quotes = quotes.sort(sortByAPR).slice(0, this.maxItems);

                    return market;
                }),
        );
    }
}
