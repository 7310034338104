import {
    BugsnagConsentConfig,
    BugsnagService,
    ConsentBanner,
    ConsentCategory,
    ConsentElement,
    ConsentGroup,
    ConsentService,
    ConsentStatus,
    ConsentTopics,
    GoogleAnalyticsConsentConfig,
    GoogleAnalyticsService,
    StorageConsentConfig,
    Topics,
} from '@swivel-finance/consent';
import '@swivel-finance/consent/dist/consent/components/consent-banner';
import '@swivel-finance/consent/dist/consent/components/consent-element';
import { OverlayBackdrop } from '@swivel-finance/ui/behaviors/overlay';
import { ENV } from '../core/env';
import { COOKIE_POLICY_ROUTE, PRIVACY_POLICY_ROUTE } from '../routes';

/**
 * The owner of this consent service
 */
const OWNER = 'Illuminate';

/**
 * The actual consent topics used by illuminate (we don't use fullstory)
 */
export const ILLUMINATE_CONSENT_TOPICS = [
    'storage',
    'google_analytics',
    'bugsnag',
] as const;

export type IlluminateConsentTopics = Topics<typeof ILLUMINATE_CONSENT_TOPICS>;

let consentBanner: ConsentBanner<IlluminateConsentTopics> | undefined;
let consentElement: ConsentElement<IlluminateConsentTopics> | undefined;

// create consent groups for the required services
const groups: ConsentGroup<IlluminateConsentTopics>[] = [
    {
        category: ConsentCategory.PREFERENCES,
        description: 'Allow us to store your preferences on your device to improve your experience. Stored preferences include your cookie consent choices, your recent transactions, your preferred user interface theme, etc.',
        configs: [
            { ...StorageConsentConfig, provider: OWNER },
        ],
    },
    {
        category: ConsentCategory.STATISTICS,
        description: 'Allow us to collect statistics data to improve our service. The collected information helps us understand how visitors interact with our service, and whether there may be technical issues.',
        configs: [
            BugsnagConsentConfig,
            GoogleAnalyticsConsentConfig,
        ],
    },
];

// create consent service from groups
const consent = new ConsentService(ILLUMINATE_CONSENT_TOPICS, groups);

// create service instances and connect them to the consent service instance
const bugsnag = new BugsnagService(consent as ConsentService<ConsentTopics>, ENV.bugsnag);
const analytics = new GoogleAnalyticsService(consent as ConsentService<ConsentTopics>, ENV.analytics);

// use the backdrop service from @swivel-finance/ui
const backdrop = new OverlayBackdrop();

// show the consent banner
export const showConsentBanner = () => {

    consentBanner = document.createElement('sw-consent-banner') as ConsentBanner<IlluminateConsentTopics>;

    consentBanner.consentService = consent;
    consentBanner.owner = OWNER;
    consentBanner.cookiePolicyUrl = COOKIE_POLICY_ROUTE.url;

    document.body.append(consentBanner);

    consentBanner.addEventListener('consent-settings', showConsentElement, { once: true });
};

// show the consent manager
export const showConsentElement = () => {

    if (consentElement) return;

    // if there's a consent banner on the page, dismiss it when opening the consent manager
    if (consentBanner) consentBanner.dismiss();

    consentElement = document.createElement('sw-consent') as ConsentElement<IlluminateConsentTopics>;

    consentElement.consentService = consent;
    consentElement.owner = OWNER;
    consentElement.cookiePolicyUrl = COOKIE_POLICY_ROUTE.url;
    consentElement.privacyPolicyUrl = PRIVACY_POLICY_ROUTE.url;

    document.body.append(consentElement);

    // show backdrop behind the consentElement
    void backdrop.show(consentElement);

    // hide the consent element when any choice was made
    consentElement.addEventListener('consent-changed', () => {

        // hide backdrop behind the consentElement
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        void backdrop.hide(consentElement!);

        consentElement?.remove();
        consentElement = undefined;

    }, { once: true });
};

// check if consent was processed
export const checkConsent = () => {

    const consentProcessed = consent.read('storage') !== ConsentStatus.UNSET;

    // if no consent has been processed/stored, show the consent element
    if (!consentProcessed) {

        showConsentBanner();
    }
};

export { consent, bugsnag, analytics };
