import { TransactionResponse } from '@ethersproject/abstract-provider';
import { Principals, Redeemer } from '@swivel-finance/illuminate-js';
import { ethers } from 'ethers';
import { LendPosition } from '../../../../types';
import { divideSafe, fixed, trim } from '../../../amount';
import { ERRORS } from '../../../constants';
import { ENV } from '../../../env';
import { ErrorService } from '../../errors';
import { LogService } from '../../logger';
import { iPT, TokenService } from '../../token';
import { Connection, WalletService } from '../../wallet';
import { IlluminateRedeemerService } from '../interfaces';

export class ChainIlluminateRedeemerService implements IlluminateRedeemerService {

    protected wallet: WalletService;

    protected token: TokenService;

    protected logger: LogService;

    protected errors: ErrorService;

    constructor (wallet: WalletService, token: TokenService, errors: ErrorService, logger: LogService) {

        this.wallet = wallet;
        this.token = token;
        this.errors = errors;
        this.logger = logger.group('illuminate-redeemer');
    }

    async allowance (address: string, connection?: Connection): Promise<string> {

        try {

            connection = connection ?? await this.wallet.connection();

            const allowance = await this.token.allowance(address, connection.account.address, ENV.redeemerAddress, connection);

            this.logger.log('allowance: ', allowance);

            return allowance;

        } catch (error) {

            throw this.errors.isProcessed(error)
                ? error
                : this.errors.process(error, ERRORS.SERVICES.ILLUMINATE.ALLOWANCE);
        }
    }

    async approve (address: string, connection?: Connection): Promise<ethers.providers.TransactionResponse> {

        try {

            connection = connection ?? await this.wallet.connection();

            const response = await this.token.approve(address, ENV.redeemerAddress, ethers.constants.MaxUint256, connection);

            this.logger.log('approval: ', response);

            return response;

        } catch (error) {

            throw this.errors.isProcessed(error)
                ? error
                : this.errors.process(error, ERRORS.SERVICES.ILLUMINATE.APPROVAL);
        }
    }

    async paused (underlying: string, maturity: string, connection?: Connection): Promise<boolean> {

        connection = connection ?? await this.wallet.connection();
        const contract = new Redeemer(ENV.redeemerAddress, connection.provider);

        try {

            return await contract.paused(underlying, maturity);

        } catch (error) {

            throw this.errors.process(error);
        }
    }

    async redeemPreview (position: LendPosition, amount?: string, connection?: Connection): Promise<string> {

        connection = connection ?? await this.wallet.connection();
        const contract = new Redeemer(ENV.redeemerAddress, connection.signer);

        try {

            const ipt = iPT(position.market);

            const [holdings, balance, totalSupply] = await Promise.all([
                contract.holdings(position.underlying, position.maturity),
                this.token.fetchBalance(ipt.address, connection.account.address, connection),
                this.token.fetchTotalSupply(ipt.address, connection),
            ]);

            // the redeemer contract will always redeem the user's entire iPT balance
            // and NOT the `totalReturned` as calculated by the backend
            amount = amount ?? balance;

            // https://github.com/Swivel-Finance/illuminate/blob/main/src/Redeemer.sol#L520
            // totalSupply can reach 0 past maturity, so we use safe division
            const redeemed = trim(divideSafe(fixed(amount).mulUnsafe(fixed(holdings)), totalSupply).floor().toString());

            this.logger.log('redeemPreview: ', redeemed);

            return redeemed;

        } catch (error) {

            throw this.errors.isProcessed(error)
                ? error
                : this.errors.process(error, ERRORS.SERVICES.ILLUMINATE.REDEEM_PREVIEW);
        }
    }

    async redeem (position: LendPosition, connection?: Connection): Promise<TransactionResponse> {

        connection = connection ?? await this.wallet.connection();
        const contract = new Redeemer(ENV.redeemerAddress, connection.signer);

        try {

            const response = await contract.redeem(Principals.Illuminate, position.underlying, position.maturity);

            this.logger.log('redeem: ', response);

            return response;

        } catch (error) {

            const processedError = this.errors.process(error);

            // TODO: make this somehow more ergonomic...
            const detail = processedError.message;

            const override = detail
                ? this.errors.updateMessage(ERRORS.SERVICES.ILLUMINATE.REDEEM, [[/\.$/, `: ${ detail }`]])
                : ERRORS.SERVICES.ILLUMINATE.REDEEM;

            throw this.errors.process(error, override);
        }
    }
}
