import { DATE_FORMAT, formatDate } from '../../../../shared/helpers';
import { Market, Pool, Principals, Token } from '../../../../types';

const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';

/**
 * Get the illuminate principal token (iPT) for a market or pool
 *
 * @param source - the market or pool for which to get the iPT
 */
export const iPT = (source?: Market | Pool): Token => {

    const address = (source as Market)?.principals?.[Principals.Illuminate] ?? (source as Pool)?.pt ?? ZERO_ADDRESS;
    const underlying = source?.token;
    const maturity = source?.maturity;

    const name = ['Illuminate Principal Token', underlying?.symbol, maturity && formatDate(maturity, DATE_FORMAT.SHORT)].join(' ').trim();
    const decimals = source?.token?.decimals ?? 18;

    return {
        address,
        decimals,
        name,
        symbol: 'iPT',
        image: 'ipt',
    };
};

/**
 * Checks if a token is an iPT
 *
 * @param token - the token to check
 */
export const isIPT = (token: Token): boolean => token.symbol === 'iPT';
