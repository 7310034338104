import { Pool, Token } from '../../types';
import { marketKey } from '../markets/helpers';
import { serviceLocator } from '../services';
import { API_SERVICE } from '../services/api';
import { TOKEN_SERVICE } from '../services/token';
import { Connection } from '../services/wallet';

/**
 * An interface for the result of a {@link fetchPoolsWithTokens} call.
 *
 * @remarks
 * Markets are keyed by their market key (see {@link marketKey} for details),
 * tokens are keyed by their token address.
 */
export interface PoolsWithTokens {
    pools: Map<string, Pool>;
    tokens: Map<string, Token>;
}

/**
 * Fetch pools and their related token info.
 *
 * @remarks
 * This method fetches pools via the API and their token information via the chain.
 * It then combines the information into {@link Pool} objects and keys pools by
 * market key and tokens by token address.
 *
 * @param connection - optional {@link EthereumConnection}
 * @returns a {@link PoolsWithTokens}
 */
export const fetchPoolsWithTokens = async (connection?: Connection): Promise<PoolsWithTokens> => {

    const api = serviceLocator.get(API_SERVICE);
    const token = serviceLocator.get(TOKEN_SERVICE);
    // const yieldspace = serviceLocator.get(YIELDSPACE_SERVICE);

    const tokenMap = new Map<string, Token>();
    const poolMap = new Map<string, Pool>();

    // fetch pools from api
    const pools = (await api.getPools()).map(pool => ({
        ...pool,
        apr: parseFloat(pool.apr) || 0,
    }));

    // fetch token for each unique underlying address from chain
    const tokens = await Promise.all(
        [...new Set(pools.map(pool => pool.underlying))]
            .map(address => token.fetch(address, connection)),
    );

    // populate the token map, keyed by the token address
    tokens.forEach(token => tokenMap.set(token.address, token));

    // populate the market map, keyed by the market key
    pools.forEach(pool => poolMap.set(marketKey(pool), {
        ...pool,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        token: tokenMap.get(pool.underlying)!,
    }));

    return {
        pools: poolMap,
        tokens: tokenMap,
    };
};
