/**
 * The event names of the available messages received via Illuminate's web socket.
 */
export const ILLUMINATE_EVENTS = {
    CREATE_MARKET: 'MARKET.POOL.SET',
    STRATEGY_INVEST: 'STRATEGY.INVEST',
    STRATEGY_DIVEST: 'STRATEGY.DIVEST',
    STRATEGY_DRAIN: 'STRATEGY.DRAIN',
    STRATEGY_EJECT: 'STRATEGY.EJECT',
    PAUSE_MARKET: 'MARKET.REDEMPTIONS.PAUSE',
    PAUSE_POOL: 'MARKET.POOL.PAUSE',
    PAUSE_ILLUMINATE: 'ILLUMINATE.PAUSE',
    LEND_POSITION_LEND: 'LENDING.CREATE',
    LEND_POSITION_EXIT: 'SWAPPING.CREATE',
    LEND_POSITION_REDEEM: 'REDEEMING.CREATE',
    POOL_POSITION_ADD: 'USER.TOKENS.MINT',
    POOL_POSITION_REMOVE: 'USER.TOKENS.BURN',
    PONG: 'PONG',
} as const;
