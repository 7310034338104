import { html, nothing, TemplateResult } from 'lit';
import { TOOLTIPS } from '../../core/constants';
import { marketKey, matured } from '../../core/markets';
import { archived, redeemable, redeemed } from '../../core/positions';
import { StrategyState } from '../../core/services/strategy';
import { POSITION } from '../../state/orchestrator';
import { LendPosition, PoolPosition } from '../../types';
import { LendPositionTooltips, PoolPositionTooltips, PositionStatus } from './types';

export const lendPositionStatus = (positionState: Partial<POSITION.Context>, position: LendPosition, isLenderPaused = false): PositionStatus => {

    const market = position.market;
    const pool = positionState.pools?.get(marketKey(market));
    const price = positionState.prices?.get(market.token.symbol) ?? '';

    const isMarketPaused = market.paused;
    const isPoolPaused = pool?.paused ?? false;
    const isMatured = matured(position);
    const isRedeemed = redeemed(position, price);
    const isArchived = archived(position, price);
    const isRedeemable = redeemable(position, price);
    const isExited = isMatured && isRedeemed;
    const isVirtual = position.virtual === true;
    const isDivesting = false;
    const isDivested = false;

    const canEnter = !isMatured && !isLenderPaused;
    const canExit = !isMatured && !isPoolPaused && isRedeemable || isMatured && !isRedeemed && !isMarketPaused;

    const isPaused = isLenderPaused && !isMatured || isMarketPaused && isMatured && !isRedeemed || isPoolPaused && !isMatured;

    const pausedTooltip = isPaused
        ? html`
        ${ isLenderPaused && !isMatured ? html`<p>${ TOOLTIPS.PAUSED.LENDER() }</p>` : nothing }
        ${ isMarketPaused && isMatured && !isRedeemed ? html`<p>${ TOOLTIPS.PAUSED.MARKET() }</p>` : nothing }
        ${ isPoolPaused && !isMatured ? html`<p>${ TOOLTIPS.PAUSED.EARLY_EXIT() }</p>` : nothing }
        <p>${ TOOLTIPS.PAUSED.MORE_INFO() }</p>
        `
        : undefined;

    const pausedTooltipId = `lend-position-paused-${ marketKey(market) }-tooltip`;

    return {
        isArchived,
        isRedeemed,
        isMatured,
        isPaused,
        isVirtual,
        isExited,
        isDivesting,
        isDivested,
        canEnter,
        canExit,
        pausedTooltip,
        pausedTooltipId,
    };
};

export const lendPositionTooltips = (position: LendPosition): LendPositionTooltips => {

    const currentValue = position.currentPositionValue;
    const maturity = position.maturity;
    const token = position.market.token;
    const id = `${ token.symbol }-${ maturity }`;

    return {
        enter: {
            id: `position-lend-enter-${ id }-tooltip`,
            content: TOOLTIPS.POSITION.LEND.LEND(token),
        },
        exit: {
            id: `position-lend-exit-${ id }-tooltip`,
            content: matured(position)
                ? TOOLTIPS.POSITION.LEND.REDEEM(token)
                : TOOLTIPS.POSITION.LEND.EXIT(),
        },
        valueAtMaturity: {
            id: `position-lend-value-at-maturity-${ id }-tooltip`,
            content: TOOLTIPS.POSITION.LEND.VALUE_AT_MATURITY(token),
        },
        currentValue: {
            id: `position-lend-current-value-${ id }-tooltip`,
            content: TOOLTIPS.POSITION.LEND.CURRENT_VALUE(token, currentValue),
        },
        redemptionValue: {
            id: `position-lend-redemption-value-${ id }-tooltip`,
            content: TOOLTIPS.POSITION.LEND.REDEMPTION_VALUE(token),
        },
        interestEarned: {
            id: `position-lend-interest-earned-${ id }-tooltip`,
            content: TOOLTIPS.POSITION.LEND.INTEREST_EARNED(),
        },
        effectiveRate: {
            id: `position-lend-effective-rate-${ id }-tooltip`,
            content: TOOLTIPS.POSITION.LEND.EFFECTIVE_RATE(),
        },
    };
};

export const lendPositionActions = (
    position: LendPosition,
    status: PositionStatus,
    tooltips: LendPositionTooltips,
    lend: (position: LendPosition) => void,
    exit: (position: LendPosition) => void,
): TemplateResult | typeof nothing => {

    return !status.isRedeemed && (status.canExit || status.canEnter)
        ? html`
        <div class="position-actions">
            ${ status.canExit
                ? html`
                <button class="${ status.isMatured ? 'primary' : '' }"
                    aria-describedby="${ tooltips.exit.id }"
                    @click=${ () => exit(position) }>
                    Exit
                </button>
                `
                : nothing
            }
            ${ status.canEnter
                ? html`
                <button class="primary"
                    aria-describedby="${ tooltips.enter.id }"
                    @click=${ () => lend(position) }>
                    Lend
                </button>
                `
                : nothing
            }
        </div>
        `
        : nothing;
};

export const poolPositionStatus = (positionState: Partial<POSITION.Context>, position: PoolPosition): PositionStatus => {

    const pool = position.pool;
    const price = positionState.prices?.get(pool.token.symbol) ?? '';
    const strategy = position.info.strategyInfo;

    // we consider the pool paused, if it's paused or its stretegy is neither INVESTED nor DIVESTED
    const isPoolPaused = pool?.paused || (strategy.state !== StrategyState.INVESTED && strategy.state !== StrategyState.DIVESTED) || false;
    const isMatured = matured(position);
    const isRedeemed = redeemed(position, price);
    const isArchived = archived(position, price);
    const isExited = position.exited === true;
    const isVirtual = false;
    const isDivesting = isMatured && !isRedeemed && !isPoolPaused && strategy.state === StrategyState.INVESTED;
    const isDivested = strategy.state === StrategyState.DIVESTED;

    const canEnter = !isMatured && !isPoolPaused && !isDivested;
    const canExit = !isRedeemed && !isPoolPaused && !isDivesting;

    const isPaused = isPoolPaused && !isRedeemed;

    const pausedTooltip = isPaused
        ? html`
        <p>${ TOOLTIPS.PAUSED.POOL() }</p>
        <p>${ TOOLTIPS.PAUSED.MORE_INFO() }</p>
        `
        : isDivesting
            ? html`<p>${ TOOLTIPS.POSITION.POOL.DIVESTING() }</p>`
            : undefined;

    const pausedTooltipId = `pool-position-paused-${ marketKey(pool) }-tooltip`;

    return {
        isArchived,
        isRedeemed,
        isMatured,
        isPaused,
        isVirtual,
        isExited,
        isDivesting,
        isDivested,
        canEnter,
        canExit,
        pausedTooltip,
        pausedTooltipId,
    };
};

export const poolPositionTooltips = (position: PoolPosition): PoolPositionTooltips => {

    const divested = position.info.strategyInfo.state === StrategyState.DIVESTED;
    const exited = position.exited === true;
    const maturity = position.maturity;
    const token = position.pool.token;
    const id = `${ token.symbol }-${ maturity }`;

    return {
        enter: {
            id: `position-pool-enter-${ id }-tooltip`,
            content: TOOLTIPS.POSITION.POOL.ADD_LIQUIDITY(token),
        },
        exit: {
            id: `position-pool-exit-${ id }-tooltip`,
            content: divested
                ? TOOLTIPS.POSITION.POOL.EXIT_DIVESTED()
                : TOOLTIPS.POSITION.POOL.EXIT(),
        },
        share: {
            id: `position-pool-share-${ id }-tooltip`,
            content: TOOLTIPS.POSITION.POOL.SHARE(),
        },
        positionValue: {
            id: `position-pool-position-value-${ id }-tooltip`,
            content: divested || exited
                ? TOOLTIPS.POSITION.POOL.POSITION_VALUE_DIVESTED(token)
                : TOOLTIPS.POSITION.POOL.POSITION_VALUE(token),
        },
        currentRate: {
            id: `position-pool-current-rate-${ id }-tooltip`,
            content: divested || exited
                ? TOOLTIPS.POSITION.POOL.AVERAGE_RATE()
                : TOOLTIPS.POSITION.POOL.CURRENT_RATE(),
        },
    };
};

export const poolPositionActions = (
    position: PoolPosition,
    status: PositionStatus,
    tooltips: PoolPositionTooltips,
    addLiquidity: (position: PoolPosition) => void,
    removeLiquidity: (position: PoolPosition) => void,
): TemplateResult | typeof nothing => {

    return !status.isRedeemed && (status.canExit || status.canEnter)
        ? html`
        <div class="position-actions">
            ${ status.canExit
                ? html`
                <button class="${ status.isMatured || status.isDivested ? 'primary' : '' }"
                    aria-describedby="${ tooltips.exit.id }"
                    @click=${ () => removeLiquidity(position) }>
                    Exit
                    ${ status.isDivested ? html`<ui-icon name="info"></ui-icon>` : nothing }
                </button>
                `
                : nothing
            }
            ${ status.canEnter
                ? html`
                <button class="primary"
                    aria-describedby="${ tooltips.enter.id }"
                    @click=${ () => addLiquidity(position) }>
                    Add Liquidity
                </button>
                `
                : nothing
            }
        </div>
        `
        : nothing;
};
