import { html } from 'lit';
import type { AppElement } from '../app';

export const privacyPolicyTemplate = function (this: AppElement) {

    return html`
    <main class="policy">
        <div class="column">
            <ill-privacy-policy></ill-privacy-policy>
        </div>
    </main>
    `;
};
