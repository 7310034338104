import { TransactionReceipt, TransactionResponse } from '@ethersproject/providers';
import { ErrorService } from '../errors';
import { SettingsService } from '../settings';
import { Connection } from '../wallet';
import { TransactionConfirmer } from './confirmation';
import type { Transaction } from './transaction';

export type Subscriber<T> = (message: T) => void;

/**
 * A helper type to exclude functions from serialized objects.
 */
export type Serialized<T> = {
    // eslint-disable-next-line @typescript-eslint/ban-types
    [P in keyof T as T[P] extends Function ? never : P]: T[P];
};

/**
 * A helper type to make optional state properties non-optional and non-undefined.
 */
export type AddState<T, K extends keyof T> = T & {
    [P in K]-?: Exclude<T[P], undefined>;
};

/**
 * A helper type to make state properties optional and undefined.
 */
export type RemoveState<T, K extends keyof T> = T & {
    [P in K]?: undefined;
};

export const TRANSACTION_STATE = {};

export type TransactionState = typeof TRANSACTION_STATE;

export const TRANSACTION_STATUS = {
    INITIAL: 'INITIAL',
    UPDATING: 'UPDATING',
    ERROR: 'ERROR',
    COMPLETE: 'COMPLETE',
    PENDING: 'PENDING',
    SUCCESS: 'SUCCESS',
    FAILURE: 'FAILURE',
    DISPOSED: 'DISPOSED',
} as const;

export type TransactionStatus = typeof TRANSACTION_STATUS[keyof typeof TRANSACTION_STATUS];

export const TRANSACTION_TOPIC = {
    STATE: 'STATE',
    STATUS: 'STATUS',
    REQUEST: 'REQUEST',
} as const;

export type TransactionTopic = typeof TRANSACTION_TOPIC[keyof typeof TRANSACTION_TOPIC];

export type TransactionMessage<
    T extends Transaction = Transaction,
    K extends string = string,
> = K extends typeof TRANSACTION_TOPIC.STATUS
    ? {
        transaction: T;
        topic: K;
        detail: T['status'] | undefined;
    }
    : K extends typeof TRANSACTION_TOPIC.STATE
        ? {
            transaction: T;
            topic: K;
            detail: Partial<T['state']>;
        }
        : K extends typeof TRANSACTION_TOPIC.REQUEST
            ? {
                transaction: T;
                topic: K;
                detail: {
                    requestId: string;
                    resolve: (result?: unknown) => void;
                    reject: (reason?: unknown) => void;
                };
            }
            : {
                transaction: T;
                topic: K;
                detail: unknown;
            };

/**
 * A type for a serialized transaction in local storage
 */
export interface SerializedTransaction<T extends Transaction = Transaction> {
    type: string;
    id: number;
    state: Partial<Serialized<T['state']>>;
    status: T['status'];
    hash: string | undefined;
    error: { name: string; message: string; } | undefined;
}

/**
 * A type for transaction data in local storage
 *
 * @remarks
 * Stored transaction data is keyed by chain id and account address.
 */
export type TransactionStorage = Record<number, Record<string, SerializedTransaction[]>>;

export type TransactionOptions<T extends Transaction = Transaction> = Partial<SerializedTransaction<T>> & {
    connection?: Connection;
    response?: TransactionResponse;
    receipt?: TransactionReceipt;
};

export interface TransactionConstructor<T extends Transaction = Transaction> {
    type: string;
    new(
        transaction?: TransactionOptions<T>,
        service?: TransactionService,
        settingsService?: SettingsService,
        errorService?: ErrorService,
    ): T;
}

export const isTransactionConstructor = <T extends Transaction> (fn: unknown): fn is TransactionConstructor<T> => {

    return fn instanceof Function
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        && fn.prototype?.constructor === fn
        && typeof (fn as TransactionConstructor<T>).type === 'string';
};

export type TransactionFactory<T extends Transaction = Transaction> = (
    transaction?: TransactionOptions<T>,
    service?: TransactionService,
    settingsService?: SettingsService,
    errorService?: ErrorService,
) => T;

export const TRANSACTION_SERVICE_TOPIC = {
    CREATED: 'CREATED',
    SUCCESS: 'SUCCESS',
    FAILURE: 'FAILURE',
    LOADED: 'LOADED',
} as const;

export type TransactionServiceTopic = typeof TRANSACTION_SERVICE_TOPIC[keyof typeof TRANSACTION_SERVICE_TOPIC];

export type TransactionServiceMessage<K extends TransactionServiceTopic = TransactionServiceTopic> =
    K extends typeof TRANSACTION_SERVICE_TOPIC.CREATED
        ? {
            topic: K;
            detail: Transaction;
        }
        : K extends typeof TRANSACTION_SERVICE_TOPIC.SUCCESS
            ? {
                topic: K;
                detail: Transaction;
            }
            : K extends typeof TRANSACTION_SERVICE_TOPIC.FAILURE
                ? {
                    topic: K;
                    detail: Transaction;
                }
                : K extends typeof TRANSACTION_SERVICE_TOPIC.LOADED
                    ? {
                        topic: K;
                        detail: Transaction[];
                    }
                    : {
                        topic: string;
                        detail: unknown;
                    };

export interface TransactionService {

    register<T extends Transaction> (type: TransactionConstructor<T>): void;
    register<T extends Transaction> (type: string, factory: TransactionFactory<T>): void;

    create<T extends Transaction> (type: TransactionConstructor<T> | string, transaction?: TransactionOptions<T>): T;

    has<T extends Transaction> (type: TransactionConstructor<T> | string): boolean;

    get<T extends Transaction> (id: number): T | undefined;

    getAll (): Transaction[];

    confirm: TransactionConfirmer;

    subscribe<K extends TransactionServiceTopic> (topic: K, subscriber: Subscriber<TransactionServiceMessage<K>>): void;

    unsubscribe<K extends TransactionServiceTopic> (topic: K, subscriber: Subscriber<TransactionServiceMessage<K>>): void;
}
