import { html, nothing } from 'lit';
import { customElement } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { maturityDate, REDEEM_STATUS } from '../../core/markets';
import { iPT } from '../../core/services/token';
import { errorMessage, loadingBar, tokenBalance, tokenImage } from '../../shared/templates';
import { RedeemTransactionBaseElement } from './redeem-transaction-base';

const template = function (this: RedeemTransactionStatusElement) {

    if (!this.transaction) return nothing;

    const isPending = this.transaction?.status === REDEEM_STATUS.PENDING || this.transaction?.status === REDEEM_STATUS.APPROVING;
    const isFetching = this.transaction.status === REDEEM_STATUS.UPDATING;
    const hasError = this.transaction?.status === REDEEM_STATUS.ERROR || this.transaction?.status === REDEEM_STATUS.FAILURE;

    if (!isFetching && !isPending && !hasError) return nothing;

    const { market, amount } = this.transaction.state;

    if (!market || !amount) return nothing;

    const token = iPT(market);

    return html`
    <div class=${ classMap({ widget: true, fetching: isFetching, pending: isPending, error: hasError }) }>

        <div class="widget-header">
            <label>
                ${ tokenImage(market.token) }
                ${ maturityDate(market.maturity) }
            </label>
        </div>

        <div class="widget-main">

            ${ hasError
                ? errorMessage(this.transaction.error?.message ?? 'An unknown error occurred.', 'exclamation')
                : isFetching
                    ? html`
                    <div class="preview">
                        ${ loadingBar('Finding current iPT price...') }
                    </div>
                    `
                    : html`
                    <div class="preview">
                        <div class="preview-item preview-item-big">
                            <span class="label">Exiting</span>
                            ${ tokenBalance(amount, token) }
                        </div>
                        ${ loadingBar('Transaction pending...') }
                    </div>
                    `
            }

        </div>
    </div>
    `;
};

@customElement('ill-redeem-transaction-status')
export class RedeemTransactionStatusElement extends RedeemTransactionBaseElement {

    protected createRenderRoot (): Element | ShadowRoot {

        return this;
    }

    protected render (): unknown {

        return template.apply(this);
    }
}
