import { ListConfig, LIST_CONFIG_MENU_RADIO, SelectEvent, selectionAttribute } from '@swivel-finance/ui/behaviors/list';
import { ValueChangeEvent } from '@swivel-finance/ui/elements/input';
import { ListBoxElement } from '@swivel-finance/ui/elements/listbox';
import { ListItemElement } from '@swivel-finance/ui/elements/listitem';
import { setAttribute } from '@swivel-finance/ui/utils/dom';
import { html, LitElement, nothing, TemplateResult } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { createRef, Ref, ref } from 'lit/directives/ref.js';
import { compareAmounts, empty, emptyOrZero, expandAmount, fraction, pad, partialAmount, trim } from '../../core/amount';
import { balance, tokenSymbol } from '../../shared/templates';
import { Token } from '../../types';

let uniqueId = 0;

const LIST_CONFIG_BUTTON_MENU: ListConfig = { ...LIST_CONFIG_MENU_RADIO, orientation: 'horizontal' };

const QUICK_FILL_OPTIONS = [0, 0.25, 0.5, 0.75, 1];

const QUICK_FILL_RANGE = 0.25;

const balanceTemplate = function (this: TokenInputElement) {

    return (this.showBalance && this.balance && this.token)
        ? html`<span class="balance">${ balance(expandAmount(this.balance, this.token), this.token, 2) }</span>${ tokenSymbol(this.token) }`
        : tokenSymbol(this.token);
};

const labelTemplate = function (this: TokenInputElement) {

    return (this.label || this.showBalance)
        ? html`<label for=${ this.id }>${ this.label || '' }${ balanceTemplate.apply(this) }</label>`
        : html``;
};

const quickFillButtonTemplate = function (this: TokenInputElement, fill: number) {

    return html`
    <ui-listitem aria-disabled="${ this.disabled || emptyOrZero(this.balance) }" .value=${ fill }>
        ${ Math.round(fill * 100) }%
    </ui-listitem>
    `;
};

const quickFillTemplate = function (this: TokenInputElement) {

    return this.balance
        ? html`
        <ui-listbox
            class="quick-fill"
            .config=${ LIST_CONFIG_BUTTON_MENU }
            @ui-select-item=${ (e: SelectEvent) => this.handleSelectionChange(e) }
            @ui-value-changed=${ (e: ValueChangeEvent) => this.handleValueChange(e) }
            ${ ref(this.quickFillRef) }>
            ${
                QUICK_FILL_OPTIONS
                    .map(fill => quickFillButtonTemplate.call(this, fill))
                    .reduce(
                        (prev, curr, index, orig) => (index < orig.length - 1)
                            ? [...prev, curr, html`<span class="spacer"></span>`]
                            : [...prev, curr],
                        [] as TemplateResult[],
                    )
            }
        </ui-listbox>
        `
        : nothing;
};

const template = function (this: TokenInputElement) {

    return html`
    ${ labelTemplate.apply(this) }
    <input ${ ref(this.inputRef) }
        type="text"
        autocomplete="off"
        id=${ this.id }
        name=${ this.name }
        placeholder=${ this.placeholder }
        value=${ this.value }
        ?disabled=${ this.disabled }
        aria-invalid="${ this.invalid }"
        @change=${ this.handleChange.bind(this) }
        @input=${ this.handleInput.bind(this) }
        @blur=${ this.handleBlur.bind(this) }>
    ${ quickFillTemplate.apply(this) }
    ${ !this.label && !this.showBalance ? tokenSymbol(this.token) : '' }
    `;
};

export type TokenInputChangeEvent = CustomEvent<{ value: string; }>;

@customElement('ill-token-input')
export class TokenInputElement extends LitElement {

    protected _max: string | undefined;

    protected _value = '';

    protected inputRef: Ref<HTMLInputElement> = createRef();

    protected quickFillRef: Ref<ListBoxElement> = createRef();

    protected validationTimeout?: number;

    /**
     * The maximum possible value (contracted)
     */
    @property({ attribute: false })
    set max (v: string | undefined) {

        const prev = this._max;
        this._max = v;

        this.setValue(this.limit(this.value));

        this.requestUpdate('max', prev);
    }

    get max (): string | undefined {

        return this._max;
    }

    /**
     * The current value (contracted)
     */
    @property({ attribute: false })
    set value (v: string) {

        const prev = this._value;
        this._value = v;

        if (this.inputRef.value) {

            this.inputRef.value.value = this._value;
        }

        this.requestUpdate('value', prev);
    }

    get value (): string {

        return this._value;
    }

    @property({ attribute: false })
    token?: Token;

    /**
     * The available user balance (contracted)
     */
    @property({ attribute: false })
    balance?: string;

    @property({
        reflect: true,
        type: Boolean,
    })
    disabled = false;

    @property({
        reflect: true,
        type: Boolean,
    })
    invalid = false;

    @property({
        attribute: 'show-decimals',
        reflect: true,
        type: Number,
    })
    showDecimals = 2;

    @property({
        attribute: 'show-balance',
        reflect: true,
        type: Boolean,
    })
    showBalance = false;

    @property({ attribute: true })
    id = `token-input-${ uniqueId++ }`;

    @property({ attribute: false })
    name = 'amount';

    @property({ attribute: false })
    label = '';

    @property({ attribute: false })
    placeholder = pad('0', this.showDecimals);

    @state()
    filled = 0;

    @state()
    error?: string;

    setValue (v: string) {

        const valid = this.validate(v);
        const changed = trim(this.value) !== trim(v);

        this.validationFeedback(valid);

        if (valid) {

            this.value = v;

            if (changed) {

                this.emitChange();
            }
        }

        if (this.balance && this.token) {

            this.filled = fraction(this.value, this.balance, this.token);
        }

        this.updateQuickFill();
    }

    setFill (v: number) {

        if (!this.balance || !this.token) return;

        const value = partialAmount(this.balance, v, this.token);

        this.setValue(value);

        this.format();
    }

    constructor () {

        super();

        this.handleClick = this.handleClick.bind(this);
    }

    connectedCallback () {

        super.connectedCallback();

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.addEventListener('click', this.handleClick);

        this.format();
    }

    disconnectedCallback () {

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.removeEventListener('click', this.handleClick);

        super.disconnectedCallback();
    }

    protected updated () {

        this.updateQuickFill();
    }

    protected createRenderRoot (): Element | ShadowRoot {

        return this;
    }

    protected render (): unknown {

        return template.apply(this);
    }

    protected handleClick (e: MouseEvent) {

        e.stopPropagation();

        this.inputRef.value?.focus();
    }

    protected handleChange (e: InputEvent) {

        // prevent the owned input's events from interfering with this element's events
        e.stopImmediatePropagation();
    }

    protected handleInput (e: InputEvent) {

        // prevent the owned input's events from interfering with this element's events
        e.stopImmediatePropagation();

        this.setValue((e.target as HTMLInputElement).value);

        this.sanitizeInput();
    }

    protected handleBlur () {

        this.format();
    }

    protected handleValueChange (e: ValueChangeEvent) {

        // prevent the owned listbox's events from interfering with this element's events
        e.stopImmediatePropagation();
    }

    protected handleSelectionChange (e: SelectEvent) {

        // prevent the owned listbox's events from interfering with this element's events
        e.stopImmediatePropagation();

        const item = e.detail.current?.item as ListItemElement<number> | undefined;

        const value = item?.value;

        if (value !== undefined) this.setFill(value);
    }

    protected sanitizeInput () {

        if (this.inputRef.value) {

            this.inputRef.value.value = this.value;
        }
    }

    protected updateQuickFill () {

        const selected = QUICK_FILL_OPTIONS.findIndex(fill => {

            const low = fill - QUICK_FILL_RANGE / 2;
            const high = fill + QUICK_FILL_RANGE / 2;

            return this.filled >= low && this.filled < high;
        });

        this.quickFillRef.value?.querySelectorAll<ListItemElement>('ui-listitem').forEach((item, index) => {

            setAttribute(item, selectionAttribute(item), index === selected);
        });
    }

    protected validationFeedback (valid: boolean) {

        this.invalid = !valid;

        window.clearTimeout(this.validationTimeout);

        if (!valid) {

            this.validationTimeout = window.setTimeout(() => this.validationFeedback(true), 1000);
        }
    }

    protected emitChange () {

        const value = trim(this.value);

        const event: TokenInputChangeEvent = new CustomEvent('change', {
            bubbles: true,
            cancelable: true,
            composed: true,
            detail: {
                value,
            },
        });

        this.dispatchEvent(event);
    }

    /**
     * Validates an input value
     *
     * @remarks
     * Validation ensures only numbers and one decimal delimiter is present in the input value,
     * and the number of decimals is not larger than the number of decimals of the token. In
     * additions, validation will check if the input value is larger than the allowed `max`.
     *
     * @param v - the value to validate
     * @returns `true` if the value is valid, `false` otherwise
     */
    protected validate (v: string): boolean {

        const decimals = this.token?.decimals ?? 18;

        const validator = new RegExp(`^\\d*\\.?\\d{0,${ decimals }}$`);

        if (!validator.test(v)) return false;

        if (!empty(this.max) && !emptyOrZero(v)) {

            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            return compareAmounts(v, this.max!, decimals) < 1;
        }

        return true;
    }

    /**
     * Limits an input value to the `max` property
     *
     * @param v - the value to limit
     * @returns `max` if `v` is larger than `max`, `v` otherwise
     */
    protected limit (v: string): string {

        if (!emptyOrZero(this.max) && !emptyOrZero(v)) {

            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            if (compareAmounts(v, this.max!, this.token ?? 18) > 0) {

                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                return this.max!;
            }
        }

        return v;
    }

    /**
     * Format the input value according to the decimals configuration
     */
    protected format () {

        if (empty(this.value)) return;

        this.setValue(pad(this.value, this.showDecimals));
    }
}
