import { ENV } from '../../env';
import { LogService } from '../logger';

// disable the logger if the `debug` flag is not set to `true`
// on production builds this will be the case if not explicitly set
const disabled = !ENV.debug;

/**
 * Create a log group with optional prefix
 *
 * @param n - the name of the log group (a common prefix)
 * @returns a {@link LogService} instance
 */
const group = (n?: string): LogService => {

    const prefix = n ? `[${ n }] ` : undefined;

    return {

        disabled: false,

        log (m?: unknown, ...p: unknown[]): void {

            if (this.disabled || disabled) return;

            console.log(...args(prefix, m, ...p));
        },

        warn (m?: unknown, ...p: unknown[]) {

            if (this.disabled || disabled) return;

            console.warn(...args(prefix, m, ...p));
        },

        error (m?: unknown, ...p: unknown[]) {

            if (this.disabled || disabled) return;

            console.error(...args(prefix, m, ...p));
        },

        group,
    };
};

/**
 * Parse the arguments for `console` invocations
 *
 * @param n - the name of the log group (the log group prefix)
 * @param m - the log message
 * @param p - additional log params
 * @returns an array of arguments for the `console` invocation
 */
const args = (n?: string, m?: unknown, ...p: unknown[]): unknown[] => {

    return [
        ...((n && typeof m === 'string')
            ? [n + m]
            : n
                ? [n, m]
                : [m]
        ),
        ...p,
    ];
};

/**
 * The default LogService implementation.
 */
export const logService: LogService = {

    // the log service is a log group without prefix...
    ...group(),
};
