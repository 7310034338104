import { ValueChangeEvent } from '@swivel-finance/ui/elements/input';
import { html, LitElement, nothing } from 'lit';
import { customElement } from 'lit/decorators.js';
import { ERRORS, TOOLTIPS } from '../../core/constants';
import { errorMessage, infoMessage, rate, status, tokenSymbol } from '../../shared/templates';
import { orchestrator, POOL } from '../../state/orchestrator';
import { Token } from '../../types';

const template = function (this: PoolTokenElement) {

    const state = this.poolMachine.state;
    const context = state.context;

    const isReady = state.matches(POOL.STATES.SELECT_TOKEN)
        || state.matches(POOL.STATES.SELECT_POOL)
        || state.matches(POOL.STATES.COMPLETE);

    const isFetching = state.matches(POOL.STATES.FETCHING);
    const hasError = state.matches(POOL.STATES.ERROR);

    const { poolsByToken, activeTokensByAPR } = context;

    const hasPools = activeTokensByAPR.length > 0;

    return html`
    <div class="widget">

        <div class="widget-header">
            <label id="tokens-label" aria-describedby="pool-select-currency-header-tooltip">Select a Currency</label>
            <ui-tooltip id="pool-select-currency-header-tooltip">
                ${ TOOLTIPS.POOL.SELECT_CURRENCY.HEADER() }
            </ui-tooltip>
        </div>

        <div class="widget-main">

            ${ isReady && hasPools
                ? html`
                <ui-listbox
                    class="neo-list token-list"
                    aria-labelledby="tokens-label"
                    @ui-value-changed=${ (event: ValueChangeEvent<Token>) => this.handleTokenChange(event) }>
                        ${ activeTokensByAPR.map(token => html`
                        <ui-listitem class="neo-item" .value=${ token } aria-disabled="${ !poolsByToken.has(token.address) }">
                            <div class="neo-item-content">
                                <span class="token">
                                    <ill-token-symbol .name=${ token.image } .ipt=${ true }></ill-token-symbol>
                                    ${ tokenSymbol(token) }
                                </span>
                                <!-- NB: we need to manually set the tabindex to "-1" as the tooltip will add a
                                    tabindex of "0" to tooltip triggers by default (for accessibility)
                                    however, these tooltips are children of a focusable list item and, as such,
                                    they shouldn't be focusable -->
                                ${ !poolsByToken.has(token.address)
                                    ? html`
                                    <ui-icon name="pause" aria-describedby="pool-${ token.symbol }-unavailable-tooltip" tabindex="-1"></ui-icon>
                                    `
                                    : html`
                                    <span class="rate" aria-describedby="pool-${ token.symbol }-rate-preview-tooltip" tabindex="-1">
                                        ${ rate(poolsByToken.get(token.address)?.apr) }
                                    </span>
                                    `
                                }
                            </div>
                        </ui-listitem>

                        <ui-tooltip id="pool-${ token.symbol }-rate-preview-tooltip">
                            ${ TOOLTIPS.POOL.BEST_RATE(token) }
                        </ui-tooltip>

                        <ui-tooltip id="pool-${ token.symbol }-unavailable-tooltip">
                            ${ TOOLTIPS.POOL.NO_ACTIVE_POOLS() }
                        </ui-tooltip>
                        `) }
                </ui-listbox>
                `
                : isFetching
                    ? status('loading')
                    : hasError || !hasPools
                        ? html`
                        ${ hasError
                            ? errorMessage(state.context.error, 'exclamation')
                            : infoMessage(ERRORS.STATE.POOL.NO_ACTIVE_POOLS.message, 'info')
                        }
                        <button class="primary" @click=${ () => this.retry() }>Retry</button>
                        `
                        : nothing
            }

        </div>

    </div>
    `;
};

@customElement('ill-pool-token')
export class PoolTokenElement extends LitElement {

    protected poolMachine = orchestrator.pool;

    constructor () {

        super();

        this.handlePoolTransition = this.handlePoolTransition.bind(this);
    }

    connectedCallback (): void {

        super.connectedCallback();

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.poolMachine.onTransition(this.handlePoolTransition);
    }

    disconnectedCallback (): void {

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.poolMachine.off(this.handlePoolTransition);

        super.disconnectedCallback();
    }

    protected createRenderRoot (): Element | ShadowRoot {

        return this;
    }

    protected render (): unknown {

        return template.apply(this);
    }

    protected handlePoolTransition (): void {

        this.requestUpdate();
    }

    protected handleTokenChange (event: ValueChangeEvent<Token>): void {

        const { current, change } = event.detail;

        if (!change) return;

        this.poolMachine.send(POOL.model.events[POOL.EVENTS.SET_TOKEN](current.address));
    }

    protected retry (): void {

        this.poolMachine.send(POOL.model.events[POOL.EVENTS.FETCH]());
    }
}
