import { PanelDirection, PanelNavigationEvent } from '@swivel-finance/ui/elements/panel-container';
import { dispatch } from '@swivel-finance/ui/utils/events';
import { html, nothing } from 'lit';
import { customElement } from 'lit/decorators.js';
import { TOOLTIPS } from '../../core/constants';
import { matured, maturityDate, MATURITY_DATE_FORMAT, REDEEM_STATUS } from '../../core/markets';
import { effectiveAPR, positionAPR } from '../../core/positions';
import { iPT } from '../../core/services/token';
import { date, errorMessage, rate, tokenBalance, tokenImage, transactionLink } from '../../shared/templates';
import { RedeemTransactionBaseElement } from './redeem-transaction-base';

const template = function (this: RedeemTransactionResultElement) {

    if (!this.transaction) return nothing;

    const isReady = this.transaction?.status === REDEEM_STATUS.SUCCESS;
    const isPending = this.transaction?.status === REDEEM_STATUS.PENDING;
    const hasError = this.transaction?.status === REDEEM_STATUS.ERROR || this.transaction?.status === REDEEM_STATUS.FAILURE;

    if (!isReady && !isPending && !hasError) return nothing;

    const { market, position, amount, amountReturned } = this.transaction.state;

    if (!market || !position || !amount) return nothing;

    const isMatured = matured(market);

    const effectiveRate = isMatured
        ? positionAPR(position, market)
        : amountReturned
            ? effectiveAPR(amount, amountReturned, position, market)
            : undefined;

    const token = iPT(market);

    return html`
    <div class="widget">

        <div class="widget-header">
            <label>
                ${ tokenImage(market.token) }
                ${ maturityDate(market.maturity) }
            </label>
            <button class="button-icon" @click=${ () => dispatch(this, new PanelNavigationEvent({ target: this, panel: PanelDirection.FIRST })) }>
                <ui-icon name="cross-circle"></ui-icon>
            </button>
        </div>

        <div class="widget-main">

            ${ hasError
                ? errorMessage(this.transaction.error?.message ?? 'An unknown error occurred.', 'exclamation')
                : html`
                <div class="preview">

                    <div class="preview-headline">
                        Exited Successfully
                    </div>

                    <ill-preview-item
                        .label=${ 'Exited:' }
                        .current=${ tokenBalance(amount, token) }
                        .loading=${ isPending }></ill-preview-item>

                    <ill-preview-item
                        .label=${ 'Returned:' }
                        .current=${ tokenBalance(amountReturned, market.token) }
                        .tooltip=${ TOOLTIPS.REDEEM.PROCEEDS(market?.token) }
                        .loading=${ isPending }></ill-preview-item>

                    ${ !position.virtual
                        ? html`
                        <ill-preview-item
                            .label=${ 'Effective Rate:' }
                            .current=${ rate(effectiveRate, '%', false) }
                            .tooltip=${ TOOLTIPS.REDEEM.EFFECTIVE_RATE() }
                            .loading=${ isPending }></ill-preview-item>
                        `
                        : nothing
                    }

                    <ill-preview-item
                        class="preview-maturity"
                        .label=${ isMatured ? 'Matured' : 'Matures' }
                        .current=${ date(market.maturity, MATURITY_DATE_FORMAT.LONG) }
                        .tooltip=${ TOOLTIPS.REDEEM.MATURES() }>
                    </ill-preview-item>

                    <div class="preview-item preview-transaction">
                        ${ transactionLink(this.transaction?.response?.hash) }
                    </div>

                </div>
                `
            }

        </div>
    </div>
    `;
};

@customElement('ill-redeem-transaction-result')
export class RedeemTransactionResultElement extends RedeemTransactionBaseElement {

    protected createRenderRoot (): Element | ShadowRoot {

        return this;
    }

    protected render (): unknown {

        return template.apply(this);
    }
}
