import { ErrorService } from '../../errors';
import { LogService } from '../../logger';
import { TokenService } from '../../token';
import { WalletService } from '../../wallet';
import { YieldSpaceService } from '../../yieldspace';
import { IlluminateLenderService, IlluminateMarketPlaceService, IlluminateRedeemerService, IlluminateService } from '../interfaces';
import { ChainIlluminateLenderService } from './lender';
import { ChainIlluminateMarketPlaceService } from './marketplace';
import { ChainIlluminateRedeemerService } from './redeemer';

export class ChainIlluminateService implements IlluminateService {

    protected wallet: WalletService;

    protected token: TokenService;

    protected yieldspace: YieldSpaceService;

    protected errors: ErrorService;

    protected logger: LogService;

    lender: IlluminateLenderService;

    redeemer: IlluminateRedeemerService;

    marketPlace: IlluminateMarketPlaceService;

    constructor (
        wallet: WalletService,
        token: TokenService,
        yieldspace: YieldSpaceService,
        errors: ErrorService,
        logger: LogService,
    ) {

        this.wallet = wallet;
        this.token = token;
        this.yieldspace = yieldspace;
        this.errors = errors;
        this.logger = logger.group('illuminate');

        this.lender = new ChainIlluminateLenderService(this.wallet, this.token, this.errors, this.logger);
        this.redeemer = new ChainIlluminateRedeemerService(this.wallet, this.token, this.errors, this.logger);
        this.marketPlace = new ChainIlluminateMarketPlaceService(this.wallet, this.token, this.yieldspace, this.errors, this.logger);
    }
}
