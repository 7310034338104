import { html, LitElement, nothing } from 'lit';
import { customElement } from 'lit/decorators.js';
import { expandAmount } from '../../core/amount';
import { LendTransaction, RedeemTransaction } from '../../core/markets';
import { AddLiquidityTransaction, RemoveLiquidityTransaction } from '../../core/pools';
import { serviceLocator } from '../../core/services';
import { iPT, lpT } from '../../core/services/token';
import { Transaction, TransactionMessage, TransactionServiceMessage, TRANSACTION_SERVICE, TRANSACTION_SERVICE_TOPIC, TRANSACTION_STATUS, TRANSACTION_TOPIC } from '../../core/services/transaction';
import { infoMessage, rate, status, tokenBalance, transactionLink } from '../../shared/templates';

import '@swivel-finance/ui/elements/time/time-ago';

type TransactionTemplate = (transaction: Transaction) => unknown;

const TRANSACTION_TEMPLATES: Record<string, TransactionTemplate> = {
    [LendTransaction.type]: ((transaction: LendTransaction) => html`
    <span class="transaction-description">
        <span class="type">Lend</span>
        ${ tokenBalance(expandAmount(transaction.state.amount ?? '0', transaction.state.market?.token ?? 18), transaction.state.market?.token, 2) }
        at
        ${ rate(transaction.state.quote?.apr, undefined, false) }
    </span>
    `) as TransactionTemplate,
    [RedeemTransaction.type]: ((transaction: RedeemTransaction) => html`
    <span class="transaction-description">
        <span class="type">Exit</span>
        ${ tokenBalance(transaction.state.amount, iPT(transaction.state.market), 2) }
        for
        ${ tokenBalance(transaction.state.amountReturned, transaction.state.market?.token, 2) }
    </span>
    `) as TransactionTemplate,
    [AddLiquidityTransaction.type]: ((transaction: AddLiquidityTransaction) => html`
    <span class="transaction-description">
        <span class="type">Add Liquidity</span>
        ${ tokenBalance(transaction.state.iPTAmount, iPT(transaction.state.pool), 2) }
        for
        ${ rate(transaction.state.preview?.share, undefined, false) }
    </span>
    `) as TransactionTemplate,
    [RemoveLiquidityTransaction.type]: ((transaction: RemoveLiquidityTransaction) => html`
    <span class="transaction-description">
        <span class="type">Remove Liquidity</span>
        ${ tokenBalance(transaction.state.lpAmount, lpT(transaction.state.pool), 2) }
        for
        ${ tokenBalance(transaction.state.preview?.baseOut, transaction.state.pool?.token, 2) }
    </span>
    `) as TransactionTemplate,
};

const template = function (this: AccountTransactionsElement) {

    return html`
    <ul class="transaction-list">
        ${ this.transactions.length
            ? this.transactions.map(transaction => html`
            <li class="transaction-item">
                <span class="transaction-status ${ transaction.status?.toLocaleLowerCase() ?? '' }">
                    ${ transaction.isPending()
                        ? status('loading')
                        : transaction.status === TRANSACTION_STATUS.SUCCESS
                            ? html`<ui-icon name="check"></ui-icon>`
                            : transaction.status === TRANSACTION_STATUS.FAILURE
                                ? html`<ui-icon name="times"></ui-icon>`
                                : nothing
                    }
                </span>
                ${ TRANSACTION_TEMPLATES[transaction.type](transaction) }
                <span class="transaction-info">
                    <ui-time-ago .date=${ transaction.id }></ui-time-ago>
                    ${ transactionLink(transaction.hash) }
                </span>
            </li>
            `)
            : infoMessage('You have no recent transactions.')
        }
    </ul>
    `;
};

@customElement('ill-account-transactions')
export class AccountTransactionsElement extends LitElement {

    protected service = serviceLocator.get(TRANSACTION_SERVICE);

    protected transactions = [] as Transaction[];

    constructor () {

        super();

        this.handleTransactionsChange = this.handleTransactionsChange.bind(this);
        this.handleTransactionChange = this.handleTransactionChange.bind(this);
    }

    connectedCallback (): void {

        super.connectedCallback();

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.service.subscribe(TRANSACTION_SERVICE_TOPIC.LOADED, this.handleTransactionsChange);
        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.service.subscribe(TRANSACTION_SERVICE_TOPIC.CREATED, this.handleTransactionsChange);
        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.service.subscribe(TRANSACTION_SERVICE_TOPIC.SUCCESS, this.handleTransactionsChange);
        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.service.subscribe(TRANSACTION_SERVICE_TOPIC.FAILURE, this.handleTransactionsChange);

        this.updateTransactions();
    }

    disconnectedCallback (): void {

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.service.unsubscribe(TRANSACTION_SERVICE_TOPIC.LOADED, this.handleTransactionsChange);
        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.service.unsubscribe(TRANSACTION_SERVICE_TOPIC.CREATED, this.handleTransactionsChange);
        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.service.unsubscribe(TRANSACTION_SERVICE_TOPIC.SUCCESS, this.handleTransactionsChange);
        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.service.unsubscribe(TRANSACTION_SERVICE_TOPIC.FAILURE, this.handleTransactionsChange);

        super.disconnectedCallback();
    }

    protected createRenderRoot (): Element | ShadowRoot {

        return this;
    }

    protected render (): unknown {

        return template.apply(this);
    }

    protected updateTransactions (): void {

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.transactions.forEach(tx => tx.unsubscribe(TRANSACTION_TOPIC.STATUS, this.handleTransactionChange));

        this.transactions = this.service.getAll();

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.transactions.forEach(tx => tx.subscribe(TRANSACTION_TOPIC.STATUS, this.handleTransactionChange));
    }

    protected handleTransactionsChange (message: TransactionServiceMessage): void {

        switch (message.topic) {

            case TRANSACTION_SERVICE_TOPIC.CREATED:
            case TRANSACTION_SERVICE_TOPIC.LOADED:
            case TRANSACTION_SERVICE_TOPIC.SUCCESS:
            case TRANSACTION_SERVICE_TOPIC.FAILURE:

                this.updateTransactions();
                break;
        }

        this.requestUpdate();
    }

    protected handleTransactionChange (message: TransactionMessage): void {

        switch (message.topic) {

            case TRANSACTION_TOPIC.STATUS:

                this.requestUpdate();
                break;
        }
    }
}
