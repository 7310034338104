import { CollapsibleElement } from '@swivel-finance/ui/elements/collapsible/collapsible';
import { PanelChangeEvent } from '@swivel-finance/ui/elements/panel-container';
import { TabsElement } from '@swivel-finance/ui/elements/tabs/tabs';
import { WizardElement } from '@swivel-finance/ui/elements/wizard/wizard';
import { cancel } from '@swivel-finance/ui/utils/events';
import { html, LitElement, nothing } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { createRef, ref } from 'lit/directives/ref.js';
import { ERROR_SERVICE, LOG_SERVICE, serviceLocator } from '../../core/services';
import { POSITIONS_ROUTE, RouteMatch, router } from '../../routes';
import { orchestrator, POSITION } from '../../state/orchestrator';
import { PositionEvent } from './events';
import { LendPositionStrategy, PoolPositionStrategy, PositionStrategy } from './position-strategy';
import { POSITION_STEPS, POSITION_TYPES, STEP_LABELS } from './types';

const template = function (this: PositionsPageElement) {

    return html`
    <ui-wizard .current=${ this.step } @ui-panel-changed=${ (event: PanelChangeEvent) => this.handlePanelChange(event) } ${ ref(this.wizard) }>

        <nav class="step-navigation" aria-label="Position Steps">
            <ul class="step-list" data-part="triggers">
                <li class="step-link">
                    <a data-part="trigger" href="#">
                        ${ STEP_LABELS[POSITION_STEPS.POSITION] }
                    </a>
                </li>
                <li class="step-link">
                    <a data-part="trigger" href="#" aria-disabled=${ this.strategy?.stepDisabled(POSITION_STEPS.PREVIEW) ?? true }>
                        ${ this.strategy?.stepLabel(POSITION_STEPS.PREVIEW) ?? STEP_LABELS[POSITION_STEPS.PREVIEW] }
                    </a>
                </li>
                <li class="step-link">
                    <a data-part="trigger" href="#" aria-disabled=${ this.strategy?.stepDisabled(POSITION_STEPS.RESULT) ?? true }>
                        ${ this.strategy?.stepLabel(POSITION_STEPS.RESULT) ?? STEP_LABELS[POSITION_STEPS.RESULT] }
                    </a>
                </li>
            </ul>
        </nav>

        <div class="step-container" data-part="panels">

            <div class="step-panel" data-part="panel">

                <ill-portfolio></ill-portfolio>

                <ui-tabs ${ ref(this.tabs) }>

                    <div class="tab-list" data-part="triggers">
                        <button class="tab" data-part="trigger">Active Markets</button>
                        <button class="tab" data-part="trigger">Matured Markets</button>
                        <button class="tab" data-part="trigger">Archived Markets</button>
                    </div>

                    <div class="tab-panel-container" data-part="panels">

                        <div class="tab-panel" data-part="panel">
                            <ill-positions .status=${ 'ACTIVE' } @ill-position=${ (event: PositionEvent) => this.handlePositionEvent(event) }></ill-positions>
                        </div>

                        <div class="tab-panel" data-part="panel">
                            <ill-positions .status=${ 'MATURED' } @ill-position=${ (event: PositionEvent) => this.handlePositionEvent(event) }></ill-positions>
                        </div>

                        <div class="tab-panel" data-part="panel">
                            <ill-positions .status=${ 'ARCHIVED' }></ill-positions>
                        </div>

                    </div>

                </ui-tabs>

            </div>

            <div class="step-panel" data-part="panel">
                ${ this.strategy?.stepPanelContent(POSITION_STEPS.PREVIEW) ?? nothing }
            </div>

            <div class="step-panel" data-part="panel">
                ${ this.strategy?.stepPanelContent(POSITION_STEPS.RESULT) ?? nothing }
            </div>

        </div>

    </ui-wizard>
    `;
};

@customElement('ill-positions-page')
export class PositionsPageElement extends LitElement {

    protected logger = serviceLocator.get(LOG_SERVICE).group('positions-page');

    protected errors = serviceLocator.get(ERROR_SERVICE);

    protected router = router();

    protected positionMachine = orchestrator.position;

    protected strategy?: PositionStrategy;

    protected wizard = createRef<WizardElement>();

    protected tabs = createRef<TabsElement>();

    @state()
    step = POSITION_STEPS.POSITION;

    constructor () {

        super();

        this.handleRouteChange = this.handleRouteChange.bind(this);
        this.handlePositionsTransition = this.handlePositionsTransition.bind(this);
    }

    connectedCallback (): void {

        super.connectedCallback();

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.router.subscribe(this.handleRouteChange);

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.positionMachine.onTransition(this.handlePositionsTransition);

        this.positionMachine.send(POSITION.model.events[POSITION.EVENTS.FETCH]());
    }

    disconnectedCallback (): void {

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.router.unsubscribe(this.handleRouteChange);

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.positionMachine.off(this.handlePositionsTransition);

        this.strategy?.dispose();

        super.disconnectedCallback();

        this.step = POSITION_STEPS.POSITION;
    }

    protected createRenderRoot (): Element | ShadowRoot {

        return this;
    }

    protected render (): unknown {

        return template.apply(this);
    }

    protected handlePositionsTransition (state: typeof orchestrator.position.state, event: POSITION.Event): void {

        // ignore state changes that happen on different routes
        if (this.router.activeRoute?.route !== POSITIONS_ROUTE) return;

        this.logger.log(`handlePositionsTransition... ${ event.type } --> ${ state.value.toString() }: `, state.context);

        // if we're on the result step, we can stay there, a position update is not gonna change a previous result
        if (state.matches(POSITION.STATES.FETCHING) && this.step !== POSITION_STEPS.RESULT) {

            this.step = POSITION_STEPS.POSITION;
        }

        if (state.matches(POSITION.STATES.ERROR) && this.step !== POSITION_STEPS.RESULT) {

            this.step = POSITION_STEPS.POSITION;
        }

        this.requestUpdate();
    }

    protected handlePositionEvent (event: PositionEvent): void {

        const { position, type, mode } = event.detail;

        cancel(event);

        this.logger.log('handlePositionsEvent()... ', event);

        this.strategy?.dispose();

        // create a new strategy for the selected position action
        this.strategy = type === POSITION_TYPES.LEND
            ? new LendPositionStrategy(position, mode, this)
            : new PoolPositionStrategy(position, mode, this);

        // let the strategy create a new transaction instance
        this.strategy.createTransaction();

        // and set the step to transaction preview
        this.step = POSITION_STEPS.PREVIEW;

        this.requestUpdate();
    }

    protected handlePanelChange (event: PanelChangeEvent): void {

        switch (event.detail.target) {

            case this.wizard.value:

                this.handleStepChange(event);
                break;

            case this.tabs.value:

                this.handleTabChange(event);
                break;
        }

        cancel(event);
    }

    protected handleStepChange (event: PanelChangeEvent): void {

        const step = event.detail.panel as POSITION_STEPS;

        if (step === this.step) return;

        this.step = step;

        switch (step) {

            case POSITION_STEPS.POSITION:

                this.positionMachine.send(POSITION.model.events[POSITION.EVENTS.FETCH]());
                this.strategy?.dispose();
                this.strategy = undefined;
                break;

            case POSITION_STEPS.PREVIEW:

                this.strategy?.createTransaction();
                break;

            case POSITION_STEPS.RESULT:

                // nothing for now
                break;
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected handleTabChange (event: PanelChangeEvent): void {

        const collapsibles = this.renderRoot.querySelectorAll<CollapsibleElement>('ui-tabs ui-collapsible');

        collapsibles.forEach(collapsible => collapsible.updateHeight());
    }

    protected handleRouteChange (match: RouteMatch): void {

        if (match.route === POSITIONS_ROUTE) {

            // we can simply hijack the step change handler and reset the step
            this.handleStepChange(new PanelChangeEvent({ target: this, panel: POSITION_STEPS.POSITION }));
        }
    }
}
