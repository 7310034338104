import { PanelChangeEvent } from '@swivel-finance/ui/elements/panel-container';
import { html, LitElement } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { ERRORS, NOTIFICATIONS, TOOLTIPS } from '../../core/constants';
import { LendTransaction, LEND_STATUS } from '../../core/markets';
import { ERROR_SERVICE, LOG_SERVICE, serviceLocator } from '../../core/services';
import { STATUS_SERVICE } from '../../core/services/status';
import { TransactionMessage, TransactionTopic, TRANSACTION_SERVICE, TRANSACTION_TOPIC } from '../../core/services/transaction';
import { WALLET_SERVICE } from '../../core/services/wallet';
import { LEND_ROUTE, RouteMatch, router } from '../../routes';
import { createNotification, notifications, NOTIFICATION_TIMEOUTS } from '../../services/notification';
import { message } from '../../shared/templates';
import { ACCOUNT, MARKET, orchestrator } from '../../state/orchestrator';

const template = function (this: LendPageElement) {

    const isPending = this.transaction?.isPending();

    return this.isLenderPaused
        ? message(
            html`
            <div class="content">
                <h2>${ TOOLTIPS.PAUSED.LENDER() }</h2>
                <p>${ TOOLTIPS.PAUSED.MORE_INFO() }</p>
            </div>
            `,
            'pause',
        )
        : html`
        <ui-wizard .current=${ this.step } @ui-panel-changed=${ (event: PanelChangeEvent) => this.handleStepChange(event) }>

            <nav class="step-navigation" aria-label="Lend Steps">
                <ul class="step-list" data-part="triggers">
                    <li class="step-link"><a data-part="trigger" href="#">Select a Currency</a></li>
                    <li class="step-link"><a data-part="trigger" href="#" aria-disabled=${ this.step < LEND_STEPS.MARKET }>Select a Maturity</a></li>
                    <li class="step-link"><a data-part="trigger" href="#" aria-disabled=${ this.step < LEND_STEPS.PREVIEW }>Lend Preview</a></li>
                    <li class="step-link"><a data-part="trigger" href="#" aria-disabled=${ this.step < LEND_STEPS.RESULT }>Lend Result</a></li>
                </ul>
            </nav>

            <div class="step-container" data-part="panels">

                <div class="step-panel" data-part="panel">
                    <ill-lend-token></ill-lend-token>
                    <ill-rate-overview></ill-rate-overview>
                </div>

                <div class="step-panel" data-part="panel">
                    <ill-lend-market></ill-lend-market>
                    <ill-rate-overview></ill-rate-overview>
                </div>

                <div class="step-panel" data-part="panel">
                    <ill-lend-transaction-preview .transaction=${ this.transaction }></ill-lend-transaction-preview>
                </div>

                <div class="step-panel" data-part="panel">
                    ${ isPending
                        ? html`<ill-lend-transaction-status .transaction=${ this.transaction }></ill-lend-transaction-status>`
                        : html`<ill-lend-transaction-result .transaction=${ this.transaction }></ill-lend-transaction-result>`
                    }
                </div>

            </div>

        </ui-wizard>
        `;
};

const enum LEND_STEPS {
    TOKEN,
    MARKET,
    PREVIEW,
    RESULT,
}

@customElement('ill-lend-page')
export class LendPageElement extends LitElement {

    protected logger = serviceLocator.get(LOG_SERVICE).group('lend-page');

    protected errors = serviceLocator.get(ERROR_SERVICE);

    protected status = serviceLocator.get(STATUS_SERVICE);

    protected router = router();

    protected accountMachine = orchestrator.account;

    protected marketMachine = orchestrator.market;

    protected transactionService = serviceLocator.get(TRANSACTION_SERVICE);

    protected walletService = serviceLocator.get(WALLET_SERVICE);

    protected notification?: string;

    @state()
    protected isLenderPaused = false;

    @state()
    protected transaction?: LendTransaction;

    @state()
    protected step = LEND_STEPS.TOKEN;

    constructor () {

        super();

        this.handleRouteChange = this.handleRouteChange.bind(this);
        this.handleMarketTransition = this.handleMarketTransition.bind(this);
        this.handleTransactionChange = this.handleTransactionChange.bind(this);
    }

    connectedCallback (): void {

        super.connectedCallback();

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.router.subscribe(this.handleRouteChange);

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.marketMachine.onTransition(this.handleMarketTransition);

        this.marketMachine.send(MARKET.model.events[MARKET.EVENTS.FETCH]());

        this.status.getStatus().then(
            status => { this.isLenderPaused = status.illuminatePaused; },
            () => { /* we ignore this for now... */ },
        );
    }

    disconnectedCallback (): void {

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.router.unsubscribe(this.handleRouteChange);

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.marketMachine.off(this.handleMarketTransition);

        this.disposeTransaction();

        super.disconnectedCallback();

        this.step = LEND_STEPS.TOKEN;
    }

    protected createRenderRoot (): Element | ShadowRoot {

        return this;
    }

    protected render (): unknown {

        return template.apply(this);
    }

    protected async createTransaction (): Promise<void> {

        if (!this.accountMachine.state.matches(ACCOUNT.STATES.CONNECTED)) return;
        if (!this.marketMachine.state.matches(MARKET.STATES.COMPLETE)) return;

        const connection = this.walletService.state.connection;
        const { markets, quotesByMarket, selectedMarket } = this.marketMachine.state.context;

        const market = markets.get(selectedMarket);
        const quote = quotesByMarket.get(selectedMarket);

        if (!market || !quote) {

            throw this.errors.process(ERRORS.COMPONENTS.LEND.MARKET);
        }

        this.disposeTransaction();

        // we create transactions through a service - this allows other components to detect when a transaction is created
        // and subscribe to it for status changes (e.g. globally notify on failed transactions)
        // and it allows the transaction service to serialize/parse transaction into/from local storage and associate
        // transaction constructors with the serialized data
        this.transaction = this.transactionService.create(LendTransaction, {
            state: {
                market,
                apr: quote.apr,
            },
            connection,
        });

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.transaction.subscribe(TRANSACTION_TOPIC.STATUS, this.handleTransactionChange);
        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.transaction.subscribe(TRANSACTION_TOPIC.STATE, this.handleTransactionChange);

        this.logger.log('createTransation()... ', this.transaction);

        this.requestUpdate();

        await this.updateComplete;
    }

    protected disposeTransaction (): void {

        this.logger.log('disposeTransation()... ', this.transaction);

        // we first want to dispose of ongoing notifications for the current transaction
        this.disposeNotifications();

        // when a call is made to dispose of a transaction, we always want to unsubscribe from future updates,
        // as the lend-page is done with this transaction (it might be further observed by the transaction service)

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.transaction?.unsubscribe(TRANSACTION_TOPIC.STATE, this.handleTransactionChange);
        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.transaction?.unsubscribe(TRANSACTION_TOPIC.STATUS, this.handleTransactionChange);

        // don't dispose of transactions which are submitted to the chain...
        if (this.transaction?.isPending() || this.transaction?.isFinal()) return;

        this.transaction?.dispose();

        this.transaction = undefined;
    }

    protected disposeNotifications (): void {

        if (!this.notification) return;

        if (this.transaction?.isPending()) {

            notifications.update(this.notification, createNotification({
                id: this.notification,
                type: 'info',
                content: NOTIFICATIONS.LEND.TRANSACTION_DISPOSED,
            }));

        } else {

            // ensure the notification will dismiss automatically
            notifications.update(this.notification, { dismissable: true, timeout: NOTIFICATION_TIMEOUTS.info });
        }
    }

    protected handleMarketTransition (state: typeof orchestrator.market.state, event: MARKET.Event): void {

        // ignore state changes that happen on different routes
        if (router().activeRoute?.route !== LEND_ROUTE) return;

        this.logger.log(`handleMarketTransition... ${ event.type } --> ${ state.value.toString() }: `, state.context);

        if (state.matches(MARKET.STATES.FETCHING) || state.matches(MARKET.STATES.SELECT_TOKEN) || (this.step === LEND_STEPS.TOKEN && state.matches(MARKET.STATES.ERROR))) {

            this.step = LEND_STEPS.TOKEN;
        }

        if (state.matches(MARKET.STATES.FETCHING_QUOTES) || state.matches(MARKET.STATES.SELECT_MARKET) || (this.step === LEND_STEPS.MARKET && state.matches(MARKET.STATES.ERROR))) {

            this.step = LEND_STEPS.MARKET;
        }

        if (state.matches(MARKET.STATES.COMPLETE) && this.step === LEND_STEPS.MARKET) {

            void this.createTransaction().then(() => this.step = LEND_STEPS.PREVIEW);
        }

        this.requestUpdate();
    }

    protected handleTransactionChange (message: TransactionMessage<LendTransaction, TransactionTopic>): void {

        this.logger.log('handleTransactionChange()... ', message);

        if (message.topic === TRANSACTION_TOPIC.STATUS) {

            switch (message.transaction.status) {

                case LEND_STATUS.APPROVING:

                    this.notification = notifications.show({
                        type: 'progress',
                        content: NOTIFICATIONS.LEND.APPROVAL_PENDING,
                        dismissable: false,
                    });

                    this.step = LEND_STEPS.RESULT;

                    break;

                case LEND_STATUS.PENDING:

                    if (this.notification) {

                        notifications.update(this.notification, createNotification({
                            id: this.notification,
                            type: 'success',
                            content: NOTIFICATIONS.LEND.APPROVAL_SUCCESS,
                        }));
                    }

                    this.step = LEND_STEPS.RESULT;

                    break;

                case LEND_STATUS.DISPOSED:

                    // transaction is terminal and won't change any longer
                    // eslint-disable-next-line @typescript-eslint/unbound-method
                    message.transaction.unsubscribe(TRANSACTION_TOPIC.STATUS, this.handleTransactionChange);
                    // eslint-disable-next-line @typescript-eslint/unbound-method
                    message.transaction.unsubscribe(TRANSACTION_TOPIC.STATE, this.handleTransactionChange);

                    break;

                case LEND_STATUS.SUCCESS:
                case LEND_STATUS.FAILURE:

                    // transaction is terminal and won't change any longer
                    // eslint-disable-next-line @typescript-eslint/unbound-method
                    message.transaction.unsubscribe(TRANSACTION_TOPIC.STATUS, this.handleTransactionChange);
                    // eslint-disable-next-line @typescript-eslint/unbound-method
                    message.transaction.unsubscribe(TRANSACTION_TOPIC.STATE, this.handleTransactionChange);

                    // if we're coming from APPROVING to FAILURE
                    if (message.transaction.status === LEND_STATUS.FAILURE && message.detail === LEND_STATUS.APPROVING) {

                        if (this.notification) {

                            notifications.update(this.notification, createNotification({
                                id: this.notification,
                                type: 'failure',
                                content: NOTIFICATIONS.LEND.APPROVAL_FAILURE(message.transaction.error?.message ?? 'Unknown error.'),
                            }));

                        } else {

                            this.notification = notifications.show({
                                type: 'failure',
                                content: NOTIFICATIONS.LEND.APPROVAL_FAILURE(message.transaction.error?.message ?? 'Unknown error.'),
                            });
                        }
                    }

                    this.step = LEND_STEPS.RESULT;

                    break;
            }
        }

        this.requestUpdate();
    }

    protected handleStepChange (event: PanelChangeEvent): void {

        this.logger.log('handleStepChange()... ', event);

        const step = event.detail.panel as LEND_STEPS;

        if (step === this.step) return;

        this.step = step;

        switch (step) {

            case LEND_STEPS.TOKEN:

                this.marketMachine.send(MARKET.model.events[MARKET.EVENTS.FETCH]());
                this.disposeTransaction();
                break;

            case LEND_STEPS.MARKET:

                this.marketMachine.send(MARKET.model.events[MARKET.EVENTS.SET_TOKEN](this.marketMachine.state.context.selectedToken));
                this.disposeTransaction();
                break;

            case LEND_STEPS.PREVIEW:

                void this.createTransaction();
                break;

            case LEND_STEPS.RESULT:

                // nothing for now
                break;
        }
    }

    protected handleRouteChange (match: RouteMatch): void {

        if (match.route === LEND_ROUTE) {

            // we can simply hijack the step change handler and reset the step
            this.handleStepChange(new PanelChangeEvent({ target: this, panel: LEND_STEPS.TOKEN }));
        }
    }
}
