import { FixedNumber } from 'ethers';
import { Quote } from '../../types';
import { empty, fixed, trim } from '../amount';
import { SECONDS_PER_YEAR } from '../constants';
import { marketKey } from '../markets/helpers';
import { serviceLocator } from '../services';
import { TIME_SERVICE } from '../services/time';

/**
 * Create a market key from a quote
 *
 * @param q - quote
 */
export const marketKeyFromQuote = (q: Quote): string => marketKey({
    underlying: q.underlying.address,
    maturity: q.maturity,
});

/**
 * Reduces a list of quotes to a map of token address keys and the respective best quote for each token.
 *
 * @param q - a list of quotes
 * @returns a map of best quotes keyed by token address and sorted by APR
 */
export const bestQuotesByToken = (q: Quote[]): Map<string, Quote> => {

    const quotesByToken = new Map<string, Quote>();

    q.forEach(quote => {

        const underlying = quote.underlying.address;
        const apr = parseFloat(quote.apr ?? '0');
        const current = quotesByToken.get(underlying);

        if (!current || parseFloat(current.apr ?? '0') < apr) {

            quotesByToken.set(underlying, quote);
        }
    });

    // sort the map entries by APR
    return new Map([...quotesByToken.entries()].sort((a, b) => sortByAPR(a[1], b[1])));
};

/**
 * Reduces a list of quotes to a map of market keys and the respective best quote for each market.
 *
 * @param q - a list of quotes
 * @returns a map of best quotes keyed by market key and sorted by APR
 */
export const bestQuotesByMarket = (q: Quote[]): Map<string, Quote> => {

    const quotesByMarket = new Map<string, Quote>();

    q.forEach(quote => {

        const market = marketKeyFromQuote(quote);
        const apr = parseFloat(quote.apr ?? '0');
        const current = quotesByMarket.get(market);

        if (!current || parseFloat(current.apr ?? '0') < apr) {

            quotesByMarket.set(market, quote);
        }
    });

    // sort the map entries by APR
    return new Map([...quotesByMarket.entries()].sort((a, b) => sortByAPR(a[1], b[1])));
};

/**
 * A sort function to sort quotes by APR in descending order
 *
 * @param a - a quote
 * @param b - a quote to compare to
 */
export const sortByAPR = (a: Quote, b: Quote): number => {

    const aprA = empty(a.apr) ? 0 : parseFloat(a.apr);
    const aprB = empty(b.apr) ? 0 : parseFloat(b.apr);

    return aprB - aprA;
};

/**
 * Get the best quote from a list of quotes.
 *
 * @param q - a list of quotes
 */
export const bestQuote = (q: Quote[]): Quote => {

    let current = q[0];

    q.forEach(quote => {

        const apr = parseFloat(quote.apr ?? '0');

        if (parseFloat(current.apr ?? '0') < apr) {

            current = quote;
        }
    });

    return current;
};

/**
 * Calculates a factor for scaling a token amount to 18 decimals
 *
 * @param decimals - the decimals of the token to scale
 */
const scaleFactor = (decimals: number): FixedNumber => fixed(`1${ Array(18 - decimals).fill('0').join('') }`);

/**
 * Get a quote's yield at maturity.
 *
 * @remarks
 * The yield at amturity is the amount of underlying earned (excluding the amount of underlying paid).
 *
 * @param q - a quote
 */
export const yieldAtMaturity = (q: Quote): string => {

    const uScale = scaleFactor(q.underlying.decimals);
    const ptScale = scaleFactor(q.pt.decimals);

    // scale underlying amount paid to 18 decimals
    const amountPaid = fixed(q.amount).mulUnsafe(uScale);
    // scale pt amount returned to 18 decimals
    const amountReturned = fixed(q.pt.amount).mulUnsafe(ptScale);
    // pts will be worth 1 underlying at maturity - we can subtract the amount paid and unscale to underlying decimals
    const yieldAtMaturity = amountReturned.subUnsafe(amountPaid).divUnsafe(uScale).floor();

    return trim(yieldAtMaturity.toString());
};

/**
 * Get a quote's amount of iPT purchased.
 *
 * @param q - a quote
 */
export const iPTPurchase = (q: Quote): string => {

    const uScale = scaleFactor(q.underlying.decimals);
    const ptScale = scaleFactor(q.pt.decimals);

    // iPT and underlying have the same number of decimals
    // we scale the PT amount to 18 decimals and then unscale to the underlying decimals
    const iPTPurchase = fixed(q.pt.amount).mulUnsafe(ptScale).divUnsafe(uScale).floor();

    return trim(iPTPurchase.toString());
};

/**
 * Get a quote's iPT price.
 *
 * @param q - a quote
 */
export const iPTPrice = (q: Quote): string => {

    const uScale = scaleFactor(q.underlying.decimals);
    const ptScale = scaleFactor(q.pt.decimals);

    // scale underlying amount paid to 18 decimals
    const amountPaid = fixed(q.amount).mulUnsafe(uScale);
    // scale pt amount returned to 18 decimals
    const amountReturned = fixed(q.pt.amount).mulUnsafe(ptScale);
    // the price is a ratio of 2 token amounts with 18 decimals - no scaling is required
    const iPTPrice = amountPaid.divUnsafe(amountReturned);

    return iPTPrice.toString();
};

/**
 * Get a quote's rate after illuminate fees are deducted.
 *
 * @param q - a quote
 */
export const rateAfterFees = (q: Quote): string => {

    const timeService = serviceLocator.get(TIME_SERVICE);

    const spent = fixed(q.amount);
    const received = fixed(iPTPurchase(q));
    const duration = fixed(q.maturity).subUnsafe(fixed(timeService.time() / 1000));
    const year = fixed(SECONDS_PER_YEAR);

    if (spent.isZero()) return '0';

    // rate = (y / p) - 1)
    const rate = received.divUnsafe(spent).subUnsafe(fixed(1));
    // apr = rate * (a / t)
    const apr = rate.mulUnsafe(year.divUnsafe(duration));

    return apr.toString();
};

/**
 * Get a quote's fee in percent.
 *
 * @param q - a quote
 */
export const feePercentage = (q: Quote): string => {

    const spent = fixed(q.amount);
    const fee = fixed(q.fee);

    if (spent.isZero()) return '0';

    const percent = fee.divUnsafe(spent);

    return percent.toString();
};
