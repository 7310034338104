/**
 * The event detail for a toggle request
 */
export interface ToggleRequestEventDetail {
    // TODO: we can add other element selectors if we want to enable them to be toggled by external events
    element: 'ill-account-popup';
    show?: boolean;
}

/**
 * An event for requesting an element to be toggled
 *
 * @remarks
 * We currently use this to request the account popup to be opened from different locations
 * (i.e. notifications), but the same approach (and event) can be used to toggle any element
 * which supports being toggled.
 */
export class ToggleRequestEvent extends CustomEvent<ToggleRequestEventDetail> {

    type!: 'ill-toggle-request';

    constructor (detail: ToggleRequestEventDetail) {

        super('ill-toggle-request', {
            bubbles: true,
            cancelable: true,
            composed: true,
            detail,
        });
    }
}

/**
 * Add the event to the global HTMLElementEventMap.
 */
declare global {
    interface HTMLElementEventMap {
        'ill-toggle-request': ToggleRequestEvent;
    }
}
