import { LitElement } from 'lit';
import { LendTransaction } from '../../core/markets';
import { serviceLocator } from '../../core/services';
import { TRANSACTION_TOPIC } from '../../core/services/transaction';
import { WALLET_SERVICE } from '../../core/services/wallet';
import { ACCOUNT, orchestrator } from '../../state/orchestrator';

export abstract class LendTransactionBaseElement extends LitElement {

    protected accountMachine = orchestrator.account;

    protected walletService = serviceLocator.get(WALLET_SERVICE);

    protected _transaction?: LendTransaction;

    get transaction (): LendTransaction | undefined {

        return this._transaction;
    }

    set transaction (value: LendTransaction | undefined) {

        const previous = this._transaction;

        // when setting a new transaction instance on the transaction element, we
        // unsubscribe from any previous transaction (it's now discarded or in
        // the global pending transaction queue) and subscribe to the new one

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this._transaction?.unsubscribe(TRANSACTION_TOPIC.STATUS, this.handleTransactionChange);
        // eslint-disable-next-line @typescript-eslint/unbound-method
        this._transaction?.unsubscribe(TRANSACTION_TOPIC.STATE, this.handleTransactionChange);

        this._transaction = value;

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this._transaction?.subscribe(TRANSACTION_TOPIC.STATUS, this.handleTransactionChange);
        // eslint-disable-next-line @typescript-eslint/unbound-method
        this._transaction?.subscribe(TRANSACTION_TOPIC.STATE, this.handleTransactionChange);

        // when using custom getters/setters for lit-element properties,
        // we need to request updates manually
        this.requestUpdate('transaction', previous);
    }

    constructor () {

        super();

        this.handleAccountTransition = this.handleAccountTransition.bind(this);
        this.handleTransactionChange = this.handleTransactionChange.bind(this);
    }

    connectedCallback (): void {

        super.connectedCallback();

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.accountMachine.onTransition(this.handleAccountTransition);
    }

    disconnectedCallback (): void {

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.accountMachine.off(this.handleAccountTransition);
        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.transaction?.unsubscribe(TRANSACTION_TOPIC.STATUS, this.handleTransactionChange);
        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.transaction?.unsubscribe(TRANSACTION_TOPIC.STATE, this.handleTransactionChange);

        super.disconnectedCallback();
    }

    protected handleAccountTransition (): void {

        if (this.transaction) {

            this.transaction.connection = this.accountMachine.state.matches(ACCOUNT.STATES.CONNECTED)
                || this.accountMachine.state.matches(ACCOUNT.STATES.FETCHING)
                ? this.walletService.state.connection
                : undefined;
        }

        this.requestUpdate();
    }

    protected handleTransactionChange (): void {

        this.requestUpdate();
    }
}
