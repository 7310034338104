/* eslint-disable @typescript-eslint/unbound-method */
import { Subscriber } from '../../../types';
import { ERRORS } from '../../constants';
import { ENV } from '../../env';
import { ErrorService } from '../errors';
import { InteractivityService, InteractivityState } from '../interactivity';
import { LogService } from '../logger';
import { TimeService } from '../time';
import { ILLUMINATE_EVENTS } from './constants';
import { IlluminateEvent, IlluminateMessage, MessageService, SNSMessage } from './interfaces';

/**
 * The topic wildcard to subscribe to all events received via Illuminate's web socket.
 */
const WILDCARD = '*';

/**
 * The MessagesService class
 */
export class BrowserMessageService implements MessageService {

    protected connectionPromise?: Promise<void>;

    protected keepAlive?: number;

    protected socket?: WebSocket;

    protected interactivity: InteractivityService;

    protected timer: TimeService;

    protected errors: ErrorService;

    protected logger: LogService;

    /**
     * Stores subscribers and prevents duplicate subscriptions
     */
    protected subscribers = new Set<Subscriber<IlluminateMessage>>();

    /**
     * Stores subscribed topics with their subscriber
     */
    protected topics = new WeakMap<Subscriber<IlluminateMessage>, Set<string>>();

    constructor (interactivity: InteractivityService, timer: TimeService, errors: ErrorService, logger: LogService) {

        this.interactivity = interactivity;
        this.timer = timer;
        this.errors = errors;
        this.logger = logger.group('messages-service');

        this.handleOpen = this.handleOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleMessage = this.handleMessage.bind(this);
        this.handleError = this.handleError.bind(this);
        this.handleInteractivityChange = this.handleInteractivityChange.bind(this);
    }

    subscribe (subscriber: Subscriber<IlluminateMessage>, topic?: IlluminateEvent | IlluminateEvent[]): void {

        const topics = ([] as string[]).concat(topic ?? []);
        const subscribedTopics = this.topics.get(subscriber) ?? new Set();

        if (!topics.length) {

            subscribedTopics.add(WILDCARD);

        } else {

            topics.forEach(topic => subscribedTopics.add(topic));
        }

        this.subscribers.add(subscriber);
        this.topics.set(subscriber, subscribedTopics);
    }

    unsubscribe (subscriber: Subscriber<IlluminateMessage>, topic?: IlluminateEvent | IlluminateEvent[]): boolean {

        const topics = ([] as string[]).concat(topic ?? []);
        const subscribedTopics = this.topics.get(subscriber);

        if (!topics.length) {

            return this.subscribers.delete(subscriber) || this.topics.delete(subscriber);
        }

        let unsubscribed = false;

        topics.forEach(topic => unsubscribed = subscribedTopics?.delete(topic) || unsubscribed);

        if (!subscribedTopics?.size) {

            return this.subscribers.delete(subscriber) || this.topics.delete(subscriber);
        }

        return unsubscribed;
    }

    connected (): boolean {

        return this.socket?.readyState === WebSocket.OPEN;
    }

    connect (): Promise<void> {

        const readyState = this.socket?.readyState;

        switch (readyState) {

            case WebSocket.CONNECTING:

                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                return this.connectionPromise!;

            case WebSocket.OPEN:

                return Promise.resolve();

            default:

                this.disconnect();

                this.connectionPromise = new Promise(resolve => {

                    this.logger.log('connect new instance...');

                    // we send a version param to the websocket to receive the correct messages
                    this.socket = new WebSocket(ENV.socketUrl);

                    // resolve the connectionPromise when websocket is open
                    this.socket.onopen = () => resolve();

                    this.socket.addEventListener('open', this.handleOpen);
                    this.socket.addEventListener('close', this.handleClose);
                    this.socket.addEventListener('message', this.handleMessage);
                    this.socket.addEventListener('error', this.handleError);

                    this.interactivity.subscribe(this.handleInteractivityChange);

                    // keep the websocket connection alive
                    this.keepAlive = window.setInterval(() => void this.ping(), ENV.socketInterval);
                });

                return this.connectionPromise;
        }
    }

    disconnect (): void {

        this.logger.log('disconnect...');

        if (this.socket?.readyState === WebSocket.CONNECTING || this.socket?.readyState === WebSocket.OPEN) {

            this.socket?.close();
        }

        this.socket?.removeEventListener('open', this.handleOpen);
        this.socket?.removeEventListener('close', this.handleClose);
        this.socket?.removeEventListener('message', this.handleMessage);
        this.socket?.removeEventListener('error', this.handleError);

        this.interactivity.unsubscribe(this.handleInteractivityChange);

        window.clearInterval(this.keepAlive);

        this.socket = undefined;
    }

    protected async ping () {

        const data = JSON.stringify({
            action: 'ping',
            message: 'ping',
        });

        // if we somehow disconnected, reconnect
        if (!this.connected()) {

            await this.connect();
        }

        this.socket?.send(data);
    }

    protected handleOpen (e: Event) {

        this.logger.log('open: ', e);
    }

    protected handleClose (e: CloseEvent) {

        this.logger.log('close: ', e);

        // error events usually lead to a termination of the websocket connection and a successive close event
        // the code `1000` means 'normal closure' which is fine, but for everything else we should reconnect
        if (e.code !== 1000 || !e.wasClean) {

            this.logger.log('close: attempting to reconnect...');

            void this.connect();
        }
    }

    protected handleMessage (e: MessageEvent) {

        const message = this.parseMessage(e);

        this.logger.log('message: ', message);

        if (!message) return;

        this.subscribers.forEach(subscriber => {

            if (this.matchTopic(subscriber, message.name)) subscriber(message);
        });
    }

    protected handleError (e: Event) {

        this.errors.process(e, ERRORS.SERVICES.WEBSOCKET.ERROR);
    }

    protected handleInteractivityChange (s: InteractivityState) {

        this.logger.log('interactivity change: %o, connected: %s', s, this.connected());

        const shouldConnect = s.connected && s.visible && !this.connected();

        if (shouldConnect) {

            void this.connect();
        }
    }

    /**
     * Parse a message received over the websocket connection
     *
     * @param e - websocket message event
     */
    protected parseMessage (e: MessageEvent): IlluminateMessage | undefined {

        let message: IlluminateMessage | undefined;

        if (e.data === 'pong') {

            // we create a special message for the keepAlive 'pong' message for debugging purposes
            message = { name: ILLUMINATE_EVENTS.PONG, timestamp: this.timer.time() / 1000 } as IlluminateMessage;

        } else {

            try {

                const data = JSON.parse(e.data as string) as SNSMessage[];

                message = JSON.parse(data[0].Sns.Message) as IlluminateMessage;

            } catch (error) {

                this.errors.process(error, ERRORS.SERVICES.WEBSOCKET.PARSE_ERROR);
            }
        }

        return message;
    }

    /**
     * Matches a subscriber's topics with a message's name
     *
     * @remarks
     * Topic matching is very simple: '*' means any message, otherwise it's a simple `String.startsWith` check, e.g.:
     * - topic 'ORDER' matches message name 'ORDER.CREATE' | 'ORDER.INITIATE' |...
     * - topic 'ORDER.CREATE' matches message name 'ORDER.CREATE'
     *
     * @param s - subscriber callback
     * @param n - message name
     * @returns `true` if the subscriber's topics match the message's name, `false` otherwise
     */
    protected matchTopic (s: Subscriber<IlluminateMessage>, n: string): boolean {

        if (!this.topics.has(s)) return false;

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const topics = this.topics.get(s)!;

        // we hide the keepAlive 'PONG' message from wildcard subscriptions - only explicit subscriptions will get it
        return (n !== ILLUMINATE_EVENTS.PONG && topics.has(WILDCARD))
            || [...topics.values()].some(topic => n.startsWith(topic));
    }
}
