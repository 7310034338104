import { IDGenerator } from '@swivel-finance/ui/utils/dom';
import { ErrorService, ERROR_SERVICE, serviceLocator } from '../../core/services';
import { NOTIFICATION_ICONS, NOTIFICATION_TIMEOUTS } from './constants';
import type { NotificationOutletElement } from './notification-outlet';
import { Notification, NotificationOptions } from './types';

// we can keep these errors local, they are not user facing and exist for diagnostic reasons
const NO_OUTLET = 'Error showing notification: No notification outlet found in page.';
const UNKNOWN_OUTLET = 'Error showing notification: The specified outlet is not connected.';

const idGenerator = new IDGenerator('ill-notification-');

export const createNotification = (n: NotificationOptions): Notification => {

    const id = n.id ?? idGenerator.getNext();
    const type = n.type ?? 'info';
    const icon = n.icon ?? NOTIFICATION_ICONS[type];
    const timeout = n.timeout ?? NOTIFICATION_TIMEOUTS[type];
    const dismissable = n.dismissable ?? true;

    return {
        ...n,
        id,
        type,
        icon,
        timeout,
        dismissable,
    };
};

class NotificationService {

    protected outlets = new Map<string, NotificationOutletElement>();

    protected notifications = new Map<string, string>();

    protected defaultOutlet: string | undefined;

    protected errors: ErrorService;

    constructor (errors: ErrorService) {

        this.errors = errors;
    }

    connect (o: NotificationOutletElement) {

        this.outlets.set(o.id, o);

        if (!this.defaultOutlet) {

            this.defaultOutlet = o.id;
        }
    }

    disconnect (o: NotificationOutletElement) {

        if (this.outlets.has(o.id)) {

            this.outlets.delete(o.id);
        }
    }

    show (n: NotificationOptions, o?: string): string {

        const outlet = this.getOutlet(o);

        const id = outlet.show(createNotification(n));

        this.notifications.set(id, outlet.id);

        return id;
    }

    dismiss (i: string) {

        const outlet = this.getOutlet(this.notifications.get(i));

        outlet.dismiss(i);

        this.notifications.delete(i);
    }

    update (i: string, n: NotificationOptions) {

        const outlet = this.getOutlet(this.notifications.get(i));

        outlet.refresh(i, n);
    }

    protected getOutlet (o?: string): NotificationOutletElement {

        let outlet: NotificationOutletElement;

        if (o) {

            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            outlet = this.outlets.get(o)!;

            if (!outlet) throw this.errors.process(new Error(UNKNOWN_OUTLET));

        } else {

            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            outlet = this.outlets.get(this.defaultOutlet || '')!;

            if (!outlet) throw this.errors.process(new Error(NO_OUTLET));
        }

        return outlet;
    }
}

export const notifications = new NotificationService(
    serviceLocator.get(ERROR_SERVICE),
);
