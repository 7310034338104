import { html, nothing } from 'lit';
import { rate, tokenBalance, tokenSymbol } from '../../shared/templates/amounts';
import { Token } from '../../types';
import { expandAmount } from '../amount';
import { ENV } from '../env';
import { QUOTE_PREVIEW_AMOUNT, QUOTE_PREVIEW_AMOUNT_ETHER } from './constants';
import { isETHToken } from './tokens';

export const TOOLTIPS = {
    ACCOUNT: {
        COPY_ADDRESS: 'Click to copy address to clipboard.',
        DISCONNECT: 'Disconnect your wallet.',
    },
    LEND: {
        NO_QUOTES: (token?: Token) => html`There are currently no quotes available for ${ token ? tokenSymbol(token) : 'this token' }.`,
        SELECT_CURRENCY: {
            HEADER: () => html`Select a currency to lend. Illuminate will find the PTs with the most discounted price.`,
            BEST_RATE: (token?: Token) => html`
                <p>APR when lending ${ token ? tokenBalance(expandAmount(isETHToken(token) ? QUOTE_PREVIEW_AMOUNT_ETHER : QUOTE_PREVIEW_AMOUNT, token), token, 2) : `${ QUOTE_PREVIEW_AMOUNT } underlying` }.</p>
                <p>Rate may vary based upon amount lent, selected maturity, and best PT source.</p>
                `,
        },
        SELECT_MATURITY: {
            HEADER: () => html`Select a maturity. Illuminate will find the PTs with the most discounted price.`,
            BEST_RATE: (token?: Token) => html`
                <p>APR when lending ${ token ? tokenBalance(expandAmount(isETHToken(token) ? QUOTE_PREVIEW_AMOUNT_ETHER : QUOTE_PREVIEW_AMOUNT, token), token, 2) : `${ QUOTE_PREVIEW_AMOUNT } underlying` }.</p>
                <p>Rate may vary based upon amount lent and best PT source.</p>
                `,
        },
        PREVIEW: {
            RATE_PREVIEW: (illuminateRate?: string) => html`
                <p>The rate offered by the source lending protocol. Illuminate takes a small fee for facilitating the lend operation and minting the iPT, which will save you additional transaction fees.</p>
                ${ illuminateRate
                    ? html`<p>Your effective rate after Illuminate fees is ${ rate(illuminateRate, '%', false) }.</p>`
                    : nothing
                }
                `,
            SOURCE: () => html`Illuminate aggregates from multiple fixed rate protocols. We source liquidity from the protocol which offers the best price for PTs for the lending amount set.`,
            IPT_PRICE: (token?: Token) => html`The discounted price you are paying per iPT. Each iPT will be worth ${ token ? tokenBalance(expandAmount('1', token), token, 2) : '1 underlying' } at maturity.`,
        },
        RESULT: {
            // TODO: ask feedback
            RATE: () => html`The effective annual percentage rate after Illuminate fees.`,
        },
        IPT_PURCHASED: (token?: Token) => html`The amount of ${ token ? tokenSymbol(token) : 'underlying' } redeemable at maturity.`,
        YIELD_AT_MATURITY: () => html`The amount you made from buying PTs at a discount.`,
        MATURES: (amount?: string, token?: Token) => html`The time at which iPTs can be redeemed${ amount && token ? html` for ${ tokenBalance(amount, token, 2) }` : ' in full' }.`,
        FEE: (fee?: string) => html`The fee taken by illuminate${ fee ? html` (${ rate(fee, undefined, false, 2) })` : nothing }.`,
    },
    REDEEM: {
        PROCEEDS: (token?: Token) => html`The amount of ${ token ? tokenSymbol(token) : 'underlying' } received for your iPTs.`,
        // EFFECTIVE_RATE: () => html`The effective rate represents a comparable annual percentage rate considering the price of your ${ tokenSymbol(iPT()) } purchases and the duration you held your ${ tokenSymbol(iPT()) } in the market.`,
        EFFECTIVE_RATE: () => html`Your effective rate represents the total of your accrued fixed-yield and any market shifts that occurred before exiting.`,
        IPT_PRICE: () => html`The price you are selling your iPTs for.`,
        EXIT_COST: () => html`The unrealized gain sacrificed by redeeming iPTs before maturity.`,
        // TODO: ask feedback
        MATURES: (amount?: string, token?: Token) => html`The time at which iPTs can be redeemed${ amount && token ? html` for ${ tokenBalance(amount, token, 2) }` : ' in full' }.`,
    },
    POOL: {
        SELECT_CURRENCY: {
            // TODO: ask feedback
            HEADER: () => html`Select a currency to provide liquidity for. By providing liquidity to a pool, you are earning fees based on trades and passive interest.`,
        },
        SELECT_MATURITY: {
            // TODO: ask feedback
            HEADER: () => html`Select a maturity to provide liquidity for. By providing liquidity to a pool, you are earning fees based on trades and passive interest.`,
        },
        BEST_RATE: (token?: Token) => html`The highest available APR of a pool trading ${ token ? tokenSymbol(token) : 'this token' }.`,
        // TODO: ask feedback:
        // - Liquidity Provider APR vs. Pool APR?
        // - Always Euler?
        // - We always show a tilde, as rate does vary over time, based on trade activity (fee generation) and passive yield. Should we mention that?
        RATE: () => html`Pool APR comprises of both trading fees and passive yield generation through ${ ENV.yieldSpaceSharesProtocol }.`,
        // CURRENT_RATE: () => html`The current APR of this pool.`,
        // EFFECTIVE_RATE: () => html`The effective rate represents a comparable annual percentage rate considering the price of your ${ tokenSymbol(iPT()) } purchases and the duration you held your ${ tokenSymbol(iPT()) } in the market.`,
        SHARE: () => html`Your portion of the pool's accrued fees.`,
        USE_OWNED_IPT: () => html`Enable this option if you want to add liquidity using iPTs you already own.<br><a href="${ ENV.docsUrl }" target="_blank">More information</a>`,
        BURN_FOR_UNDERLYING: () => html`Disable this option if you don't want to immediately sell the iPTs received from burning your LPs.<br><a href="${ ENV.docsUrl }" target="_blank">More information</a>`,
        NO_ACTIVE_POOLS: (token?: Token) => html`There are currently no active pools available for ${ token ? tokenSymbol(token) : 'this token' }.`,
    },
    POSITION: {
        LEND: {
            EXIT: () => html`Exit early to redeem any amount of iPTs for the current market price.`,
            // TODO: ask feedback
            REDEEM: (token?: Token) => html`Redeem your iPTs 1:1 for ${ token ? tokenSymbol(token) : 'underlying' }.`,
            LEND: (token?: Token) => html`Lend additional ${ token ? tokenSymbol(token) : 'underlying' } by purchasing more PTs at the best possible price.`,
            VALUE_AT_MATURITY: (token?: Token) => html`The amount of ${ token ? tokenSymbol(token) : 'underlying' } redeemable at maturity.`,
            // TODO: ask feedback
            REDEMPTION_VALUE: (token?: Token) => html`The amount of ${ token ? tokenSymbol(token) : 'underlying' } redeemable.`,
            // TODO: ask feedback: "...if you sell all iPTs now."?
            CURRENT_VALUE: (token?: Token, currentValue?: string) => html`The amount of ${ token ? tokenSymbol(token) : 'underlying' } received if you redeem all iPTs now.${ !currentValue ? html`<br><span class="error">We are unable to retrieve your position's current value. This is likely due to the pool not having enough liquidity for early exit.</span>` : nothing }`,
            INTEREST_EARNED: () => html`Your interest earned represents your accrued fixed-yield as well as any further market activity or general market shifts that have occurred since initiating your position.`,
            // TODO: ask feedback
            EFFECTIVE_RATE: () => html`The effective annual percentage rate your lending position has achieved over the duration of the market.`,
        },
        POOL: {
            EXIT: () => html`Remove liquidity from the pool.`,
            EXIT_DIVESTED: () => html`You can exit a portion of your position or the complete balance. A complete exit will retire the position. Any balance left will automatically roll over.`,
            ADD_LIQUIDITY: (token?: Token) => html`Add more ${ token ? tokenSymbol(token) : 'underlying' } and iPTs to the pool.`,
            SHARE: () => html`Your portion of the pool's accrued fees.`,
            POSITION_VALUE: (token?: Token) => html`The amount of ${ token ? tokenSymbol(token) : 'underlying' } and iPT that your LP is worth.`,
            POSITION_VALUE_DIVESTED: (token?: Token) => html`The amount of ${ token ? tokenSymbol(token) : 'underlying' } your pool position is worth.`,
            CURRENT_RATE: () => html`The current rate represents a comparable annual percentage rate based on the pool's LP token value increase, averaged over the last week.`,
            AVERAGE_RATE: () => html`The average rate represents a comparable annual percentage rate based on the pool's LP token value increase, averaged over the pool's lifetime.`,
            DIVESTING: () => html`The pool is awaiting redemption. You will be able to redeem your position momentarily.`,
        },
    },
    PAUSED: {
        LENDER: () => html`Lending has been temporarily paused.`,
        MARKET: () => html`Redemption has been temporarily paused.`,
        POOL: () => html`This pool has been temporarily paused.`,
        QUOTES: () => html`This market has been temporarily paused.`,
        EARLY_EXIT: () => html`Early exits have been temporarily paused.`,
        MORE_INFO: () => html`For more information please visit our <a href="${ ENV.discordUrl }" target="_blank">Discord</a>.`,
    },
} as const;
