import { DEFAULT_LOCALE } from '../../core/constants/constants';

/**
 * Locale dependent date formatters.
 */
export const DATE_FORMAT = {
    LONG: new Intl.DateTimeFormat(DEFAULT_LOCALE, { dateStyle: 'long' }),
    MEDIUM: new Intl.DateTimeFormat(DEFAULT_LOCALE, { dateStyle: 'medium' }),
    SHORT: new Intl.DateTimeFormat(DEFAULT_LOCALE, { year: 'numeric', month: '2-digit', day: '2-digit' }),
};

/**
 * Creates a native timestamp from a Date, number or string in milliseconds.
 *
 * @remarks
 * If the value is a string, we assume it's a unix timestamp and parse to number and convert it to ms.
 * (Usually timestamps retrieved from an API or smart contract are unix timestamps as strings,
 * whereas timestamps in JavaScript will typically be Dates or numbers in milliseconds.)
 */
export const nativeTimestamp = (value: Date | number | string): number => {

    return (value instanceof Date)
        ? value.getTime()
        : (typeof value === 'number')
            ? value
            : parseInt(value) * 1000;
};

/**
 * Formats a timestamp as human-readable date.
 *
 * @param timestamp - timestamp (if a string is passed we assume it's a unix timestamp)
 * @param format - optional `Intl.DateTimeFormat`
 */
export const formatDate = (timestamp: string | number | Date, format = DATE_FORMAT.LONG): string => {

    const date = new Date(nativeTimestamp(timestamp));

    return format.format(date);
};
