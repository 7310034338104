import { StrategyState } from '@swivel-finance/illuminate-js';
import { PanelDirection, PanelNavigationEvent } from '@swivel-finance/ui/elements/panel-container';
import { ToggleChangeEvent } from '@swivel-finance/ui/elements/toggle/events';
import { cancel, dispatch } from '@swivel-finance/ui/utils/events';
import { BigNumber } from 'ethers';
import { html, nothing, PropertyValues } from 'lit';
import { customElement } from 'lit/decorators.js';
import { contractAmount, emptyOrZero, expandAmount } from '../../core/amount';
import { TOOLTIPS } from '../../core/constants';
import { AddLiquidityTransaction, ADD_LIQUIDITY_STATUS } from '../../core/pools';
import { iPT, lpT } from '../../core/services/token';
import { debounce } from '../../shared/helpers';
import { date, errorMessage, rate, tokenBalance } from '../../shared/templates';
import { SETTINGS_TYPE } from '../transaction-settings/transaction-settings';
import { PoolTransactionBaseElement } from './pool-transaction-base';

const invalidStateTemplate = function (this: AddLiquidityTransactionPreviewElement) {

    const { pool } = this.transaction?.state ?? {};

    return html`
    <div class="widget">

        <div class="widget-header">
            <button class="button-icon" @click=${ () => dispatch(this, new PanelNavigationEvent({ target: this, panel: PanelDirection.PREVIOUS })) }>
                <ui-icon name="back-circle"></ui-icon>
            </button>
            <label>
                <ill-token-symbol .name=${ pool?.token.image } .ipt=${ true }></ill-token-symbol>
                ${ date(pool?.maturity) }
            </label>
        </div>

        <div class="widget-main">

            ${ errorMessage('No liquidity can be added to this pool at this time.', 'exclamation') }

            <button class="primary" ?disabled=${ true }>Add Liquidity</button>

        </div>

    </div>
    `;
};

const template = function (this: AddLiquidityTransactionPreviewElement) {

    const isReady = !!this.transaction?.canAddLiquidity();
    const isFetching = this.transaction?.status === ADD_LIQUIDITY_STATUS.UPDATING;
    const hasError = this.transaction?.status === ADD_LIQUIDITY_STATUS.ERROR;

    const { pool, info: poolInfo, iPTAmount, iptMax, underlyingAmount, underlyingMax, useIPT, preview } = this.transaction?.state ?? {};
    const { yieldSpaceInfo, iPTBalance, lpBalance, lpShare, underlyingBalance, totalLiquidity } = poolInfo ?? {};
    const { baseBalance, realFYTokenBalance } = yieldSpaceInfo ?? {};

    const ipToken = pool ? iPT(pool) : undefined;
    const lpToken = pool ? lpT(pool) : undefined;

    return html`
    <div class="widget">

        <div class="widget-header">
            <button class="button-icon" @click=${ () => dispatch(this, new PanelNavigationEvent({ target: this, panel: PanelDirection.PREVIOUS })) }>
                <ui-icon name="back-circle"></ui-icon>
            </button>
            <label>
                <ill-token-symbol .name=${ pool?.token.image } .ipt=${ true }></ill-token-symbol>
                ${ date(pool?.maturity) }
            </label>
            <ill-transaction-settings .type=${ SETTINGS_TYPE.POOL }></ill-transaction-settings>
        </div>

        <div class="widget-inputs">
            <div class="use-owned-ipt">
                <label class="use-owned-ipt">
                    <ui-toggle
                        .checked=${ useIPT }
                        .disabled=${ emptyOrZero(iPTBalance?.balance) }
                        @ui-toggle-changed=${ (event: ToggleChangeEvent) => this.handleUseIPTChange(event) }>
                    </ui-toggle>
                    Use owned iPT
                </label>
                <ui-icon name="question" aria-describedby="use-owned-ipt-tooltip"></ui-icon>
                <ui-tooltip id="use-owned-ipt-tooltip">
                    ${ TOOLTIPS.POOL.USE_OWNED_IPT() }
                </ui-tooltip>
            </div>

            <ill-token-input
                .value=${ underlyingAmount && underlyingBalance ? contractAmount(underlyingAmount, underlyingBalance) : '' }
                .token=${ underlyingBalance }
                .balance=${ underlyingBalance ? contractAmount(underlyingBalance.balance, underlyingBalance) : '0' }
                .max=${ underlyingMax && underlyingBalance ? contractAmount(underlyingMax, underlyingBalance) : '0' }
                .disabled=${ this.transaction?.isPending() || this.transaction?.isFinal() || false }
                @change=${ (event: CustomEvent<{ value: string; }>) => this.handleAmountChange(event, 'underlying') }>
            </ill-token-input>

            <div class="max-amount">
                <a href="#" @click=${ (event: MouseEvent) => this.handleMaxAmount(event, 'underlying') }>
                    Max amount: ${ tokenBalance(underlyingMax, underlyingBalance) }
                </a>
            </div>

            ${ useIPT
                ? html`
                <ill-token-input
                    .value=${ iPTAmount && iPTBalance ? contractAmount(iPTAmount, iPTBalance) : '' }
                    .token=${ iPTBalance }
                    .balance=${ iPTBalance ? contractAmount(iPTBalance.balance, iPTBalance) : '0' }
                    .max=${ iptMax && iPTBalance ? contractAmount(iptMax, iPTBalance) : '0' }
                    .disabled=${ this.transaction?.isPending() || this.transaction?.isFinal() || false }
                    @change=${ (event: CustomEvent<{ value: string; }>) => this.handleAmountChange(event, 'ipt') }>
                </ill-token-input>

                <div class="max-amount">
                    <a href="#" @click=${ (event: MouseEvent) => this.handleMaxAmount(event, 'ipt') }>
                        Max amount: ${ tokenBalance(iptMax, iPTBalance) }
                    </a>
                </div>
                `
                : nothing
            }

        </div>

        <div class="widget-main">

            ${ hasError
                ? errorMessage(this.transaction?.error?.message ?? 'An unknown error occurred.', 'exclamation')
                : nothing
            }

            <div class="preview">

                <ill-preview-item
                    class="preview-rate"
                    .label=${ 'Pool APR:' }
                    .current=${ rate(pool?.apr, '% APR') }
                    .tooltip=${ TOOLTIPS.POOL.RATE() }
                    .loading=${ isFetching }
                    .highlight=${ true }></ill-preview-item>

                <ill-preview-item
                    .current=${ html`<ill-share-rate .rate=${ pool?.apr } .underlying=${ pool?.token.symbol } .type=${ 'pool' }></ill-share-rate>` }
                    .loading=${ isFetching }></ill-preview-item>

                <ill-preview-item
                    .label=${ 'Pool Share:' }
                    .current=${ rate(lpShare || 0, '%', false) }
                    .preview=${ preview ? rate(preview.share || 0, '%', false) : undefined }
                    .tooltip=${ TOOLTIPS.POOL.SHARE() }
                    .loading=${ isFetching }></ill-preview-item>

                <ill-preview-item
                    .label=${ 'LP Tokens:' }
                    .current=${ tokenBalance(lpBalance?.balance ?? '0', lpToken) }
                    .preview=${ preview ? tokenBalance(BigNumber.from(lpBalance?.balance ?? '0').add(BigNumber.from(preview.lpTokensMinted)).toString(), lpToken) : undefined }
                    .loading=${ isFetching }></ill-preview-item>

                <ui-collapsible class="preview-details">
                    <h3 data-part="header">
                        <button class="ghost" data-part="trigger">Details <ui-icon name="chevron"></ui-icon></button>
                    </h3>
                    <div data-part="region">

                        <ill-preview-item
                            .label=${ 'Total Liquidity:' }
                            .current=${ tokenBalance(totalLiquidity, pool?.token) }
                            .preview=${ preview ? tokenBalance(preview.totalLiquidity, pool?.token) : undefined }
                            .loading=${ isFetching }></ill-preview-item>

                        <ill-preview-item
                            .label=${ 'Underlying Supply:' }
                            .current=${ tokenBalance(baseBalance, pool?.token) }
                            .preview=${ preview ? tokenBalance(preview.baseBalance, pool?.token) : undefined }
                            .loading=${ isFetching }></ill-preview-item>

                        <ill-preview-item
                            .label=${ 'iPT Supply:' }
                            .current=${ tokenBalance(realFYTokenBalance, ipToken) }
                            .preview=${ preview ? tokenBalance(preview.realFYTokenBalance, ipToken) : undefined }
                            .loading=${ isFetching }></ill-preview-item>

                    </div>
                </ui-collapsible>

            </div>

            <button class="primary" ?disabled=${ !isReady } @click=${ () => this.addLiquidity() }>Add Liquidity</button>

        </div>

    </div>
    `;
};

@customElement('ill-add-liquidity-transaction-preview')
export class AddLiquidityTransactionPreviewElement extends PoolTransactionBaseElement<AddLiquidityTransaction> {

    constructor () {

        super();

        this.handleAmountChange = debounce(this.handleAmountChange.bind(this), 500);
    }

    protected createRenderRoot (): Element | ShadowRoot {

        return this;
    }

    protected render (): unknown {

        const strategy = this.transaction?.state.info?.strategyInfo;
        const validState = !strategy || strategy?.state === StrategyState.INVESTED;

        return !validState
            ? invalidStateTemplate.apply(this)
            : template.apply(this);
    }

    protected updated (changes: PropertyValues<AddLiquidityTransactionPreviewElement>): void {

        if (changes.has('transaction')) {

            void this.transaction?.update();
        }
    }

    protected handleUseIPTChange (event: ToggleChangeEvent): void {

        cancel(event);

        if (this.transaction?.isFinal() || this.transaction?.isPending()) return;

        void this.transaction?.setUseIPT(event.detail.checked);
    }

    protected handleAmountChange (event: InputEvent | CustomEvent<{ value: string; }>, type: 'underlying' | 'ipt'): void {

        if (this.transaction?.isFinal() || this.transaction?.isPending()) {

            cancel(event);
            return;
        }

        const amount = ((event as CustomEvent<{ value: string; }>).detail.value !== undefined)
            ? (event as CustomEvent<{ value: string; }>).detail.value
            : (event.target as HTMLInputElement).value;

        const pool = this.transaction?.state.pool;

        if (!pool) return;

        (type === 'underlying')
            ? this.transaction?.setUnderlyingAmount(expandAmount(amount, pool.token))
            : this.transaction?.setIPTAmount(expandAmount(amount, iPT(pool)));
    }

    protected handleMaxAmount (event: MouseEvent, type: 'underlying' | 'ipt'): void {

        cancel(event);

        if (this.transaction?.isFinal() || this.transaction?.isPending()) return;

        const pool = this.transaction?.state.pool;
        const max = (type === 'underlying')
            ? this.transaction?.state.underlyingMax
            : this.transaction?.state.iptMax;

        if (!pool) return;

        (type === 'underlying')
            ? this.transaction?.setUnderlyingAmount(max)
            : this.transaction?.setIPTAmount(max);
    }

    protected retry (): void {

        void this.transaction?.update();
    }

    protected addLiquidity (): void {

        void this.transaction?.send();
    }
}
