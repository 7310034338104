import { PanelDirection, PanelNavigationEvent } from '@swivel-finance/ui/elements/panel-container';
import { cancel, dispatch } from '@swivel-finance/ui/utils/events';
import { html, nothing } from 'lit';
import { customElement } from 'lit/decorators.js';
import { contractAmount, emptyOrZero, expandAmount, fixed } from '../../core/amount';
import { TOOLTIPS } from '../../core/constants';
import { matured, maturityDays, MATURITY_DATE_FORMAT, REDEEM_STATUS } from '../../core/markets';
import { earlyExitCost, effectiveAPR, positionAPR } from '../../core/positions';
import { iPT } from '../../core/services/token';
import { debounce } from '../../shared/helpers';
import { date, errorMessage, FALLBACK, rate, tokenBalance, tokenImage, tokenPrice } from '../../shared/templates';
import { Market } from '../../types';
import { SETTINGS_TYPE } from '../transaction-settings/transaction-settings';
import { RedeemTransactionBaseElement } from './redeem-transaction-base';

const maturityTemplate = (market?: Market) => {

    const daysRemaining = market ? maturityDays(market.maturity) : undefined;

    return html`
    ${ date(market?.maturity, MATURITY_DATE_FORMAT.LONG) }
    (${ daysRemaining ?? FALLBACK } day${ daysRemaining !== 1 ? 's' : '' })
    `;
};

const template = function (this: RedeemTransactionPreviewElement) {

    const isReady = this.transaction?.canRedeem();
    const isFetching = this.transaction?.status === REDEEM_STATUS.UPDATING;
    const hasError = this.transaction?.status === REDEEM_STATUS.ERROR;

    const { market, position, amount, amountReturned } = this.transaction?.state ?? {};

    const token = iPT(market);
    const isMatured = !!market && matured(market);

    const iptBalance = position?.iptBalance || '0';

    const currentPrice = !!amountReturned && !!amount && !emptyOrZero(amount)
        ? fixed(amountReturned).divUnsafe(fixed(amount)).toString()
        : undefined;

    const effectiveRate = !!amount && !!market && !!position
        ? isMatured
            ? positionAPR(position, market)
            : amountReturned
                ? effectiveAPR(amount, amountReturned, position, market)
                : undefined
        : undefined;

    return html`
    <div class="widget">

        <div class="widget-header">
            <button class="button-icon" @click=${ () => dispatch(this, new PanelNavigationEvent({ target: this, panel: PanelDirection.PREVIOUS })) }>
                <ui-icon name="back-circle"></ui-icon>
            </button>
            <label>
                ${ tokenImage(market?.token) }
                ${ date(market?.maturity) }
            </label>
            <ill-transaction-settings .type=${ SETTINGS_TYPE.LEND }></ill-transaction-settings>
        </div>

        ${ isMatured
            ? nothing
            : html`
            <div class="widget-inputs">
                <ill-token-input
                    .value=${ amount && token ? contractAmount(amount, token) : '' }
                    .token=${ token }
                    .balance=${ contractAmount(iptBalance, token) }
                    .max=${ contractAmount(iptBalance, token) }
                    .disabled=${ this.transaction?.isPending() || this.transaction?.isFinal() || false }
                    @change=${ (event: CustomEvent<{ value: string; }>) => this.handleAmountChange(event) }>
                </ill-token-input>
            </div>
            `
        }

        <div class="widget-main">

            ${ hasError
                ? errorMessage(this.transaction?.error?.message ?? 'An unknown error occurred.', 'exclamation')
                : nothing
            }

            <div class="preview">

                <ill-preview-item
                    class="preview-ipt-proceeds"
                    .label=${ 'Returned:' }
                    .current=${ tokenBalance(amountReturned, market?.token) }
                    .tooltip=${ TOOLTIPS.REDEEM.PROCEEDS(market?.token) }
                    .loading=${ isFetching }
                    .highlight=${ true }></ill-preview-item>

                <ill-preview-item
                    .label=${ 'iPT Price:' }
                    .current=${ tokenPrice(currentPrice, market?.token) }
                    .tooltip=${ TOOLTIPS.REDEEM.IPT_PRICE() }
                    .loading=${ isFetching }></ill-preview-item>

                ${ !position?.virtual
                    ? html`
                    <ill-preview-item
                        .label=${ 'Effective Rate:' }
                        .current=${ rate(effectiveRate, '%', false) }
                        .tooltip=${ TOOLTIPS.REDEEM.EFFECTIVE_RATE() }
                        .loading=${ isFetching }></ill-preview-item>
                    `
                    : nothing
                }

                <ui-collapsible class="preview-details">
                    <h3 data-part="header">
                        <button class="ghost" data-part="trigger">Details <ui-icon name="chevron"></ui-icon></button>
                    </h3>
                    <div data-part="region">

                        ${ !isMatured
                            ? html`
                            <ill-preview-item
                                .label=${ 'Early Exit Cost:' }
                                .current=${ tokenBalance(amount && amountReturned ? earlyExitCost(amount, amountReturned) : '', market?.token) }
                                .tooltip=${ TOOLTIPS.REDEEM.EXIT_COST() }
                                .loading=${ isFetching }></ill-preview-item>
                            `
                            : nothing
                        }

                        <ill-preview-item
                            class="preview-maturity"
                            .label=${ isMatured ? 'Matured' : 'Matures' }
                            .current=${ maturityTemplate(market) }
                            .tooltip=${ TOOLTIPS.REDEEM.MATURES(iptBalance, market?.token) }
                            .loading=${ isFetching }></ill-preview-item>

                    </div>
                </ui-collapsible>
            </div>

            <button class="primary" ?disabled=${ !isReady } @click=${ () => this.redeem() }>${ isMatured ? 'Exit' : 'Exit Early' }</button>

        </div>

    </div>
    `;
};

@customElement('ill-redeem-transaction-preview')
export class RedeemTransactionPreviewElement extends RedeemTransactionBaseElement {

    constructor () {

        super();

        this.handleAmountChange = debounce(this.handleAmountChange.bind(this), 500);
    }

    protected createRenderRoot (): Element | ShadowRoot {

        return this;
    }

    protected render (): unknown {

        return template.apply(this);
    }

    protected handleAmountChange (event: InputEvent | CustomEvent<{ value: string; }>): void {

        if (this.transaction?.isFinal() || this.transaction?.isPending()) {

            cancel(event);
            return;
        }

        const amount = ((event as CustomEvent<{ value: string; }>).detail.value !== undefined)
            ? (event as CustomEvent<{ value: string; }>).detail.value
            : (event.target as HTMLInputElement).value;

        const market = this.transaction?.state.market;

        if (!market) return;

        this.transaction?.setAmount(expandAmount(amount, market.token));
    }

    protected retry (): void {

        void this.transaction?.update();
    }

    protected redeem (): void {

        void this.transaction?.send();
    }
}
