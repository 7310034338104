import { ERRORS } from '../../constants';
import { APIService } from '../api';
import { ErrorService } from '../errors';
import { LogService } from '../logger';
import { IlluminateStatus, StatusService } from './interfaces';

export class WebStatusService implements StatusService {

    protected api: APIService;

    protected errors: ErrorService;

    protected logger: LogService;

    protected status?: IlluminateStatus;

    constructor (api: APIService, errors: ErrorService, logger: LogService) {

        this.api = api;
        this.errors = errors;
        this.logger = logger;
    }

    async getStatus (refresh = false): Promise<IlluminateStatus> {

        try {

            if (!this.status || refresh) {

                this.status = await this.api.getStatus();
            }

            return this.status;

        } catch (error) {

            throw this.errors.process(error, ERRORS.SERVICES.STATUS.FETCH);
        }
    }
}
