import { ERRORS, NOTIFICATIONS } from '../../../core/constants';
import { ERROR_SERVICE, serviceLocator } from '../../../core/services';
import { notifications } from '../../../services/notification';
import { truncateHash } from '../../../shared/helpers';
import { ACCOUNT, orchestrator } from '../../../state/orchestrator';

const errors = serviceLocator.get(ERROR_SERVICE);

/**
 * Display a wallet address in truncated fashion.
 *
 * @param h - the address hash
 * @returns - a truncated version of the original hash
 */
export const displayAddress = (h: string) => {

    return truncateHash(h, 7, 5);
};

/**
 * Copy the user's connected wallet address to clipboard.
 */
export const copyAddress = async () => {

    const accountMachine = orchestrator.account;

    if (!accountMachine.state.matches(ACCOUNT.STATES.CONNECTED)) return;

    try {

        await navigator.clipboard.writeText(accountMachine.state.context.account);

        notifications.show({
            content: NOTIFICATIONS.ACCOUNT.COPY_ADDRESS_SUCCESS,
            type: 'success',
        });

    } catch (error) {

        const processedError = errors.process(error, ERRORS.COMPONENTS.ACCOUNT.CLIPBOARD);

        notifications.show({
            content: NOTIFICATIONS.ACCOUNT.COPY_ADDRESS_FAILURE(processedError.message),
            type: 'failure',
        });
    }
};
