/* eslint-disable @typescript-eslint/no-unused-vars */
import { createMachine, DoneInvokeEvent, EventFrom, MachineOptions, TransitionsConfig } from 'xstate';
import { createModel } from 'xstate/lib/model';
import { ERRORS, QUOTE_PREVIEW_AMOUNT, QUOTE_PREVIEW_AMOUNT_ETHER } from '../core/constants';
import { fetchMarketsWithTokens, isETHMarket, MarketsWithTokens, matured } from '../core/markets';
import { bestQuotesByMarket, bestQuotesByToken, fetchQuotes, fetchQuotesByUnderlying } from '../core/quotes';
import { ERROR_SERVICE, serviceLocator } from '../core/services';
import { Market, Quote, Token } from '../types';
import { AddContext, RemoveContext } from './helpers';

/**
 * This type is used internally for fetching the base state.
 *
 * @remarks
 * This type contains a map of markets, a map of unique tokens used by the markets
 * and a map of best quotes for each token.
 */
type FetchResult = MarketsWithTokens & { quotesByToken: Map<string, Quote>; };

// ---------------
// machine context
// ---------------

export type Context = {
    /**
     * Markets are keyed by market key
     */
    markets: Map<string, Market>;
    /**
     * Tokens are keyed by token address
     */
    tokens: Map<string, Token>;
    /**
     * Quotes are keyed by token address and sorted by quote APR
     */
    quotesByToken: Map<string, Quote>;
    /**
     * Quotes are keyed by market key and sorted by quote APR
     */
    quotesByMarket: Map<string, Quote>;
    /**
     * An array of tokens from active markets sorted by their respective quote APR
     */
    activeTokensByAPR: Token[];
    /**
     * An array of active markets sorted by their respective quote APR
     */
    activeMarketsByAPR: Market[];
    /**
     * The selected amount is stored without decimals (contracted).
     */
    selectedAmount?: string;
    /**
     * The selected token is the token address
     */
    selectedToken?: string;
    /**
     * The selected market is the market key
     */
    selectedMarket?: string;
    /**
     * The selected markets are an array of market keys
     */
    selectedMarkets?: string[];
    error?: string;
};

const initialContext: Context = {
    markets: new Map(),
    tokens: new Map(),
    quotesByToken: new Map(),
    quotesByMarket: new Map(),
    activeTokensByAPR: [],
    activeMarketsByAPR: [],
    selectedAmount: QUOTE_PREVIEW_AMOUNT,
    selectedToken: undefined,
    selectedMarket: undefined,
    selectedMarkets: undefined,
    error: undefined,
};

// ---------------
// machine state
// ---------------

export const enum STATES {
    INITIAL = 'initial',
    FETCHING = 'fetching',
    FETCHING_QUOTES = 'fetchingQuotes',
    SELECT_TOKEN = 'selectToken',
    SELECT_MARKET = 'selectMarket',
    COMPLETE = 'complete',
    ERROR = 'error',
}

export type State =
    {
        value: STATES.INITIAL;
        context: Context;
    }
    | {
        value: STATES.FETCHING;
        context: Context;
    }
    | {
        value: STATES.SELECT_TOKEN;
        context: Context & AddContext<Context, 'selectedAmount'>;
    }
    | {
        value: STATES.SELECT_MARKET;
        context: Context & AddContext<Context, 'selectedAmount' | 'selectedToken' | 'selectedMarkets'>;
    }
    | {
        value: STATES.FETCHING_QUOTES;
        context: Context & AddContext<Context, 'selectedAmount' | 'selectedToken' | 'selectedMarkets'>;
    }
    | {
        value: STATES.COMPLETE;
        context: Context
        & AddContext<Context, 'selectedAmount' | 'selectedToken' | 'selectedMarkets' | 'selectedMarket'>
        & RemoveContext<Context, 'error'>;
    }
    | {
        value: STATES.ERROR;
        context: Context & AddContext<Context, 'error'>;
    };

// ----------------------
// machine model & events
// ----------------------

export const enum EVENTS {
    INIT = 'MARKET.INIT',
    FETCH = 'MARKET.FETCH',
    SET_AMOUNT = 'MARKET.SET_AMOUNT',
    SET_TOKEN = 'MARKET.SET_TOKEN',
    SET_MARKET = 'MARKET.SET_MARKET',
    FETCH_QUOTES = 'MARKET.FETCH_QUOTES',
    // internal events from services
    FETCH_SUCCESS = 'done.invoke.fetch',
    FETCH_FAILURE = 'error.platform.fetch',
    FETCH_MARKET_QUOTES_SUCCESS = 'done.invoke.fetchMarketQuotes',
    FETCH_MARKET_QUOTES_FAILURE = 'error.platform.fetchMarketQuotes',
}

export const model = createModel(
    initialContext,
    {
        events: {
            [EVENTS.FETCH]: () => ({}),
            [EVENTS.SET_AMOUNT]: (payload: string) => ({ payload }),
            [EVENTS.SET_TOKEN]: (payload: string | undefined) => ({ payload }),
            [EVENTS.SET_MARKET]: (payload: string | undefined) => ({ payload }),
            [EVENTS.FETCH_QUOTES]: () => ({}),
        },
    },
);

export type Event = EventFrom<typeof model>;

// ---------------
// machine helpers
// ---------------

/**
 * Filters a map of markets by an underlying token and returns the filtered market keys.
 *
 * @param markets - a map of markets keyed by `marketKey`
 * @param underlying - an underlying address
 * @returns an array of non-matured market keys whose underlying is the specified underlying
 */
const filterSelectedMarkets = (markets: Map<string, Market>, underlying: string): string[] => {

    return [...markets.entries()]
        .filter(([key, market]) => !matured(market) && market.underlying === underlying)
        .map(([key, market]) => key);
};

/**
 * Creates an array of all active tokens (tokens from non-matured markets) sorted by their respective best-quote APR.
 *
 * @remarks
 * This array is used to generate the token list view for the Lend page.
 * We want to view a list of active tokens sorted by the highest available APR within the markets belonging to each token.
 * Tokens without available quotes should remain in the view, sorted to the bottom and marked as unavailable.
 *
 * @param markets - a map of all markets keyed by `marketKey`
 * @param tokens - a map of all tokens keyed by token address
 * @param quotesByToken - a map of the best quotes per token keyed by token address
 */
const sortTokensByAPR = (markets: Map<string, Market>, tokens: Map<string, Token>, quotesByToken: Map<string, Quote>): Token[] => {

    // filter all non-matured markets
    const activeMarkets = [...markets.values()].filter(market => !matured(market));

    // get all tokens from non-matured markets
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const activeTokens = activeMarkets.map(market => tokens.get(market.underlying)!);

    // get all tokens from non-matured markets sorted by quote APR
    // NOTE: we merge the tokens from the sorted quotes into a Set
    // with all active tokens - duplicates are ignored and we
    // preserve the intial sorting by quote APR
    const activeTokensByAPR = Array.from(new Set(
        [...quotesByToken.keys()]
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            .map(key => tokens.get(key)!)
            .concat(...activeTokens),
    ));

    return activeTokensByAPR;
};

/**
 * Creates an array of all active and selected markets sorted by their respective best-quote APR.
 *
 * @remarks
 * This array is used to generate the maturity list view for the Lend page.
 * We want to view a list of active markets for a selected token sorted by the highest available APR.
 * Markets without available quotes should remain in the view, sorted to the bottom and marked as unavailable.
 *
 * @param markets - a map of all markets keyed by `marketKey`
 * @param selectedMarkets - an array of selected `marketKey`s
 * @param quotesByMarket - a map of the best quotes per market keyed by `marketKey`
 */
const sortMarketsByAPR = (markets: Map<string, Market>, selectedMarkets: string[], quotesByMarket: Map<string, Quote>): Market[] => {

    // get all selected and non-matured markets sorted by quote APR
    // NOTE: we merge the markets from the sorted quotes into a Set
    // with all active ans selected markets - duplicates are ignored
    // and we preserve the intial sorting by quote APR
    const activeMarketsByAPR = Array.from(new Set(
        [...quotesByMarket.keys()]
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            .map(key => markets.get(key)!)
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            .concat(...selectedMarkets.map(key => markets.get(key)!)),
    ));

    return activeMarketsByAPR;
};

// ---------------
// machine actions
// ---------------

export const enum ACTIONS {
    SET_AMOUNT = 'SET_AMOUNT',
    SET_TOKEN = 'SET_TOKEN',
    SET_MARKET = 'SET_MARKET',
    FETCH_SUCCESS = 'FETCH_SUCCESS',
    FETCH_FAILURE = 'FETCH_FAILURE',
    FETCH_MARKET_QUOTES_SUCCESS = 'FETCH_MARKET_QUOTES_SUCCESS',
    FETCH_MARKET_QUOTES_FAILURE = 'FETCH_MARKET_QUOTES_FAILURE',
}

const actions = {
    [ACTIONS.SET_AMOUNT]: model.assign(
        (context, event) => ({
            ...context,
            selectedAmount: event.payload,
        }),
        EVENTS.SET_AMOUNT,
    ),
    [ACTIONS.SET_TOKEN]: model.assign(
        (context, event) => {

            const selectedToken = event.payload
                // don't allow selecting non-existent tokens
                ? context.tokens.has(event.payload)
                    ? event.payload
                    : context.selectedToken
                : undefined;

            // filter the markets which match the selected token
            const selectedMarkets = selectedToken
                ? filterSelectedMarkets(context.markets, selectedToken)
                : undefined;

            return {
                ...context,
                selectedToken,
                selectedMarkets,
                selectedMarket: undefined,
            };
        },
        EVENTS.SET_TOKEN,
    ),
    [ACTIONS.SET_MARKET]: model.assign(
        (context, event) => {

            const selectedMarket = event.payload
                // don't allow selecting non-existent markets
                ? context.markets.has(event.payload)
                    ? event.payload
                    : context.selectedMarket
                : undefined;

            return {
                ...context,
                selectedMarket,
            };
        },
        EVENTS.SET_MARKET,
    ),
    [ACTIONS.FETCH_SUCCESS]: model.assign(
        (context, event) => {

            const result = (event as DoneInvokeEvent<FetchResult>).data;

            // generate the active tokens by APR list
            const activeTokensByAPR = sortTokensByAPR(result.markets, result.tokens, result.quotesByToken);

            // preserve the selected token, if it exists in the result
            const selectedToken = context.selectedToken && result.tokens.has(context.selectedToken)
                ? context.selectedToken
                : [...result.tokens.keys()][0];

            // filter the markets which match the selected token
            const selectedMarkets = filterSelectedMarkets(result.markets, selectedToken);

            // reset the selected amount, as the quotes in a market fetch are for a fixed underlying
            const selectedAmount = selectedToken
                ? isETHMarket({ underlying: selectedToken })
                    ? QUOTE_PREVIEW_AMOUNT_ETHER
                    : QUOTE_PREVIEW_AMOUNT
                : initialContext.selectedAmount;

            return {
                ...context,
                ...result,
                activeTokensByAPR,
                selectedAmount,
                selectedToken,
                selectedMarkets,
                selectedMarket: undefined,
                error: undefined,
            };
        },
    ),
    [ACTIONS.FETCH_FAILURE]: model.assign(
        (context, event) => ({
            ...context,
            markets: new Map(),
            tokens: new Map(),
            quotesByToken: new Map(),
            quotesByMarket: new Map(),
            activeTokensByAPR: [],
            activeMarketsByAPR: [],
            selectedToken: undefined,
            selectedMarket: undefined,
            selectedMarkets: undefined,
            error: (event as DoneInvokeEvent<Error>).data.message,
        }),
    ),
    [ACTIONS.FETCH_MARKET_QUOTES_SUCCESS]: model.assign(
        (context, event) => {

            const result = (event as DoneInvokeEvent<Map<string, Quote>>).data;
            const quotes = context.quotesByMarket;
            const selectedMarkets = context.selectedMarkets;

            // update the affected quotes in the context
            selectedMarkets?.forEach(key => {

                const quote = result.get(key);

                if (quote) {

                    quotes.set(key, quote);

                } else {

                    quotes.delete(key);
                }
            });

            // reset the selected amount, as the quotes in a market fetch are for a fixed underlying
            const selectedAmount = context.selectedToken
                ? isETHMarket({ underlying: context.selectedToken })
                    ? QUOTE_PREVIEW_AMOUNT_ETHER
                    : QUOTE_PREVIEW_AMOUNT
                : initialContext.selectedAmount;

            // generate the active markets by APR list
            const activeMarketsByAPR = sortMarketsByAPR(context.markets, selectedMarkets ?? [], result);

            return {
                ...context,
                selectedAmount,
                quotesByMarket: quotes,
                activeMarketsByAPR,
                error: undefined,
            };
        },
    ),
    [ACTIONS.FETCH_MARKET_QUOTES_FAILURE]: model.assign(
        (context, event) => {

            // const markets = context.markets;
            const quotes = context.quotesByMarket;
            const selectedMarkets = context.selectedMarkets;

            // update the affected quotes in the context
            selectedMarkets?.forEach(key => {

                quotes.delete(key);
            });

            return {
                ...context,
                quotesByMarket: quotes,
                activeMarketsByAPR: [],
                error: (event as DoneInvokeEvent<Error>).data.message,
            };
        },
    ),
};

// -------------------
// machine transitions
// -------------------

const enum TRANSITIONS {
    FETCH = 'FETCH',
    SET_AMOUNT = 'SET_AMOUNT',
    SET_TOKEN = 'SET_TOKEN',
    SET_MARKET = 'SET_MARKET',
    FETCH_QUOTES = 'FETCH_QUOTES',
}

const transitions: Record<TRANSITIONS, TransitionsConfig<Context, Event>> = {
    [TRANSITIONS.FETCH]: {
        [EVENTS.FETCH]: {
            target: STATES.FETCHING,
        },
    },
    [TRANSITIONS.SET_TOKEN]: {
        [EVENTS.SET_TOKEN]: {
            actions: ACTIONS.SET_TOKEN,
            target: STATES.FETCHING_QUOTES,
        },
    },
    [TRANSITIONS.SET_AMOUNT]: {
        [EVENTS.SET_AMOUNT]: {
            actions: ACTIONS.SET_AMOUNT,
            target: STATES.FETCHING_QUOTES,
        },
    },
    [TRANSITIONS.SET_MARKET]: {
        [EVENTS.SET_MARKET]: {
            actions: ACTIONS.SET_MARKET,
            target: STATES.COMPLETE,
        },
    },
    [TRANSITIONS.FETCH_QUOTES]: {
        [EVENTS.FETCH_QUOTES]: {
            target: STATES.FETCHING_QUOTES,
        },
    },
};

// ---------------
// machine config
// ---------------

export const options: Partial<MachineOptions<Context, Event>> = {
    services: {
        fetch: async (context): Promise<FetchResult> => {

            const errors = serviceLocator.get(ERROR_SERVICE);

            try {

                const [marketsWithTokens, quotes] = await Promise.all([
                    fetchMarketsWithTokens(),
                    fetchQuotes(),
                ]);

                if (marketsWithTokens.markets.size === 0) {

                    throw errors.process(ERRORS.STATE.MARKET.NO_MARKETS);
                }

                const quotesByToken = bestQuotesByToken(quotes);

                return {
                    ...marketsWithTokens,
                    quotesByToken,
                };

            } catch (error) {

                throw errors.process(error, ERRORS.STATE.MARKET.FETCH, false, true);
            }
        },
        fetchMarketQuotes: async (context): Promise<Map<string, Quote>> => {

            const errors = serviceLocator.get(ERROR_SERVICE);

            try {

                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                const underlying = context.tokens.get(context.selectedToken!)!;

                const previewAmount = isETHMarket({ underlying: underlying.address })
                    ? QUOTE_PREVIEW_AMOUNT_ETHER
                    : QUOTE_PREVIEW_AMOUNT;

                const quotes = await fetchQuotesByUnderlying(underlying, previewAmount);

                const quotesByMarket = bestQuotesByMarket(quotes);

                return quotesByMarket;

            } catch (error) {

                throw errors.process(error, ERRORS.STATE.MARKET.FETCH_MARKET_QUOTES, false, true);
            }
        },
    },
    actions,
};

// ---------------
// machine
// ---------------

export const machine = createMachine<Context, Event, State>(
    {
        context: model.initialContext,
        initial: STATES.INITIAL,
        states: {
            [STATES.INITIAL]: {
                always: [
                    {
                        target: STATES.FETCHING,
                    },
                ],
            },
            [STATES.FETCHING]: {
                invoke: {
                    id: 'fetch',
                    src: 'fetch',
                    onDone: {
                        actions: ACTIONS.FETCH_SUCCESS,
                        target: STATES.SELECT_TOKEN,
                    },
                    onError: {
                        actions: ACTIONS.FETCH_FAILURE,
                        target: STATES.ERROR,
                    },
                },
            },
            [STATES.SELECT_TOKEN]: {
                on: {
                    ...transitions[TRANSITIONS.SET_TOKEN],
                    ...transitions[TRANSITIONS.FETCH],
                },
            },
            [STATES.SELECT_MARKET]: {
                on: {
                    ...transitions[TRANSITIONS.SET_TOKEN],
                    ...transitions[TRANSITIONS.SET_AMOUNT],
                    ...transitions[TRANSITIONS.SET_MARKET],
                    ...transitions[TRANSITIONS.FETCH_QUOTES],
                    ...transitions[TRANSITIONS.FETCH],
                },
            },
            [STATES.FETCHING_QUOTES]: {
                invoke: {
                    id: 'fetchMarketQuotes',
                    src: 'fetchMarketQuotes',
                    onDone: {
                        actions: ACTIONS.FETCH_MARKET_QUOTES_SUCCESS,
                        target: STATES.SELECT_MARKET,
                    },
                    onError: {
                        actions: ACTIONS.FETCH_MARKET_QUOTES_FAILURE,
                        target: STATES.ERROR,
                    },
                },
            },
            [STATES.COMPLETE]: {
                on: {
                    ...transitions[TRANSITIONS.FETCH],
                    ...transitions[TRANSITIONS.FETCH_QUOTES],
                    ...transitions[TRANSITIONS.SET_TOKEN],
                    ...transitions[TRANSITIONS.SET_AMOUNT],
                    ...transitions[TRANSITIONS.SET_MARKET],
                },
            },
            [STATES.ERROR]: {
                on: {
                    ...transitions[TRANSITIONS.FETCH],
                    ...transitions[TRANSITIONS.FETCH_QUOTES],
                    ...transitions[TRANSITIONS.SET_TOKEN],
                    ...transitions[TRANSITIONS.SET_AMOUNT],
                },
            },
        },
    },
    options,
);
