import { ENV } from '../env/environment';

export const ERRORS = {
    HTTP: {
        BAD_RESPONSE: {
            name: 'HTTP.BAD_RESPONSE',
            message: 'Unable to parse response.',
        },
        BAD_REQUEST: {
            name: 'HTTP.BAD_REQUEST',
            message: 'Bad request.',
        },
        UNAUTHORIZED: {
            name: 'HTTP.UNAUTHORIZED',
            message: 'Access not authorized.',
        },
        FORBIDDEN: {
            name: 'HTTP.FORBIDDEN',
            message: 'Access forbidden.',
        },
        NOT_FOUND: {
            name: 'HTTP.NOT_FOUND',
            message: 'Resource not found.',
        },
        INTERNAL: {
            name: 'HTTP.INTERNAL',
            message: 'Internal server error.',
        },
        UNKNOWN: {
            name: 'HTTP.UNKNOWN',
            message: 'Unknown server error.',
        },
        NETWORK: {
            name: 'HTTP.NETWORK',
            message: 'Network error.',
        },
    },
    ETHEREUM: {
        // https://eips.ethereum.org/EIPS/eip-1193#provider-errors
        PROVIDER: {
            REJECTED: {
                name: 'ETHEREUM.PROVIDER.REJECTED',
                message: 'The user rejected the request.',
            },
            UNAUTHORIZED: {
                name: 'ETHEREUM.PROVIDER.UNAUTHORIZED',
                message: 'The requested account and/or method has not been authorized by the user.',
            },
            UNSUPPORTED: {
                name: 'ETHEREUM.PROVIDER.UNSUPPORTED',
                message: 'The requested method is not supported by this Ethereum provider.',
            },
            DISCONNECTED: {
                name: 'ETHEREUM.PROVIDER.DISCONNECTED',
                message: 'The provider is disconnected from all chains.',
            },
            DISCONNECTED_CHAIN: {
                name: 'ETHEREUM.PROVIDER.DISCONNECTED_CHAIN',
                message: 'The provider is disconnected from the requested chain.',
            },
        },
        // https://eips.ethereum.org/EIPS/eip-1474#error-codes
        RPC: {
            PARSE_ERROR: {
                name: 'ETHEREUM.RPC.PARSE_ERROR',
                message: 'Invalid JSON was received by the server.',
            },
            INVALID_REQUEST: {
                name: 'ETHEREUM.RPC.INVALID_REQUEST',
                message: 'The JSON sent is not a valid Request object.',
            },
            METHOD_NOT_FOUND: {
                name: 'ETHEREUM.RPC.METHOD_NOT_FOUND',
                message: 'The method does not exist / is not available.',
            },
            INVALID_PARAMS: {
                name: 'ETHEREUM.RPC.INVALID_PARAMS',
                message: 'Invalid method parameter(s).',
            },
            INVALID_INPUT: {
                name: 'ETHEREUM.RPC.INVALID_INPUT',
                message: 'Missing or invalid parameter(s).',
            },
            RESOURCE_NOT_FOUND: {
                name: 'ETHEREUM.RPC.RESOURCE_NOT_FOUND',
                message: 'Requested resource not found.',
            },
            RESOURCE_NOT_AVAILABLE: {
                name: 'ETHEREUM.RPC.RESOURCE_NOT_AVAILABLE',
                message: 'Requested resource not available.',
            },
            TRANSACTION_REJECTED: {
                name: 'ETHEREUM.RPC.TRANSACTION_REJECTED',
                message: 'Transaction rejected.',
            },
            METHOD_NOT_SUPPORTED: {
                name: 'ETHEREUM.RPC.METHOD_NOT_SUPPORTED',
                message: 'Method is not supported.',
            },
            LIMIT_EXCEEDED: {
                name: 'ETHEREUM.RPC.LIMIT_EXCEEDED',
                message: 'Request limit exceeded.',
            },
            VERSION: {
                name: 'ETHEREUM.RPC.VERSION',
                message: 'Version of JSON-RPC protocol is not supported.',
            },
            INTERNAL: {
                name: 'ETHEREUM.RPC.INTERNAL',
                message: 'Internal JSON-RPC error.',
            },
            UNKNOWN: {
                name: 'ETHEREUM.RPC.UNKNOWN',
                message: 'Unknown JSON-RPC error.',
            },
        },
        TRANSACTION: {
            SERVER: {
                name: 'ETHEREUM.TRANSACTION.SERVER',
                message: 'Server error.',
            },
            TIMEOUT: {
                name: 'ETHEREUM.TRANSACTION.TIMEOUT',
                message: 'Transaction timeout.',
            },
            CALL_EXCEPTION: {
                name: 'ETHEREUM.TRANSACTION.CALL_EXCEPTION',
                message: 'Transaction failed.',
            },
            FUNDS: {
                name: 'ETHEREUM.TRANSACTION.FUNDS',
                message: 'Insufficient funds.',
            },
            NETWORK: {
                name: 'ETHEREUM.TRANSACTION.NETWORK',
                message: 'Network validation error.',
            },
            NONCE: {
                name: 'ETHEREUM.TRANSACTION.NONCE',
                message: 'Nonce expired.',
            },
            UNDERPRICED: {
                name: 'ETHEREUM.TRANSACTION.UNDERPRICED',
                message: 'Transaction underpriced.',
            },
            CANCELLED: {
                name: 'ETHEREUM.TRANSACTION.CANCELLED',
                message: 'Transaction cancelled.',
            },
            REPLACED: {
                name: 'ETHEREUM.TRANSACTION.REPLACED',
                message: 'Transaction replaced.',
            },
            REVERTED: {
                name: 'ETHEREUM.TRANSACTION.REVERTED',
                message: 'Transaction reverted.',
            },
            UNSUPPORTED_OPERATION: {
                name: 'ETHEREUM.TRANSACTION.UNSUPPORTED_OPERATION',
                message: 'Network does not support requested operation.',
            },
            UNKNOWN: {
                name: 'ETHEREUM.TRANSACTION.UNKNOWN',
                message: 'Unknown transaction error.',
            },
        },
        METAMASK: {
            NO_METAMASK: {
                name: 'ETHEREUM.METAMASK.NO_METAMASK',
                message: 'Unable to detect window.ethereum. Make sure you have MetaMask installed.',
            },
            NON_METAMASK: {
                name: 'ETHEREUM.METAMASK.NON_METAMASK',
                message: 'Non-MetaMask window.ethereum detected.',
            },
            CHAIN: {
                name: 'ETHEREUM.METAMASK.CHAIN',
                message: `Please connect your MetaMask to the ${ ENV.networkName } network.`,
            },
        },
    },
    ETHERS: {
        NUMERIC_FAULT: {
            name: 'ETHERS.NUMERIC_FAULT',
            message: 'Number conversion error.',
        },
    },
    SERVICES: {
        WEBSOCKET: {
            ERROR: {
                name: 'SERVICES.WEBSOCKET.ERROR',
                message: 'Websocket error.',
            },
            PARSE_ERROR: {
                name: 'SERVICES.WEBSOCKET.PARSE_ERROR',
                message: 'Unable to parse message.',
            },
        },
        STATUS: {
            FETCH: {
                name: 'SERVICES.STATUS.FETCH',
                message: 'Failed to fetch status.',
            },
        },
        ILLUMINATE: {
            ALLOWANCE: {
                name: 'SERVICES.ILLUMINATE.ALLOWANCE',
                message: 'Failed to check allowance.',
            },
            APPROVAL: {
                name: 'SERVICES.ILLUMINATE.APPROVAL',
                message: 'Failed to process approval.',
            },
            LEND: {
                name: 'SERVICES.ILLUMINATE.LEND',
                message: 'Failed to lend.',
            },
            EXIT: {
                name: 'SERVICES.ILLUMINATE.EXIT',
                message: 'Failed to exit.',
            },
            REDEEM: {
                name: 'SERVICES.ILLUMINATE.REDEEM',
                message: 'Failed to redeem.',
            },
            REDEEM_PREVIEW: {
                name: 'SERVICES.ILLUMINATE.REDEEM_PREVIEW',
                message: 'Failed to fetch redeem preview.',
            },
            ADD_LIQUIDITY: {
                name: 'SERVICES.ILLUMINATE.ADD_LIQUIDITY',
                message: 'Failed to add liquidity.',
            },
            REMOVE_LIQUIDITY: {
                name: 'SERVICES.ILLUMINATE.REMOVE_LIQUIDITY',
                message: 'Failed to remove liquidity.',
            },
            LENDER: {
                META: {
                    name: 'SERVICES.ILLUMINATE.LENDER.META',
                    message: 'Invalid or missing quote meta data.',
                },
                ELEMENT: {
                    PAUSED: {
                        name: 'SERVICES.ILLUMINATE.LENDER.ELEMENT.PAUSED',
                        message: 'Element market is paused.',
                    },
                },
                SENSE: {
                    MATURITY: {
                        name: 'SERVICES.ILLUMINATE.LENDER.SENSE.MATURITY',
                        message: 'Quote is missing maturity for Sense\'s market.',
                    },
                },
            },
        },
        STRATEGY: {
            INFO: {
                name: 'SERVICES.STRATEGY.INFO',
                message: 'Failed to fetch strategy info.',
            },
            ALLOWANCE: {
                name: 'SERVICES.STRATEGY.ALLOWANCE',
                message: 'Failed to check allowance.',
            },
            APPROVAL: {
                name: 'SERVICES.STRATEGY.APPROVAL',
                message: 'Failed to process approval.',
            },
            ADD_LIQUIDITY: {
                FAILURE: {
                    name: 'SERVICES.STRATEGY.ADD_LIQUIDITY.FAILURE',
                    message: 'Failed to add liquidity.',
                },
                INVALID_STATE: {
                    name: 'SERVICES.STRATEGY.ADD_LIQUIDITY.INVALID_STATE',
                    message: 'Unable to add liquidity: Strategy is in invalid state.',
                },
            },
            REMOVE_LIQUIDITY: {
                FAILURE: {
                    name: 'SERVICES.STRATEGY.REMOVE_LIQUIDITY',
                    message: 'Failed to remove liquidity.',
                },
                INVALID_STATE: {
                    name: 'SERVICES.STRATEGY.REMOVE_LIQUIDITY.INVALID_STATE',
                    message: 'Unable to remove liquidity: Strategy is in invalid state.',
                },
            },
        },
        POOL: {
            SELL_FY_TOKEN_PREVIEW: {
                name: 'SERVICES.POOL.SELL_FY_TOKEN_PREVIEW',
                message: 'Unable to preview: Price impact too high.',
            },
        },
        POSITION: {
            CURRENT_POSITION_VALUE: {
                name: 'SERVICES.POSITION.CURRENT_POSITION_VALUE',
                message: 'Unable to calculate current position value.',
            },
        },
        FAUCET: {
            UNAVAILABLE: {
                name: 'SERVICES.FAUCET.UNAVAILABLE',
                message: 'No faucet configured for this environment.',
            },
        },
        TRANSACTION: {
            FACTORY: {
                name: 'SERVICES.TRANSACTION.FACTORY',
                message: 'No factory registered for the requested transaction.',
            },
            STATUS: {
                FINAL: {
                    name: 'SERVICES.TRANSACTION.STATUS.FINAL',
                    message: 'Transaction is final and can no longer be changed.',
                },
                INCOMPLETE: {
                    name: 'SERVICES.TRANSACTION.STATUS.INCOMPLETE',
                    message: 'Transaction is incomplete.',
                },
            },
            ADD_LIQUIDITY: {
                PREVIEW: {
                    NO_UNDERLYING: {
                        name: 'SERVICES.TRANSACTION.ADD_LIQUIDITY.PREVIEW.NO_UNDERLYING',
                        message: 'Unable to calculate preview: No underlying amount provided.',
                    },
                    NO_IPT: {
                        name: 'SERVICES.TRANSACTION.ADD_LIQUIDITY.PREVIEW.NO_IPT',
                        message: 'Unable to calculate preview: No iPT amount provided.',
                    },
                    NO_UPDATE: {
                        name: 'SERVICES.TRANSACTION.ADD_LIQUIDITY.PREVIEW.NO_UPDATE',
                        message: 'Unable to calculate preview: Transaction is not updated.',
                    },
                },
            },
            REMOVE_LIQUIDITY: {
                PREVIEW: {
                    NO_LP: {
                        name: 'SERVICES.TRANSACTION.REMOVE_LIQUIDITY.PREVIEW.NO_LP',
                        message: 'Unable to calculate preview: No LP amount provided.',
                    },
                    NO_UNDERLYING: {
                        name: 'SERVICES.TRANSACTION.REMOVE_LIQUIDITY.PREVIEW.NO_UNDERLYING',
                        message: 'Unable to calculate preview: No underlying amount provided.',
                    },
                    NO_UPDATE: {
                        name: 'SERVICES.TRANSACTION.REMOVE_LIQUIDITY.PREVIEW.NO_UPDATE',
                        message: 'Unable to calculate preview: Transaction is not updated.',
                    },
                },
            },
        },
    },
    COMPONENTS: {
        APP: {
            METAMASK: {
                name: 'COMPONENTS.APP.METAMASK',
                message: 'Failed to connect to MetaMask.',
            },
        },
        ACCOUNT: {
            CLIPBOARD: {
                name: 'COMPONENTS.ACCOUNT.CLIPBOARD',
                message: 'Failed to copy address to clipboard.',
            },
        },
        DEPLOYMENT: {
            MISSING: {
                name: 'COMPONENTS.DEPLOYMENT.MISSING',
                message: `DEPLOYMENT configuration error: The current DEPLOYMENT_ID '${ ENV.deploymentId }' is not configured in DEPLOYMENTS '${ JSON.stringify(ENV.deployments) }'`,
            },
        },
        MARKETS: {
            DEFAULT: {
                name: 'COMPONENTS.MARKETS.DEFAULT',
                message: 'Failed to fetch markets.',
            },
        },
        BALANCES: {
            DEFAULT: {
                name: 'COMPONENTS.BALANCES.DEFAULT',
                message: 'Failed to fetch balances.',
            },
        },
        LEND: {
            MARKET: {
                name: 'COMPONENTS.LEND.MARKET',
                message: 'Failed to retrieve selected market.',
            },
        },
        POOL: {
            POOL: {
                name: 'COMPONENTS.POOL.POOL',
                message: 'Failed to retrieve selected pool.',
            },
        },
        POSITIONS: {
            MARKET: {
                name: 'COMPONENTS.POSITIONS.MARKET',
                message: 'Failed to retrieve selected market.',
            },
        },
    },
    STATE: {
        ORCHESTRATOR: {
            MARKET: {
                name: 'STATE.ORCHESTRATOR.MARKET',
                message: 'Failed to retrieve selected market.',
            },
        },
        ACCOUNT: {
            CONNECT: {
                name: 'STATE.ACCOUNT.CONNECT',
                message: 'There was a problem connecting your account.',
            },
            BALANCES: {
                name: 'STATE.ACCOUNT.BALANCES',
                message: 'There was a problem fetching your balances.',
            },
        },
        MARKET: {
            FETCH: {
                name: 'STATE.MARKET.FETCH',
                message: 'Failed to fetch markets.',
            },
            FETCH_MARKET_QUOTES: {
                name: 'STATE.MARKET.FETCH_MARKET_QUOTES',
                message: 'Failed to fetch market quotes.',
            },
            NO_MARKETS: {
                name: 'STATE.MARKET.NO_MARKETS',
                message: 'No markets available.',
            },
            NO_QUOTES: {
                name: 'STATE.MARKET.NO_QUOTES',
                message: 'No quotes available.',
            },
        },
        POOL: {
            FETCH: {
                name: 'STATE.POOL.FETCH',
                message: 'Failed to fetch pools.',
            },
            NO_POOLS: {
                name: 'STATE.POOL.NO_POOLS',
                message: 'No pools available.',
            },
            NO_ACTIVE_POOLS: {
                name: 'STATE.POOL.NO_ACTIVE_POOLS',
                message: 'No active pools available.',
            },
        },
        POSITIONS: {
            NO_POSITIONS: {
                name: 'STATE.POSITIONS.NO_POSITIONS',
                message: 'No positions available for the connected account. Try connecting a different account.',
            },
            FETCH: {
                name: 'STATE.POSITIONS.FETCH',
                message: 'Failed to fetch positions.',
            },
            NO_MARKET: {
                name: 'STATE.POSITIONS.NO_MARKET',
                message: 'No matching market found for position.',
            },
            NO_POOL: {
                name: 'STATE.POSITIONS.NO_POOL',
                message: 'No matching pool found for position.',
            },
        },
        LEND: {
            NO_QUOTES: {
                name: 'STATE.LEND.NO_QUOTES',
                message: 'No quotes are available for the requested amount. Try entering a smaller amount.',
            },
            FETCH: {
                name: 'STATE.LEND.FETCH',
                message: 'Failed to fetch quote.',
            },
            ALLOWANCE: {
                name: 'STATE.LEND.ALLOWANCE',
                message: 'Failed to check allowance.',
            },
            APPROVAL: {
                name: 'STATE.LEND.APPROVAL',
                message: 'Failed to process approval.',
            },
            LEND: {
                name: 'STATE.LEND.LEND',
                message: 'Failed to lend.',
            },
        },
        REDEEM: {
            FETCH: {
                name: 'STATE.REDEEM.FETCH',
                message: 'Failed to fetch marketplace information.',
            },
            ALLOWANCE: {
                name: 'STATE.REDEEM.ALLOWANCE',
                message: 'Failed to check allowance.',
            },
            APPROVAL: {
                name: 'STATE.REDEEM.APPROVAL',
                message: 'Failed to process approval.',
            },
            REDEEM: {
                name: 'STATE.REDEEM.REDEEM',
                message: 'Failed to exit position.',
            },
        },
    },
    DEFAULT: {
        name: 'DEFAULT',
        message: 'Unknown error.',
    },
};

export const ERROR_CODES = {
    HTTP: {
        '400': ERRORS.HTTP.BAD_REQUEST,
        '401': ERRORS.HTTP.UNAUTHORIZED,
        '403': ERRORS.HTTP.FORBIDDEN,
        '404': ERRORS.HTTP.NOT_FOUND,
        '500': ERRORS.HTTP.INTERNAL,
        'DEFAULT': ERRORS.HTTP.UNKNOWN,
    },
    ETHEREUM: {
        PROVIDER: {
            '4001': ERRORS.ETHEREUM.PROVIDER.REJECTED,
            '4100': ERRORS.ETHEREUM.PROVIDER.UNAUTHORIZED,
            '4200': ERRORS.ETHEREUM.PROVIDER.UNSUPPORTED,
            '4900': ERRORS.ETHEREUM.PROVIDER.DISCONNECTED,
            '4901': ERRORS.ETHEREUM.PROVIDER.DISCONNECTED_CHAIN,
        },
        RPC: {
            '-32700': ERRORS.ETHEREUM.RPC.PARSE_ERROR,
            '-32600': ERRORS.ETHEREUM.RPC.INVALID_REQUEST,
            '-32601': ERRORS.ETHEREUM.RPC.METHOD_NOT_FOUND,
            '-32602': ERRORS.ETHEREUM.RPC.INVALID_PARAMS,
            '-32603': ERRORS.ETHEREUM.RPC.INTERNAL,
            '-32000': ERRORS.ETHEREUM.RPC.INVALID_INPUT,
            '-32001': ERRORS.ETHEREUM.RPC.RESOURCE_NOT_FOUND,
            '-32002': ERRORS.ETHEREUM.RPC.RESOURCE_NOT_AVAILABLE,
            '-32003': ERRORS.ETHEREUM.RPC.TRANSACTION_REJECTED,
            '-32004': ERRORS.ETHEREUM.RPC.METHOD_NOT_SUPPORTED,
            '-32005': ERRORS.ETHEREUM.RPC.LIMIT_EXCEEDED,
            '-32006': ERRORS.ETHEREUM.RPC.VERSION,
        },
        TRANSACTION: {
            'SERVER_ERROR': ERRORS.ETHEREUM.TRANSACTION.SERVER,
            'TIMEOUT': ERRORS.ETHEREUM.TRANSACTION.TIMEOUT,
            'CALL_EXCEPTION': ERRORS.ETHEREUM.TRANSACTION.CALL_EXCEPTION,
            'INSUFFICIENT_FUNDS': ERRORS.ETHEREUM.TRANSACTION.FUNDS,
            'NONCE_EXPIRED': ERRORS.ETHEREUM.TRANSACTION.NONCE,
            'REPLACEMENT_UNDERPRICED': ERRORS.ETHEREUM.TRANSACTION.UNDERPRICED,
            'UNPREDICTABLE_GAS_LIMIT': ERRORS.ETHEREUM.TRANSACTION.CALL_EXCEPTION,
            'TRANSACTION_REPLACED': ERRORS.ETHEREUM.TRANSACTION.REPLACED,
            'UNSUPPORTED_OPERATION': ERRORS.ETHEREUM.TRANSACTION.UNSUPPORTED_OPERATION,
        },
    },
    ETHERS: {
        'NUMERIC_FAULT': ERRORS.ETHERS.NUMERIC_FAULT,
    },
};
