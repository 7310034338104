import { interpret } from 'xstate';
import { LendTransaction, RedeemTransaction } from '../core/markets';
import { AddLiquidityTransaction, RemoveLiquidityTransaction } from '../core/pools';
import { LOG_SERVICE, serviceLocator } from '../core/services';
import { INTERACTIVITY_SERVICE, InteractivityState } from '../core/services/interactivity';
import { ILLUMINATE_EVENTS, IlluminateMessage, IlluminateUserMessageData, MESSAGE_SERVICE } from '../core/services/messages';
import { TRANSACTION_SERVICE, TRANSACTION_SERVICE_TOPIC, TransactionServiceMessage } from '../core/services/transaction';
import { WALLET_SERVICE } from '../core/services/wallet';
import { throttle } from '../shared/helpers';
import * as ACCOUNT from './account';
import * as MARKET from './market';
import * as POOL from './pool';
import * as POSITION from './position';

export { ACCOUNT, MARKET, POOL, POSITION };

const logger = serviceLocator.get(LOG_SERVICE).group('orchetrator');
const wallet = serviceLocator.get(WALLET_SERVICE);
const messages = serviceLocator.get(MESSAGE_SERVICE);
const interactivity = serviceLocator.get(INTERACTIVITY_SERVICE);
const transactions = serviceLocator.get(TRANSACTION_SERVICE);

/**
 * Fetches based on websocket events are throttled by this timeout to reduce excessive reloads/updates.
 */
const REFRESH_TIMEOUT = 3000;

/**
 * Refresh data after 60 seconds of inactivity
 */
const INACTIVITY_THRESHOLD = 60000;

let inactive: number | undefined;

let started = false;

/**
 * The state machine orchetrator.
 *
 * @remarks
 * The orchestrator facilitates inter-machine coordination/communication and synchronizes the state machines
 * and other system-wide services to keep the application state up-to-date.
 */
export const orchestrator = {

    account: interpret(ACCOUNT.machine),

    market: interpret(MARKET.machine),

    pool: interpret(POOL.machine),

    position: interpret(POSITION.machine),

    start () {

        if (started) return;

        syncMachines();

        this.position.start();
        this.pool.start();
        this.market.start();
        this.account.start();

        wallet.listen('connect', handleWalletConnect);
        wallet.listen('disconnect', handleWalletDisconnect);
        wallet.listen('accountsChanged', handleWalletAccountsChanged);
        wallet.listen('chainChanged', handleWalletChainChanged);

        messages.subscribe(handleMessage);
        interactivity.subscribe(handleInteractivity);
        transactions.subscribe(TRANSACTION_SERVICE_TOPIC.SUCCESS, handleTransactionSuccess);

        void messages.connect();

        started = true;
    },

    stop () {

        if (!started) return;

        wallet.unlisten('connect', handleWalletConnect);
        wallet.unlisten('disconnect', handleWalletDisconnect);
        wallet.unlisten('accountsChanged', handleWalletAccountsChanged);
        wallet.unlisten('chainChanged', handleWalletChainChanged);

        messages.unsubscribe(handleMessage);
        interactivity.unsubscribe(handleInteractivity);
        transactions.unsubscribe(TRANSACTION_SERVICE_TOPIC.SUCCESS, handleTransactionSuccess);

        messages.disconnect();

        this.position.stop();
        this.pool.stop();
        this.market.stop();
        this.account.stop();

        started = false;
    },
};

/**
 * Syncs state machine events
 */
const syncMachines = () => {

    const {
        account: accountMachine,
        market: marketMachine,
        pool: poolMachine,
        position: positionMachine,
    } = orchestrator;

    accountMachine.onTransition((state, event) => {

        const isConnected = state.matches(ACCOUNT.STATES.CONNECTED);

        const isConnectionEvent = event.type === ACCOUNT.EVENTS.CONNECTED
            || event.type as unknown === ACCOUNT.EVENTS.CONNECT_SUCCESS;

        if (isConnected && isConnectionEvent) {

            const marketsReady = marketMachine.state.matches(MARKET.STATES.SELECT_TOKEN)
                || marketMachine.state.matches(MARKET.STATES.SELECT_MARKET)
                || marketMachine.state.matches(MARKET.STATES.COMPLETE);

            const poolsReady = poolMachine.state.matches(POOL.STATES.SELECT_TOKEN)
                || poolMachine.state.matches(POOL.STATES.SELECT_POOL)
                || poolMachine.state.matches(POOL.STATES.COMPLETE);

            const positionsOutdated = positionMachine.state.matches(POSITION.STATES.INITIAL)
                || positionMachine.state.matches(POSITION.STATES.ERROR);

            const initPositions = positionsOutdated && marketsReady && poolsReady;

            const connection = state.context;

            // fetch token balances if markets or pools are available
            if (marketsReady || poolsReady) {

                const marketTokens = marketMachine.state.context.tokens;
                const poolTokens = poolMachine.state.context.tokens;

                const balances = Array.from(new Set([
                    ...marketTokens.values(),
                    ...poolTokens.values(),
                ]));

                logger.log('accountMachine.onTransition()... instructing account machine to fetch balances...');

                accountMachine.send(ACCOUNT.model.events[ACCOUNT.EVENTS.FETCH](balances));
            }

            // initialize the positions machine if account is connected and both markets and pools are fetched
            if (initPositions) {

                const { markets, tokens: marketTokens } = marketMachine.state.context;
                const { pools, tokens: poolTokens } = poolMachine.state.context;

                const tokens = new Map([...marketTokens.entries(), ...poolTokens.entries()]);

                logger.log('accountMachine.onTransition()... instructing position machine to initialize...');

                positionMachine.send(POSITION.model.events[POSITION.EVENTS.INIT]({
                    account: connection.account,
                    markets,
                    pools,
                    tokens,
                }));
            }
        }
    });

    marketMachine.onTransition((state, event) => {

        if (event.type as unknown === MARKET.EVENTS.FETCH_SUCCESS) {

            const poolsReady = poolMachine.state.matches(POOL.STATES.SELECT_TOKEN)
                || poolMachine.state.matches(POOL.STATES.SELECT_POOL)
                || poolMachine.state.matches(POOL.STATES.COMPLETE);

            const accountsReady = accountMachine.state.matches(ACCOUNT.STATES.CONNECTED)
                || accountMachine.state.matches(ACCOUNT.STATES.FETCHING);

            // we always have to re-fetch positions after markets or pools have been updated,
            // as markets and pools get mixed into the position models for ease of use
            const initPositions = poolsReady && accountsReady;

            // fetch token balances if account is connected
            if (accountsReady) {

                const { tokens } = marketMachine.state.context;

                const balances = [
                    // the underlying tokens
                    ...tokens.values(),
                ];

                logger.log('marketMachine.onTransition()... instructing account machine to fetch balances...');

                accountMachine.send(ACCOUNT.model.events[ACCOUNT.EVENTS.FETCH](balances));
            }

            // initialize the positions machine if account is connected and both markets and pools are fetched
            if (initPositions) {

                const connection = accountMachine.state.context;
                const { markets, tokens: marketTokens } = marketMachine.state.context;
                const { pools, tokens: poolTokens } = poolMachine.state.context;

                const tokens = new Map([...marketTokens.entries(), ...poolTokens.entries()]);

                logger.log('marketMachine.onTransition()... instructing position machine to initialize...');

                positionMachine.send(POSITION.model.events[POSITION.EVENTS.INIT]({
                    account: connection.account,
                    markets,
                    pools,
                    tokens,
                }));
            }
        }
    });

    poolMachine.onTransition((state, event) => {

        if (event.type as unknown === POOL.EVENTS.FETCH_SUCCESS) {

            const marketsReady = marketMachine.state.matches(MARKET.STATES.SELECT_TOKEN)
                || marketMachine.state.matches(MARKET.STATES.SELECT_MARKET)
                || marketMachine.state.matches(MARKET.STATES.COMPLETE);

            const accountsReady = accountMachine.state.matches(ACCOUNT.STATES.CONNECTED)
                || accountMachine.state.matches(ACCOUNT.STATES.FETCHING);

            // we always have to re-fetch positions after markets or pools have been updated,
            // as markets and pools get mixed into the position models for ease of use
            const initPositions = marketsReady && accountsReady;

            // fetch token balances if account is connected
            // the pools could potentially have underlying tokens, that were not yet fetched by the markets
            if (accountsReady) {

                const { tokens } = poolMachine.state.context;

                const balances = [
                    // the underlying tokens
                    ...(tokens?.values() ?? []),
                ];

                logger.log('poolMachine.onTransition()... instructing account machine to fetch balances...');

                accountMachine.send(ACCOUNT.model.events[ACCOUNT.EVENTS.FETCH](balances));
            }

            // initialize the positions machine if account is connected and both markets and pools are fetched
            if (initPositions) {

                const connection = accountMachine.state.context;
                const { markets, tokens: marketTokens } = marketMachine.state.context;
                const { pools, tokens: poolTokens } = poolMachine.state.context;

                const tokens = new Map([...marketTokens.entries(), ...poolTokens.entries()]);

                logger.log('poolMachine.onTransition()... instructing position machine to initialize...');

                positionMachine.send(POSITION.model.events[POSITION.EVENTS.INIT]({
                    account: connection.account,
                    markets,
                    pools,
                    tokens,
                }));
            }
        }
    });
};

/**
 * Handles changes from the {@link InteractivityService} and updates state machines accordingly
 *
 * @param state - the interactivity state
 */
const handleInteractivity = (state: InteractivityState) => {

    if (!state.connected || !state.visible) {

        inactive = Date.now();

    } else {

        if (inactive && (Date.now() - inactive >= INACTIVITY_THRESHOLD)) {

            refreshMarkets();
        }

        inactive = undefined;
    }
};

/**
 * Handles messages from the {@link MessageService} (websocket) and updates state machines accordingly
 *
 * @param message - the parsed message from the message service
 */
const handleMessage = (message: IlluminateMessage) => {

    let user: string | undefined;

    switch (message.name) {

        // when a new market is created, it is connected to a pool which will be invested by strategy
        // when a market matures, its pool matures and the strategy will divest
        // this basically replaces the previously used `CREATE_MARKET` event
        case ILLUMINATE_EVENTS.STRATEGY_INVEST:
        case ILLUMINATE_EVENTS.STRATEGY_DIVEST:
        case ILLUMINATE_EVENTS.STRATEGY_DRAIN:
        case ILLUMINATE_EVENTS.STRATEGY_EJECT:

            refreshMarkets();
            break;

        case ILLUMINATE_EVENTS.PAUSE_ILLUMINATE:

            refreshAll();
            break;

        case ILLUMINATE_EVENTS.PAUSE_MARKET:
        case ILLUMINATE_EVENTS.PAUSE_POOL:

            refreshMarkets();
            break;

        case ILLUMINATE_EVENTS.LEND_POSITION_LEND:
        case ILLUMINATE_EVENTS.LEND_POSITION_EXIT:
        case ILLUMINATE_EVENTS.LEND_POSITION_REDEEM:
        case ILLUMINATE_EVENTS.POOL_POSITION_ADD:
        case ILLUMINATE_EVENTS.POOL_POSITION_REMOVE:

            user = (message as IlluminateMessage<IlluminateUserMessageData>).data?.user;

            if (wallet.state.connection?.account.address !== user) return;

            refreshPositions();
            break;
    }
};

/**
 * Handles connect from the {@link WalletSservice}
 *
 * @remarks
 * The `connect` event occurs when the `WalletService` successfully resolved the connection
 * to an ethereum provider and has obtained a wallet address.
 */
const handleWalletConnect = () => {

    // nothing to do here...
};

/**
 * Handles disconnect from the {@link WalletService} and updates state machines accordingly
 *
 * @remarks
 * The `disconnect` event only occurs when an EIP-1193 ethereum provider becomes unable to submit
 * rpc requests: https://docs.metamask.io/guide/ethereum-provider.html#disconnect.
 */
const handleWalletDisconnect = () => {

    orchestrator.account.send(ACCOUNT.model.events[ACCOUNT.EVENTS.DISCONNECTED]());
};

/**
 * Handles wallet account change events
 *
 * @remarks
 * When a wallet account changes, we refresh the page to clear any state. The
 * `autoConnect` preference will take care of auto-connecting to the same wallet
 * on page load (with the newly connected account).
 *
 * If the page that captures the event is not visible, it indicates that the
 * account change happened in a different tab. In this case, we disconnect the
 * wallet and refresh the page when it becomes active again.
 */
const handleWalletAccountsChanged = () => {

    if (!interactivity.visible) {

        void wallet.disconnect();

        return;
    }

    refresh();
};

/**
 * Handles wallet chain change events
 *
 * @remarks
 * When a wallet chain changes, we refresh the page to clear any state. The
 * `autoConnect` preference will take care of auto-connecting to the same wallet
 * on page load (with the newly connected chain).
 *
 * If the page that captures the event is not visible, it indicates that the
 * chain change happened in a different tab. In this case, we disconnect the
 * wallet and refresh the page when it becomes active again.
 */
const handleWalletChainChanged = () => {

    if (!interactivity.visible) {

        void wallet.disconnect();

        return;
    }

    refresh();

    // we could also check which chain the user has changed to and redirect
    // to a matching deployment...
};

/**
 * Handles 'SUCCESS' events from the transaction service
 *
 * @remarks
 * Successful transactions may change a users account/token balances and positions. Therefore
 * we need to re-fetch those after tansactions succeed.
 */
const handleTransactionSuccess = (message: TransactionServiceMessage<typeof TRANSACTION_SERVICE_TOPIC.SUCCESS>) => {

    const transaction = message.detail;

    switch (transaction.type) {

        case LendTransaction.type:
        case RedeemTransaction.type:
        case AddLiquidityTransaction.type:
        case RemoveLiquidityTransaction.type:

            refreshPositions();
            break;
    }
};

/**
 * Refresh market-related state machines
 *
 * @remarks
 * We wrap the state machine event dispatches in a throttle call to prevent excessive re-fetching.
 * Refreshing markets or pools will also refresh positions.
 */
const refreshMarkets = throttle(() => {

    // re-fetch the user's balances
    orchestrator.account.send(ACCOUNT.model.events[ACCOUNT.EVENTS.FETCH]([]));
    // re-fetch the markets
    orchestrator.market.send(MARKET.model.events[MARKET.EVENTS.FETCH]());
    // re-fetch the pools
    orchestrator.pool.send(POOL.model.events[POOL.EVENTS.FETCH]());

}, REFRESH_TIMEOUT);

/**
 * Refresh position-related state machines
 *
 * @remarks
 * We wrap the state machine event dispatches in a throttle call to prevent excessive re-fetching.
 */
const refreshPositions = throttle(() => {

    // re-fetch the user's balances
    orchestrator.account.send(ACCOUNT.model.events[ACCOUNT.EVENTS.FETCH]([]));
    // re-fetch the user's positions
    orchestrator.position.send(POSITION.model.events[POSITION.EVENTS.FETCH]());

}, REFRESH_TIMEOUT);

/**
 * Refresh everything
 *
 * @remarks
 * We wrap the reload call in a throttle call to prevent excessive re-loading.
 */
const refreshAll = throttle(() => {

    if (window) {

        window.location.reload();

    } else {

        // for non-browser environments we can re-start the orchestrator to have an effect similar to a page reload
        orchestrator.stop();
        orchestrator.start();
    }

}, REFRESH_TIMEOUT);

/**
 * Refresh the page
 *
 * @remarks
 * If the page is not visible, we subscribe to the `interactivity` service to
 * refresh the page when it becomes visible. This helps us avoid refreshing a
 * page that is not visible (e.g. when the user is on a different tab) and
 * prevents the inactive page from requesting wallet interactions at load.
 */
const refresh = () => {

    if (interactivity.visible) {

        window.location.reload();

    } else {

        interactivity.subscribe(state => {

            if (state.visible) window.location.reload();
        });
    }
};
