import { TransactionReceipt, TransactionResponse } from '@ethersproject/providers';
import { defer, TypedObject } from '../../../shared/helpers';
import { ERRORS } from '../../constants';
import { ErrorLike, ErrorService, ProcessedError } from '../errors';
import { SettingsService } from '../settings';
import { Connection } from '../wallet';
import { SerializedTransaction, Subscriber, TransactionConstructor, TransactionMessage, TransactionOptions, TransactionService, TransactionState, TRANSACTION_STATUS, TRANSACTION_TOPIC } from './interfaces';
import { transactionId } from './utils';

export class Transaction<
    // eslint-disable-next-line @typescript-eslint/ban-types
    State extends {} = TransactionState,
    Status extends string = string,
    Topic extends string = string,
> {

    /**
     * The type (or serialized name) of the transaction instance.
     *
     * @remarks
     * This property is used to register a transaction constructor or factory with a serializable name
     * and allows the transaction service to de-serialize transactions from plain JSON objects into
     * their original instances.
     *
     * Each concrete transaction class must set a custom type property.
     */
    static type = this.name;

    protected _id: number;

    protected _state: Partial<State> = {};

    protected _status? = TRANSACTION_STATUS.INITIAL as Status;

    protected _hash?: string;

    protected _response?: TransactionResponse;

    protected _receipt?: TransactionReceipt;

    protected _error?: ErrorLike;

    protected subscribers = new Map<Topic, Set<Subscriber<TransactionMessage<Transaction>>>>();

    protected service?: TransactionService;

    protected settingsService?: SettingsService;

    protected errorService?: ErrorService;

    get type (): string {

        return (this.constructor as TransactionConstructor).type;
    }

    get id (): number {

        return this._id;
    }

    get state (): Partial<State> {

        return this._state;
    }

    get status (): Status | undefined {

        return this._status;
    }

    get hash (): string | undefined {

        return this._hash;
    }

    get response (): TransactionResponse | undefined {

        return this._response;
    }

    get receipt (): TransactionReceipt | undefined {

        return this._receipt;
    }

    get error (): ErrorLike | undefined {

        return this._error;
    }

    connection?: Connection;

    constructor (
        transaction?: TransactionOptions<Transaction<State, Status, Topic>>,
        service?: TransactionService,
        settingsService?: SettingsService,
        errorService?: ErrorService,
    ) {

        this.service = service;
        this.settingsService = settingsService;
        this.errorService = errorService;

        this._id = transaction?.id ?? transactionId();

        this._error = transaction?.error;
        this._hash = transaction?.hash;
        this._response = transaction?.response;
        this._receipt = transaction?.receipt;
        this.connection = transaction?.connection;

        this._status = transaction?.status ?? this._status;
        this._state = { ...transaction?.state };

        // only subscribe to settings service if transaction is not final
        if (!this.isFinal()) {

            this.handleSettingsChange = this.handleSettingsChange.bind(this);
            // eslint-disable-next-line @typescript-eslint/unbound-method
            this.settingsService?.subscribe(this.handleSettingsChange);
        }

        // defer resuming the transaction, so that state and status events are triggered after the
        // constructor has finished setting up everything and dependencies are resolved
        defer(() => {

            if (transaction?.status === TRANSACTION_STATUS.PENDING) {

                void this.resume();
            }
        });
    }

    isFinal (): boolean {

        return this.status === TRANSACTION_STATUS.SUCCESS
            || this.status === TRANSACTION_STATUS.FAILURE
            || this.status === TRANSACTION_STATUS.DISPOSED;
    }

    isPending (): boolean {

        return this.status === TRANSACTION_STATUS.PENDING;
    }

    subscribe<K extends Topic> (topic: K, subscriber: Subscriber<TransactionMessage<this, K>>): void {

        if (!this.subscribers.has(topic)) this.subscribers.set(topic, new Set());

        this.subscribers.get(topic)?.add(subscriber as Subscriber<TransactionMessage<Transaction>>);
    }

    unsubscribe<K extends Topic> (topic: K, subscriber: Subscriber<TransactionMessage<this, K>>): void {

        this.subscribers.get(topic)?.delete(subscriber as Subscriber<TransactionMessage<Transaction>>);
    }

    async send (connection?: Connection): Promise<void> {

        try {

            if (this.status !== 'COMPLETE') return this.throw(ERRORS.SERVICES.TRANSACTION.STATUS.INCOMPLETE);

            this.updateStatus(TRANSACTION_STATUS.PENDING as Status);

            this._response = await this.performTransaction(connection ?? this.connection);

            this._hash = this._response.hash;

            // TODO: currently we dispatch and empty STATE change event to signal to the transaction service
            // that sth changed (we want the hash to be stored in localStorage) - that's not very intuitive
            this.notify(TRANSACTION_TOPIC.STATE as Topic, {});

            this._receipt = await this.service?.confirm(this._response);

            this._error = undefined;

            this.updateStatus(TRANSACTION_STATUS.SUCCESS as Status);

        } catch (error) {

            this._error = this.errorService?.isProcessed(error)
                ? error
                : this.errorService?.process(error) ?? error as ErrorLike;

            this.updateStatus(TRANSACTION_STATUS.FAILURE as Status);
        }
    }

    dispose () {

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.settingsService?.unsubscribe(this.handleSettingsChange);

        this.updateStatus(TRANSACTION_STATUS.DISPOSED as Status);
    }

    toJSON (): SerializedTransaction<this> {

        return {
            type: this.type,
            id: this.id,
            state: this.state,
            status: this.status,
            hash: this.hash,
            error: this.error,
        };
    }

    protected updateState (state: Partial<State>): void {

        if (this.isFinal()) return this.throw(ERRORS.SERVICES.TRANSACTION.STATUS.FINAL);

        const changes = TypedObject.keys(state).reduce((changes, current) => {

            if (state[current] !== this._state[current]) {
                // back up the previous data value in the changes object
                changes[current] = this._state[current];
            }

            return changes;

        }, {} as Partial<State>);

        this._state = {
            ...this.state,
            ...state,
        };

        this.handleStateChange(changes);
    }

    protected updateStatus (status: Status | undefined): void {

        if (this._status === status) return;

        if (this.isFinal()) return this.throw(ERRORS.SERVICES.TRANSACTION.STATUS.FINAL);

        const change = this._status;

        this._status = status;

        this.handleStatusChange(change);
    }

    /**
     * Performs the actual transaction and returns a TransactionResponse
     *
     * @remarks
     * Implement this with the concrete transaction's logic.
     */
    protected performTransaction (connection?: Connection): Promise<TransactionResponse> {

        return Promise.resolve({
            hash: `0x${ this.id }`,
            from: connection?.account.address,
            chainId: connection?.network.chainId,
        } as TransactionResponse);
    }

    protected notify<K extends Topic> (topic: K, detail: TransactionMessage<this, K>['detail']): void {

        this.subscribers.get(topic)?.forEach(subscriber => {

            subscriber({
                transaction: this,
                topic,
                detail,
            });
        });
    }

    protected async request<T> (requestId: string): Promise<T> {

        return new Promise<T>((resolve, reject) => {

            this.notify(TRANSACTION_TOPIC.REQUEST as Topic, {
                requestId,
                resolve: resolve as (result: unknown) => void,
                reject,
            });
        });
    }

    protected async resume (): Promise<void> {

        if (!this.isPending() || !this._hash) return;

        try {

            this._receipt = await this.service?.confirm(this._hash, this.connection);

            this._error = undefined;

            this.updateStatus(TRANSACTION_STATUS.SUCCESS as Status);

        } catch (error) {

            this._error = error as ErrorLike;

            this.updateStatus(TRANSACTION_STATUS.FAILURE as Status);
        }
    }

    protected throw (error: ErrorLike): never {

        throw this.errorService?.process(error) ?? new ProcessedError(error);
    }

    /**
     * Handles changes to the transaction's state and notifies subscribers
     *
     * @remarks
     * Use this to react to state changes like transaction input data,
     * e.g. fetch swap previews, check allowances, etc...
     *
     * @param changes - a record of the changed state properties before the update
     */
    protected handleStateChange (changes: Partial<State>): void {

        this.notify(TRANSACTION_TOPIC.STATE as Topic, changes);
    }

    /**
     * Handles changes to the transaction's status and notifies subscribers
     *
     * @remarks
     * Use this to react to status transitions, e.g. request additional data, perform
     * implicit transitions or consitency checks...
     *
     * @param status - the transaction status before the update
     */
    protected handleStatusChange (status: Status | undefined): void {

        this.notify(TRANSACTION_TOPIC.STATUS as Topic, status);

        if (this.isFinal()) {

            // eslint-disable-next-line @typescript-eslint/unbound-method
            this.settingsService?.unsubscribe(this.handleSettingsChange);

            // TODO: if entering a terminal state, we could theoretically unsubscribe any subscriber
            // after notifying them for a last time - nothing is gonna change anymore...
        }
    }

    /**
     * Handles changes to the settings via the SettingsService
     *
     * @remarks
     * To be implemented by a transaction that needs to react to settings changes.
     */
    protected handleSettingsChange (): void {

        // implement in concrete transaction
    }
}
