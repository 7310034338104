import { TransactionResponse } from '@ethersproject/abstract-provider';
import { Market, MarketPlace, Principals } from '@swivel-finance/illuminate-js';
import { ethers } from 'ethers';
import { ERRORS } from '../../../constants';
import { ENV } from '../../../env';
import { ErrorService } from '../../errors';
import { LogService } from '../../logger';
import { TokenService } from '../../token';
import { Connection, WalletService } from '../../wallet';
import { YieldSpaceService } from '../../yieldspace';
import { IlluminateMarketPlaceService } from '../interfaces';

// the `markets` method of the `MarketPlace` contract now returns all the principal token addresses
// and pool address for a given market, meaning we don't have to separately fetch these pieces of
// information any longer
// this also means, that we can actually cache the results of the `markets` method, since the
// `IlluminateMarketPlaceService` interface still returns the principal token address for the `markets`
// method and the pool address for the `pools` method

const MARKET_CACHE = new Map<string, Map<string, Market>>();

// refresh the cache every 5 minutes
const MARKET_CACHE_REFRESH_TIME = 5 * 60 * 1000;

let MARKET_CACHE_LAST_UPDATED = 0;

/**
 * Get a market from the cache or fetch it from the contract and cache it.
 */
const getMarket = async (underlying: string, maturity: string, connection: Connection): Promise<Market> => {

    if (Date.now() - MARKET_CACHE_LAST_UPDATED > MARKET_CACHE_REFRESH_TIME) {

        MARKET_CACHE.clear();

        MARKET_CACHE_LAST_UPDATED = Date.now();
    }

    if (!MARKET_CACHE.has(underlying)) {

        MARKET_CACHE.set(underlying, new Map<string, Market>());
    }

    if (!MARKET_CACHE.get(underlying)?.has(maturity)) {

        const contract = new MarketPlace(ENV.marketplaceAddress, connection.provider);

        const market = await contract.markets(underlying, maturity);

        MARKET_CACHE.get(underlying)?.set(maturity, market);
    }

    return MARKET_CACHE.get(underlying)?.get(maturity) as Market;
};

export class ChainIlluminateMarketPlaceService implements IlluminateMarketPlaceService {

    protected wallet: WalletService;

    protected token: TokenService;

    protected yieldspace: YieldSpaceService;

    protected errors: ErrorService;

    protected logger: LogService;

    constructor (
        wallet: WalletService,
        token: TokenService,
        yieldspace: YieldSpaceService,
        errors: ErrorService,
        logger: LogService,
    ) {

        this.wallet = wallet;
        this.token = token;
        this.yieldspace = yieldspace;
        this.errors = errors;
        this.logger = logger.group('illuminate-marketplace');
    }

    async allowance (address: string, connection?: Connection): Promise<string> {

        try {

            connection = connection ?? await this.wallet.connection();

            const allowance = await this.token.allowance(address, connection.account.address, ENV.marketplaceAddress, connection);

            this.logger.log('allowance: ', allowance);

            return allowance;

        } catch (error) {

            throw this.errors.isProcessed(error)
                ? error
                : this.errors.process(error, ERRORS.SERVICES.ILLUMINATE.ALLOWANCE);
        }
    }

    async approve (address: string, connection?: Connection): Promise<TransactionResponse> {

        try {

            connection = connection ?? await this.wallet.connection();

            const response = await this.token.approve(address, ENV.marketplaceAddress, ethers.constants.MaxUint256, connection);

            this.logger.log('approval: ', response);

            return response;

        } catch (error) {

            throw this.errors.isProcessed(error)
                ? error
                : this.errors.process(error, ERRORS.SERVICES.ILLUMINATE.APPROVAL);
        }
    }

    async markets (underlying: string, maturity: string, principal: Principals, connection?: Connection): Promise<string> {

        connection = connection ?? await this.wallet.connection();

        try {

            return (await getMarket(underlying, maturity, connection)).tokens[principal];

        } catch (error) {

            throw this.errors.process(error);
        }
    }

    async pools (underlying: string, maturity: string, connection?: Connection): Promise<string> {

        connection = connection ?? await this.wallet.connection();

        try {

            return (await getMarket(underlying, maturity, connection)).pool;

        } catch (error) {

            throw this.errors.process(error);
        }
    }

    async sellPrincipalTokenPreview (underlying: string, maturity: string, amount: string, pool?: string, connection?: Connection): Promise<string> {

        connection = connection ?? await this.wallet.connection();

        pool = pool ?? await this.pools(underlying, maturity, connection);

        try {

            const preview = await this.yieldspace.sellFYTokenPreview(pool, amount, connection);

            return preview;

        } catch (error) {

            throw this.errors.process(error);
        }
    }

    async sellPrincipalToken (underlying: string, maturity: string, amount: string, slippage: string, connection?: Connection): Promise<TransactionResponse> {

        connection = connection ?? await this.wallet.connection();
        const contract = new MarketPlace(ENV.marketplaceAddress, connection.signer);

        try {

            const response = await contract.sellPrincipalToken(underlying, maturity, amount, slippage);

            this.logger.log('sellPrincipalToken: ', response);

            return response;

        } catch (error) {

            const processedError = this.errors.process(error);

            // TODO: make this somehow more ergonomic...
            const detail = processedError.message;

            const override = detail
                ? this.errors.updateMessage(ERRORS.SERVICES.ILLUMINATE.EXIT, [[/\.$/, `: ${ detail }`]])
                : ERRORS.SERVICES.ILLUMINATE.EXIT;

            throw this.errors.process(error, override);
        }
    }

    async mint (underlying: string, maturity: string, base: string, principal: string, minRatio: string, maxRatio: string, connection?: Connection): Promise<TransactionResponse> {

        connection = connection ?? await this.wallet.connection();
        const contract = new MarketPlace(ENV.marketplaceAddress, connection.signer);

        try {

            const response = await contract.mint(underlying, maturity, base, principal, minRatio, maxRatio);

            this.logger.log('mint: ', response);

            return response;

        } catch (error) {

            const processedError = this.errors.process(error);

            // TODO: make this somehow more ergonomic...
            const detail = processedError.message;

            const override = detail
                ? this.errors.updateMessage(ERRORS.SERVICES.ILLUMINATE.ADD_LIQUIDITY, [[/\.$/, `: ${ detail }`]])
                : ERRORS.SERVICES.ILLUMINATE.ADD_LIQUIDITY;

            throw this.errors.process(error, override);
        }
    }

    async mintWithUnderlying (underlying: string, maturity: string, base: string, principal: string, minRatio: string, maxRatio: string, connection?: Connection): Promise<TransactionResponse> {

        connection = connection ?? await this.wallet.connection();
        const contract = new MarketPlace(ENV.marketplaceAddress, connection.signer);

        try {

            const response = await contract.mintWithUnderlying(underlying, maturity, base, principal, minRatio, maxRatio);

            this.logger.log('mintWithUnderlying: ', response);

            return response;

        } catch (error) {

            const processedError = this.errors.process(error);

            // TODO: make this somehow more ergonomic...
            const detail = processedError.message;

            const override = detail
                ? this.errors.updateMessage(ERRORS.SERVICES.ILLUMINATE.ADD_LIQUIDITY, [[/\.$/, `: ${ detail }`]])
                : ERRORS.SERVICES.ILLUMINATE.ADD_LIQUIDITY;

            throw this.errors.process(error, override);
        }
    }

    async burn (underlying: string, maturity: string, amount: string, minRatio: string, maxRatio: string, connection?: Connection): Promise<TransactionResponse> {

        connection = connection ?? await this.wallet.connection();
        const contract = new MarketPlace(ENV.marketplaceAddress, connection.signer);

        try {

            const response = await contract.burn(underlying, maturity, amount, minRatio, maxRatio);

            this.logger.log('burn: ', response);

            return response;

        } catch (error) {

            const processedError = this.errors.process(error);

            // TODO: make this somehow more ergonomic...
            const detail = processedError.message;

            const override = detail
                ? this.errors.updateMessage(ERRORS.SERVICES.ILLUMINATE.REMOVE_LIQUIDITY, [[/\.$/, `: ${ detail }`]])
                : ERRORS.SERVICES.ILLUMINATE.REMOVE_LIQUIDITY;

            throw this.errors.process(error, override);
        }
    }

    async burnForUnderlying (underlying: string, maturity: string, amount: string, minRatio: string, maxRatio: string, connection?: Connection): Promise<TransactionResponse> {

        connection = connection ?? await this.wallet.connection();
        const contract = new MarketPlace(ENV.marketplaceAddress, connection.signer);

        try {

            const response = await contract.burnForUnderlying(underlying, maturity, amount, minRatio, maxRatio);

            this.logger.log('burnForUnderlying: ', response);

            return response;

        } catch (error) {

            const processedError = this.errors.process(error);

            // TODO: make this somehow more ergonomic...
            const detail = processedError.message;

            const override = detail
                ? this.errors.updateMessage(ERRORS.SERVICES.ILLUMINATE.REMOVE_LIQUIDITY, [[/\.$/, `: ${ detail }`]])
                : ERRORS.SERVICES.ILLUMINATE.REMOVE_LIQUIDITY;

            throw this.errors.process(error, override);
        }
    }
}
