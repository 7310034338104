import { TemplateResult } from 'lit';

export const enum POSITION_STEPS {
    POSITION,
    PREVIEW,
    RESULT,
}

export const enum POSITION_TYPES {
    LEND,
    POOL,
}

export const enum POSITION_MODES {
    ENTER,
    EXIT,
}

export const STEP_LABELS = [
    'Select a Position',
    'Preview',
    'Result',
];

export interface PositionStatus {
    isArchived: boolean;
    isRedeemed: boolean;
    isMatured: boolean;
    isPaused: boolean;
    isExited: boolean;
    isVirtual: boolean;
    isDivesting: boolean;
    isDivested: boolean;
    canEnter: boolean;
    canExit: boolean;
    pausedTooltip: TemplateResult | undefined;
    pausedTooltipId: string;
}

export interface TooltipData {
    id: string;
    content: TemplateResult;
}

export interface LendPositionTooltips {
    enter: TooltipData;
    exit: TooltipData;
    valueAtMaturity: TooltipData;
    currentValue: TooltipData;
    redemptionValue: TooltipData;
    interestEarned: TooltipData;
    effectiveRate: TooltipData;
}

export interface PoolPositionTooltips {
    enter: TooltipData;
    exit: TooltipData;
    share: TooltipData;
    positionValue: TooltipData;
    currentRate: TooltipData;
}
