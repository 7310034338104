let lastId: number | undefined;

export const transactionId = (): number => {

    let id = Date.now();

    if (lastId && id <= lastId) {

        id = lastId + 1;
    }

    return (lastId = id);
};
