import { ValueChangeEvent } from '@swivel-finance/ui/elements/input';
import { html, LitElement, nothing } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { ERRORS, TOOLTIPS } from '../../core/constants';
import { errorMessage, rate, status, tokenSymbol } from '../../shared/templates';
import { MARKET, orchestrator } from '../../state/orchestrator';
import { Quote, Token } from '../../types';

const template = function (this: LendTokenElement) {

    const state = this.marketMachine.state;
    const context = state.context;

    const isReady = state.matches(MARKET.STATES.SELECT_TOKEN)
        || state.matches(MARKET.STATES.FETCHING_QUOTES)
        || state.matches(MARKET.STATES.SELECT_MARKET)
        || state.matches(MARKET.STATES.COMPLETE);

    const isFetching = state.matches(MARKET.STATES.FETCHING);

    const hasError = state.matches(MARKET.STATES.ERROR);

    const { quotesByToken, activeTokensByAPR } = context;

    const hasQuotes = quotesByToken.size > 0;

    return html`
    <div class="widget">

        <div class="widget-header">
            <label id="tokens-label" aria-describedby="lend-select-currency-header-tooltip">Select a Currency</label>
            <ui-tooltip id="lend-select-currency-header-tooltip">
                ${ TOOLTIPS.LEND.SELECT_CURRENCY.HEADER() }
            </ui-tooltip>
        </div>

        <div class="widget-main">

            ${ isReady && hasQuotes
                ? html`
                <ui-listbox
                    class="neo-list token-list"
                    aria-labelledby="tokens-label"
                    @ui-value-changed=${ (event: ValueChangeEvent<Token>) => this.handleTokenChange(event) }>
                        ${ activeTokensByAPR.map(token => html`
                        <ui-listitem class="neo-item ${ this.highlightToken === token.address ? 'highlight' : '' }" .value=${ token } aria-disabled="${ !quotesByToken.has(token.address) }">
                            <div class="neo-item-content">
                                <span class="token">
                                    <ill-token-symbol .name=${ token.image }></ill-token-symbol>
                                    ${ tokenSymbol(token) }
                                </span>
                                <!-- NB: we need to manually set the tabindex to "-1" as the tooltip will add a
                                    tabindex of "0" to tooltip triggers by default (for accessibility)
                                    however, these tooltips are children of a focusable list item and, as such,
                                    they shouldn't be focusable -->
                                ${ !quotesByToken.has(token.address)
                                    ? html`
                                    <ui-icon name="pause" aria-describedby="lend-${ token.symbol }-unavailable-tooltip" tabindex="-1"></ui-icon>
                                    `
                                    : html`
                                    <span class="rate" aria-describedby="lend-${ token.symbol }-rate-preview-tooltip" tabindex="-1">
                                        ${ rate(quotesByToken.get(token.address)?.apr) }
                                    </span>
                                    `
                                }
                            </div>
                        </ui-listitem>

                        <ui-tooltip id="lend-${ token.symbol }-rate-preview-tooltip">
                            ${ TOOLTIPS.LEND.SELECT_CURRENCY.BEST_RATE(token) }
                        </ui-tooltip>

                        <ui-tooltip id="lend-${ token.symbol }-unavailable-tooltip">
                            ${ TOOLTIPS.LEND.NO_QUOTES() }
                        </ui-tooltip>
                        `) }
                </ui-listbox>
                `
                : isFetching
                    ? status('loading')
                    : hasError || !hasQuotes
                        ? html`
                        ${ errorMessage(state.context.error ?? ERRORS.STATE.MARKET.NO_QUOTES.message, 'exclamation') }
                        <button class="primary" @click=${ () => this.retry() }>Retry</button>
                        `
                        : nothing
            }

        </div>

    </div>
    `;
};

@customElement('ill-lend-token')
export class LendTokenElement extends LitElement {

    protected marketMachine = orchestrator.market;

    @state()
    protected highlightToken: string | undefined;

    constructor () {

        super();

        this.handleMarketTransition = this.handleMarketTransition.bind(this);
        this.handleQuoteChange = this.handleQuoteChange.bind(this);
    }

    connectedCallback (): void {

        super.connectedCallback();

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.marketMachine.onTransition(this.handleMarketTransition);

        this.syncRateOverview();
    }

    disconnectedCallback (): void {

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.marketMachine.off(this.handleMarketTransition);

        this.unsyncRateOverview();

        super.disconnectedCallback();
    }

    protected createRenderRoot (): Element | ShadowRoot {
        return this;
    }

    protected render (): unknown {

        return template.apply(this);
    }

    protected syncRateOverview (): void {

        const rateOverview = this.parentElement?.querySelector('ill-rate-overview');

        if (rateOverview) {

            // eslint-disable-next-line @typescript-eslint/unbound-method
            rateOverview.addEventListener('quote-change', this.handleQuoteChange as EventListener);
        }
    }

    protected unsyncRateOverview (): void {

        const rateOverview = this.parentElement?.querySelector('ill-rate-overview');

        if (rateOverview) {

            // eslint-disable-next-line @typescript-eslint/unbound-method
            rateOverview.removeEventListener('quote-change', this.handleQuoteChange as EventListener);
        }
    }

    protected handleMarketTransition (): void {

        this.requestUpdate();
    }

    protected handleTokenChange (event: ValueChangeEvent<Token>): void {

        const { current, change } = event.detail;

        if (!change) return;

        this.marketMachine.send(MARKET.model.events[MARKET.EVENTS.SET_TOKEN](current.address));
    }

    protected handleQuoteChange (event: CustomEvent<Quote>): void {

        this.highlightToken = event.detail?.underlying?.address;
    }

    protected retry (): void {

        this.marketMachine.send(MARKET.model.events[MARKET.EVENTS.FETCH]());
    }
}
