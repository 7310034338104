import { TransactionReceipt, TransactionResponse } from '@ethersproject/providers';
import { Connection } from '../../wallet';

export type TransactionConfirmer = (transaction: TransactionResponse | string, connection?: Connection) => Promise<TransactionReceipt>;

export interface TransactionReplacedError extends Error {
    code: 'TRANSACTION_REPLACED';
    hash: string;
    reason: 'cancelled' | 'replaced' | 'repriced';
    cancelled: boolean;
    replacement: TransactionResponse;
    receipt: TransactionReceipt;
}

export const isTransactionReplacedError = (error: unknown): error is TransactionReplacedError => {

    return (error as TransactionReplacedError).code === 'TRANSACTION_REPLACED';
};
