import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { COOKIE_POLICY_ROUTE } from '../../routes';
import { showConsentElement } from '../../services/consent';

const template = function (this: PrivacyPolicyElement) {

    return html`
    <h2>Privacy Policy</h2>
    <span class="last-updated">Last Updated: June 22, 2022</span>

    <h3>Overview</h3>
    <p>
        This Privacy Statement explains how Swivel collects, uses, and discloses information about you through its
        websites, including web applications, and other online products and services (collectively, the “Services”) or
        when you otherwise interact with us. We may change this Privacy Statement from time to time. If we make changes,
        we will notify you by revising the date at the top of the Privacy Statement and, in some cases, we may provide
        you with additional notice (such as adding a statement to our homepage or sending you a notification). We
        encourage you to review the Privacy Statement whenever you access the Services or otherwise interact with us to
        stay informed about our information practices and the choices available to you.
    </p>

    <h3>Collection of information</h3>
    <h4>Information you provide to us</h4>
    <p>
        We collect information you provide directly to us. For example, we collect information when you participate in
        any interactive features of the Services, fill out a form, apply for a job, communicate with us via third party
        social media sites, request customer support or otherwise communicate with us. The types of information we may
        collect include your email address and any other information you choose to provide.
    </p>

    <h4>Automatically collected information</h4>
    <p>
        We don't automatically collect any personal data.
    </p>
    <p>
        When you access or use our Services, we automatically collect anonymised information about you. We collect log
        information about your use of the Services, including the type of browser you use, access times, pages viewed.
        We collect information about the computer or mobile device you use to access our Services, including the
        hardware model, operating system and version, and mobile network information.
    </p>

    <h4>Information collected by cookies and other tracking technologies</h4>
    <p>
        We don't collect any personal data through cookies or other tracking technologies.
    </p>
    <p>
        We use various technologies to collect information, including cookies and local storage. Cookies are small data
        files stored on your hard drive or in device memory that help us improve our Services and your experience, see
        which areas and features of our Services are popular and count visits. Local storage is a similar technology
        which stores data in your browser for the same purposes. For more information on cookies and how to disable them
        please see our <a href="${ COOKIE_POLICY_ROUTE.url }">Cookie Policy</a>.
    </p>

    <h3>Use of information</h3>
    <p>
        We use the information we collect to provide, maintain, and improve our Services. We may also use the
        information we collect to: Send you technical notices, updates, security alerts and support and administrative
        messages and to respond to your comments, questions and customer service requests; Communicate with you about
        products, services, offers, and events offered by Swivel and others, and provide news and information we think
        will be of interest to you; Monitor and analyze trends, usage and activities in connection with our Services;
        and Detect, investigate and prevent fraudulent transactions and other illegal activities and protect the rights
        and property of Swivel and others.
    </p>

    <h3>Sharing of information</h3>
    <p>
        We may share information about you as follows or as otherwise described in this Privacy Statement: With vendors,
        consultants and other service providers who need access to such information to carry out work on our behalf; In
        response to a request for information if we believe disclosure is in accordance with, or required by, any
        applicable law, regulation or legal process; If we believe your actions are inconsistent with our user
        agreements or policies, or to protect the rights, property and safety of Swivel or others; In connection with,
        or during negotiations of, any merger, sale of company assets, financing or acquisition of all or a portion of
        our business by another company; Between and among Swivel and our current and future parents, affiliates,
        subsidiaries and other companies under common control and ownership; and With your consent or at your direction.
    </p>

    <h3>Third-party services</h3>
    <p>
        We work with third parties (specifically analytics providers) to collect anonymised information. These entities
        may use cookies and similar technologies to collect information about your use of our Services, including your
        web browser, mobile network information, pages viewed, time spent on pages or in apps, links clicked and
        conversion information. This information may be used by Swivel and others to, among other things, analyze and
        track data, determine the popularity of certain content, better understand your online activity, and generally
        maintain and improve our Services.
    </p>

    <h3>Security</h3>
    <p>
        We take reasonable measures to help protect information about you from loss, theft, misuse and unauthorized
        access, disclosure, alteration and destruction.
    </p>

    <h3>Data retention</h3>
    <p>
        We store the information we collect about you for as long as is necessary for the purpose(s) for which we
        originally collected it, or for other legitimate business purposes, including to meet our legal or other
        regulatory obligations.
    </p>

    <h3>Residents of the European Economic Area</h3>
    <p>
        If you are a resident of the European Economic Area (“EEA”), you have certain rights and protections under the
        law regarding the processing of your personal data.
    </p>

    <h4>Legal basis for processing</h4>
    <p>
        If you are a resident of the EEA, when we process your personal data we will only do so in the following
        situations: We need to use your personal data to perform our responsibilities under our contract with you (e.g.,
        providing the Swivel services you have requested). We have a legitimate interest in processing your personal
        data. For example, we may process your personal data to send you marketing communications, to communicate with
        you about changes to our Services, and to provide, secure, and improve our Services. You have consented to the
        processing of your personal data for one or more specific purposes.</p>

    <h4>Data subject requests</h4>
    <p>
        If you are a resident of the EEA, you have the right to access personal data we hold about you and to ask that
        your personal data be corrected, erased, or transferred. You may also have the right to object to, or request
        that we restrict, certain processing. If you would like to exercise any of these rights, you can contact us as
        indicated below.
    </p>

    <h4>Questions or complaints</h4>
    <p>
        If you are a resident of the EEA and have a concern about our processing of personal data that we are not able
        to resolve, you have the right to lodge a complaint with the data privacy authority where you reside. For
        contact details of your local Data Protection Authority, please see:
        <a href="http://ec.europa.eu/justice/data-protection/article-29/structure/data-protection-authorities/index_en.htm"
            target="_blank" rel="noopener">
            http://ec.europa.eu/justice/data-protection/article-29/structure/data-protection-authorities/index_en.htm</a>.
    </p>

    <h3>Your choices</h3>
    <h4>Account information</h4>
    <p>
        You may update, correct or delete information about you at any time by emailing us at
        <a href="mailto:support@swivel.finance">support@swivel.finance</a>.
        Please note that we may retain cached or archived copies of information about you for a certain period of time.
    </p>

    <h4>Cookies</h4>
    <p>
        Most web browsers are set to accept cookies by default. You can manage your cookie settings for ${ this.domain } in
        our <a href="#" @click=${ (event: Event) => this.showConsentManager(event) }>consent manager</a>. If you prefer, you
        can usually choose to set your browser to remove or reject browser cookies. Please note that if you choose to remove
        or reject cookies, this could affect the availability and functionality of our Services.
    </p>

    <h4>Promotional communications</h4>
    <p>
        You may opt out of receiving promotional communications from Swivel by following the instructions in those
        communications or by emailing us at <a href="mailto:support@swivel.finance">support@swivel.finance</a>. If you
        opt out, we may still send you non-promotional emails, such as those about your account or our ongoing business
        relations.
    </p>

    <h4>Mobile push notifications/alerts</h4>
    <p>
        With your consent, we may send promotional and non-promotional push notifications or alerts to your mobile
        device. You can deactivate these messages at any time by changing the notification settings on your mobile
        device.
    </p>

    <h3>Contact us</h3>

    <p>
        You may update, correct or delete information about you at any time by emailing us at
        <a href="mailto:support@swivel.finance">support@swivel.finance</a>.
        Please note that we may retain cached or archived copies of information about you for a certain period of time.
    </p>
    `;
};

@customElement('ill-privacy-policy')
export class PrivacyPolicyElement extends LitElement {

    @property()
    domain = window.location.host;

    createRenderRoot () {

        return this;
    }

    render () {

        return template.call(this);
    }

    showConsentManager (event: Event) {

        event.preventDefault();

        showConsentElement();
    }
}
