import { BigNumber, FixedNumber, utils } from 'ethers';
import { Token } from '../../types';
import { SECONDS_PER_YEAR } from '../constants/constants';
import { timeUntilMaturity } from '../markets/helpers';

const EMPTY = /^\s*$/;

const EMPTY_OR_ZERO = /^(0|\s|\.)*$/;

/**
 * Checks if a string value is empty.
 *
 * @param v - the value to test
 * @returns `true` if the value is empty, `false` otherwise
 */
export const empty = (v: string | undefined): boolean => v === undefined || EMPTY.test(v);

/**
 * Checks if a string value is empty or represents zero.
 *
 * @param v - the value to test
 * @returns `true` if the value is empty or zero, `false` otherwise
 */
export const emptyOrZero = (v: string | undefined): boolean => v === undefined || EMPTY_OR_ZERO.test(v);

/**
 * Expands an amount to its smallest token denomination.
 *
 * @param a - the amount
 * @param d - the number of decimals or a {@link Token}
 * @returns the amount in its smallest denomination
 */
export const expandAmount = (a: string, d: number | Token): string => {

    const decimals = (typeof d === 'number') ? d : d.decimals;

    return emptyOrZero(a)
        ? empty(a)
            ? ''
            : '0'
        : utils.parseUnits(round(a, decimals), decimals).toString();
};

/**
 * Contracts an amount to its base token denomination.
 *
 * @param a - the amount
 * @param d - the number of decimals or a {@link Token}
 * @returns the amount in its base denomination
 */
export const contractAmount = (a: string, d: number | Token): string => {

    return emptyOrZero(a)
        ? empty(a)
            ? ''
            : '0'
        : trim(utils.formatUnits(a, (typeof d === 'number') ? d : d.decimals));
};

/**
 * Compares two amounts.
 *
 * @remarks
 * If a and b are contracted amounts, d needs to be provided. If d is not provided,
 * a and b are considered expanded BigNumber strings.
 *
 * @param a - amount a
 * @param b - amount b
 * @param d - the number of decimals or a {@link Token} (if a and b need to be expanded)
 * @returns `-1` if a is smaller than b, `1` if a is bigger than b, `0` if a and b are equal
 */
export const compareAmounts = (a: string, b: string, d?: number | Token): number => {

    const needsConversion = d !== undefined;

    const amount1 = needsConversion
        ? utils.parseUnits(emptyOrZero(a) ? '0' : a, (typeof d === 'number') ? d : d.decimals)
        : BigNumber.from(emptyOrZero(a) ? '0' : a);
    const amount2 = needsConversion
        ? utils.parseUnits(emptyOrZero(b) ? '0' : b, (typeof d === 'number') ? d : d.decimals)
        : BigNumber.from(emptyOrZero(b) ? '0' : b);

    return amount1.lt(amount2) ? -1 : amount1.gt(amount2) ? 1 : 0;
};

/**
 * Calculates a fraction of an amount.
 *
 * @param a - the amount
 * @param f - a fraction (0 to 1)
 * @param d - the number of decimals or a {@link Token}
 * @returns the specified fraction of the amount
 */
export const partialAmount = (a: string, f: number, d: number | Token): string => {

    const decimals = (typeof d === 'number') ? d : d.decimals;

    return fixed(a).mulUnsafe(fixed(f)).round(decimals).toString();
};

/**
 * Calculates the fraction of two amounts.
 *
 * @param a - amount a
 * @param b - amount b
 * @param d - the number of decimals or a {@link Token}
 * @returns the fraction of `amount a / amount b`
 */
export const fraction = (a: string, b: string, d: number | Token): number => {

    const decimals = (typeof d === 'number') ? d : d.decimals;

    return (emptyOrZero(a) || emptyOrZero(b))
        ? 0
        : fixed(a).divUnsafe(fixed(b)).round(decimals).toUnsafeFloat();
};

/**
 * Creates an `ethers.FixedNumber`.
 *
 * @remarks
 * This helper shortens the call to `FixedNumber.from(value, format)` and ensures
 * that the provided `value` doesn't have more decimals than the `format` allows.
 * `FixedNumber.from()` does not correct this automatically.
 *
 * @param n - the number to convert to `ethers.FixedNumber`
 * @param d - the number of decimals (max 18)
 * @returns the `ethers.FixedNumber`
 */
export const fixed = (n: string | number, d = 18): FixedNumber => {

    // ensure that tiny fractions will always be formatted as fixed point numbers, not scientific notation
    // we use the max amount of decimals for fixed point number formatting in JavaScript, which is 20
    const number = (typeof n === 'number') ? n.toFixed(20) : n;

    if (number.length > d) {

        const frac = number.split('.')[1] as string | undefined;

        if (frac && frac.length > d) {

            const frmt = format(frac.length);

            return FixedNumber.from(number, frmt).round(d).toFormat(format(d));
        }
    }

    return FixedNumber.from(number, format(d));
};

/**
 * Ensures a passed value is converted to a FixedNumber.
 *
 * @param value - the string or FixedNumber to convert
 */
export const ensureFixed = (value: string | number | FixedNumber, decimals?: number): FixedNumber => {

    return (value instanceof FixedNumber)
        ? value
        : fixed(typeof value === 'string' && emptyOrZero(value) ? '0' : value, decimals);
};

/**
 * Safely divide two numbers.
 *
 * @remarks
 * Returns 0 if the divisor is 0 to protect agains division-by-zero exceptions.
 */
export const divideSafe = (a: string | number | FixedNumber, b: string | number | FixedNumber, d?: number): FixedNumber => {

    a = ensureFixed(a, d);
    b = ensureFixed(b, d);

    return (a.isZero() || b.isZero())
        ? fixed('0', d)
        : a.divUnsafe(b);
};

/**
 * Return the minimum of two values
 */
export const min = (a: string | number | FixedNumber, b: string | number | FixedNumber, d = 18): FixedNumber => {

    a = a instanceof FixedNumber ? a : fixed(a, d);
    b = b instanceof FixedNumber ? b : fixed(b, d);

    return a.subUnsafe(b).isNegative() ? a : b;
};

/**
 * Return the maximum of two values
 */
export const max = (a: string | number | FixedNumber, b: string | number | FixedNumber, d = 18): FixedNumber => {

    a = a instanceof FixedNumber ? a : fixed(a, d);
    b = b instanceof FixedNumber ? b : fixed(b, d);

    return a.subUnsafe(b).isNegative() ? b : a;
};

/**
 * Trims trailing zeros from the fractional part of a number.
 *
 * @param n - the number to trim
 * @returns the trimmed number
 */
export const trim = (n: string): string => {

    // we need to use capture groups here, as Safari doesn't support lookbehinds in regular expressions
    // the first regexp removes trailing `0`s after a decimal point
    // the second regexp removes dangling decimal points
    return n.replace(/(\.\d*?)0*$/, '$1').replace(/\.$/, '');
};

/**
 * Adds trailing zeros to the fractional part of a number.
 *
 * @param n - the number to pad
 * @param d - the amount of decimals to pad
 * @returns
 */
export const pad = (n: string, d = 2): string => {

    const [int, frac] = n.split('.');
    const pad = Math.max(d - (frac?.length ?? 0), 0);

    return (pad > 0)
        ? `${ int }.${ frac || '' }${ new Array(pad).fill('0').join('') }`
        : n;
};

/**
 * Rounds a number to the specified number of decimals and trims or pads trailing zeros.
 *
 * @param n - the number to round
 * @param d - the number of decimals to round to
 * @param p - pad the result with trailing zeros to have a fixed precision (defaults to `false`)
 * @returns the rounded number
 */
export const round = (n: string | number, d = 2, p = false): string => {

    return p
        ? pad(fixed(n, d).toString(), d)
        : trim(fixed(n, d).toString());
};

/**
 * Calculates the annualization factor for effective rates based on a maturity.
 *
 * @param m - maturity
 * @param d - number of decimals for underlying (depends on token, default: `18`)
 * @returns the annualization factor
 */
export const annualize = (m: string, d = 18): FixedNumber => {

    return fixed(SECONDS_PER_YEAR)
        .divUnsafe(fixed(timeUntilMaturity(m)))
        .round(d)
        .toFormat(format(d));
};

/**
 * Calculates the de-annualization factor for annual rates based on a maturity.
 *
 * @param m - maturity
 * @param d - number of decimals for underlying (depends on token, default: `18`)
 * @returns the deannualization factor
 */
export const deannualize = (m: string, d = 18): FixedNumber => {

    return fixed(timeUntilMaturity(m))
        .divUnsafe(fixed(SECONDS_PER_YEAR))
        .round(d)
        .toFormat(format(d));
};

/**
 * Creates an `ethers.FixedNumber` format string.
 *
 * @remarks
 * https://docs.ethers.io/v5/api/utils/fixednumber/#FixedFormat
 *
 * @param d - the number of decimals
 * @returns the format string
 */
const format = (d: number): string => `fixed128x${ d }`;
