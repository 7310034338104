import { BugsnagConfig, GoogleAnalyticsConfig } from '@swivel-finance/consent';

/**
 * The required/supported environment variables
 */
export type PROCESS_ENVIRONMENT = {
    API_URL: string;
    SOCKET_URL: string;
    SOCKET_INTERVAL: number;
    DISCORD_URL: string;
    DOCS_URL: string;
    CODE_URL: string;
    ETHERSCAN_URL: string;
    TWEET_URL: string;
    SHARE_RATE_URL: string;
    CHAIN_ID: number;
    NETWORK_NAME: string;
    REDEEMED_THRESHOLD: string;
    PRICE_FEED_ADDRESS: Record<string, string>;
    FAUCET_ADDRESS: string;
    MARKETPLACE_ADDRESS: string;
    LENDER_ADDRESS: string;
    REDEEMER_ADDRESS: string;
    STRATEGY_ROUTER_ADDRESS: string;
    ETH_STRATEGY_ROUTER_ADDRESS: string;
    YIELDSPACE_SHARES_PROTOCOL: string;
    NODE_ENV: string;
    DEPLOYMENTS: Deployment[];
    DEPLOYMENT_ID: string;
    PREVIOUS_VERSION_IPT?: string[];
    PREVIOUS_VERSION_LP?: string[];
    DEBUG?: boolean;
    APP_VERSION?: string;
    BUGSNAG_ENABLED?: boolean;
    BUGSNAG_API_KEY?: string;
    ANALYTICS_ENABLED?: boolean;
    ANALYTICS_MEASUREMENT_ID?: string;
};

/**
 * Declare the process variable for browser environments.
 *
 * @remarks
 * We're not gonna use `process.env` anywhere in the code, except in this file
 * to populate the typed {@link ENV} constant. This population of values happens
 * in the build step through rollup, which will pull in the node environment and
 * replace the `process.env` references in this file.
 */
// eslint-disable-next-line no-var
declare var process: {
    env: PROCESS_ENVIRONMENT;
};

export interface Deployment {
    /**
     * The unique id of the deployment, e.g. 'mainnet'
     */
    id: string;
    /**
     * The url of the deployment, e.g. 'https://app.illuminate.finance'
     */
    url: string;
    /**
     * The displayed name of the deployment, e.g. 'Mainnet', 'Goerli', etc.
     */
    label: string;
    /**
     * The version of the deployment (this is set by the runtime and should not be configured via ENV)
     */
    version?: string;
    /**
     * A short, informative description about the deployment
     */
    description?: string;
}

/**
 * The typed environment.
 */
export interface Environment {
    apiUrl: string;
    socketUrl: string;
    socketInterval: number;
    discordUrl: string;
    docsUrl: string;
    codeUrl: string;
    etherscanUrl: string;
    tweetUrl: string;
    shareRateUrl: string;
    chainId: number;
    networkName: string;
    redeemedThreshold: string;
    priceFeedAddress: Record<string, string>;
    faucetAddress: string;
    marketplaceAddress: string;
    lenderAddress: string;
    redeemerAddress: string;
    strategyRouterAddress: string;
    ethStrategyRouterAddress: string;
    yieldSpaceSharesProtocol: string;
    deployments: Deployment[];
    deploymentId: string;
    previousVersionIPT?: string[];
    previousVersionLP?: string[];
    appVersion: string;
    appMode: 'development' | 'production';
    appEnvironment: 'development' | 'staging' | 'production';
    debug: boolean;
    bugsnag: BugsnagConfig;
    analytics: GoogleAnalyticsConfig;
}

/**
 * The populated environment.
 */
export const ENV: Environment = {
    // illuminate service urls
    apiUrl: process.env.API_URL,
    socketUrl: process.env.SOCKET_URL,
    socketInterval: process.env.SOCKET_INTERVAL,
    // static resource urls
    discordUrl: process.env.DISCORD_URL,
    docsUrl: process.env.DOCS_URL,
    codeUrl: process.env.CODE_URL,
    etherscanUrl: process.env.ETHERSCAN_URL,
    tweetUrl: process.env.TWEET_URL ?? '',
    shareRateUrl: process.env.SHARE_RATE_URL ?? '',
    // network settings
    chainId: process.env.CHAIN_ID,
    networkName: process.env.NETWORK_NAME,
    // the threshold balance under which positions are considered redeemed in USD
    redeemedThreshold: process.env.REDEEMED_THRESHOLD,
    // chainlink aggregator (price feed) settings
    priceFeedAddress: process.env.PRICE_FEED_ADDRESS,
    // faucet settings (goerli testnet only)
    faucetAddress: process.env.FAUCET_ADDRESS ?? '',
    // deployed illuminate contract addresses
    marketplaceAddress: process.env.MARKETPLACE_ADDRESS,
    lenderAddress: process.env.LENDER_ADDRESS,
    redeemerAddress: process.env.REDEEMER_ADDRESS,
    strategyRouterAddress: process.env.STRATEGY_ROUTER_ADDRESS,
    ethStrategyRouterAddress: process.env.ETH_STRATEGY_ROUTER_ADDRESS,
    // yieldspace settings
    yieldSpaceSharesProtocol: process.env.YIELDSPACE_SHARES_PROTOCOL ?? 'Yearn',
    // deployment options
    deployments: process.env.DEPLOYMENTS,
    deploymentId: process.env.DEPLOYMENT_ID ?? '',
    previousVersionIPT: process.env.PREVIOUS_VERSION_IPT ?? [],
    previousVersionLP: process.env.PREVIOUS_VERSION_LP ?? [],
    appVersion: process.env.APP_VERSION ?? '0.0.0',
    appMode: process.env.NODE_ENV === 'production' ? 'production' : 'development',
    // TODO: inlude other mainets in production (e.g. arbitrum mainnet, polygon, etc...)
    appEnvironment: process.env.CHAIN_ID === 1 ? 'production' : 'staging',
    debug: process.env.DEBUG ?? process.env.NODE_ENV !== 'production',
    // for third-party service configs we allow default values which disable the services
    bugsnag: {
        enabled: process.env.BUGSNAG_ENABLED ?? false,
        apiKey: process.env.BUGSNAG_API_KEY ?? '',
        appVersion: process.env.APP_VERSION ?? '0.0.0',
        // use `staging` releaseStage for testnet deploys
        // TODO: inlude other mainets in production (e.g. arbitrum mainnet, polygon, etc...)
        releaseStage: process.env.CHAIN_ID === 1 ? 'production' : 'staging',
    },
    analytics: {
        enabled: process.env.ANALYTICS_ENABLED ?? false,
        measurementId: process.env.ANALYTICS_MEASUREMENT_ID ?? '',
    },
};
