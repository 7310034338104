import { utils } from 'ethers';
import { html, nothing } from 'lit';
import { round } from '../../core/amount';
import { Token } from '../../types';

export const BALANCE_DECIMALS = 4;
export const BALANCE_PADDING = true;
export const PRICE_DECIMALS = 4;
export const PRICE_PADDING = true;
export const RATE_DECIMALS = 3;
export const RATE_PADDING = true;
export const FALLBACK = '--';

/**
 * This file contains commonly used templates for rendering:
 * - amounts
 * - amounts with units
 * - token balances
 * - token symbols and names
 *
 * These templates will create consitent representation of amount related outputs,
 * take care of rounding (defaults to `2` decimals) and formatting token balances
 * to their natural representation in the token denomination (18 decimals or others).
 */

/**
 * Creates a template for a unit string.
 *
 * @param u - the unit to render, e.g. '%'
 * @returns a `lit.TemplateResult` for the unit
 */
export const unit = (u?: string) => u
    ? html`<span class="unit">${ u }</span>`
    : nothing;

/**
 * Creates a template for an amount with an optional unit.
 *
 * @param a - the amount to render
 * @param u - an optional unit to render
 * @param d - an optional number of decimals to round the amount to (defaults to `2`)
 * @param p - pad the result with trailing zeros to have a fixed precision (defaults to `false`)
 * @param f - an optional fallback value if the amount is empty (defaults to `--`)
 * @returns a `lit.TemplateResult` for the amount
 */
export const amount = (a?: string, u?: string, d = 2, p = false, f = FALLBACK) => a || f
    ? html`<span class="amount"><span class="value">${ a ? round(a, d, p) : f }</span>${ unit(u) }</span>`
    : nothing;

/**
 * Creates a template for an amount label with an optional unit.
 *
 * @param l - the label to render
 * @param u - an optional unit to render
 * @returns a `lit.TemplateResult` for the amount label
 */
export const amountLabel = (l: string, u?: string) =>
    html`<span class="amount"><span class="label">${ l }</span>${ unit(u) }</span>`;

/**
 * Creates a template for a balance, formatted according to the provided token.
 *
 * @param a - the amount (balance) to render
 * @param t - a token belonging to the amount or the number of decimals
 * @param d - an optional number of decimals to round the amount to (defaults to `2`)
 * @param p - pad the result with trailing zeros to have a fixed precision (defaults to `false`)
 * @param f - an optional fallback value if the amount is empty (defaults to `--`)
 * @returns a `lit.TemplateResult` for the balance formatted according to the token
 */
export const balance = (a?: string, t?: Token | number, d = BALANCE_DECIMALS, p = BALANCE_PADDING, f = FALLBACK) =>
    amount(
        a && t ? utils.formatUnits(a, (typeof t === 'number') ? t : t.decimals) : '',
        undefined,
        d,
        p,
        f,
    );

/**
 * Creates a template for a price.
 *
 * @remarks
 * A price is already relative to its token, so we don't need any conversions here.
 *
 * @param a - the amount (price) to render
 * @param d - an optional number of decimals to round the amount to (defaults to `2`)
 * @param p - pad the result with trailing zeros to have a fixed precision (defaults to `false`)
 * @param f - an optional fallback value if the amount is empty (defaults to `--`)
 * @returns a `lit.TemplateResult` for the price formatted according to the token
 */
export const price = (a?: string, d = PRICE_DECIMALS, p = PRICE_PADDING, f = FALLBACK) =>
    amount(a, undefined, d, p, f);

/**
 * Creates a template for a token's symbol.
 *
 * @param t - the token to render
 * @returns a `lit.TemplateResult` for the token symbol
 */
export const tokenSymbol = (t?: Token) => t
    ? html`<span class="unit token-symbol">${ t.symbol }</span>`
    : nothing;

/**
 * Creates a template for a token's name.
 *
 * @param t - the token to render
 * @returns a `lit.TemplateResult` for the token name
 */
export const tokenName = (t?: Token) => t
    ? html`<span class="token-name">${ t.name }</span>`
    : nothing;

/**
 * Creates a template for a token balance, formatted according to the token and including the token's symbol.
 *
 * @param a - the amount (or balance) to render
 * @param t - a token belonging to the amount
 * @param d - an optional number of decimals to round the amount to (defaults to `2`)
 * @param p - pad the result with trailing zeros to have a fixed precision (defaults to `true`)
 * @param f - an optional fallback value if the amount is empty (defaults to `--`)
 * @returns a `lit.TemplateResult` for the token balance including the token symbol
 */
export const tokenBalance = (a?: string, t?: Token, d = BALANCE_DECIMALS, p = BALANCE_PADDING, f = FALLBACK) =>
    html`<span class="token-balance">${ balance(a, t, d, p, f) }${ tokenSymbol(t) }</span>`;

/**
* Creates a template for a token price, formatted according to the token and including the token's symbol.
*
* @param a - the price to render
* @param t - a token belonging to the price
* @param d - an optional number of decimals to round the amount to (defaults to `2`)
* @param p - pad the result with trailing zeros to have a fixed precision (defaults to `true`)
* @param f - an optional fallback value if the amount is empty (defaults to `--`)
* @returns a `lit.TemplateResult` for the token price including the token symbol
*/
export const tokenPrice = (a?: string, t?: Token, d = PRICE_DECIMALS, p = PRICE_PADDING, f = FALLBACK) =>
    html`<span class="token-balance">${ price(a, d, p, f) }${ tokenSymbol(t) }</span>`;

/**
 * Creates a template for a token's image.
 *
 * @param t - the token to render
 * @returns a `lit.TemplateResult` for the token image
 */
export const tokenImage = (t?: Token) => t
    ? html`<span class="token-image"><ill-token-symbol class="image" name=${ t.image }></ill-token-symbol></span>`
    : nothing;

/**
 * Creates a template for a market's rate.
 *
 * @param r - the rate in decimal notation
 * @param u - an optional unit, a simple `%` will be rendered if omitted
 * @param a - show the approximate sign `~` if true
 * @param d - optional number of decimals for rounding (defaults to {@link RATE_DECIMALS})
 * @param p - optional padding of the rounded rate (defaults to {@link RATE_PADDING})
 */
export const rate = (r?: string | number, u?: string, a = true, d = RATE_DECIMALS, p = RATE_PADDING, f = FALLBACK) => r || f
    ? html`<span class="rate">
        ${ a && r !== undefined ? '~' : nothing }
        <span class="value">${ r !== undefined
            ? round((typeof r === 'string' ? parseFloat(r) : r) * 100, d, p)
            : f
        }</span>
        ${ u ? unit(u) : '%' }
    </span>`
    : nothing;
