import { MarketLike } from '../../types';
import { DEFAULT_LOCALE, SECONDS_PER_DAY } from '../constants/constants';
import { isETHToken } from '../constants/tokens';
import { serviceLocator } from '../services/service-locator';
import { TIME_SERVICE } from '../services/time';

/**
 * Creates a string identifying a market
 *
 * @remarks
 * Concatenates the market's `underlying` and `maturity` to create a unique identifier.
 *
 * @param m - market
 */
export const marketKey = (m: MarketLike): string => {

    return `${ m.underlying }-${ m.maturity }`;
};

/**
 * Returns the `underlying` and `maturity` from a market key
 *
 * @param k - market key
 */
export const fromMarketKey = (k: string): MarketLike => {

    const [underlying, maturity] = k.split('-');

    return { underlying, maturity };
};

/**
 * Compares two markets
 *
 * @param m1 - first market
 * @param m2 - second market
 * @returns `true` if the markets have the same `underlying` and `maturity`, `false` otherwise
 */
export const compareMarkets = (m1?: MarketLike, m2?: MarketLike): boolean => {

    return (m1?.underlying === m2?.underlying) && (m1?.maturity === m2?.maturity);
};

/**
 * Locale dependent date formatters for maturity dates.
 */
export const MATURITY_DATE_FORMAT = {
    LONG: new Intl.DateTimeFormat(DEFAULT_LOCALE, { dateStyle: 'long' }),
    MEDIUM: new Intl.DateTimeFormat(DEFAULT_LOCALE, { dateStyle: 'medium' }),
    SHORT: new Intl.DateTimeFormat(DEFAULT_LOCALE, { year: 'numeric', month: '2-digit', day: '2-digit' }),
};

/**
 * Formats a maturity timestamp as human-readable date.
 *
 * @param m - maturity in seconds
 * @param f - optional `Intl.DateTimeFormat`
 */
export const maturityDate = (m: string, f = MATURITY_DATE_FORMAT.LONG): string => {

    const date = new Date(parseInt(m) * 1000);

    return f.format(date);
};

/**
 * Gets the number of days until maturity.
 *
 * @param m - maturity
 */
export const maturityDays = (m: string): number => {

    return Math.round(timeUntilMaturity(m) / SECONDS_PER_DAY);
};

/**
 * Gets the number of seconds until maturity.
 *
 * @param m - maturity
 */
export const timeUntilMaturity = (m: string): number => {

    const timeService = serviceLocator.get(TIME_SERVICE);
    const now = timeService.time() / 1000;
    const maturity = parseInt(m);

    return Math.round(maturity - now);
};

/**
 * Checks if a market is matured
 *
 * @param m - market
 */
export const matured = (m: { maturity: string; }): boolean => {

    return timeUntilMaturity(m.maturity) <= 0;
};

/**
 * Checks if a market is an ETH-based market
 *
 * @remarks
 * An ETH-based market is a market with an underlying token that is an ETH-based token.
 * (Currently this applies to markets with WETH as underlying.)
 *
 * @param m - market
 */
export const isETHMarket = (m: { underlying: string; }): boolean => {

    return isETHToken(m.underlying);
};
