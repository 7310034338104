import { delay } from '@swivel-finance/ui/utils/async';
import { html, nothing, PropertyValues } from 'lit';
import { customElement } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { CurrencyElement } from './currency';

const NUMERALS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

const numeralOut = [
    { transform: 'rotate3d(1, 0, 0, 0deg) translateZ(calc(var(--height) / 2)', opacity: 1 },
    { transform: 'rotate3d(1, 0, 0, -90deg) translateZ(calc(var(--height) / 2)', opacity: 0.5 },
];

const numeralOutUp = [
    { transform: 'rotate3d(1, 0, 0, 0deg) translateZ(calc(var(--height) / 2)', opacity: 1 },
    { transform: 'rotate3d(1, 0, 0, 90deg) translateZ(calc(var(--height) / 2)', opacity: 0.5 },
];

const numeralIn = [
    { transform: 'rotate3d(1, 0, 0, 90deg) translateZ(calc(var(--height) / 2)', opacity: 0.5 },
    { transform: 'rotate3d(1, 0, 0, 0deg) translateZ(calc(var(--height) / 2)', opacity: 1 },
];

const numeralInUp = [
    { transform: 'rotate3d(1, 0, 0, -90deg) translateZ(calc(var(--height) / 2)', opacity: 0.5 },
    { transform: 'rotate3d(1, 0, 0, 0deg) translateZ(calc(var(--height) / 2)', opacity: 1 },
];

const numeralTiming: KeyframeAnimationOptions & { duration: number; } = {
    duration: 250,
    iterations: 1,
    easing: 'linear',
};

const arrayify = (value: number, decimals = 2) => {

    return Math.round(value * 10 ** decimals).toString().split('').map(digit => parseInt(digit));
};

const next = (value: number) => (value + 1) % 10;

const prev = (value: number) => (value + 9) % 10;

const currencyTemplate = (currency: string) => {

    return html`<div class="currency">${ currency }</div>`;
};

const separatorTemplate = (separator: string, type: string) => {

    return html`<div class="separator separator-${ type }"><span class="content">${ separator }</span></div>`;
};

const numeralTemplate = (numeral: number, current = false) => {

    return html`<div class="${ classMap({ numeral: true, current }) }">${ numeral }</div>`;
};

const decimalTemplate = () => {

    return html`
    <div class="decimal">
        ${ NUMERALS.map((numeral) => numeralTemplate(numeral, numeral === 0)) }
    </div>
    `;
};

const template = function (this: CurrencyAnimationElement) {

    return html`${ this.parts.map(({ type, value }) => {

        switch (type) {

            case 'currency':
                return currencyTemplate(value);

            case 'decimal':
            case 'group':
                return separatorTemplate(value, type);

            case 'integer':
            case 'fraction':
                return value.split('').map(() => decimalTemplate());

            default:
                return nothing;
        }
    }) }`;
};

@customElement('ill-currency-animation')
export class CurrencyAnimationElement extends CurrencyElement {

    protected animationQueue: Array<[number, number]> = [];

    protected animationPromise?: Promise<void>;

    protected updated (changes: PropertyValues<CurrencyAnimationElement>): void {

        if (changes.has('value')) {

            this.queueAnimation(changes.get('value'), this.value);
        }
    }

    protected createRenderRoot (): Element | ShadowRoot {

        return this;
    }

    protected render (): unknown {

        return template.apply(this);
    }

    protected queueAnimation (from = 0, to = 0): void {

        from = this.round(from);
        to = this.round(to);

        if (from === to) return;

        this.animationQueue.push([from, to]);

        this.animationPromise ??= this.playAnimation().finally(() => { this.animationPromise = undefined; });
    }

    protected async playAnimation (): Promise<void> {

        const [from, to] = this.animationQueue.shift() ?? [0, 0];

        if (from === to) return;

        await this.animateValue(from, to);

        if (this.animationQueue.length > 0) {

            await this.playAnimation();
        }
    }

    protected async animateValue (from: number, to: number) {

        const direction = from < to ? 1 : -1;
        const decimals = Array.from(this.renderRoot.querySelectorAll('.decimal'));
        const current = arrayify(from, this.decimals);
        const target = arrayify(to, this.decimals);

        while (current.length < target.length) {

            current.unshift(0);
        }

        while (target.length < current.length) {

            target.unshift(0);
        }

        const last = target.length - 1;

        const time = (index: number) => {

            const random = Math.max(
                Math.min(
                    Math.random() * numeralTiming.duration,
                    numeralTiming.duration * 0.7,
                ),
                numeralTiming.duration * 0.3,
            );

            return index * numeralTiming.duration + random;
        };

        await Promise.all(
            decimals.reverse().map((decimal, index) => delay(
                () => this.animateDecimal(current[last - index], target[last - index], direction, decimal),
                time(index),
            ).done),
        );
    }

    protected async animateDecimal (from: number, to: number, direction: number, decimal: Element) {

        while (from !== to) {

            const step = (direction > 0) ? next(from) : prev(from);

            await this.animateNumeral(from, step, direction, decimal);

            from = step;
        }
    }

    protected async animateNumeral (from: number, to: number, direction: number, decimal: Element) {

        let resolveDone: (value?: unknown) => void;

        const done = new Promise((resolve) => {
            resolveDone = resolve;
        });

        const numerals = Array.from(decimal.querySelectorAll('.numeral'));

        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        requestAnimationFrame(async () => {

            if (from === to) {
                resolveDone();
                return;
            }

            const prev = numerals[from].classList.contains('current')
                ? numerals[from]
                : numerals.find(predicate => predicate.classList.contains('current'));
            const next = numerals[to];

            prev?.classList.remove('current');
            next.classList.add('current');

            await Promise.all([
                prev?.animate((direction > 0) ? numeralOut : numeralOutUp, numeralTiming).finished,
                next?.animate((direction > 0) ? numeralIn : numeralInUp, numeralTiming).finished,
            ]);

            resolveDone();
        });

        return done;
    }
}
