import { createMachine, DoneInvokeEvent, EventFrom, MachineOptions, TransitionsConfig } from 'xstate';
import { createModel } from 'xstate/lib/model';
import { fetchAccountBalance, fetchTokenBalance } from '../core/balance';
import { ETH, isETHToken } from '../core/constants';
import { ERROR_SERVICE, LOG_SERVICE, PREFERENCES_SERVICE, serviceLocator } from '../core/services';
import { Connection, ConnectionOptions, getEthereumProvider, getEthereumProviderIdentifier, getEthereumProviders, isBrowserConnection, WALLET_SERVICE } from '../core/services/wallet';
import { Balance, Token } from '../types';
import { AddContext, isDoneInvokeEvent } from './helpers';

const logger = serviceLocator.get(LOG_SERVICE).group('account state');
const errors = serviceLocator.get(ERROR_SERVICE);
const wallet = serviceLocator.get(WALLET_SERVICE);
const preferences = serviceLocator.get(PREFERENCES_SERVICE);

/**
 * This type is used internally for fetching the base state.
 *
 * @remarks
 * This type contains an array of token balances.
 */
type FetchResult = {
    balances: Balance[];
};

// ---------------
// machine context
// ---------------

export type Context = Partial<Omit<Connection, 'account'>> & {
    balances: Map<string, Balance>;
    account?: string;
    ens?: string;
    error?: string;
};

const initialContext: Context = {
    provider: undefined,
    signer: undefined,
    network: undefined,
    account: undefined,
    ens: undefined,
    balances: new Map(),
    error: undefined,
};

// ---------------
// machine state
// ---------------

export const enum STATES {
    INITIAL = 'initial',
    CONNECTING = 'connecting',
    CONNECTED = 'connected',
    DISCONNECTING = 'disconnecting',
    DISCONNECTED = 'disconnected',
    FETCHING = 'fetching',
    ERROR = 'error',
}

export type State =
    {
        value: STATES.INITIAL | STATES.DISCONNECTED;
        context: Context;
    }
    | {
        value: STATES.DISCONNECTING;
        context: Context;
    }
    | {
        value: STATES.CONNECTING;
        context: Context;
    }
    | {
        value: STATES.FETCHING;
        context: Context & AddContext<Context, 'account' | 'ens' | 'network' | 'provider' | 'signer'>;
    }
    | {
        value: STATES.CONNECTED;
        context: Context & AddContext<Context, 'account' | 'ens' | 'network' | 'provider' | 'signer'>;
    }
    | {
        value: STATES.ERROR;
        context: Context & AddContext<Context, 'error'>;
    };

// ----------------------
// machine model & events
// ----------------------

export const enum EVENTS {
    CONNECT = 'ACCOUNT.CONNECT',
    CONNECTED = 'ACCOUNT.CONNECTED',
    DISCONNECT = 'ACCOUNT.DISCONNECT',
    DISCONNECTED = 'ACCOUNT.DISCONNECTED',
    FETCH = 'ACCOUNT.FETCH',
    // internal events from services
    CONNECT_SUCCESS = 'done.invoke.connectAccount',
    CONNECT_FAILURE = 'error.platform.connectAccount',
    DISCONNECT_SUCCESS = 'done.invoke.disconnectAccount',
    DISCONNECT_FAILURE = 'error.platform.disconnectAccount',
    FETCH_SUCCESS = 'done.invoke.fetch',
    FETCH_FAILURE = 'error.platform.fetch',
}

export const model = createModel(
    initialContext,
    {
        events: {
            [EVENTS.CONNECT]: (payload: Partial<ConnectionOptions>) => ({ payload }),
            [EVENTS.CONNECTED]: (payload: Connection) => ({ payload }),
            [EVENTS.DISCONNECT]: () => ({}),
            [EVENTS.DISCONNECTED]: () => ({}),
            [EVENTS.FETCH]: (payload: Token[]) => ({ payload }),
        },
    },
);

export type Event = EventFrom<typeof model>;

// ---------------
// machine actions
// ---------------

export const enum ACTIONS {
    CONNECT_SUCCESS = 'CONNECT_SUCCESS',
    CONNECT_FAILURE = 'CONNECT_FAILURE',
    DISCONNECT = 'DISCONNECT',
    DISCONNECT_SUCCESS = 'DISCONNECT_SUCCESS',
    DISCONNECT_FAILURE = 'DISCONNECT_FAILURE',
    FETCH_SUCCESS = 'FETCH_SUCCESS',
    FETCH_FAILURE = 'FETCH_FAILURE',
    STORE_TOKENS = 'STORE_TOKENS',
}

const actions = {
    [ACTIONS.CONNECT_SUCCESS]: model.assign(
        (context, event) => {

            const data = isDoneInvokeEvent<Connection>(event) ? event.data : event.payload;

            if (isBrowserConnection(data)) {

                const walletIdentifier = getEthereumProviderIdentifier(data.wallet);

                if (walletIdentifier) {

                    preferences.set('walletIdentifier', walletIdentifier);
                }
            }

            preferences.set('autoConnect', true);

            return {
                ...context,
                account: data.account.address,
                ens: data.account.ensAddress,
                network: data.network,
                provider: data.provider,
                signer: data.signer,
                error: undefined,
            };
        },
        EVENTS.CONNECTED,
    ),
    [ACTIONS.CONNECT_FAILURE]: model.assign(
        (context, event) => {

            preferences.delete('walletIdentifier');
            preferences.delete('autoConnect');

            return {
                ...model.initialContext,
                error: (event as DoneInvokeEvent<Error>).data.message,
            };
        },
    ),
    [ACTIONS.DISCONNECT]: model.assign(
        (context) => {

            preferences.delete('walletIdentifier');
            preferences.delete('autoConnect');

            return context;
        },
    ),
    [ACTIONS.DISCONNECT_SUCCESS]: model.assign(
        () => ({
            ...model.initialContext,
        }),
    ),
    [ACTIONS.DISCONNECT_FAILURE]: model.assign(
        (context, event) => ({
            error: (event as DoneInvokeEvent<Error>).data.message,
        }),
    ),
    [ACTIONS.FETCH_SUCCESS]: model.assign(
        (context, event) => {

            const { balances } = (event as DoneInvokeEvent<FetchResult>).data;

            balances.forEach(balance => context.balances.set(balance.address, balance));

            logger.log(`action: ${ ACTIONS.FETCH_SUCCESS }`, { balances });

            return {
                ...context,
                error: undefined,
            };
        },
    ),
    [ACTIONS.FETCH_FAILURE]: model.assign(
        (context, event) => {

            context.balances.forEach(balance => balance.balance = '');

            return {
                ...context,
                error: (event as DoneInvokeEvent<Error>).data.message,
            };
        },
    ),
    [ACTIONS.STORE_TOKENS]: model.assign(
        (context, event) => {

            const tokens = event.payload;
            const balances = context.balances;

            tokens.forEach(token => {

                if (!balances.has(token.address)) {

                    balances.set(token.address, { ...token, balance: '' });
                }
            });

            if (!balances.has(ETH.address)) {

                balances.set(ETH.address, { ...ETH, balance: '' });
            }

            return {
                ...context,
                balances,
            };
        },
        EVENTS.FETCH,
    ),
};

// -------------------
// machine transitions
// -------------------

const enum TRANSITIONS {
    CONNECT = 'CONNECT',
    CONNECTED = 'CONNECTED',
    DISCONNECT = 'DISCONNECT',
    DISCONNECTED = 'DISCONNECTED',
    FETCH = 'FETCH',
}

const transitions: Record<TRANSITIONS, TransitionsConfig<Context, Event>> = {
    [TRANSITIONS.CONNECT]: {
        [EVENTS.CONNECT]: {
            target: STATES.CONNECTING,
        },
    },
    [TRANSITIONS.CONNECTED]: {
        [EVENTS.CONNECTED]: {
            actions: ACTIONS.CONNECT_SUCCESS,
            target: STATES.CONNECTED,
        },
    },
    [TRANSITIONS.DISCONNECT]: {
        [EVENTS.DISCONNECT]: {
            actions: ACTIONS.DISCONNECT,
            target: STATES.DISCONNECTING,
        },
    },
    [TRANSITIONS.DISCONNECTED]: {
        [EVENTS.DISCONNECTED]: {
            actions: ACTIONS.DISCONNECT_SUCCESS,
            target: STATES.DISCONNECTED,
        },
    },
    [TRANSITIONS.FETCH]: {
        [EVENTS.FETCH]: {
            actions: ACTIONS.STORE_TOKENS,
            target: STATES.FETCHING,
        },
    },
};

// ---------------
// machine config
// ---------------

export const options: Partial<MachineOptions<Context, Event>> = {
    services: {
        connectAccount: async (context, event) => {

            let walletIdentifier = (event.type === EVENTS.CONNECT)
                ? event.payload.walletIdentifier
                : undefined;

            // if we're auto-connecting, we try to use the stored identifier
            if (!walletIdentifier) {

                // the `walletIdentifier` is not gonna exactly match any wallet in the detected
                // providers as its `uuid` is re-generated on each page load
                // we use it fuzzy-match a provider and recreate the identifier to match exactly
                walletIdentifier = preferences.get('walletIdentifier') ?? undefined;

                // get the available browser wallet providers
                const providers = await getEthereumProviders();

                // get the last connected browser wallet provider by fuzzy-matching the stored identifier
                // this also makes sure we don't accidentally connect to a malicious wallet
                const provider = getEthereumProvider(providers, walletIdentifier);

                // recreate the identifier to match the provider exactly
                walletIdentifier = getEthereumProviderIdentifier(provider);
            }

            // connect the wallet service
            return await wallet.connect({ walletIdentifier });
        },
        disconnectAccount: () => wallet.disconnect(),
        fetch: async (context): Promise<FetchResult> => {

            try {

                const balances = await Promise.all(
                    [...context.balances.values()]
                        .map(token => (token.address === ETH.address)
                            ? fetchAccountBalance()
                            : fetchTokenBalance(token)),
                );

                // there's a lot of places in the codebase, where account balances are accessed
                // via their token address, and we'd have to create lots of conditionals throughout
                // to handle ETH-based tokens (e.g. WETH) differently
                // so instead, we set the balance for all ETH-based tokens to the ETH balance
                // NB: this assumes that we always want the ETH balance for ETH-based tokens
                const ethBalance = balances.find(balance => balance.address === ETH.address)!;

                // set ETH balance for all ETH-based tokens
                balances.forEach(balance => {

                    balance.balance = isETHToken(balance)
                        ? ethBalance.balance
                        : balance.balance;
                });

                return { balances };

            } catch (error) {

                throw errors.isProcessed(error) ? error : errors.process(error);
            }
        },
    },
    guards: {
        // `autoConnect` will be false if user manually disconnected
        autoConnect: () => preferences.get('autoConnect') === true && preferences.get('walletIdentifier') !== undefined,
    },
    actions,
};

// ---------------
// machine
// ---------------

export const machine = createMachine<Context, Event, State>(
    {
        context: model.initialContext,
        initial: STATES.INITIAL,
        states: {
            [STATES.INITIAL]: {
                always: [
                    {
                        target: STATES.CONNECTING,
                        cond: 'autoConnect',
                    },
                    {
                        target: STATES.DISCONNECTED,
                    },
                ],
            },
            [STATES.DISCONNECTED]: {
                on: {
                    ...transitions[TRANSITIONS.CONNECT],
                    ...transitions[TRANSITIONS.CONNECTED],
                },
            },
            [STATES.DISCONNECTING]: {
                invoke: {
                    id: 'disconnectAccount',
                    src: 'disconnectAccount',
                    onDone: {
                        actions: ACTIONS.DISCONNECT_SUCCESS,
                        target: STATES.DISCONNECTED,
                    },
                    onError: {
                        actions: ACTIONS.DISCONNECT_FAILURE,
                        target: STATES.ERROR,
                    },
                },
            },
            [STATES.CONNECTING]: {
                invoke: {
                    id: 'connectAccount',
                    src: 'connectAccount',
                    onDone: {
                        actions: ACTIONS.CONNECT_SUCCESS,
                        target: STATES.CONNECTED,
                    },
                    onError: {
                        actions: ACTIONS.CONNECT_FAILURE,
                        target: STATES.ERROR,
                    },
                },
            },
            [STATES.CONNECTED]: {
                on: {
                    ...transitions[TRANSITIONS.DISCONNECT],
                    ...transitions[TRANSITIONS.DISCONNECTED],
                    ...transitions[TRANSITIONS.FETCH],
                },
            },
            [STATES.FETCHING]: {
                invoke: {
                    id: 'fetch',
                    src: 'fetch',
                    onDone: {
                        actions: ACTIONS.FETCH_SUCCESS,
                        target: STATES.CONNECTED,
                    },
                    onError: {
                        actions: ACTIONS.FETCH_FAILURE,
                        target: STATES.CONNECTED,
                    },
                },
            },
            [STATES.ERROR]: {
                on: {
                    ...transitions[TRANSITIONS.CONNECT],
                    ...transitions[TRANSITIONS.CONNECTED],
                    ...transitions[TRANSITIONS.DISCONNECT],
                    ...transitions[TRANSITIONS.DISCONNECTED],
                },
            },
        },
    },
    options,
);
