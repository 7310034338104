import { Market, Token } from '../../types';
import { serviceLocator } from '../services';
import { API_SERVICE } from '../services/api';
import { TOKEN_SERVICE } from '../services/token';
import { Connection } from '../services/wallet';
import { marketKey } from './helpers';

/**
 * An interface for the result of a {@link fetchMarketsWithTokens} call.
 *
 * @remarks
 * Markets are keyed by their market key (see {@link marketKey} for details),
 * tokens are keyed by their token address.
 */
export interface MarketsWithTokens {
    markets: Map<string, Market>;
    tokens: Map<string, Token>;
}

/**
 * Fetch markets and their related token info.
 *
 * @remarks
 * This method fetches markets via the API and their token information via the chain.
 * It then combines the information into {@link Market} objects and keys markets by
 * market key and tokens by token address.
 *
 * @param connection - optional {@link EthereumConnection}
 * @returns a {@link MarketsWithTokens}
 */
export const fetchMarketsWithTokens = async (connection?: Connection): Promise<MarketsWithTokens> => {

    const api = serviceLocator.get(API_SERVICE);
    const token = serviceLocator.get(TOKEN_SERVICE);

    const tokenMap = new Map<string, Token>();
    const marketMap = new Map<string, Market>();

    // fetch market pairs from api
    const markets = await api.getMarkets();

    // fetch token for each unique underlying address from chain
    const tokens = await Promise.all(
        [...new Set(markets.map(market => market.underlying))]
            .map(address => token.fetch(address, connection)),
    );

    // populate the token map, keyed by the token address
    tokens.forEach(token => tokenMap.set(token.address, token));

    // populate the market map, keyed by the market key
    markets.forEach(market => marketMap.set(marketKey(market), {
        ...market,
        // TODO: API returns 'created' timestamps as number, while we usually handle them as strings (e.g. maturity)
        created: market.created.toString(),
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        token: tokenMap.get(market.underlying)!,
    }));

    return {
        markets: marketMap,
        tokens: tokenMap,
    };
};
